import * as THREE from 'three';

export const lightmapVertexShader = `

varying vec2 vUv;
varying vec2 vUv2;

void main() {
    vUv = uv;
    vUv2 = uv1; // lightmap UVs
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;

export const lightmapFragmentShader = `
uniform sampler2D map; // base texture
uniform sampler2D lightmap; // lightmap texture
uniform float ambientLightIntensity; // new uniform for ambient light intensity

varying vec2 vUv;
varying vec2 vUv2;

void main() {
    // Applying gamma correction to the textures
    vec4 baseColor = pow(texture2D(map, vUv), vec4(0.9));
    vec4 lightmapColor = pow(texture2D(lightmap, vUv2), vec4(2.2));

    // Calculating light intensity with an ambient term
    float light = dot(lightmapColor.rgb, vec3(0.2126, 0.7152, 0.0722)) + ambientLightIntensity;

    // Applying light to the base color
    vec3 mappedColor = baseColor.rgb * light;

    // Applying inverse gamma correction
    mappedColor = pow(mappedColor, vec3(1.0 / 2.2));

    gl_FragColor = vec4(mappedColor, baseColor.a);
}

`;

export const lightmapProperties  = {
    transparent: false,
    blending: THREE.NormalBlending
};

export const lightmapUniforms  = {
    map: { value: null }, 
    lightmap: { value: null }
};