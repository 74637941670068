import { setupEventListener, hideAllUIElements, toggleDropdown } from '../utils/uiUtils.js';
import { micAccess, setDisplayingPopup } from "../core/sharedState.js";
import { systemManager } from "../ECS-utils/managerECS.js";
import { isMultiplayerSession } from '../multiplayer/sessionManager.js';

let settingsPopup = document.getElementById('settings-popup-window');
export let micToggleButton = document.getElementById('microphone-access');

export function showSettingsPopup() {
    setDisplayingPopup(true, true);
    settingsPopup.style.display = 'block';
    document.getElementById('backdrop').style.display = 'block';
}

export function initSettingsPopup() {
    setupEventListener('settings-btn', (e) => {
        e.stopPropagation();
        hideAllUIElements(true);
        showSettingsPopup();
    });

    setupEventListener('volume', (e) => {
        e.stopPropagation();
        let volume = e.target.value / 100; // Convert to a scale of 0 to 1
        systemManager.emitEvent('adjustVolume', volume);
    }, 'input');

    // Microphone access toggle
    setupEventListener('microphone-access', (e) => {
        e.stopPropagation();
        if(isMultiplayerSession && micAccess){
            let isMicrophoneEnabled = e.target.checked;
            systemManager.emitEvent('toggleMicrophone', isMicrophoneEnabled);
        }
        else{
            micToggleButton.checked = false;
            alert("Microphone access is only available in multiplayer mode. Please note that microphone access might have been declined.");
        }
    }, 'change');
}
