import * as THREE from 'three';
import { firebaseConfig, clientConfig } from '../config/clientConfig';

// Camera constants
export const CAMERA_FOV = 75;
export const CAMERA_NEAR = 0.1;
export const CAMERA_FAR = 1000;

//Annotation constants
export const ANNOTATION_DISTANCE_THRESHOLD = 1.5;

//Audio constants
export const SPEAKING_THRESHOLD = 100;

// World constants
export const WORLD_SIZE = 100;
export const GRAVITY = { x: 0.0, y: -9.81, z: 0.0 }

// Colors
export const DEFAULT_OBJECT_COLOR = 0xFFFFFF;
export const HIGHLIGHT_COLOR = 0xADD8E6;

// Firebase configuration
export const FIREBASE_CONFIG = firebaseConfig;

// Server constants
export const movementDataFrequency = clientConfig.movementDataFrequency ? clientConfig.movementDataFrequency : 200

// Bounding box constants
export const PLAYER_BOUNDING_BOX = new THREE.Box3();

// Player constants
export const PLAYER_HEIGHT = 1.55;
export const PLAYER_SIZE = new THREE.Vector3(0.3, PLAYER_HEIGHT, 0.3);
export const PLAYER_SPEED = 4;
export const PLAYER_JUMP_STRENGTH = 5;

//Vector constants
export const upVector = new THREE.Vector3(0, 1, 0);

//Raycaster constants
export const raycaster = new THREE.Raycaster();
export const rayLengthForObjectsInteraction = 4;
raycaster.firstHitOnly = true;
raycaster.far = rayLengthForObjectsInteraction;
raycaster.layers.set(0);

//Mouse constants
export const mouse = new THREE.Vector2(0, 0); // Center of the screen

//Mobile controls constants
export const useMobileCursor = true;

// VR text character sheet includes a comprehensive set of characters to accommodate
// various languages and special symbols. This ensures that text sanitization
// and formatting processes can accurately retain intended characters,
// supporting a wide range of internationalization requirements.
export const characterSheet = [
    'a', 'z', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'q', 's', 'd', 'f', 'g', 
    'h', 'j', 'k', 'l', 'm', 'w', 'x', 'c', 'v', 'b', 'n', 'A', 'Z', 'E', 'R', 
    'T', 'Y', 'U', 'I', 'O', 'P', 'Q', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 
    'M', 'W', 'X', 'C', 'V', 'B', 'N', 'é', 'É', 'à', 'À', 'è', 'È', 'ù', 'Ù', 
    'ë', 'Ë', 'ü', 'Ü', 'ï', 'Ï', 'â', 'ê', 'î', 'ô', 'û', 'Â', 'Ê', 'Î', 'Ô', 
    'Û', 'í', 'Í', 'á', 'Á', 'ó', 'Ó', 'ú', 'Ú', 'ñ', 'Ñ', 'ł', 'Ł', 'ç', 'Ç', 
    'ý', 'Ý', 'č', 'Č', 'š', 'Š', 'æ', 'Æ', 'œ', 'Œ', '/', '\\', '*', '-', '–', 
    '+', '7', '8', '9', '4', '5', '6', '1', '2', '3', '0', ',', ';', ':', '!', 
    '?', '¡', '¿', '.', '%', '$', '£', '€', '=', '{', '}', '(', ')', '[', ']', 
    '&', '~', "'", '"', '‘', '’', '`', '#', '_', '°', '@', 
    'А', 'а', 'Б', 'б', 'В', 'в', 'Г', 'г', 'Д', 'д', 'Е', 'е', 'Ё', 'ё', 'Ж', 'ж', 
    'З', 'з', 'И', 'и', 'Й', 'й', 'К', 'к', 'Л', 'л', 'М', 'м', 'Н', 'н', 'О', 'о', 
    'П', 'п', 'Р', 'р', 'С', 'с', 'Т', 'т', 'У', 'у', 'Ф', 'ф', 'Х', 'х', 'Ц', 'ц', 
    'Ч', 'ч', 'Ш', 'ш', 'Щ', 'щ', 'Ъ', 'ъ', 'Ы', 'ы', 'Ь', 'ь', 'Э', 'э', 'Ю', 'ю', 
    'Я', 'я', 'ü', 'Ü', 'ö', 'Ö', 'ä', 'Ä', 'ñ', 'Ñ', 'ς', 'ε', 'ρ', 'τ', 'υ', 'θ', 
    'ι', 'ο', 'π', 'α', 'σ', 'δ', 'φ', 'γ', 'η', 'ξ', 'κ', 'λ', 'ζ', 'χ', 'ψ', 'ω', 
    'β', 'ν', 'μ', 'Ε', 'Ρ', 'Τ', 'Υ', 'Θ', 'Ι', 'Ο', 'Π', 'Α', 'Σ', 'Δ', 'Φ', 'Γ', 
    'Η', 'Ξ', 'Κ', 'Λ', 'Ζ', 'Χ', 'Ψ', 'Ω', 'Β', 'Ν', 'Μ', 'å', 'Å', 'æ', 'Æ', 'ø', 'Ø'
];
