import * as THREE from 'three';
import { isInVRSession, setLoading} from '../core/sharedState.js';
import { createElement } from '../utils/domUtils.js';
import { setControllerRayAndCursorVisibilty } from '../XR/XRControllers.js';
import { setVRLoadingScreen } from '../XR/UI/XRLoadingScreenManager.js';

const manager = new THREE.LoadingManager();
let loadingOverlay;
let background;
let loadingText;
let logo;
let logoContainer;

function createLoadingUIComponents() {
  loadingOverlay = createElement('div', { className: 'loadingOverlay' });
  loadingText = createElement('h1', { className: 'loadingText', textContent: 'SHOWROOM' });
  logo = createElement('img', { className: 'logo', src: '/textures/logo.png' });
  const progressBarContainer = createElement('div', { className: 'progressBarContainer' });
  const progressBar = createElement('div', { className: 'progressBar' });
  const percentageText = createElement('p', { className: 'percentageText', innerHTML: 'Loading... <span class="percentage">0%</span>' });
  
  progressBarContainer.appendChild(progressBar);
  loadingOverlay.appendChild(logo);
  loadingOverlay.appendChild(loadingText);
  loadingOverlay.appendChild(progressBarContainer);
  loadingOverlay.appendChild(percentageText);
  
  return { loadingOverlay, progressBar, progressBarContainer, percentageText, loadingText, logoContainer, logo };
}

function initializeLoadingManager() {
  const { loadingOverlay, progressBar, progressBarContainer, percentageText, loadingText, logoContainer, logo } = createLoadingUIComponents();
  document.body.appendChild(loadingOverlay);
  background = document.querySelector('.loading-background');
  background.style.display = 'none';  
  
  manager.onStart = function (url, itemsLoaded, itemsTotal) {
    //console.log('STARTED LOADING!! file and assets: ' + url, itemsLoaded, itemsTotal);
  };

  manager.onProgress = function (url, itemsLoaded, itemsTotal) {
    const percentage = (itemsLoaded / itemsTotal) * 100;
    progressBar.style.width = percentage + '%';
    percentageText.querySelector('.percentage').textContent = Math.round(percentage) + '%';
  };

  manager.onError = function (url) {
    console.log('Error loading: ' + url);
  };
}

function setLoadingStarted() {
  setLoading(true);
  if (isInVRSession) {
    setVRLoadingScreen(true);
    setControllerRayAndCursorVisibilty(false);
  } else {
    background.style.opacity = '1';
    background.style.display = 'block';
    loadingOverlay.style.display = 'flex';
    loadingText.style.display = 'flex';
  }
}

function setLoadingFinished() {
  setLoading(false);
  setTimeout(() => {
    if (isInVRSession) {
        setVRLoadingScreen(false);
        setControllerRayAndCursorVisibilty(true);
      } 
    else {
      background.style.display = 'none';
      background.style.opacity = '0';
      loadingText.style.display = 'none';
      loadingOverlay.style.display = 'none';
    }
    }, 1000);
}

export { manager, initializeLoadingManager, setLoadingFinished, setLoadingStarted };
