import { initializeThreeSetup } from './core/threeSetup.js';
import { initializeExperience, welcomeMessage } from './core/begin.js';
import { canPlay, isMobile, setMobile, toggleCanPlay } from './core/sharedState.js';
import { checkForRoomIdInURL } from './multiplayer/roomManager.js';
import './css/mainStyles.css';
import './css/pauseGui.css';
import './css/adminTerminalPopupManager.css';
import './css/notifications.css';
import './css/debugMenu.css';
import './css/microphoneAccesModal.css';
import { useMobileCursor } from './utils/constants.js';

async function initApp() {
    try {
        setMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
        if(isMobile && !useMobileCursor) document.getElementById('dot-cursor').style.display = 'none';
        await initializeThreeSetup();
                
    } catch (error) {
        console.error("Error initializing the app:", error);
    }
}

document.addEventListener('DOMContentLoaded', initApp);
document.getElementById('startExperienceButton').addEventListener('click', (e) => {
    if(!canPlay){
        e.stopPropagation();

        initializeExperience();
        toggleCanPlay();
        if(!checkForRoomIdInURL()) welcomeMessage();
          
        document.getElementById('startExperienceButton').style.display = 'none';
        document.getElementById('startExperienceButton').removeEventListener('click', () => {});
    }
});