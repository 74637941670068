import * as THREE from 'three';
import { camera } from '../core/sharedState.js';
import { mouse, raycaster, useMobileCursor } from '../utils/constants.js';
import { resetHoveredObject, setNewHoveredObject } from '../interactions/hoverManager.js';
import { objectToBeConsideredForRaycast } from '../models/modelLoader.js';

let lastTime = 0;
const THROTTLE_MS = 100;
let currentHoveredObject = null;
let debounceTimeout = null;

/**
 * Handle the hover effect based on intersections.
 */
function handleHover(intersects) {
    // If there's no intersections, clear the current hovered object and annotation.
    if (!intersects.length) {
        if (currentHoveredObject) {
            resetHoveredObject(currentHoveredObject);
            currentHoveredObject = null;
        }
        return;
    }
    
    const object = intersects[0].object;

    // If the same object is already hovered, do nothing
    if (object === currentHoveredObject) {
        return;
    }

    // If another object was previously hovered, reset its appearance and annotation
    if (currentHoveredObject) {
        resetHoveredObject(currentHoveredObject);
    }

    // Set the new hovered object and its annotation
    setNewHoveredObject(object);
    currentHoveredObject = object;
}


function handleTouchMoveForHover(event) {
    const currentTime = Date.now();

    if (currentTime - lastTime > THROTTLE_MS) {

        let touchPosition = {};
        
        if(useMobileCursor) touchPosition = mouse;
        else touchPosition =
        {
            x: (event.touches[0].clientX / window.innerWidth) * 2 - 1,
            y: -(event.touches[0].clientY / window.innerHeight) * 2 + 1
        };

        raycaster.setFromCamera(touchPosition, camera);

        let intersects = raycaster.intersectObjects(objectToBeConsideredForRaycast);
        
        if(intersects.length > 0){
            if(intersects[0].object.userData && intersects[0].object.userData.traversableGround){
                intersects.length = 0;
            }
        }

        handleHover(intersects);
        lastTime = currentTime;
    }

    if(!useMobileCursor){
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
            // Same modification here
            const touchPosition = {
                x: (event.changedTouches[0].clientX / window.innerWidth) * 2 - 1,
                y: -(event.changedTouches[0].clientY / window.innerHeight) * 2 + 1
            };
            
            raycaster.setFromCamera(touchPosition, camera);
    
            let intersects = raycaster.intersectObjects(objectToBeConsideredForRaycast);
            
            if(intersects.length > 0){
                if(intersects[0].object.userData && intersects[0].object.userData.traversableGround){
                    intersects.length = 0;
                }
            }
    
            handleHover(intersects);
        }, THROTTLE_MS);
    }
}

export function initMobileHoverControls() {
    document.addEventListener('touchmove', handleTouchMoveForHover);
}
