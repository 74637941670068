import { showGUI as displayInfoPopup } from "../ui/pauseGui.js";
import { enableXR } from "../core/rendererSetup.js";
import { initializeControllers } from "../interactions/controllerManager.js";
import { getObjectData } from "../interactions/interactionWithObjectManager.js";
import { createPopup } from "../ui/contentPopupCreator.js";
import { setupAR } from "./ARSetup.js";

export function initializeExperience(){
    initializeControllers();
    enableXR()
    setupAR();
}

export function welcomeMessage(){
    const welcomeMessage = getObjectData('welcomeMessage', false);

    if(welcomeMessage) {
        createPopup(welcomeMessage, 'welcomeMessage', displayInfoPopup, true);
        document.getElementById('lock-backdrop').style.display = 'block';
    }
    else displayInfoPopup();
}