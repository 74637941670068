import * as THREE from 'three';
import { System } from './system.js';
import { camera, canPlay, isLoading, isMobile, pointerLocked } from '../core/sharedState.js';
import { rayLengthForObjectsInteraction, useMobileCursor } from '../utils/constants.js';
import { objectToBeConsideredForRaycast } from '../models/modelLoader.js';
import { isMultiplayerSession } from '../multiplayer/sessionManager.js';
import { sendDataToServer, socket } from '../multiplayer/multiplayerSetup.js';
import { handleKeyboardInteractions, xrKeyboardObjectsForRaycasting } from '../XR/Keyboard/XRKeyboard.js';
import { inventoryManagementSystem } from '../ECS-utils/managerECS.js';
import { getObjectUserData } from '../utils/objectDataHelper.js';
import { handleObjectInteraction } from '../interactions/interactionWithObjectManager.js';

export class InteractionSystem extends System {
    constructor(entityManager, systemManager) {
        super(entityManager, systemManager);
        this.raycaster = new THREE.Raycaster();
        this.raycaster.far = rayLengthForObjectsInteraction;
        this.raycaster.firstHitOnly = true;
        this.requiredComponents = ['ItemComponent', 'RenderComponent', 'InteractionComponent', 'StatusComponent'];
        this.excludedComponents = [];
        this.mouse = new THREE.Vector2();
        this.systemManager.onEvent('otherPlayerInteracted', this.handleOtherPlayerInteracted.bind(this));
        this.systemManager.onEvent('touch', this.handleTouch.bind(this));
        this.systemManager.onEvent('objectUnlocked', this.handleObjectUnlocked.bind(this));
        document.addEventListener('click', this.handleClick.bind(this));
    }

    update(deltaTime) {
    }

    // Returns entities as an array, using a cached version if available
    getEntitiesAsArray() {
        if (!this.cachedEntitiesArray) {
            this.cachedEntitiesArray = [...this.getEntities()];
        }
        return this.cachedEntitiesArray;
    }

    // Ensure to invalidate the entities array cache when entities change
    invalidateEntitiesCache() {
        super.invalidateEntitiesCache();
        this.cachedEntitiesArray = null;
    }

    handleClick(event) {
        if(!canPlay || isMobile || !pointerLocked || isLoading) return;

        if (pointerLocked) {
            // Explicitly set the mouse position to the center of the screen when the pointer is locked
            this.mouse.x = 0;
            this.mouse.y = 0;
        } else {
            // Update the mouse position only when the pointer is not locked
            this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
        }

        this.handleIntersectedObject();
        handleKeyboardInteractions();
    }

    handleTouch(event) {
        // Convert touch coordinates to normalized device coordinates
        if (event.changedTouches && event.changedTouches.length > 0) {

            if(!useMobileCursor){
                this.mouse.x = (event.changedTouches[0].clientX / window.innerWidth) * 2 - 1;
                this.mouse.y = -(event.changedTouches[0].clientY / window.innerHeight) * 2 + 1;
            }
            
            this.handleIntersectedObject();
        }
    }

    handleIntersectedObject(position = this.mouse, direction = camera, vrInteraction = false) {

        // Set the raycaster position and direction based on the interaction type
        if(vrInteraction)this.raycaster.set(position, direction);
        else this.raycaster.setFromCamera(position, direction);

        // First check if we got an active item in the inventory. If true, we should try to use it, and return.
        if(inventoryManagementSystem.checkForActiveItem()){
            this.systemManager.emitEvent('useActiveItem');
            return;
        }

        // If the raycaster intersects with a keyboard object, return
        if(xrKeyboardObjectsForRaycasting.length !== 0){
            const xrKeyboardObjectsIntersects = this.raycaster.intersectObjects(xrKeyboardObjectsForRaycasting);
            if(xrKeyboardObjectsIntersects.length > 0){
                xrKeyboardObjectsIntersects.length = 0;
                return;
            }
        }   
         
        const interactableEntities = this.getEntitiesAsArray();
        const interactableObjects = interactableEntities.map(entity => entity.getComponent('RenderComponent').mesh);
        const interactableObjectsIntersects = this.raycaster.intersectObjects(interactableObjects);
        const allRaycastableObjectsIntersects = this.raycaster.intersectObjects(objectToBeConsideredForRaycast);

        // If the raycaster intersects with a raycastable object that blocks interaction, return
        if(allRaycastableObjectsIntersects.length > 0){
            if(getObjectUserData(allRaycastableObjectsIntersects[0].object, 'raycastBlocker')){
                allRaycastableObjectsIntersects.length = 0;
                return;
            }
        }
        
        // If the raycaster intersects with a interactable object, call its callback
        if (interactableObjectsIntersects.length > 0 ) {
            const interactableMesh = interactableObjectsIntersects[0].object;
            const interactableEntity = this.entityManager.findEntityByMeshAndRequiredComponents(interactableMesh, this.requiredComponents);

            if(!interactableEntity)return;

            const interactionComponent = interactableEntity.getComponent('InteractionComponent');
            const itemComponent = interactableEntity.getComponent('ItemComponent');
            const renderComponent = interactableEntity.getComponent('RenderComponent');
            const statusComponent = interactableEntity.getComponent('StatusComponent');

                
            if(interactionComponent && interactionComponent.callbacks && interactionComponent.callbacks.click){
                interactionComponent.callbacks.click(interactableEntity);
                // Inform listeners that this item mesh was clicked
                if(renderComponent) this.systemManager.emitEvent('itemMeshInteracted', {mesh: renderComponent.mesh, interactionId: "clickedOn"});
            } 

            if(isMultiplayerSession){
                sendDataToServer('itemInteractionRequest', {
                    itemId: interactableMesh.name,
                    itemInteractionType: interactionComponent.interactionType,
                    itemType: itemComponent.itemType,
                    itemAnimationState: null,
                    itemState: statusComponent.state,
                });
            }

            return true;
        }
    }

    handleOtherPlayerInteracted(data) {
        if(data.playerId === socket.id) return;

        const interactableEntities = this.getEntitiesAsArray();
        const interactableObjects = interactableEntities.map(entity => entity.getComponent('RenderComponent').mesh);
        const interactableMesh = interactableObjects.find(mesh => mesh.name === data.itemId);
        const interactableEntity = this.entityManager.findEntityByMeshAndRequiredComponents(interactableMesh, this.requiredComponents);
        const interactionComponent = interactableEntity.getComponent('InteractionComponent');

        if(interactionComponent && interactionComponent.callbacks && interactionComponent.callbacks.multiplayerClick)
            interactionComponent.callbacks.multiplayerClick(interactableEntity); // No need for updating item state as this has already been handled in the syncManager.
    }

    handleObjectUnlocked(entity) {
        const interactionComponent = entity.getComponent('InteractionComponent');
        if(interactionComponent){
            interactionComponent.isLocked = 0;
            this.systemManager.emitEvent('playSound', 'succes');

            setTimeout(() => {
                handleObjectInteraction(entity);
            }, 1250);
        }
      
    }
 }
    

