import * as THREE from 'three';

const raycaster = new THREE.Raycaster();
raycaster.far = 20;
raycaster.firstHitOnly = true;
const raycasterAlt = new THREE.Raycaster();
raycasterAlt.far = 20;
raycasterAlt.firstHitOnly = true;
raycaster.layers.set(0);

const reusableMatrix = new THREE.Matrix4();
const reusableDirections = [
    new THREE.Vector3(),
    new THREE.Vector3(),
    new THREE.Vector3()
];

export function getIntersectingPoint(objectArray, position, direction, rayLength = 20, useAlternateRaycaster = false) {
    const chosenRaycaster = useAlternateRaycaster ? raycasterAlt : raycaster;
    chosenRaycaster.set(position, direction);
    chosenRaycaster.far = rayLength;
    
    for (let i = 0; i < objectArray.length; i++) {
        const object = objectArray[i];
        const intersects = chosenRaycaster.intersectObject(object);
        if (intersects.length) {
            return intersects[0].point;
        }
    }
}

export function getSurfaceNormal(objectArray, position, direction, rayLength = 20) {
    raycaster.far = rayLength;
    raycaster.set(position, direction);

    for (let i = 0; i < objectArray.length; i++) {
        const object = objectArray[i];
        const intersects = raycaster.intersectObject(object);
        if (intersects.length && intersects[0].face) {
            return intersects[0].face.normal;
        }
    }
}

export function getSurfaceNormalsInAllDirections(objectArray, position, direction, rayLength = 20) {
    reusableDirections[0].copy(direction);
    
    // +90° rotation
    reusableMatrix.makeRotationY(Math.PI / 2);
    reusableDirections[1].copy(direction).applyMatrix4(reusableMatrix);
    
    // +270° rotation (or -90°)
    reusableMatrix.makeRotationY(3 * Math.PI / 2);
    reusableDirections[2].copy(direction).applyMatrix4(reusableMatrix);

    const normals = reusableDirections.map(dir => getSurfaceNormal(objectArray, position, dir, rayLength));

    return normals;
}
