// XR/XRRaycasting.js
import * as THREE from 'three';

export let cursors = [];
const globalTempVector = new THREE.Vector3();

export const RAY_COLORS = {
    DEFAULT: 0xFFFFFF,
    GROUND: 0x00A7CA,
    CLICKABLE: 0x00FF00,
    KEYBOARD: 0xFF00FF
};

export function setupControllerRay(controller) {
    const rayGeometry = new THREE.BufferGeometry();
    rayGeometry.setAttribute('position', new THREE.Float32BufferAttribute([-0.005, 0, -0.1, 0.005, 0, -0.1, 0.0025, 0, -0.6, -0.0025, 0, -0.6], 3));
    rayGeometry.setIndex(new THREE.Uint16BufferAttribute([0, 1, 2, 2, 3, 0], 1));

    const rayMaterial = new THREE.ShaderMaterial({
        uniforms: {
            color: { value: new THREE.Color(RAY_COLORS.DEFAULT) }
        },
        vertexShader: `
            varying vec2 vUv;
            void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `,
        fragmentShader: `
            varying vec2 vUv;
            uniform vec3 color;
            void main() {
                float intensity = 1.0 - vUv.y;
                gl_FragColor = vec4(color * intensity, 1.0);
            }
        `,
        blending: THREE.AdditiveBlending,
        depthTest: true,
        transparent: true,
        side: THREE.DoubleSide
    });
    
    const rayMesh = new THREE.Mesh(rayGeometry, rayMaterial);
    rayMesh.renderOrder = 10000000;
    rayMesh.name = "rayMesh";
    controller.add(rayMesh);
}

export function setupCursor(controller) {
    const canvas = document.createElement('canvas');
    canvas.width = 64;
    canvas.height = 64;
    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.arc(32, 32, 29, 0, 2 * Math.PI);
    ctx.lineWidth = 5;
    ctx.stroke();
    ctx.fillStyle = 'white';
    ctx.fill();

    const texture = new THREE.CanvasTexture(canvas);
    const spriteMaterial = new THREE.SpriteMaterial({ map: texture, depthTest: false, transparent: true, depthWrite: false});
    const pointer = new THREE.Sprite(spriteMaterial);
    pointer.renderOrder = 10000000;
    pointer.name = "cursor";
    pointer.scale.set(0.015, 0.015, 1);
    pointer.position.z = -0.5;
    controller.add(pointer);
    return pointer;
}

export function updateRayColor(controller, colorHex) {
    const rayMesh = controller.getObjectByName("rayMesh");
    if (rayMesh) {
        rayMesh.material.uniforms.color.value.setHex(colorHex);
    }
}

export function updateRayLength(controller, intersectionPointWorld, maxLength = 2, setDefault = false) {
    const rayMesh = controller.getObjectByName("rayMesh");
    if (!rayMesh) {
        console.error("Ray Mesh not found on controller.");
        return;
    }

    controller.getWorldPosition(globalTempVector);
    globalTempVector.sub(intersectionPointWorld);

    // Calculate the new length as the negative distance to the intersection point,
    // ensuring it does not exceed the specified maximum length.
    let newLength = -globalTempVector.length();
    newLength = Math.max(newLength, -maxLength); // Use Math.max to ensure we don't exceed maxLength

    const positions = rayMesh.geometry.attributes.position.array;
    positions[8] = positions[11] = setDefault ? -0.6 : newLength;

    rayMesh.geometry.attributes.position.needsUpdate = true;
}

export function updateCursorVisibility(controller, isVisible) {
    const cursor = controller.getObjectByName("cursor");
    if (cursor) {
        cursor.visible = isVisible;
    }
}

export function updateCursorPosition(controller, position) {
    const cursor = controller.getObjectByName("cursor");
    if (cursor) {
        cursor.position.copy(position);
    }
}

export function setCursorVisibility(controllers, isVisible) {
    controllers.forEach(controller => {
        const cursor = controller.getObjectByName('cursor');
        if (cursor) cursor.visible = isVisible;
    });
}

export function setRayVisibility(controllers, isVisible) {
    controllers.forEach(controller => {
        const rayMesh = controller.getObjectByName('rayMesh');
        if (rayMesh) rayMesh.visible = isVisible;
    });
}

