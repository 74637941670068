// ui/spinner.js

export function showSpinner(targetElement) {
    const loadingSpinner = document.createElement('div');
    loadingSpinner.className = 'loading-spinner';
    loadingSpinner.style.display = 'block';  // Make it visible
    targetElement.appendChild(loadingSpinner);
    document.getElementById('lock-backdrop').style.display = 'block';
    document.getElementById('dot-cursor').style.display = 'none';

}

export function hideSpinner(targetElement) {
    const loadingSpinner = targetElement.querySelector('.loading-spinner');
    if (loadingSpinner) {
        loadingSpinner.remove();
        document.getElementById('lock-backdrop').style.display = 'none';
        document.getElementById('dot-cursor').style.display = 'block';
    }
}
