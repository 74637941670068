export let isMultiplayerSession = false;
export let isConnectingToRoom = false;

export function setMultiplayerSession(status) {
    isMultiplayerSession = status;
}

export function setConnectingState(status) {
    isConnectingToRoom = status;
}

export function checkConnectingState() {
    if (isConnectingToRoom) {
        alert("Please wait while we connect you to the room.");
        return true; // Indicates the user is still connecting
    }
    return false; // Indicates the user is not connecting
}
