import { initMultiplayer, sendDataToServer, socket } from './multiplayerSetup.js';
import { setMultiplayerSession, setConnectingState, checkConnectingState } from './sessionManager.js';
import { cameraRig, currentSceneName, micAcces, setDisplayingPopup, setMicAccess } from '../core/sharedState.js';
import { selectAvatar, showAvatarSelectionPopup } from '../ui/selectPopupAvatarManager.js';

let currentRoom = null;
let playerName = null;
let avatarName = null;

export function setPlayerName(name) {
    playerName = name;
}

export function getPlayerName() {
    return playerName;
}

export function checkForRoomIdInURL() {
    const urlParams = new URLSearchParams(window.location.search);
    const roomId = urlParams.get('roomId');
    if (roomId) {
        console.log("Found a room ID in the URL:", roomId);
        // Populate the invitation-popup-window with the Room ID
        document.getElementById('invitation-room-id').textContent = `Room ID: ${roomId}`;
        
        // Display the invitation-popup-window
        const invitationPopup = document.getElementById('invitation-popup-window');
        invitationPopup.style.display = 'block';
        setDisplayingPopup(true);
        document.getElementById('lock-backdrop').style.display = 'block';    
        return true;

    } else {
        return false;
    }
}

function generateRoomId() {
    let roomId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    console.log("Generated a new room ID:", roomId);
    return roomId;
}

export function generateInvitationLink(roomId) {
    const baseUrl = window.location.origin;
    const invitationLink = `${baseUrl}/join?roomId=${roomId}`;
    return invitationLink;
}

// This function prompts the user and requests microphone access if the user agrees
async function requestMicrophoneAccessWithModal() {
    return new Promise((resolve, reject) => {
        // Display the custom modal
        document.getElementById('microphone-access-modal').style.display = 'block';

        // If the user allows microphone access
        document.getElementById('grant-microphone-access-btn').onclick = async () => {
            try {
                console.log('Requesting microphone access...');
                await navigator.mediaDevices.getUserMedia({ audio: true });
                console.log('Microphone access granted.');
                document.getElementById('microphone-access-modal').style.display = 'none';
                setMicAccess(true);
                resolve(true);
            } catch (error) {
                alert('Unable to access the microphone. Please ensure that you have a microphone connected and that you have granted permission for this site to use it.');
                document.getElementById('microphone-access-modal').style.display = 'none';
                setMicAccess(false);
                resolve(false);
            }
        };

        // If the user decides to close the modal without granting access
        document.getElementById('closeModal').onclick = () => {
            console.log('User closed the microphone access modal.');
            document.getElementById('microphone-access-modal').style.display = 'none';
            alert('If you decide to enable voice features at a later time, please reload the page to grant microphone access.');
            setMicAccess(false);
            resolve(false);
        };
    });
}

export async function joinRoom(roomId, playerName) {

    // Request microphone access and wait for user response
    setMicAccess(await requestMicrophoneAccessWithModal());
    
    /// Await avatar selection
    try {
        avatarName = await selectAvatar();
    } catch (error) {
        console.error('Error during avatar selection:', error);
        // Handle the error or abort joining the room
        return;
    }

    // Proceed with joining the room
    if (checkConnectingState()) return; // Exit if already connecting
    setConnectingState(true);

    try {
        if (!roomId) {
            roomId = generateRoomId();
        }

        if (currentRoom) {
            console.warn("Already in a room - " + currentRoom + ". Leaving the current room to join a new one.");
            leaveRoom();
        }

        if (!socket) {
            console.warn("Not connected to the server. Initializing connection...");
            await initMultiplayer();
        }

        setMultiplayerSession(true);

        currentRoom = roomId;
        sendDataToServer('joinRoom', { playerName: playerName, position: cameraRig.position, playerAvatar: avatarName });
        return roomId;
    } catch (error) {
        console.error("Error while trying to join room:", error);
        throw error; // or handle the error as needed
    } finally {
        setConnectingState(false);
        // Reset the scene for proper state management
        //sceneManager.loadScene('roomStart','/models/roomStart.glb');
    }
}

export function leaveRoom() {
    if (currentRoom) {
        sendDataToServer('leaveRoom', { roomId: currentRoom });
        currentRoom = null;
        setMultiplayerSession(false);
    }
}

export function getCurrentRoom() {
    return currentRoom;
}
