// uiUtils.js
import DropdownManager from '../ui/dropdownManager';
import { canPlay, setDisplayingPopup } from "../core/sharedState.js";
import popupManager from '../ui/popupManager.js';

let listenersAttached = false;
let cogIcon = document.getElementById('cog-icon');
let dropdown = document.getElementById('dropdown');
const escIconContainer = document.getElementById('esc-container-id');

// Function to hide all UI elements
export function hideAllUIElements(dontAdjustPointerLock = false) {

    const uiElements = [
        'settings-popup-window',
        'login-popup-window',
        'create-user-popup-window',
        'multiplayer-popup-window',
        'multiplayer-session-container',
        'multiplayer-init-container',
        'avatar-selection-popup',
        'invitation-popup-window',
        'pause-gui',
        'backdrop',
        'lock-backdrop',
        'pdf-overlay'
    ];
    
    uiElements.forEach(id => {
        const element = document.getElementById(id);
        if (element) {
            element.style.display = 'none';
        }
    });
    
    DropdownManager.hideDropdown(document.getElementById('dropdown'));
    popupManager.closePopup(popupManager.activePopup);
    setDisplayingPopup(false, dontAdjustPointerLock);
}

// Function to set up an event listener
export function setupEventListener(elementId, callback, eventType = 'click') {
    const element = document.getElementById(elementId);
    if (element) {
        element.addEventListener(eventType, callback);
    }
}

// Function to initialize global click listener
export function initGlobalClickListener() {
    if (listenersAttached) return;

    document.getElementById('backdrop').addEventListener('click', (e) => {
        e.stopPropagation();
        hideAllUIElements();
        popupManager.closePopup(popupManager.activePopup);
    }
    );

    cogIcon.addEventListener('click', (e) => {
        e.stopPropagation();
        popupManager.closePopup(popupManager.activePopup);
        if (!dropdown.contains(e.target)) {
            toggleDropdown(canPlay, dropdown);
        } else {
            hideAllUIElements();
        }
    });

    listenersAttached = true;
}

// Function to toggle the dropdown visibility
export function toggleDropdown(canPlay, dropdown) {
    if (!canPlay) return;
    if (DropdownManager.isDropdownVisible(dropdown)) {
        DropdownManager.hideDropdown(dropdown);
    } else {
        DropdownManager.showDropdown(dropdown);
    }
}

export function setEscIconVisibility(visible) {
    if (visible) {
        escIconContainer.style.display = 'block';
    } else {
        escIconContainer.style.display = 'none';
    }
}
