import { BaseEntity } from './baseEntity.js';
import { PlayerComponent } from '../ECS-components/playerComponent.js';
import { InventoryComponent } from '../ECS-components/inventoryComponent.js';
import { HealthComponent } from '../ECS-components/healthComponent.js';
import { PositionComponent } from '../ECS-components/positionComponent.js';

export class PlayerEntity extends BaseEntity {
    constructor(id, playerName, maxHealth) {
        super(id);

        const playerComponent = new PlayerComponent(playerName);
        this.addComponent(playerComponent);

        const inventoryComponent = new InventoryComponent();
        this.addComponent(inventoryComponent);

        const healthComponent = new HealthComponent(maxHealth);
        this.addComponent(healthComponent);

        const positionComponent = new PositionComponent();
        this.addComponent(positionComponent);
    }
}

