// Import the System class
import objectInfo from '../content/objectData.json';
import { System } from './system.js';
import { PulseComponent } from '../ECS-components/pulseComponent.js';
import notificationManager from '../ui/notificationManager.js';
import { scene } from '../core/sharedState.js';
import FloatingPopupManager from '../ui/floatingPopupManager.js';

export class LearningSystem extends System {
    constructor(entityManager, systemManager) {
        super(entityManager, systemManager);
        this.systemManager.onEvent('startLearningModule', this.startLearningModule.bind(this));
        this.systemManager.onEvent('actionCompleted', this.onActionCompleted.bind(this));

        // Specific to LearningManager
        this.currentStepIndex = 0;
        this.currentStepElements = new Map(); // Map to store the interactive elements for the current step
        this.currentLearningModule = null; // The current learning module
        this.currentModuleMode = null; // The current mode of the learning module
        this.interactionComponent = null; // Interaction component for the object that is associated with starting the current learning module
        this.actionsCompletedForStep = new Map(); // Map to store the result of the verification for each step
        this.popupPosition = null;
    }

    // Function to start a learning module
    startLearningModule(learningPackage) {
        this.systemManager.emitEvent('setAllAnimationsToInitialState'); // Pause the game when a learning module is started

        // Extract the learning module details from the learning package
        const { moduleId, modeId, objectName } = learningPackage;

        // Find the learning module by its ID
        const learningModule = this.findLearningModule(moduleId);
        if (!learningModule) {
            console.error(`Learning module ${moduleId} not found.`);
            return;
        }

        // Find the interaction component for the object that is associated with starting the learning module
        const interactionComponent = this.entityManager.findEntityByObjectName(objectName).getComponent('InteractionComponent');
        if (interactionComponent) this.interactionComponent = interactionComponent;

        // If a positionHelperObject is defined, use it to position the popup
        if (interactionComponent.positionHelperObject) {
            const positionHelperObject = scene.getObjectByName(interactionComponent.positionHelperObject);
            if(positionHelperObject) this.popupPosition = positionHelperObject.position;
        }

        // Find the index of the selected mode in the learning module
        const modeIdIndex = learningModule.operationModes.findIndex(mode => mode.modeId === modeId);

        // Set the current learning module and mode
        this.currentLearningModule = learningModule;
        this.currentModuleMode = modeIdIndex;
        this.currentStepIndex = 0;
        this.currentStepElements.clear(); // Clear the map of interactive elements for the current step
        this.actionsCompletedForStep.clear(); // Clear the map of completed actions for each step

        // Display the first step of the learning module
        this.displayCurrentStep();
    }

    // Find a learning module by its ID
    findLearningModule(moduleId) {
        const { learningModules } = objectInfo;
        for (const module of learningModules) {
            if (module.moduleId === moduleId) {
                return module;
            }
            else return null;
        }
    }

    // Display the details of the current step to the user
    displayCurrentStep() {
        if (!this.currentLearningModule || this.currentStepIndex >= this.currentLearningModule.operationModes[this.currentModuleMode].steps.length) {
            console.log("No more steps in this learning module.");
            return;
        }

        // Get the current step
        const currentStep = this.currentLearningModule.operationModes[this.currentModuleMode].steps[this.currentStepIndex];

        // Check for interactive elements in the step and handle them
        const interactiveElements = currentStep.stepDetails.interactiveElements;
        if (interactiveElements) {
            for (const element of interactiveElements) {
                const entity = this.entityManager.findEntityByObjectName(element.elementDetails.visualObject);
                const renderComponent = entity.getComponent('RenderComponent');
                const originalColor = renderComponent?.mesh?.material?.color || new THREE.Color(0xffffff);

                const entityForInteractionObject = this.entityManager.findEntityByObjectName(element.elementDetails.interactionObject);
                const interactionComponent = entityForInteractionObject.getComponent('InteractionComponent');
                const interactiveVisuals = element.elementDetails.interactiveVisuals;
                if (entity) {
                    // Handle how to display the interactive elements
                    if (interactiveVisuals === 'pulseHighlightScale') {
                        if (!entity.hasComponent('PulseComponent')){
                            entity.addComponent(new PulseComponent({
                                animateScale: true, 
                                animateColor: false,
                                normalColor: originalColor,
                                pulseSpeed: 0.6,
                                maxScale: 1.1,
                                normalScale: 1.0,
                                minScale: 0.9
                            }));
                            this.entityManager.notifySystems();
                        } 
                        else {
                            const pulseComponent = entity.getComponent('PulseComponent');
                            pulseComponent.active = true; // Activate pulse component
                            pulseComponent.animateScale = true; // Activate scale animation
                            pulseComponent.animateColor = false; // Deactivate color animation
                        }
                    } 
                    else if (interactiveVisuals === 'pulseHighlightMaterial') {
                        if (!entity.hasComponent('PulseComponent')){
                            entity.addComponent(new PulseComponent({animateScale: false, animateColor: true}));
                            this.entityManager.notifySystems();
                        } 
                        else {
                            const pulseComponent = entity.getComponent('PulseComponent');
                            pulseComponent.active = true; // Activate pulse component
                            pulseComponent.animateScale = false; // Deactivate scale animation
                            pulseComponent.animateColor = true; // Activate color animation
                        }
                    }

                    // Set the completion criteria on the interaction component of the interaction object
                    if (element.elementDetails.actionRequired) {
                        interactionComponent.setStepAction(element.elementDetails.actionRequired, element.elementDetails.interactionObject);
                    }

                    // Store the interactive elements for the current step
                    this.currentStepElements.set(element.elementDetails.interactionObject, element);
                }
            }
            notificationManager.showNotification(currentStep.stepId, "info", 2000, "top", 12);
            FloatingPopupManager.displayPopup({ headline: currentStep.stepTitle, text: currentStep.stepDetails.textInstructions }, this.popupPosition, 100000);
        }

    }

    // Method to advance to the next step
    advanceToNextStep() {
        this.currentStepIndex++;
        if (this.currentStepIndex < this.currentLearningModule.operationModes[this.currentModuleMode].steps.length) {
            this.displayCurrentStep(); // Move to the next step and display it
        } else {
            notificationManager.showNotification("Completed!", "info", 3000, "top", 12);
            FloatingPopupManager.displayPopup({ headline: "Completed!", text: "You have completed all steps of the learning module." }, this.popupPosition, 5000);
        }
    }

    onActionCompleted(eventDetails) {
        // Retrieve the actionId from the event details
        const { actionId, objectName } = eventDetails;
    
        // Now, we also need to check if the action involves a specific object interaction
        // This requires extending the eventDetails to include such information when dispatched
        console.log(`Action completed: ${actionId} on object: ${objectName}.`);
    
        if (this.verifyCompletionCriteria(actionId, objectName)) {
            for (const [objectName, element] of this.currentStepElements) {
                // reset stepAction for the object's interaction component
                const entity = this.entityManager.findEntityByObjectName(element.elementDetails.visualObject);
                const interactionComponent = entity.getComponent('InteractionComponent');
                const pulseComponent = entity.getComponent('PulseComponent');
                if (interactionComponent) interactionComponent.setStepAction(null, null);
                if (pulseComponent) pulseComponent.active = false; // Deactivate pulse component
            }
            this.currentStepElements.clear(); // Clear the map of interactive elements for the current step
            
            this.advanceToNextStep();
        } else {
            console.log("Action completed, but does not fulfill the current step's criteria.");
            // Optionally, provide user feedback when criteria are not met
            this.provideFeedback("The action was completed, but it doesn't meet the step's requirements.");
        }
    }
    
    verifyCompletionCriteria(actionId, objectName) {
        const currentStep = this.currentLearningModule?.operationModes[this.currentModuleMode]?.steps[this.currentStepIndex];
    
        if (!currentStep?.stepDetails?.interactiveElements) {
            console.log('No interactive elements defined for this step.');
            return false;
        }
        
        if (!this.actionsCompletedForStep.has(this.currentStepIndex)) {
            this.actionsCompletedForStep.set(this.currentStepIndex, new Set());
        }
    
        let completedActionsForStep = this.actionsCompletedForStep.get(this.currentStepIndex);
        
        let matchedElement = currentStep.stepDetails.interactiveElements.find(element =>
            element.elementDetails.actionRequired === actionId && element.elementDetails.interactionObject === objectName);
        
        if (matchedElement) {
            console.log(`Matched action: ${actionId} for object: ${objectName}.`);
            const entity = this.entityManager.findEntityByObjectName(matchedElement.elementDetails.visualObject);
            const pulseComponent = entity.getComponent('PulseComponent');
            if (pulseComponent) pulseComponent.active = false; // Deactivate pulse component
            completedActionsForStep.add(actionId);
        } else {
            console.log(`No match found for action: ${actionId} on object: ${objectName}.`);
            // Early return if no match is found for debugging
            return false;
        }
    
        // Debug: Log expected actions vs. completed actions
        console.log(`Expected actions:`, currentStep.stepDetails.interactiveElements.map(e => e.elementDetails.actionRequired));
        console.log(`Completed actions for step ${this.currentStepIndex}:`, Array.from(completedActionsForStep));
    
        const allActionsCompleted = currentStep.stepDetails.interactiveElements.every(element =>
            completedActionsForStep.has(element.elementDetails.actionRequired));
    
        console.log(`All actions completed for step ${this.currentStepIndex}?`, allActionsCompleted);
        return allActionsCompleted;
    }
    
    
    
    provideFeedback(message) {
        // Example using XRPopupManager for XR environments

        //XRPopupManager.displayPopup({ headline: "Feedback", text: message }, false);
        // For non-XR environments, adjust accordingly, perhaps using popupManager or custom UI elements
    }
    
    // Override the update method to include logic specific to learning modules, if necessary
    update(deltaTime) {
        super.update(deltaTime);
        // Add any per-frame update logic here, if needed for learning modules
    }
}
