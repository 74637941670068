import { BaseEntity } from './baseEntity.js';
import { TriggerComponent } from '../ECS-components/triggerComponent.js';
import { StatusComponent } from '../ECS-components/statusComponent.js';

export class TriggerEntity extends BaseEntity {
    constructor(id, options) {
        super(id);

        const triggerComponent = new TriggerComponent(options);
        this.addComponent(triggerComponent);

        const statusComponent = new StatusComponent("");
        this.addComponent(statusComponent);
    }
}

