import { moveForward, moveBackward, moveLeft, moveRight, isJumping, stopJump, isSprinting, controls,  } from './fpsControls.js';
import { getIntersectingPoint, getSurfaceNormalsInAllDirections } from './raycaster.js';
import { traversableGrounds } from '../models/modelLoader.js';
import { PLAYER_SPEED } from '../utils/constants.js';
import { sendPlayerMovementData } from '../multiplayer/playerMovementDataSender.js';
import { camera, cameraRig } from './sharedState.js';
import * as THREE from 'three';

const OFF_GROUND_SPEED = PLAYER_SPEED * 0.5;
const clock = new THREE.Clock();
let velocity = new THREE.Vector3(0, 0, 0);
const GRAVITY = -9.8;
const TERMINAL_VELOCITY = -50; // Max falling speed
let delta;
const JUMP_FORCE = 3;
const SPRINT_MULTIPLIER = 1.5;
const downVector = new THREE.Vector3(0, -1, 0);
let playerSpeedFinal;

const reusableEuler = new THREE.Euler();
const reusableQuaternion = new THREE.Quaternion();

const reusableVectors = {
    v1: new THREE.Vector3(),
    v2: new THREE.Vector3(),
    v3: new THREE.Vector3(),
    v4: new THREE.Vector3(),
    v5: new THREE.Vector3(),
    v6: new THREE.Vector3(),
};

let lastValidPosition = null;

export function initializeLastValidPosition() {
    lastValidPosition = cameraRig.position.clone();
}

export function handleMovement() {

    const grounded = isStepOnGround();
    if (grounded) {
        lastValidPosition.copy(cameraRig.position);
    }
    delta = clock.getDelta();
    const moveDir = reusableVectors.v1.set(Number(moveRight) - Number(moveLeft), 0, Number(moveForward) - Number(moveBackward)).normalize();
    
    
    if (isJumping && grounded && controls.isLocked) {
        velocity.y = JUMP_FORCE;
    }
    
    applyGravity(grounded);

    const speed = computeMovementSpeed(grounded);

    applyMovement(moveDir, delta, speed);
    if(isJumping && velocity.y <= 0 && isStepOnGround()) {
        stopJump();
    }
}

function computeMovementSpeed(grounded) {

    if(grounded){
        if (isSprinting) {
            playerSpeedFinal = PLAYER_SPEED * SPRINT_MULTIPLIER;
        }
        else {
            playerSpeedFinal = PLAYER_SPEED;
        }
    }  
    return playerSpeedFinal;
}

function applyMovement(direction, delta, speed) {

    const actualMoveDirection = computeActualMoveDirection(direction, reusableVectors.v1);
    actualMoveDirection.y = 0;

    const aboveGroundOffset = reusableVectors.v3.set(0, 0.2, 0);
    const aboveGround = reusableVectors.v2.copy(cameraRig.position).add(aboveGroundOffset);
    const collisionNormals = getSurfaceNormalsInAllDirections(traversableGrounds, aboveGround, actualMoveDirection, 0.3, reusableVectors.v3);

    const collisionInfluences = collisionNormals.map(normal => {
        if (normal) {
            return actualMoveDirection.dot(normal);
        }
        return null;
    });

    const primaryInfluence = collisionInfluences[0];
    if (primaryInfluence !== null && primaryInfluence < 0) {
        // Adjust the movement for the primary collision
        actualMoveDirection.add(collisionNormals[0].multiplyScalar(-primaryInfluence));

        // Check for other collisions and adjust movement further
        for (let i = 1; i < collisionInfluences.length; i++) {
            if (collisionInfluences[i] !== null && collisionInfluences[i] < 0) {
                actualMoveDirection.add(collisionNormals[i].multiplyScalar(-collisionInfluences[i]));
            }
        }
        speed = OFF_GROUND_SPEED;
        // Normalize to ensure consistent movement speed
        actualMoveDirection.normalize();
    }

    cameraRig.position.add(actualMoveDirection.multiplyScalar(speed * delta));
    cameraRig.position.y = getGroundYAtPlayerPosition();
    sendPlayerMovementData(cameraRig, camera);
}

function computeActualMoveDirection(direction) {
    // Get the yaw rotation from the camera's quaternion
    reusableEuler.setFromQuaternion(camera.quaternion, 'YXZ');
    reusableQuaternion.setFromEuler(reusableEuler.set(0, reusableEuler.y, 0, 'YXZ'));

    // Create forward and right vectors using only the yaw rotation
    const cameraForward = reusableVectors.v4.set(0, 0, -1).applyQuaternion(reusableQuaternion);
    const cameraRight = reusableVectors.v5.set(1, 0, 0).applyQuaternion(reusableQuaternion);
    
    // Compute the actual move direction
    return reusableVectors.v6.set(
        cameraRight.x * direction.x + cameraForward.x * direction.z,
        0,
        cameraRight.z * direction.x + cameraForward.z * direction.z
    ).normalize();
}

function applyGravity(grounded) {

    if (grounded && velocity.y <= 0 && !isJumping) {
        velocity.y = 0;  // Reset vertical velocity if on ground
        return;
    }

    // Always apply gravity, even if on the ground or jumping
    velocity.y += GRAVITY * delta;
    velocity.y = Math.max(velocity.y, TERMINAL_VELOCITY);

    cameraRig.position.y += velocity.y * delta;
    
}

export function isStepOnGround() {
    const playerFeetPosition = reusableVectors.v1.copy(cameraRig.position).add(reusableVectors.v2.set(0, 0.4, 0)); 
    const intersectingPoint = getIntersectingPoint(traversableGrounds, playerFeetPosition, downVector, 20, true);
    if(!intersectingPoint) return false;
    return (cameraRig.position.y - intersectingPoint.y )< 0.1;
}

function getGroundYAtPlayerPosition() {
    const aboveGround = reusableVectors.v1.copy(cameraRig.position).add(reusableVectors.v2.set(0, 1.3, 0));
    const intersectPointAboveGround = getIntersectingPoint(traversableGrounds, aboveGround, downVector);
    
    if(isJumping){
        return cameraRig.position.y;
    }
    
    if (intersectPointAboveGround && isFinite(intersectPointAboveGround.y)) {
        return intersectPointAboveGround.y;
    } else {
        // Push the player back to the last known valid position
        cameraRig.position.copy(lastValidPosition);
        return lastValidPosition.y;
    }
}

export function setPlayerPosition(x, y, z) {
    // Validate the incoming coordinates (Optional but recommended)
    if (typeof x !== 'number' || typeof y !== 'number' || typeof z !== 'number') {
        console.error("Invalid coordinates supplied. All coordinates should be numbers.");
        return;
    }

    // Set the cameraRig's position to the new coordinates
    cameraRig.position.set(x, y, z);

    // Update lastValidPosition since we have manually set a new position
    lastValidPosition.copy(cameraRig.position);
}


