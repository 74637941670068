// XR/XRHover.js
import * as THREE from 'three';
import { camera, isLoading } from "../core/sharedState";
import { handleHoveredObject } from "../interactions/hover";
import { animateFootprints, hideFootprints } from "../interactions/uxHelpers";
import { traversableGrounds } from "../models/modelLoader";
import { updateCursorPosition, updateCursorVisibility, updateRayColor, RAY_COLORS, updateRayLength } from "./XRRayAndCursor";
import { getControllerData } from "./XRUtilities";
import { controllers } from "./XRControllers";

let tempVector = new THREE.Vector3();
let objectIntersects = {};

function processIntersectedObjects(intersects, controller, rayColor) {
    if (intersects.length > 0) {
        updateRayColor(controller, rayColor);
        tempVector.copy(intersects[0].point);
        const localPoint = controller.worldToLocal(tempVector);
        updateCursorPosition(controller, localPoint);
        updateCursorVisibility(controller, true);
        updateRayLength(controller, intersects[0].point);

        if (rayColor === RAY_COLORS.GROUND) {
            animateFootprints(intersects[0].point, controller.getWorldDirection(tempVector));
        } else {
            hideFootprints();
        }
        return true;
    }
    return false;
}

function checkIntersections(controller, raycaster, objectsForRaycast) {
    
    raycaster.camera = camera;
    objectIntersects = raycaster.intersectObjects(objectsForRaycast);

    if (processIntersectedObjects(objectIntersects, controller, RAY_COLORS.CLICKABLE)) return;

    const groundIntersects = raycaster.intersectObjects(traversableGrounds);
    const isGroundIntersected = groundIntersects.some(intersect => intersect.face.normal.y > 0.8);
    if (isGroundIntersected) {
        processIntersectedObjects(groundIntersects, controller, RAY_COLORS.GROUND);
    } else {
        updateCursorVisibility(controller, false);
        updateRayColor(controller, RAY_COLORS.DEFAULT);
        hideFootprints();
    }
}

export function checkVRHover(controller, raycaster, alsoHandleHoveredObject, objectsForRaycast) {
    if (!controller || isLoading) {
        console.log('Controller is not available');
        return;
    }

    const [controllerPosition, , controllerDirection] = getControllerData(controller);
    raycaster.set(controllerPosition, controllerDirection);

    checkIntersections(controller, raycaster, objectsForRaycast);

    if (alsoHandleHoveredObject) {
        const controllerSource = controller === controllers[0] ? 'controller1' : 'controller2';
        handleHoveredObject(objectIntersects, controllerSource);
    }
}
