import { inventoryManagementSystem } from "../ECS-utils/managerECS";
import { setDisplayingPopup } from "../core/sharedState";
import { webrtc } from "../multiplayer/multiplayerSetup";
import { players } from "../multiplayer/playerManager";
import { getEnvironmentState } from "../multiplayer/syncManager";
import { hideAllUIElements } from "./uiUtils";

const debugMenu = document.getElementById('debugMenu');
let debugOutput = document.getElementById('debugOutput');

function toggleDebugMenu() {
  debugMenu.style.display = debugMenu.style.display === 'none' ? 'flex' : 'none';

  if (debugMenu.style.display !== 'none') {
    hideAllUIElements(true);
    setDisplayingPopup(true);
  }

  else {
    hideAllUIElements();
  }
}

function displayPlayers() {
  let playersInfo = '<h2>Players in the Room</h2>';

  // Assuming getEnvironmentState().players returns a similar structure to the players object
  const playersFromEnvironmentState = getEnvironmentState().players;

  // Loop through players from the environment state
  for (const playerData of Object.values(playersFromEnvironmentState)) {
    // Use playerId to get the isVR status from the original players object
    // Default to false if player is not found in the original players object
    const isVRStatus = players[playerData.playerId] ? players[playerData.playerId].isVR : false;

    playersInfo += `
      <div class="player-info">
        <h3>Player ID: ${playerData.playerId}</h3>
        <p>Name: ${playerData.playerName}</p>
        <p>Scene: ${playerData.sceneName}</p>
        <p>Avatar: ${playerData.playerAvatar}</p>
        <p>Is VR: ${isVRStatus ? 'Yes' : 'No'}</p>
        <p>Equipped Item: ${playerData.equippedItem ? playerData.equippedItem : 'None'}</p> 
      </div>
    `;
  }
  
  if (Object.keys(playersFromEnvironmentState).length === 0) {
    playersInfo += '<p>No players currently in the room.</p>';
  }

  debugOutput.innerHTML = playersInfo;
}

function displayInventory() {
  const inventoryManager = inventoryManagementSystem; // Adjust based on how you access the InventoryManagementSystem
  const playerEntity = inventoryManager.entityManager.getEntitiesWithComponent('PlayerComponent')[0];
  let inventoryInfo = '<h2>Player Inventory</h2>';

  if (playerEntity) {
    const inventory = playerEntity.getComponent('InventoryComponent');
    if (inventory && inventory.items.length > 0) {
      inventory.items.forEach((itemEntity, index) => {
        const itemComponent = itemEntity.getComponent('ItemComponent');
        inventoryInfo += `
          <div class="inventory-item">
            <h3>Item ${index + 1}: ${itemComponent.itemName} (${itemComponent.itemType})</h3>
            <p>Damage: ${itemComponent.damage}</p>
            <p>Range: ${itemComponent.range}</p>
            <p>Cooldown: ${itemComponent.cooldown}ms</p>
            <p>Pickupable: ${itemComponent.pickupable ? 'Yes' : 'No'}</p>
          </div>
        `;
      });
    } else {
      inventoryInfo += '<p>Inventory is empty.</p>';
    }
  } else {
    inventoryInfo += '<p>No player entity found.</p>';
  }

  debugOutput.innerHTML = inventoryInfo;
}

function displayWebRTCConnections() {
  if (!webrtc) {
    debugOutput.innerHTML = 'WebRTC Manager is not initialized.';
    return;
  }

  let connectionsInfo = '<h2>Active WebRTC Connections</h2>';
  const peerConnections = webrtc.peerConnections;
  const connectionIds = Object.keys(peerConnections);

  if (connectionIds.length === 0) {
    connectionsInfo += '<p>No active WebRTC connections.</p>';
  } else {
    connectionIds.forEach(id => {
      const pc = peerConnections[id];
      const state = pc.connectionState;
      connectionsInfo += `
        <div class="connection-info">
          <h3>Connection ID: ${id}</h3>
          <p>State: ${state}</p>
        </div>
      `;
    });
  }

  debugOutput.innerHTML = connectionsInfo;
}

export function initializeDebugMenu() {
  window.addEventListener('keydown', function(event) {
    if (event.ctrlKey && event.key === 'm') {
      console.log('Ctrl+M was pressed');
      event.preventDefault();
      toggleDebugMenu();
    }
  });

  document.getElementById('debugDisplayPlayersButton').addEventListener('click', displayPlayers);
  document.getElementById('debugDisplayWebRTCConnectionsButton').addEventListener('click', displayWebRTCConnections);
  document.getElementById('debugDisplayInventoryButton').addEventListener('click', displayInventory);

} 
