import { System } from './system.js';
import { Tween, Easing } from '@tweenjs/tween.js';
import * as THREE from 'three';
import { shootableObjects } from '../models/modelLoader.js';
import { isMultiplayerSession } from '../multiplayer/sessionManager.js';
import { playerHitEffect } from '../multiplayer/playerManagerHelper.js';
import { sendDataToServer } from '../multiplayer/multiplayerSetup.js';
import { camera, currentSceneName, isInVRSession, scene } from '../core/sharedState.js';
import { lastTriggeredControllerIndex } from '../XR/XRInteractions.js';
import { addImpulse } from '../physics/physicsManager.js';

export class WeaponSystem extends System {
  constructor(entityManager, systemManager) {
    super(entityManager, systemManager);
    this.requiredComponents = ['InventoryComponent'];
    this.systemManager.onEvent('fire', this.fireWeapon.bind(this));
    this.localForward = new THREE.Vector3(0, 0, -1); // Pre-allocate to reduce object creation
    this.tempVector = new THREE.Vector3();
    this.raycaster = new THREE.Raycaster();
    this.positionTarget = new THREE.Vector3();
    this.tweenPosition = new Tween(this.positionTarget);
    this.tweenPosition2 = new Tween(this.positionTarget);
    this.originalPosition = null;
    this.recoildPosition = null;
    this.recoilOffsetVector = new THREE.Vector3(0, 0, 0.1);

  }

  fireWeapon(firePackage) {
    if(firePackage.itemType !== 'revolver' && firePackage.itemType !== 'shotgun' && 
      firePackage.itemType !== 'rifle' && firePackage.itemType !== 'sniper' && 
      firePackage.itemType !== 'grenade' && firePackage.itemType !== 'gun')
      return;

    let activeItemEntity;
    let activeItemComponent;
    let activeItemStatusComponent;

    activeItemEntity = this.entityManager.findEntityByObjectName(firePackage.itemId);
    activeItemComponent = activeItemEntity.getComponent('ItemComponent');
    activeItemStatusComponent = activeItemEntity.getComponent('StatusComponent');
    
    if (!this.canFire(activeItemComponent, activeItemStatusComponent)) return;

    let intersectionData = null;
    let intersectionPoint = null;

    // First check if this has been called from another player in a multiplayer session. If so,
    // we will get the intersectionData from the firePackage
    if(isMultiplayerSession) {
      if(firePackage.playerId && firePackage.otherPlayerShooting) {
        const object = scene.getObjectByName(firePackage.intersectedObjectId);
        console.log('firePackage', firePackage);
        intersectionData = {object: object, point: firePackage.hitPosition};
      }
    }

    // If intersectionData is still null, this has been called from the local player and we should
    // get the intersectionData from a raycast
    if(intersectionData === null) {
      if(!isInVRSession) intersectionData = this.raycastFromCenterOfScreen();
      else intersectionData = this.raycastFromWeapon(activeItemComponent);
    }

    if (intersectionData) {
      intersectionPoint = intersectionData.point;
      
      if(intersectionPoint){

        // Spawn particle effect at intersection point
        this.systemManager.emitEvent('spawnEffectOnPosition', {
            effectType: 'hitWhitePlaster',
            worldPosition: intersectionPoint,
            direction: intersectionData.normal
        });

        // if intersectionData has an object, handle the hit
        if(intersectionData.object) {

          //if the object has a health component, emit an event to reduce the health
          const hitDamage = this.calculateHitDamage(activeItemComponent.itemType, intersectionPoint);
          const hitObjectEntityWithHealth = this.entityManager.findEntityByMeshAndRequiredComponents(intersectionData.object, ['RenderComponent', 'HealthComponent']);
          if(hitObjectEntityWithHealth){
            const healthComponent = hitObjectEntityWithHealth.getComponent('HealthComponent');
            if(healthComponent && healthComponent.isAlive) this.systemManager.emitEvent('healthChange', {mesh: intersectionData.object, damage: hitDamage});
          }

          // If the object has a rigidbody, apply force to it
          this.tempVector.set(0, 0, 0);
          const activeItemWorldPosition = activeItemComponent.mesh.getWorldPosition(this.tempVector);
          const force = activeItemWorldPosition.sub(intersectionPoint).normalize().multiplyScalar(hitDamage * 0.3);
          force.negate();      
          addImpulse(intersectionData.object, force, intersectionData.point);

          // Inform listeners that this item mesh was shot
          this.systemManager.emitEvent('itemMeshInteracted', {mesh: intersectionData.object, interactionId: 'shotOn'});
        }
      }
      else console.log('No intersection point found');
    }

    if(isMultiplayerSession) {

      // If hitting another player, also send the playerId. Otherwise, send null
      let playerBeingHit = null;
      let intersectedObjectId = null
      let hitDamage = 0;
      
      if(intersectionData && intersectionData.object) {
        console.log('Intersection data:', intersectionData);
        intersectedObjectId = intersectionData.object.name;
        if(intersectionData.object.userData.playerId) {
          playerBeingHit = intersectionData.object.userData.playerId;
          hitDamage = this.calculateHitDamage(activeItemComponent.itemType, intersectionPoint, playerBeingHit);
          playerHitEffect(playerBeingHit, intersectionPoint);
        }
      }
      
      // For the loving god, please dont send a fire request if this is not the player that fired the weapon. 
      // Only a firepackage received clientside (local) should send the fire request to the server. The clientside firepackage
      // will never include the playerId, which can be used as a condition to determine if the fire request should be sent to the server.
      // This is to avoid an endless loop of fire requests if more than one player is in the room

      if(!firePackage.playerId && !firePackage.otherPlayerShooting) {
        console.log('Sending fire request to server');
        sendDataToServer('itemFireRequest', {
          itemId: activeItemComponent.itemName,
          itemScene: currentSceneName,
          itemType: activeItemComponent.itemType,
          hitPosition: intersectionPoint,
          intersectedObjectId: intersectedObjectId,
          playerBeingHit: playerBeingHit,
          hitDamage: hitDamage
        });
      }
    }
  
    this.triggerFire(activeItemComponent, activeItemEntity.id);
    this.applyRecoil(activeItemComponent);
  }

  raycastFromWeapon(activeItemComponent) {
    if (!activeItemComponent.mesh) return null;
    const origin = new THREE.Vector3();
    const direction = this.calculateWorldForward(activeItemComponent.mesh);
  
    this.raycaster.camera = camera; // Set the raycaster's camera to the main camera (used if shootableObjects have sprites in it)

    activeItemComponent.mesh.localToWorld(origin);
    this.raycaster.set(origin, direction);

    // only mesh objects are shootable
    const filteredShootableObjects = shootableObjects.filter(obj => {
      if(obj && obj.isMesh) return obj;
    });
  
    const intersects = this.raycaster.intersectObjects(filteredShootableObjects);
  
    if (intersects.length > 0) {
      return { 
        point: intersects[0].point, 
        normal: intersects[0].face.normal,
        object: intersects[0].object };
    }
  
    return null;
  }

  raycastFromCenterOfScreen() {
    // Calculate the center of the screen in normalized device coordinates (NDC)
    // (-1 to +1) for both components
    const ndcCenterX = 0;
    const ndcCenterY = 0;
  
    // Set the picking ray from the camera position and direction
    this.raycaster.setFromCamera({ x: ndcCenterX, y: ndcCenterY }, camera);
  
    // Filter the objects that can be interacted with using the raycaster
    const filteredShootableObjects = shootableObjects.filter(obj => obj && obj.isMesh);
  
    // Calculate intersects
    const intersects = this.raycaster.intersectObjects(filteredShootableObjects, true);
  
    // Return the first intersect object, if any
    if (intersects.length > 0) {
      return {
        point: intersects[0].point,
        normal: intersects[0].face.normal,
        object: intersects[0].object
      };
    }
    return null;
  }
  
  getActiveItemEntity() {
    const playerEntity = this.entityManager.getEntitiesWithComponent('PlayerComponent');
    const inventory = playerEntity[0].getComponent('InventoryComponent');
    const activeItemEntity = this.entityManager.getEntity(inventory.activeItem);
    return activeItemEntity;
  }

  canFire(activeItemComponent, activeItemStatusComponent) {
    const finishedCooldown = activeItemComponent && Date.now() - activeItemComponent.lastFired >= activeItemComponent.cooldown;
    let canfire = finishedCooldown;
    
    if(isInVRSession) {
      const convertIndexToHand = lastTriggeredControllerIndex === 0 ? 'right' : 'left';
      const itemInTheHandThatTriggersFire = convertIndexToHand === activeItemStatusComponent.equippedInHand;
      canfire = finishedCooldown && itemInTheHandThatTriggersFire;
    }

    return canfire;
  }

  calculateWorldForward(mesh) {
    mesh.updateMatrixWorld(true);
    return this.localForward.clone().transformDirection(mesh.matrixWorld);
  }

  calculateHitDamage(itemType, hitPosition, playerId) {
    if(itemType === 'revolver' || itemType === 'gun') {
      return 10;
    }
    else if(itemType === 'shotgun') {
      return 20;
    }
    else if(itemType === 'rifle') {
      return 30;
    }
    else if(itemType === 'sniper') {
      return 50;
    }
    else if(itemType === 'grenade') {
      return 100;
    }
  }

  triggerFire(activeItemComponent, activeItemEntityId) {
    activeItemComponent.lastFired = Date.now();
    this.systemManager.emitEvent('playSound', 'gunShot');

    const worldForward = this.calculateWorldForward(activeItemComponent.mesh);
    const entitiesWithParticleComponent = this.entityManager.getEntitiesWithComponent('ParticleEffectComponent');

    for (const entity of entitiesWithParticleComponent) {
      const particleComponent = entity.getComponent('ParticleEffectComponent');
      if (this.isChildParticle(activeItemComponent, particleComponent)) {
        this.systemManager.emitEvent('spawnEffect', {
          entityId: entity.id,
          effectType: particleComponent.effectType,
          direction: worldForward
        });
      }
    }
  }

  isChildParticle(activeItemComponent, particleComponent) {
    return activeItemComponent.mesh.children.some(child => child.name === particleComponent.mesh.name);
  }

  applyRecoil(activeItemComponent) {
    this.originalPosition = activeItemComponent.mesh.position.clone();
    this.recoildPosition = activeItemComponent.mesh.position.clone().add(this.recoilOffsetVector);
    this.tweenRecoil(activeItemComponent.mesh.position);
  }

  tweenRecoil(target) {

    // Reset position to original position
    target.copy(this.originalPosition);
    this.positionTarget.copy(this.originalPosition);

    // Stop any ongoing tweens
    this.tweenPosition.stop();
    this.tweenPosition2.stop();
    
    this.tweenPosition
      .to(this.recoildPosition, 30)
      .easing(Easing.Quadratic.Out)
      .onUpdate(() => {
        target.copy(this.positionTarget);
      })
      .onComplete(() => {
        this.tweenPosition2
          .to(this.originalPosition, 100)
          .easing(Easing.Quadratic.Out)
          .onUpdate(() => {
            target.copy(this.positionTarget);
          })
          .start(undefined, true);
      })
      .start(undefined, true);
  }
}
