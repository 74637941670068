import * as THREE from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import {camera, scene, setSelectState, isInVRSession,} from '../../core/sharedState';
import { mouse, raycaster } from '../../utils/constants';
import { getControllerData, positionInFrontOfCamera } from '../XRUtilities';
import { RAY_COLORS, updateCursorPosition, updateRayColor, updateRayLength } from '../XRRayAndCursor';
import { handleLogin } from '../../firebase/userAuthAndManagement';
import { getCSSVariableValue } from '../../utils/domUtils';

const FontJSON = '/fonts/Roboto-msdf.json';
const FontImage = '/fonts/Roboto-msdf.png';
const Backspace = '/textures/XRKeyboard/backspace.png';
const Enter = '/textures/XRKeyboard/enter.png';
const Shift = '/textures/XRKeyboard/shift.png';

const colors = {
  keyboardBack: new THREE.Color(getCSSVariableValue('--background-color')),
  panelBack: new THREE.Color(getCSSVariableValue('--background-color')),
  button: new THREE.Color(getCSSVariableValue('--background-color')),
  hovered: new THREE.Color(getCSSVariableValue('--XRButton-hover-color')),
  selected: new THREE.Color(getCSSVariableValue('--title-color')),
  errorText: new THREE.Color(getCSSVariableValue('--button-remove-color')),
};

let container, keyboard;
let currentLayoutButton;
let currentInputField;
let activeTextField;
let userNameText = ''; // Reference to the ThreeMeshUI.Text component for the username
let userPasswordText = ''; // Reference to the ThreeMeshUI.Text component for the password

let tempVector = new THREE.Vector3();

export let xrKeyboardObjectsForRaycasting = [];

export function createXRKeyboardUI() {
  if (!container) {
    console.log('Creating XR Keyboard UI');
    container = new THREE.Group();
    positionInFrontOfCamera(container, camera, 1.2, 1.4, -0.15);
    scene.add(container);

    createInputField("Enter username", 0.3, false); // For username
    createInputField("Enter password", 0.075, true); // For password
    activeTextField = userNameText; // Set the active text field to the username field

    createLoginButton(); 
    createLayoutOptionsPanel();
    setupEventListeners();
  } else 
  {
    console.log('XR Keyboard UI already exists');
    positionInFrontOfCamera(container, camera, 1.2, 1.4, -0.15);
    if(keyboard) positionInFrontOfCamera(keyboard, camera, 1.0, 0.8, -0.50);
    container.visible = true;
    if(keyboard) keyboard.visible = true;
  }
}

function updateElementState(element, state) {
  if (element && element.isUI) {
    if ( element.states[ state ] ) element.setState(state);
  }
}

function raycast() {

	return xrKeyboardObjectsForRaycasting.reduce( ( closestIntersection, obj ) => {

		// keys in panels that are hidden are not tested
		if ( !container.getObjectById( obj.id ) &&
			!keyboard.getObjectById( obj.id ) 
		) {

			return closestIntersection;
		}

    
		const intersection = raycaster.intersectObject( obj, true );
    
		// if intersection is an empty array, we skip
		if ( !intersection[ 0 ] ) return closestIntersection;
    
		// if this intersection is closer than any previous intersection, we keep it
		if ( !closestIntersection || intersection[ 0 ].distance < closestIntersection.distance ) {
      
      // Make sure to return the UI object, and not one of its children (text, frame...)
			intersection[ 0 ].object = obj;
      
			return intersection[ 0 ];
      
		}

		return closestIntersection;

	}, null );

}

export function updateKeyboardButtons(controller = null) {
  if (!keyboard) return;
  if (!container.visible) return;
  if (!keyboard.visible) return;

  console.log('Updating keyboard buttons');


  let intersect;
  if (isInVRSession && controller) {
    const [position, , direction] = getControllerData(controller);
    raycaster.set(position, direction);
  } else {
    raycaster.setFromCamera(mouse, camera);
  }
  intersect = raycast();

  if (intersect && intersect.object.isUI) {
    if (isInVRSession) {
      updateCursorPositionAndRay(controller, intersect);
      updateRayLength(controller, intersect.point);
    }
    updateElementState(intersect.object, 'hovered');
  }
    xrKeyboardObjectsForRaycasting.forEach(obj => {
      if (obj !== intersect?.object) updateElementState(obj, 'idle');
    });
  
}

export function handleKeyboardInteractions(controller) {
  if (!keyboard) return true;

  let intersect;

  if (isInVRSession && controller) {
    const [position, , direction] = getControllerData(controller);
    raycaster.set(position, direction);
  } else {
    raycaster.setFromCamera(mouse, camera);
  }

  intersect = raycast();

  if (intersect && intersect.object.isUI) {
    updateElementState(intersect.object, 'selected');  
    return false;  
  }

  return true
}

function updateCursorPositionAndRay(controller, intersect) {
  // Use the existing tempVector to copy the point coordinates without cloning
  if(intersect && intersect.point){
    tempVector.copy(intersect.point);
    const localPoint = controller.worldToLocal(tempVector);
    updateCursorPosition(controller, localPoint);
    updateRayColor(controller, RAY_COLORS.KEYBOARD);
  }
}

const setupEventListeners = () => {
  window.addEventListener('pointerdown', () => { setSelectState(true); });
  window.addEventListener('pointerup', () => { setSelectState(false); });
};

const createInputField = (title, yOffset = 0, isPassword = false) => {
  const inputField = new ThreeMeshUI.Block({
    fontFamily: FontJSON,
    fontTexture: FontImage,
    width: 1,
    height: 0.2,
    borderRadius: 0.02,
    justifyContent: 'center',
    backgroundColor: colors.panelBack,
    backgroundOpacity: 0,
  });

  inputField.position.set(0, yOffset, 0);
  container.add(inputField);

  const panelTitleField = createTitleBlock(title);
  const userEntryText = new ThreeMeshUI.Text({ content: '' });
  const userEntryTextField = createTextFieldBlock().add(userEntryText);

  inputField.add(panelTitleField, userEntryTextField);

  userEntryTextField.userData.isXRKeyboardButton = true;

  if (isPassword) {
    userPasswordText = userEntryText;
  } else {
    userNameText = userEntryText;
  }

  setupInputFieldStates(userEntryTextField, userEntryText);
  xrKeyboardObjectsForRaycasting.push(userEntryTextField);

  return inputField;
};

const createLayoutOptionsPanel = () => {
  const layoutOptions = new ThreeMeshUI.Block({
    fontFamily: FontJSON,
    fontTexture: FontImage,
    height: 0.2,
    width: 1,
    backgroundColor: colors.panelBack,
    backgroundOpacity: 0,
  });

  layoutOptions.add(
    createLayoutTitleBlock('Select a keyboard layout:'),
    createLayoutButtonsRow(['English', 'Nordic', 'German', 'Spanish'], 0),
    createLayoutButtonsRow(['French', 'Russian', 'Greek'], 1),
  );

  layoutOptions.position.set(0, -0.32, 0);
  container.add(layoutOptions);

  return layoutOptions;
};

const createLayoutButtonsRow = (buttonLabels, row) => {
  const buttonsRow = new ThreeMeshUI.Block({
    height: 0.075,
    width: 1,
    contentDirection: 'row',
    justifyContent: 'center',
    backgroundOpacity: 0,
  });

  buttonLabels.forEach(label => {
    const button = createLayoutButton(label);
    buttonsRow.add(button);
    if (label === 'English') {
      button.setState('selected');
      currentLayoutButton = button;
    }
  });

  return buttonsRow;
};

const createLayoutButton = (label) => {
  const button = new ThreeMeshUI.Block({
    height: 0.06,
    width: 0.2,
    margin: 0.012,
    justifyContent: 'center',
    borderRadius: 0.02,
    backgroundColor: colors.button,
    backgroundOpacity: 1,
  }).add(new ThreeMeshUI.Text({
    fontSize: 0.025,
    content: label,
  }));

  // Assign an identifying property for interactive elements
  button.userData.isXRKeyboardButton = true;

  const languageLayout = getLanguageLayout(label);

  setupLanguageButtonStates(button, languageLayout);
  xrKeyboardObjectsForRaycasting.push(button);
  return button;
};

const getLanguageLayout = (language) => {
  switch (language) {
      case 'English':
        return 'en';
      case 'Nordic':
        return 'nord';
      case 'German':
        return 'de';
      case 'Spanish':
        return 'es';
      case 'French':
        return 'fr';
      case 'Russian':
        return 'ru';
      case 'Greek':
        return 'el';
      default:
        return 'en';
    }
};

const setupLanguageButtonStates = (button, layout) => {
    // Idle state
    button.setupState({
      state: 'idle',
      attributes: {
        offset: 0.02,
        backgroundColor: colors.button,
        backgroundOpacity: 1,
      },
    });

    // Hovered state
    button.setupState({
      state: 'hovered',
      attributes: {
        offset: 0.02,
        backgroundColor: colors.hovered,
        backgroundOpacity: 1,
      },
    });

    // Selected state - triggers layout change
    button.setupState({
      state: 'selected',
      attributes: {
        offset: 0.01,
        backgroundColor: colors.selected,
        backgroundOpacity: 1,
      },
      onSet: () => {
        if (currentLayoutButton && currentLayoutButton !== button) {
          currentLayoutButton.setState('idle');
        }
        currentLayoutButton = button;

        if(keyboard) {
          clear( keyboard );
          keyboard.panels.forEach( panel => clear( panel ) );
        }
        
        makeKeyboard(layout); // Create keyboard with new layout
      },
    });
};

const setupInputFieldStates = (inputField, textField) => {
  // Idle state
  inputField.setupState({
    state: 'idle',
    attributes: {
      backgroundColor: colors.button,
      backgroundOpacity: 1,
      borderRadius: [0, 0, 0.02, 0.02],
      borderWidth: 0,
      borderColor: colors.selected,
      borderOpacity: 0
    },
  });

  // Hovered state
  inputField.setupState({
    state: 'hovered',
    attributes: {
      backgroundColor: colors.hovered,
      backgroundOpacity: 1,
      borderRadius: [0, 0, 0.02, 0.02],
		  borderWidth: 0.01,
		  borderColor: colors.selected,
		  borderOpacity: 1
    },
  });

  // Selected state - triggers layout change
  inputField.setupState({
    state: 'selected',
    attributes: {
      borderRadius: [0, 0, 0.02, 0.02],
      backgroundColor: colors.selected,
      backgroundOpacity: 1,
    },
    onSet: () => {
      if (currentInputField) {
        currentInputField.setState('idle');
      }

      currentInputField = inputField;
      activeTextField = textField;
      activeTextField.set({ content: '' });
    },
  });
};

const isValidEmail = (email) => {
  // This regex checks for:
  // 1. "@" not at the start
  // 2. At least one character between "@" and the dot
  // 3. The dot not directly after "@"
  // 4. The email ending with ".something" where something is at least 2 characters long
  const re = /^[^@]+@[^@.]+[.][a-zA-Z]{2,}$/;
  return re.test(email);
};

const makeKeyboard = (language) => {
    keyboard = new ThreeMeshUI.Keyboard({
      language: language,
      fontFamily: FontJSON,
      fontTexture: FontImage,
      fontSize: 0.035, // fontSize will propagate to the keys blocks
      backgroundColor: colors.keyboardBack,
      backgroundOpacity: 0.5,
      backspaceTexture: Backspace,
      shiftTexture: Shift,
      enterTexture: Enter,
    });
  
    positionInFrontOfCamera(keyboard, camera, 1.0, 0.8, -0.50);
    scene.add(keyboard);

    if(container) positionInFrontOfCamera(container, camera, 1.2, 1.4, -0.15);

    console.log('Keyboard created, now setting up keys'); 
  
    keyboard.keys.forEach((key) => {
      key.userData.isXRKeyboardButton = true;
      setupKeyStates(key);
      xrKeyboardObjectsForRaycasting.push(key);
    });

    console.log('Keys setup');
};

const setupKeyStates = (key) => {
    console.log('Setting up key states for key: ', key);
    key.setupState({
      state: 'idle',
      attributes: {
        offset: 0,
        backgroundColor: colors.button,
        backgroundOpacity: 1,
      },
    });
  
    key.setupState({
      state: 'hovered',
      attributes: {
        offset: 0,
        backgroundColor: colors.hovered,
        backgroundOpacity: 1,
      },
    });
  
    key.setupState({
      state: 'selected',
      attributes: {
        offset: -0.009,
        backgroundColor: colors.selected,
        backgroundOpacity: 1,
      },
      onSet: () => handleKeySelection(key),
    });
};

const handleKeySelection = (key) => {
    const command = key.info.command;
    switch (command) {
      case 'switch':
        setNextKeyboardPanel();
        break;
      case 'switch-set':
        setNextKeyboardCharset();
        break;
      case 'enter':
        activeTextField.set({ content: activeTextField.content + '\n' });
        break;
      case 'space':
        activeTextField.set({ content: activeTextField.content + ' ' });
        break;
      case 'backspace':
        activeTextField.set({ content: activeTextField.content.slice(0, -1) });
        break;
      case 'shift':
        keyboard.toggleCase();
        break;
      default:
        if (key.info.input) {
          activeTextField.set({ content: activeTextField.content + key.info.input });
        }
        break;
    }
};

function setNextKeyboardPanel() {
  if (keyboard) {
    keyboard.setNextPanel();
  }
}

function setNextKeyboardCharset() {
  if (keyboard) {
    keyboard.setNextCharset();
  }
}

function clear( uiComponent ) {

	scene.remove( uiComponent );

	// We must call this method when removing a component,
	// to make sure it's removed from the update registry.
	uiComponent.clear();

	uiComponent.traverse( ( child ) => {

		if ( xrKeyboardObjectsForRaycasting.includes( child ) ) xrKeyboardObjectsForRaycasting.splice( xrKeyboardObjectsForRaycasting.indexOf( child ), 1 );

	} );
}

const createTitleBlock = (content) => {
    return new ThreeMeshUI.Block({
      width: 1.0,
      height: 0.05,
      justifyContent: 'center',
      borderRadius: [0.02, 0.02, 0, 0],
      fontSize: 0.035,
      backgroundOpacity: 0.5,
    }).add(new ThreeMeshUI.Text({ content }));
};
  
const createTextFieldBlock = () => {
    return new ThreeMeshUI.Block({
      width: 1,
      height: 0.15,
      fontSize: 0.023,
      borderRadius: [0, 0, 0.02, 0.02],
      justifyContent: 'center',
      padding: 0.02,
      backgroundOpacity: 0,
    });
};

const createLayoutTitleBlock = (content) => {
    return new ThreeMeshUI.Block({
      height: 0.05,
      width: 0.5,
      justifyContent: 'center',
      backgroundOpacity: 0.5,
    }).add(new ThreeMeshUI.Text({
      fontSize: 0.02,
      content,
    }));
};

const createLoginButton = () => {
  const loginButton = new ThreeMeshUI.Block({
      width: 0.5, // Adjust the width as needed
      height: 0.1, // Adjust the height as needed
      alignContent: 'center',
      justifyContent: 'center',
      fontFamily: FontJSON,
      fontTexture: FontImage,
      borderRadius: 0.02,
      borderColor: colors.selected,
      backgroundColor: colors.button,
      backgroundOpacity: 1,
  }).add(new ThreeMeshUI.Text({
      content: 'Login',
      fontSize: 0.04,
  }));

  // Position the login button between password input and layout options
  loginButton.position.set(0, -0.1, 0); // Adjust Y position as needed
  loginButton.userData.isXRKeyboardButton = true;
  loginButton.layers.set(2);

  container.add(loginButton);
  xrKeyboardObjectsForRaycasting.push(loginButton);

  // Setup interaction states including the login logic on 'selected'
  loginButton.setupState({
      state: 'idle',
      attributes: {
          backgroundColor: colors.button,
          borderWidth: 0,
          backgroundOpacity: 1,
      },
  });

  loginButton.setupState({
      state: 'hovered',
      attributes: {
          backgroundColor: colors.hovered,
          borderWidth: 0.01,
          backgroundOpacity: 1,
      },
  });

  loginButton.setupState({
      state: 'selected',
      attributes: {
          backgroundColor: colors.selected,
          backgroundOpacity: 1,
      },
      onSet: () => {
          // Validate username and password before login
          let validationPassed = true;
          if (!isValidEmail(userNameText.content)) {
              userNameText.set({ content: "Not a valid email" });
              validationPassed = false;
          } 
          if (userPasswordText.content.length === 0) {
              userPasswordText.set({ content: "Password cannot be empty" });
              validationPassed = false;
          }

          if (validationPassed) {
              // Call your login handling function here
              handleLogin(userNameText.content, userPasswordText.content, (errorMsg) => {
                  if (errorMsg) {
                      userPasswordText.set({ content: errorMsg });
                  }
              });
          }
      },
  });
};

export function hideXRKeyboardUI() {
  if (container) {
    container.visible = false;
  }

  if (keyboard) {
    keyboard.visible = false;
  }
}

