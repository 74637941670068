//Purpose: The core module for basic popup operations like creating, showing, and closing popups.

import { setDisplayingPopup } from "../core/sharedState";

class PopupManager {
    constructor() {
        this.activePopup = null; // Stores the currently active popup
        this.activePopupCallbackAtClose = null; // Stores the callback to be called when the popup is closed
    }

    createPopup(content, additionalClasses = []) {
        const popup = document.createElement('div');
        popup.className = 'popup ' + additionalClasses.join(' ');
        if (content instanceof HTMLElement) {
            popup.appendChild(content);
        } else {
            popup.innerHTML = content;
        }
        
        if (this.boundStopPropagationHandler) {
            document.removeEventListener('click', this.boundStopPropagationHandler);
        }

        this.boundStopPropagationHandler = this.stopPropagationHandler.bind(this);
        popup.addEventListener('click', this.boundStopPropagationHandler);

        if (this.boundCloseHandler) {
            document.removeEventListener('click', this.boundCloseHandler);
        }
        this.boundCloseHandler = this.closeHandler.bind(this);
        document.addEventListener('click', this.boundCloseHandler);
    
        document.body.appendChild(popup);
        return popup;
    }

    closeHandler(e) {
        if (e.target.classList.contains('submit-button-class')) {
            return; // Don't do anything if the submit button was clicked
        }
        if (this.activePopup) {
            this.closePopup(this.activePopup);
        }
    }

    stopPropagationHandler(e) {

        // Uncomment if eventually needed
        //e.stopPropagation();
    }

    showPopup(popup, callbackAtClose = null, dontAdjustPointerLock = false) {
        if (this.activePopup) {
            this.closePopup(this.activePopup, dontAdjustPointerLock);
        }
        
        setDisplayingPopup(true, dontAdjustPointerLock);
        popup.style.display = 'block';
        setTimeout(() => {
            this.activePopupCallbackAtClose = callbackAtClose;
            popup.style.opacity = '1';
            popup.style.transform = 'translate(-50%, -50%) scale(1)';
        }, 50);
        this.activePopup = popup;
    }

    closePopup(popup, dontAdjustPointerLock = false) {
        if (popup) {
            setDisplayingPopup(false, dontAdjustPointerLock);

            document.removeEventListener('click', this.boundCloseHandler);
            popup.removeEventListener('click', this.stopPropagationHandler);
    
            popup.style.opacity = '0';
            popup.style.transform = 'translate(-50%, -50%) scale(0.1)';
            setTimeout(() => {
                if (document.body.contains(popup)) {
                    document.body.removeChild(popup);
                }
            }, 500);

            this.activePopup = null;
            if (this.activePopupCallbackAtClose) {
                this.activePopupCallbackAtClose();
                this.activePopupCallbackAtClose = null;
            }
        }
    }   
}

export default new PopupManager();
