// XR/XRInteractions.js
import { cameraRig, isLoading } from '../core/sharedState.js';
import { traversableGrounds } from '../models/modelLoader.js';
import { interactionSystem, inventoryManagementSystem, systemManager } from '../ECS-utils/managerECS.js';
import { NUM_CONTROLLERS, controllers } from './XRControllers.js';
import { getControllerData } from './XRUtilities.js';
import { RAY_COLORS, updateRayColor } from './XRRayAndCursor.js';
import { raycaster } from '../utils/constants.js';
import { animateFootprints } from '../interactions/uxHelpers.js';
import XRPopupManager from './UI/XRPopupManager.js';
import { vibrationOnSelect, vibrationOnTeleport } from './XRVibrationFeedback.js';
import { handleKeyboardInteractions, hideXRKeyboardUI } from './Keyboard/XRKeyboard.js';

export let activeControllerIndex = 0; // The controller that is currently in 'interacting' mode
export let lastTriggeredControllerIndex = 0; // The controller that was last triggered
let controllerStates = new Array(NUM_CONTROLLERS).fill(false);  // false indicates 'switching' mode, true indicates 'interacting' mode

export function handleControllerInteraction(event) {
    if (isLoading) return; // Check loading state to prevent interactions during loading

    const controller = event.target;
    const controllerIndex = controllers.indexOf(controller);
    lastTriggeredControllerIndex = controllerIndex;

    // If the controller is not in interacting mode
    if (!controllerStates[controllerIndex]) {

        // If the controller is holding an item, go straight to object interaction
        if (holdingItemInTriggerHand()){
            console.log('controller is not in interacting mode, but holding an item')
            handleObjectInteraction(controller);
        }

        // Else, switch to interacting mode
        else switchControllerState(controllerIndex);
    }

    // If the controller is in interacting mode, handle object interaction
    else {
        let proceedWithNextInteractionCheck = handleKeyboardInteractions(controller);
        if(proceedWithNextInteractionCheck) proceedWithNextInteractionCheck = XRPopupManager.handleXRButtonInteractions(controller);
        if(proceedWithNextInteractionCheck) handleObjectInteraction(controller);
    }

}

function handleObjectInteraction(controller) {
    const [position, quaternion, direction] = getControllerData(controller);

    // Reset ray color to default at the start of each interaction check
    updateRayColor(controller, RAY_COLORS.DEFAULT);
    
    // Hide any active VR popups if the user is clicking the controller
    if(XRPopupManager.activeVrPopup) XRPopupManager.hidePopup();

    // Check for clickable objects and handle interactions
    const intersectsWithClickableObject = interactionSystem.handleIntersectedObject(position, direction, true);
    vibrationOnSelect(controller);

    // Update ray color to indicate clickable object
    if (intersectsWithClickableObject)  {
        updateRayColor(controller, RAY_COLORS.CLICKABLE);
    }

    // Handle ground intersections for teleportation if no interactable objects were hit
    else {

        let itemInTheHandThatTriggersFire = false;
        
        const activeItemEntity = inventoryManagementSystem.checkForActiveItem();
        if (activeItemEntity){
            const activeItemStatusComponent = activeItemEntity.getComponent('StatusComponent');
            const convertIndexToHand = lastTriggeredControllerIndex === 0 ? 'right' : 'left';
            itemInTheHandThatTriggersFire = convertIndexToHand === activeItemStatusComponent.equippedInHand;
        }

        if (itemInTheHandThatTriggersFire) return;

        raycaster.set(position, direction);
        const groundIntersects = raycaster.intersectObjects(traversableGrounds, true);
        if (groundIntersects.length > 0) {
            const hit = groundIntersects[0];
            // Ensure the surface is mostly upward facing
            if (hit.face.normal.y > 0.8) 
            {        
                //Teleportation logic
                cameraRig.position.copy(hit.point);
                systemManager.emitEvent('playSound', 'teleport');
                animateFootprints(hit.point, direction);
                updateRayColor(controller, RAY_COLORS.GROUND);
                vibrationOnTeleport(controller);
                hideXRKeyboardUI();
            }
        }
    }
}

export function holdingItemInTriggerHand(){

    const activeItemEntity = inventoryManagementSystem.checkForActiveItem();

    if(activeItemEntity){
        const activeItemStatusComponent = activeItemEntity.getComponent('StatusComponent');
        const convertIndexToHand = lastTriggeredControllerIndex === 0 ? 'right' : 'left';

        // If the controller is holding an item, and the item is in the hand that triggers the fire
        if (convertIndexToHand === activeItemStatusComponent.equippedInHand){
            return true;
        }
    }
    return false;
}

export function switchControllerState(controllerIndex){
    for (let i = 0; i < controllerStates.length; i++) {
        controllerStates[i] = false;
    }
    activeControllerIndex = controllerIndex;
    controllerStates[controllerIndex] = true;
}


