import { System } from './system.js';
import * as THREE from 'three';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import { unlockPointer } from '../core/fpsControls.js';
import { setDisplayingPopup } from '../core/sharedState.js';
import { hideAllUIElements } from '../utils/uiUtils.js';
import popupManager from '../ui/popupManager.js';

export class PDFRenderingSystem extends System {
    constructor(entityManager, systemManager) {
        super(entityManager, systemManager);
        this.requiredComponents = ['PDFRenderingComponent', 'RenderComponent'];
        GlobalWorkerOptions.workerSrc = 'pdf.worker.mjs';
        this.setupPdfOverlay();
        this.currentPdf = null;
        this.currentPageNumber = 1;
        this.systemManager.onEvent('showPDF', this.showPdfOverlay.bind(this));
        this.systemManager.onEvent('cleanUp', this.cleanup.bind(this));
        this.zoomLevel = 2.0;
        this.pdfCache = new Map();
    }

    update(deltaTime) {
        super.update(deltaTime);
        this.getEntities().forEach(entity => {
            const pdfComponent = entity.getComponent('PDFRenderingComponent');
            const renderComponent = entity.getComponent('RenderComponent');

            if (pdfComponent && renderComponent && !pdfComponent.texture && !pdfComponent.isLoading) {
                pdfComponent.isLoading = true;
                this.loadAndRenderPDFToMesh(pdfComponent, renderComponent.mesh).then(() => {
                    pdfComponent.isLoading = false;
                }).catch(error => {
                    console.error('Error loading PDF:', error);
                    pdfComponent.isLoading = false;
                });
            }
        });
    }

    async loadAndRenderPDFToMesh(pdfComponent, mesh) {
        const pdf = await getDocument(pdfComponent.pdfURL).promise;
        const page = await pdf.getPage(pdfComponent.pageNumber);

        const scale = 4;
        const viewport = page.getViewport({ scale: scale });

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        context.save();
        context.scale(1, -1);
        context.translate(0, -canvas.height);

        const renderContext = { canvasContext: context, viewport: viewport };
        await page.render(renderContext).promise;

        context.restore();

        pdfComponent.texture = new THREE.CanvasTexture(canvas);
        pdfComponent.texture.magFilter = THREE.LinearFilter;
        pdfComponent.texture.minFilter = THREE.LinearMipmapLinearFilter;
        mesh.material.map = pdfComponent.texture;
        mesh.material.needsUpdate = true;
    }

    setupPdfOverlay() {
        let touchStartX = 0;
        let touchStartY = 0;

        const overlay = document.createElement('div');
        overlay.id = 'pdf-overlay';
        overlay.style.display = 'none';

        const canvas = document.createElement('canvas');
        canvas.id = 'pdf-canvas';
        overlay.appendChild(canvas);

        const prevBtn = document.createElement('button');
        prevBtn.id = 'prev-page-btn';
        prevBtn.textContent = 'Previous Page';
        overlay.appendChild(prevBtn);

        const nextBtn = document.createElement('button');
        nextBtn.id = 'next-page-btn';
        nextBtn.textContent = 'Next Page';
        overlay.appendChild(nextBtn);

        const closeBtn = document.createElement('button');
        closeBtn.id = 'close-pdf-btn';
        closeBtn.textContent = 'Close';
        overlay.appendChild(closeBtn);


        // Zoom In Button
        const zoomInBtn = document.createElement('button');
        zoomInBtn.textContent = '+';
        zoomInBtn.id = 'zoom-in-btn';
        zoomInBtn.className = 'zoom-btn';
        zoomInBtn.addEventListener('click', () => {
            this.zoomLevel *= 1.1;
            this.renderPage(this.currentPageNumber);
        });

        // Zoom Out Button
        const zoomOutBtn = document.createElement('button');
        zoomOutBtn.textContent = '-';
        zoomOutBtn.id = 'zoom-out-btn';
        zoomOutBtn.className = 'zoom-btn';
        zoomOutBtn.addEventListener('click', () => {
            this.zoomLevel *= 0.9;
            this.renderPage(this.currentPageNumber);
        });

        overlay.appendChild(zoomInBtn);
        overlay.appendChild(zoomOutBtn);

        const buttonsContainer = document.createElement('div');
        buttonsContainer.className = 'buttons-container';

        // Append buttons to buttonsContainer
        buttonsContainer.appendChild(prevBtn);
        buttonsContainer.appendChild(nextBtn);
        buttonsContainer.appendChild(zoomInBtn);
        buttonsContainer.appendChild(zoomOutBtn);

        // Append buttonsContainer to overlay
        overlay.appendChild(buttonsContainer);
        overlay.appendChild(closeBtn);

        document.body.appendChild(overlay);        

        prevBtn.addEventListener('click', async () => {
            if (this.currentPageNumber > 1) {
                this.currentPageNumber--;
                await this.renderPage(this.currentPageNumber);
            }
        });

        nextBtn.addEventListener('click', async () => {
            if (this.currentPdf && this.currentPageNumber < this.currentPdf.numPages) {
                this.currentPageNumber++;
                await this.renderPage(this.currentPageNumber);
            }
        });

        closeBtn.addEventListener('click', (e) => {        
            e.stopPropagation();
            hideAllUIElements();
        });

        overlay.addEventListener('wheel', (event) => {
            event.preventDefault();

            const scrollAmount = -event.deltaY * 1; // Adjust this value as needed
            const currentTop = parseInt(canvas.style.top || 0);
            const newTop = currentTop + scrollAmount;

            // Calculate the boundaries
            const upperLimit = overlay.clientHeight * 0.4; // 40% above the document
            const lowerLimit = -canvas.clientHeight + overlay.clientHeight * 0.6; // 40% below the document

            // Constrain the new top position within the boundaries
            if (newTop < upperLimit && newTop > lowerLimit) {
                canvas.style.top = `${newTop}px`;
            }
        });

        overlay.addEventListener('touchstart', (event) => {
            touchStartX = event.touches[0].clientX;
            touchStartY = event.touches[0].clientY;
        });
    
        overlay.addEventListener('touchmove', (event) => {
            event.preventDefault();
        
            const touchEndX = event.touches[0].clientX;
            const touchEndY = event.touches[0].clientY;
        
            const deltaX = touchEndX - touchStartX;
            const deltaY = touchEndY - touchStartY;
        
            touchStartX = touchEndX;
            touchStartY = touchEndY;
        
            let newTop = parseInt(canvas.style.top || 0) + deltaY;
            let newLeft = parseInt(canvas.style.left || 0) + deltaX;
        
            // Boundary checks for vertical movement
            const upperLimit = overlay.clientHeight * 0.2; // 20% above the document
            const lowerLimit = -canvas.clientHeight + overlay.clientHeight * 0.8; // 20% below the document
        
            // Adjusted boundary calculations for horizontal movement
            const leftLimit = -canvas.clientWidth + overlay.clientWidth * 0.2; // Allow moving all the way to the left edge with 20% margin
            const rightLimit = canvas.clientWidth - overlay.clientWidth * 0.2;; // Allow moving all the way to the right edge with 20% margin
        
            newTop = Math.min(Math.max(newTop, lowerLimit), upperLimit);
            newLeft = Math.max(Math.min(newLeft, rightLimit), leftLimit);
        
            canvas.style.top = `${newTop}px`;
            canvas.style.left = `${newLeft}px`;
        });
    }        

    async showPdfOverlay(pdfURL, pageNumber = 1) {

        if (this.pdfCache.has(pdfURL)) {
            console.log('PDF already loaded, using cached version');
            this.currentPdf = this.pdfCache.get(pdfURL);
        } else {
            console.log('PDF not loaded, loading now');
            this.currentPdf = await getDocument(pdfURL).promise;
            this.pdfCache.set(pdfURL, this.currentPdf); // Cache the loaded PDF
        }

        this.currentPageNumber = pageNumber;
        await this.renderPage(this.currentPageNumber);
        const overlay = document.getElementById('pdf-overlay');

        overlay.style.display = 'flex';
        document.getElementById('backdrop').style.display = 'block';

        setDisplayingPopup(true);
    }

    async renderPage(pageNumber) {
        const page = await this.currentPdf.getPage(pageNumber);
    
        // Adjust the scale based on the zoom level
        const scale = this.zoomLevel; // Use the current zoomLevel for scaling
        const viewport = page.getViewport({ scale: scale });
    
        const canvas = document.getElementById('pdf-canvas');
        const context = canvas.getContext('2d');
        canvas.width = viewport.width;
        canvas.height = viewport.height;
    
        // Clear the previous content
        context.clearRect(0, 0, canvas.width, canvas.height);
    
        // Render the page onto the canvas with the new scale
        const renderContext = { canvasContext: context, viewport: viewport };
        await page.render(renderContext).promise;
    }
    
    cleanup() {
        console.log('Cleaning up PDFRenderingSystem');
        // Clean up the cached PDFs if needed
        this.pdfCache.clear();
    }
    
}
