import * as THREE from 'three';
import { currentSceneName, scene } from '../core/sharedState.js';
import { interpolatePosition, interpolateRotation, releaseTween } from './interpolationManager.js';
import { shootableObjects } from '../models/modelLoader.js';
import { movementDataFrequency } from '../utils/constants.js';
import { getEnvironmentState } from './syncManager.js';
import { socket } from './multiplayerSetup.js';
import { entityManager } from '../ECS-utils/managerECS.js';
import { createPlayerMesh, createPlayerNameTextMesh, createSpeakerIcon, returnPlayerAttachedItemToWorld } from './playerManagerHelper.js';

// Object to store other players' data - local player data is stored as an entity and in the envionmentState object
export const players = {};
export let avatarModelCopies = {};

export function syncPlayersStoredInThisModuleWithEnvironmentState(data) {

    console.log('Current player state from playerMangerModule:', players);
    console.log('Syncing players with environment state:', data);

    // If not the local player, update the player state with the latest environment state
    if (data.playerId !== socket.id) {
        if (players[data.playerId]) {
            players[data.playerId].sceneName = data.sceneName;
            players[data.playerId].equippedItem = data.equippedItem;
        }
    }

    console.log('Player state after syncing with environment state:', players);
}

export async function spawnPlayer(playerId, initialPosition, playerName, sceneName, playerAvatar) {
    const speakerIcon = createSpeakerIcon();
    speakerIcon.visible = false;

    
    players[playerId] = {
        mesh: null,
        playerAvatar: playerAvatar,
        playerId: playerId,
        playerName: playerName,
        equippedItem: null,
        position: initialPosition,
        sceneName: sceneName,
        speakerIcon: speakerIcon,
        isVR: false,
        controllers: { left: null, right: null }
    };
    
    const playerMesh = await createPlayerMesh(initialPosition, playerAvatar, playerId);
    playerMesh.userData.playerId = playerId;
    playerMesh.add(speakerIcon); 
    players[playerId].mesh = playerMesh;
    shootableObjects.push(playerMesh); 

    console.log('Player spawned, the player pool is now:', players);

    const playerNameTextMesh = createPlayerNameTextMesh(playerName, playerMesh);
    players[playerId].textMesh = playerNameTextMesh;
    setTimeout(() => playerMesh.add(playerNameTextMesh), 100);
}

export function updatePlayerPosition(playerId, newPosition, newRotation = new THREE.Quaternion()) {

    if (!players[playerId] && !players[playerId].mesh) {
        console.warn(`Cant update position for Player ${playerId} - does not exist (yet) .`);
        return;
    }

    const player = players[playerId];
    const playerMesh = player.mesh;

    // Update player position and rotation
    if(movementDataFrequency > 50) {
        interpolatePosition(playerMesh, newPosition, playerId);
        interpolateRotation(playerMesh, newRotation, playerId);
    }
    else {
        playerMesh.position.copy(newPosition);
        playerMesh.quaternion.copy(newRotation);
    }
}

export function removePlayer(playerId) {
    if (players[playerId]) {

        // Remove the player's name textMesh
        if (players[playerId].textMesh) {
            players[playerId].textMesh.geometry.dispose();
            if (typeof players[playerId].textMesh.material.dispose === 'function') {
                players[playerId].textMesh.material.dispose();
            }
            players[playerId].textMesh.parent.remove(players[playerId].textMesh);
            delete players[playerId].textMesh;
        }

        // Release any interpolation tweens that were being used by the player who left
        releaseTween(playerId);

        // Remove the player mesh from the shootable objects array
        const index = shootableObjects.indexOf(players[playerId].mesh);
        if (index > -1) {
            shootableObjects.splice(index, 1);
        }
    
        // Remove the player's mesh from the scene
        scene.remove(players[playerId].mesh);
        players[playerId].mesh.geometry.dispose();
        players[playerId].mesh.material.dispose();

        // Remove VR controller meshes if the player is a VR player
        if (players[playerId].isVR) {
            Object.values(players[playerId].controllers).forEach(controller => {
                if (controller.mesh) {
                    scene.remove(controller.mesh);
                    controller.mesh.geometry.dispose();
                    controller.mesh.material.dispose();
                }
            });
        }

        if (players[playerId].analyser) {
            delete players[playerId].analyser;
        }
        // Cleanup speaker icon
        if (players[playerId].speakerIcon) {
            players[playerId].speakerIcon.material.dispose(); // Dispose of the material
            players[playerId].mesh.remove(players[playerId].speakerIcon); // Remove the sprite from the scene
            delete players[playerId].speakerIcon; // Remove the reference
        }

        // Delete player data
        delete players[playerId];
    }
}

async function setPlayerVisibility(player, visible) {
    player.mesh.visible = visible;
    if (player.textMesh) player.textMesh.visible = visible;

    // Set VR controller visibility if the player is a VR player
    if (player.isVR) {
        Object.values(player.controllers).forEach(controller => {
            if (controller.mesh) controller.mesh.visible = visible;
        });
    }
}

export function updatePlayersVisibility() {
    Object.values(players).forEach(player => {
        console.log('Checking player:', player);
        if (player.sceneName === currentSceneName) {
            setPlayerVisibility(player, true);
            scene.add(player.mesh);

            // Add VR controller meshes if the player is a VR player
            if (player.isVR) {
                Object.values(player.controllers).forEach(controller => {
                    if (controller.mesh) scene.add(controller.mesh);
                });
            }
        } else {
            console.log('Hiding player:', player.playerId);
            removeAllItemsFromPlayer(player.playerId);
            setPlayerVisibility(player, false);
        }
    });
}
    
export function removeAllItemsFromPlayer(playerId) {
    const player = players[playerId];
    if (!player) {
        console.warn(`Player ${playerId} does not exist, this should not happen, please investigate.`);
        return;
    }

    // Check and remove the equipped item, if any
    if (player.equippedItem) {

        if(player.isVR){
            object.values(player.controllers).forEach(controller => {
                if (controller.mesh) controller.mesh.remove(player.equippedItem);
            });
        }
        else player.mesh.remove(player.equippedItem);
        player.equippedItem = null;
    }
}

export function removeAllItemsFromAllPlayers() {
    Object.keys(players).forEach(playerId => {
        removeAllItemsFromPlayer(playerId);
    });
}

export function updatePlayerModelWithItem(itemEntity, data) {

    console.log('updating player model with item:', data);

    const environmentState = getEnvironmentState();

    // Check which hand the item is being equipped to
    let controllerHand = "";
    if(environmentState.items[data.itemId] && environmentState.items[data.itemId].controllerHand){
        controllerHand = environmentState.items[data.itemId].controllerHand;
    }
    
    const player = players[data.playerId];
    const itemModel = itemEntity.getComponent('RenderComponent').mesh;
    if (player && itemModel) {

        if(player.isVR){
            if(controllerHand === "left") player.controllers.left.mesh.add(itemModel);
            else player.controllers.right.mesh.add(itemModel);   
        }
        else player.mesh.add(itemModel);

        itemModel.position.set(player.isVR ? 0 : 0.34, player.isVR ? 0 : 0.9, player.isVR ? 0 : -0.1);
        itemModel.rotation.set(0, 0, 0);
        itemModel.visible = true;
        player.equippedItem = itemModel;
    } else {
        console.warn(`Player ${data.playerId} or item ${data.itemId} does not exist.`);
    }
}

export function removeItemFromPlayerModel(data) {

    console.log('Removing item from player model:', data);
    const player = players[data.playerId];
    
    if (player && data.itemId) {

        if(player.isVR){
            for(const controller in player.controllers){
                if(player.controllers[controller].mesh) player.controllers[controller].mesh.remove(player.equippedItem);
            }
        }
        else player.mesh.remove(player.equippedItem);
        
        player.equippedItem = null;

        const itemEntity = entityManager.findEntityByObjectName(data.itemId);
        if(itemEntity)returnPlayerAttachedItemToWorld(itemEntity, data.itemScene);
    } else {
        console.warn(`Player ${data.playerId} does not have an equipped item.`);
    }
}

//VR Players
export function updateControllerPosition(playerId, controllerData) {
    if (!players[playerId]) {
        console.warn('Player not found:', playerId);
        return;
    }

    if (!players[playerId].isVR) {
        players[playerId].isVR = true;

        // Initialize controller meshes for the first time
        players[playerId].controllers = {
            left: { mesh: createControllerMesh(playerId, true), position: new THREE.Vector3(), rotation: new THREE.Quaternion() },
            right: { mesh: createControllerMesh(playerId, false), position: new THREE.Vector3(), rotation: new THREE.Quaternion() }
        };

        // Add controller meshes to the scene
        Object.values(players[playerId].controllers).forEach(controller => {
            scene.add(controller.mesh);
        });
    }

    controllerData.forEach(data => {
        const controller = players[playerId].controllers[data.controllerId];
        if (controller && controller.mesh) {

            const uniqueId = playerId + "___" + data.controllerId;
            // Update controller mesh position and rotation
            controller.position.set(data.position.x, data.position.y, data.position.z);
            controller.rotation.set(data.rotation[0], data.rotation[1], data.rotation[2], data.rotation[3]);

            interpolatePosition(controller.mesh, controller.position, uniqueId);
            interpolateRotation(controller.mesh, controller.rotation, uniqueId);


        } else {
            console.warn('Controller mesh not found for', data.controllerId, 'of player', playerId);
        }
    });
}

export function isPlayerVR(playerId) {
    return players[playerId] && players[playerId].isVR;
}

function createControllerMesh(playerId, isLeft) {

    const children = avatarModelCopies[playerId].children;

    // Check if children is an object and not null
    if (typeof children === 'object' && children !== null) {
        const controllerType = isLeft ? 'leftController' : 'rightController';

        // Find and return the first matching controller
        for (const key in children) {
            if (children.hasOwnProperty(key)) {
                const child = children[key];
                if (child.userData && child.userData[controllerType]) {
                    return child;
                }
            }
        }
    }

    // Create a default mesh if no suitable controller is found
    return createDefaultMesh();
}

function createDefaultMesh() {
    const geometry = new THREE.BoxGeometry(0.1, 0.1, 0.1);
    const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
    return new THREE.Mesh(geometry, material);
}
