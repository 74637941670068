import * as THREE from 'three';

export class TriggerComponent {
    constructor(options) {
        this.type = 'TriggerComponent';
        this.object = options.object;
        this.useObjectData = options.useObjectData || false;
        this.objectData = null;
        this.enteredTrigger = false;
        this.eventDropActiveItem = options.eventDropActiveItem || "";
        this.eventLoadRoom = options.eventLoadRoom || "";
        this.eventPlayAnimation = options.eventPlayAnimation || "";
        this.eventPlayAudio = options.eventPlayAudio || "";
        this.eventShowMessage = options.eventShowMessage || "";
        this.eventShowMessageDuration = options.eventShowMessageDuration || 0;
        this.boundingBox = new THREE.Box3().setFromObject(options.object);
    }

    updateBoundingBox() {
        this.boundingBox.setFromObject(this.object);
    }

    getBoundingBox() {
        return this.boundingBox;
    }
}
