// XR/XRControllers.js
import { isInVRSession, renderer, selectState, setSelectState } from '../core/sharedState.js';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory.js';
import { setCursorVisibility, setupControllerRay, setupCursor, setRayVisibility } from './XRRayAndCursor.js';
import { activeControllerIndex, handleControllerInteraction } from './XRInteractions.js';
import { isMultiplayerSession } from '../multiplayer/sessionManager.js';
import { camera, cameraRig } from '../core/sharedState.js';
import * as THREE from 'three';
import { sendControllerData, sendPlayerMovementData } from '../multiplayer/playerMovementDataSender.js';
import { checkVRHover } from './XRHover.js';
import { updateKeyboardButtons } from './Keyboard/XRKeyboard.js';
import { inventoryManagementSystem } from '../ECS-utils/managerECS.js';
import { objectToBeConsideredForRaycast, shootableObjects } from '../models/modelLoader.js';
import { rayLengthForObjectsInteraction } from '../utils/constants.js';
import XRPopupManager from './UI/XRPopupManager.js';

export const NUM_CONTROLLERS = 2;
export let controllers = [];
export let controllerGrips = [];

let tempVector = new THREE.Vector3();
let vrPositionData = { position: new THREE.Vector3() };
export let cursors = [];

const hoverCheckRaycasters = Array(NUM_CONTROLLERS).fill().map(() => {
    const raycaster = new THREE.Raycaster();
    raycaster.firstHitOnly = true;
    raycaster.far = rayLengthForObjectsInteraction;
    return raycaster;
});

export function initializeXRControllers() {
    const controllerModelFactory = new XRControllerModelFactory();
    controllers = [...Array(NUM_CONTROLLERS).keys()].map(index => {
        const controller = renderer.xr.getController(index);
        setupControllerRay(controller);
        controller.addEventListener( 'selectstart', () => {setSelectState(true);} );
        controller.addEventListener( 'selectend', () => {setSelectState(false);} );
        controller.addEventListener('selectstart', handleControllerInteraction);
        controller.isHand = index === 0 ? "right" : "left";
        return controller;
    });

    console.log("controllers:", controllers );

    cursors = controllers.map(controller => setupCursor(controller));
    controllerGrips = controllers.map((_, index) => {
        const grip = renderer.xr.getControllerGrip(index);
        grip.add(controllerModelFactory.createControllerModel(grip));
        cameraRig.add(grip);
        return grip;
    });
    cameraRig.add(...controllers, ...controllerGrips);
}

export function updateXRControls() {
    controllers.forEach((controller, index) => {
        // Update hover checks for the active controller
        // Hover checks will be performed on objectToBeConsideredForRaycast (all clickable objects in the scene)
        if (index === activeControllerIndex) {
            checkVRHover(controller, hoverCheckRaycasters[index], true, objectToBeConsideredForRaycast);
            updateKeyboardButtons(controller);
            XRPopupManager.updateXRButtons(controller);
        }

        // Update hover checks for the inactive controller if there's an equipped item (it will always be held by the inactive controller)
        // Hover checks will be performed on shootableObjects (all objects that has a visble mesh and is not an item)
        else if (inventoryManagementSystem?.inventory?.activeItem) {
            checkVRHover(controller, hoverCheckRaycasters[index], false, shootableObjects);
        }

        // Only update the ray visibility for the active controller
        const rayMesh = controller.getObjectByName("rayMesh");
        if (rayMesh) rayMesh.visible = index === activeControllerIndex;
    });

    // Send movement data to server
    if(isInVRSession && isMultiplayerSession) {
        sendControllerData(controllers);

        // Get world position for the camera and cameraRig
        camera.getWorldPosition(tempVector);
        vrPositionData.position.x = tempVector.x;
        cameraRig.getWorldPosition(tempVector);
        vrPositionData.position.y = tempVector.y;
        camera.getWorldPosition(tempVector);
        vrPositionData.position.z = tempVector.z;

        sendPlayerMovementData(vrPositionData, camera);
    }
}

export function setControllerRayAndCursorVisibilty(isVisible) {
    controllers.forEach(controller => {
        controller.visible = isVisible;

        // Hide the cursor if the controller is not visible
        setCursorVisibility(controllers, isVisible);

        // Hide the grip model if the controller is not visible
        const grip = controllerGrips[controllers.indexOf(controller)];
        grip.visible = isVisible;

        // Hide the ray if the controller is not visible
        setRayVisibility(controllers, isVisible);
    });
}

export function getActiveController() {
    return controllers[activeControllerIndex];
}

export function getControllerAtIndex(index) {
    return controllers[index];
}

export function getControllerAtHand(hand) {
    return controllers.find(controller => controller.isHand === hand);
}

