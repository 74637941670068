import * as THREE from 'three';

export const aerialPerspectiveVertexShader = `

varying vec2 vUv;
varying float vDistanceFromCamera;

void main() {
    // Calculate the distance from the center of the object
    float distanceFromCenter = length(position);
    vec3 pos = position;
  
    // Update the model view position with the displaced position
    vec4 mvPosition = modelViewMatrix * vec4(pos, 1.0);

    vDistanceFromCamera = length(-mvPosition.xyz);
    vUv = uv;
    gl_Position = projectionMatrix * mvPosition;
}
`;

export const aerialPerspectiveFragmentShader = `
uniform sampler2D baseTexture;
uniform float gamma;
varying vec2 vUv;
varying float vDistanceFromCamera;

void main() {
  vec4 texColor = pow(texture(baseTexture, vUv), vec4(gamma));

  // Lift Shadows
  texColor.rgb += 0.1;  // Add a constant value to lift the shadows. Adjust as needed.

  float maxDistance = 300.0;

  // Use smoothstep to soften the aerial effect
  float aerialFactor = smoothstep(0.0, 1.0, vDistanceFromCamera / maxDistance);

  vec3 atmosphericColor = vec3(0.5, 0.7, 1.0);

  // Apply the aerial perspective
  vec3 finalColor = mix(texColor.rgb, atmosphericColor, aerialFactor * 0.3); 

  gl_FragColor = vec4(finalColor, 1.0);
}

`;

export const aerialPerspectiveProperties = {
  transparent: false,
  depthWrite: true,
  depthTest: true,
  blending: THREE.NormalBlending,
  side: THREE.FrontSide,
  lights: false,
};

export const aerialPerspectiveUniforms = {
  baseTexture: { value: null },
  gamma: { value: 0.7 },
};