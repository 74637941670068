import * as THREE from 'three';

let frameCount = 0;
let lastLogTime = performance.now();

// Function to convert degrees to radians
export function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
}

// Function to convert radians to degrees
export function radiansToDegrees(radians) {
    return radians * (180 / Math.PI);
}

// Function to clamp a value between a min and max
export function clamp(value, min, max) {
    return Math.max(min, Math.min(value, max));
}

// Function to get a random number between a range
export function getRandomInRange(min, max) {
    return Math.random() * (max - min) + min;
}

// Function to check if a value is within a certain range
export function isWithinRange(value, lowerBound, upperBound) {
    return value >= lowerBound && value <= upperBound;
}

// Function to linearly interpolate between two values
export function lerp(start, end, factor) {
    return (1 - factor) * start + factor * end;
}

export function copyToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    
    const successful = document.execCommand('copy');
    document.body.removeChild(textArea);

    if (!successful) {
        alert('Failed to copy automatically. Please copy the link manually.');
    }
}

export function logFPS() {
    // Increment the frame count
    frameCount++;

    // Get the current time
    const now = performance.now();

    // Calculate the elapsed time since the last log
    const elapsedTime = now - lastLogTime;

    // Check if a second or more has passed since the last log
    if (elapsedTime >= 1000) {
        // Calculate and log the FPS
        const fps = (frameCount / elapsedTime) * 1000;
        console.log(`FPS: ${fps.toFixed(2)}`);

        // Reset the frame count and last log time
        frameCount = 0;
        lastLogTime = now;
    }
}

export function filterDataByAllowedKeys(data, allowedKeys) {
    const filteredData = {};
    Object.keys(data).forEach(key => {
        if (allowedKeys.includes(key)) {
            filteredData[key] = data[key];
        }
    });
    return filteredData;
}

export async function checkMicrophoneInput(stream) {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    source.connect(analyser);
    analyser.fftSize = 2048;

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    function analyze() {
        analyser.getByteTimeDomainData(dataArray);
        let isSpeaking = dataArray.some(value => value !== 128); // 128 is the zero level
        if (isSpeaking) {
            console.log('Microphone is picking up sound');
        } else {
            console.log('Microphone is not picking up sound');
        }
        requestAnimationFrame(analyze);
    }
    analyze();
}

export function ensureVector3(point) {
    if (point instanceof THREE.Vector3) {
      return point.clone();
    } else if (typeof point === 'object' && 'x' in point && 'y' in point && 'z' in point) {
      return new THREE.Vector3(point.x, point.y, point.z);
    } else {
      throw new Error('Invalid point data');
    }
  }
  






