import * as THREE from 'three';
import { camera, renderer, cameraRig } from '../core/sharedState.js';
import { traversableGrounds } from '../models/modelLoader.js';
import { getIntersectingPoint } from '../core/raycaster.js';
import { sendPlayerMovementData } from '../multiplayer/playerMovementDataSender.js';
import { initMobileHoverControls } from './mobileHover.js';
import { interactionSystem } from '../ECS-utils/managerECS.js';
import { animateFootprints, hideFootprints } from '../interactions/uxHelpers.js';
import { useMobileCursor } from '../utils/constants.js';

const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();

// Camera Rotation Globals
let isDragging = false;
let prevTouch = { x: 0, y: 0 };
const sensitivity = 0.005;
let azimuthalAngle = 0;
let polarAngle = Math.PI / 2;

// Movement and Interactions Globals
let moving = false;
let lastTap = 0;
let touchStartTime = 0;
let touchStartPosition = { x: 0, y: 0 };
const heightOffset = new THREE.Vector3(0, 1.85, 0);
const intersectGroundRayDirection = new THREE.Vector3(0, -1, 0);
let endPosition = new THREE.Vector3();
let startPosition = new THREE.Vector3();
let rotationQuaternion = new THREE.Quaternion().setFromEuler(new THREE.Euler());
let currentMovementId = null; 
const reusableVector = new THREE.Vector3();
const reusableQuaternion1 = new THREE.Quaternion();
const reusableQuaternion2 = new THREE.Quaternion();
const reusableVector1 = new THREE.Vector3(0, 1, 0);
const reusableVector2 = new THREE.Vector3(1, 0, 0);

// More general functions related to player controls - not specific to mobile
function adjustPlayerHeight(traversableGround) {
    const positionAboveGround = reusableVector.copy(cameraRig.position).add(heightOffset);
    const intersectPoint = getIntersectingPoint(traversableGround, positionAboveGround, intersectGroundRayDirection, 50);

    if (intersectPoint) {
        cameraRig.position.setY(intersectPoint.y);
    }
}

function movePlayerToPosition(targetPosition, callback = null) {
    const thisMovementId = Symbol();  // Create a unique ID for this movement
    currentMovementId = thisMovementId;  // Set this ID as the current movement

    moving = true;
    startPosition.set(cameraRig.position.x, cameraRig.position.y, cameraRig.position.z);
    endPosition.set(targetPosition.x, targetPosition.y, targetPosition.z);

    const length = endPosition.distanceTo(startPosition);
    const duration = THREE.MathUtils.clamp(length / 50, 1, 10) * 1000;
    const startTime = Date.now();

    function animate() {
        if (traversableGrounds.length === 0 || currentMovementId !== thisMovementId) return;  // Add a check for the movement ID
        const elapsed = Date.now() - startTime;
        const t = Math.min(elapsed / duration, 1);

        const easeInOut = t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        cameraRig.position.lerpVectors(startPosition, endPosition, easeInOut);
        adjustPlayerHeight(traversableGrounds);
    
        if (t < 1) {
            requestAnimationFrame(animate);
        } else {
            if (currentMovementId === thisMovementId) {  // Reset the current movement only if it hasn't been changed
                moving = false;

                if (callback) {
                    callback();
                }
            }
        }
    }
    
    animate();
}

function isDoubleClickOrTap(duration = 300) {
    const currentTime = Date.now();
    const doubleClickOrTab = currentTime - lastTap < duration;
    lastTap = currentTime;
    return doubleClickOrTab;
}

export function handleDoubleClickOrTap(event, mobile = false) {

    if(!isDoubleClickOrTap()) return;
    
    if(mobile){
        event.preventDefault();

        if(!useMobileCursor){
            mouse.x = (event.touches[0].clientX / window.innerWidth) * 2 - 1;
            mouse.y = -(event.touches[0].clientY / window.innerHeight) * 2 + 1;
        }
    }
    
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(traversableGrounds);
    
    if (intersects.length > 0) {
        
        const targetPosition = intersects[0].point;
        
        if (intersects[0].face.normal.y < 0.9) {
            // Push the target position back by 1 meter in the direction of the intersection normal
            targetPosition.add(intersects[0].face.normal.normalize().multiplyScalar(1)); 
        }
        
        const positionAboveGround = reusableVector.copy(targetPosition).add(heightOffset);
        const intersectionPoint = getIntersectingPoint(traversableGrounds, positionAboveGround, intersectGroundRayDirection, 50);
        const footprintsTarget = intersectionPoint ? intersectionPoint : targetPosition;
        //reverse the direction to face the target position
        const direction = reusableVector.copy(footprintsTarget).sub(cameraRig.position).normalize();
        direction.negate();
        
        animateFootprints(footprintsTarget, direction);
        movePlayerToPosition(targetPosition, hideFootprints);
    }
}

// Mobile Camera Rotation Handlers
function handleTouchStartForRotation(event) {
    event.preventDefault();
    isDragging = true;

    prevTouch.x = event.touches[0].clientX;
    prevTouch.y = event.touches[0].clientY;
}

function handleTouchMoveForRotation(event) {
    event.preventDefault();

    if (!isDragging) return;

    const touchMovePosition = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY
    };

    const deltaX = (touchMovePosition.x - prevTouch.x) * sensitivity;
    const deltaY = (touchMovePosition.y - prevTouch.y) * sensitivity;

    azimuthalAngle -= deltaX;
    polarAngle = THREE.MathUtils.clamp(polarAngle - deltaY, 0.1, Math.PI - 0.1);

    reusableQuaternion1.setFromAxisAngle(reusableVector1, azimuthalAngle);
    rotationQuaternion.multiply(reusableQuaternion1);

    reusableQuaternion2.setFromAxisAngle(reusableVector2, polarAngle - Math.PI / 2);
    rotationQuaternion.multiply(reusableQuaternion2);
    camera.quaternion.copy(rotationQuaternion);

    prevTouch.x = touchMovePosition.x;
    prevTouch.y = touchMovePosition.y;

    //reset the rotationQuaternion without creating a new vector or quaternion
    rotationQuaternion.set(0, 0, 0, 1);
}

// Mobile Movement and Interaction Handlers
function handleTouchStartForInteraction(event) {
    event.preventDefault();

    touchStartPosition.x = event.touches[0].clientX;
    touchStartPosition.y = event.touches[0].clientY;
    touchStartTime = Date.now();
    
    handleDoubleClickOrTap(event, true); 
}

function handleTouchEndForInteraction(event) {
    
    event.preventDefault();
    
    const touchEndPosition = {
        x: event.changedTouches[0].clientX,
        y: event.changedTouches[0].clientY
    };

    if (Date.now() - touchStartTime < 200) {
        if (Math.abs(touchEndPosition.x - touchStartPosition.x) < 10 && 
            Math.abs(touchEndPosition.y - touchStartPosition.y) < 10) {
                if(interactionSystem) interactionSystem.handleTouch(event);
        }
    }
}

// Init mobile controls
export function initMobileControls() {
    // Rotation
    renderer.domElement.addEventListener('touchstart', handleTouchStartForRotation);
    renderer.domElement.addEventListener('touchmove', handleTouchMoveForRotation);
    
    // Movement and Interactions
    renderer.domElement.addEventListener('touchstart', handleTouchStartForInteraction);
    renderer.domElement.addEventListener('touchend', handleTouchEndForInteraction);

    // Hover
    initMobileHoverControls();
}

// Send mobile movement data to the server
export function updateMobileControls() {
    sendPlayerMovementData(cameraRig, camera);
}
