import { db } from './firebaseSetup';
import { updateMemoryCache } from '../utils/cacheManager';
import { serverTimestamp, setDoc, doc, updateDoc, getDoc } from 'firebase/firestore';

export async function updateObjectInFirestore(objectName, data) {
  let uniqueDocId = objectName;
  if (data.userType !== "all") uniqueDocId = `${objectName}_${data.userType}`;
  const docRef = doc(db, 'sceneObjects', uniqueDocId);

  try {
    // Regardless of whether it's an update or a new document, set the data
    const newData = {
      ...data,
      ...(await getDoc(docRef)).exists() ? { lastUpdated: serverTimestamp() } : { created: serverTimestamp() }
    };
    await setDoc(docRef, newData, { merge: true });

    updateMemoryCache({ [objectName]: newData });

    // Update the metadata document's lastUpdated timestamp
    const metadataRef = doc(db, 'config', 'metadata');
    await setDoc(metadataRef, { lastUpdated: serverTimestamp() }, { merge: true });

  } catch (error) {
    console.error("Error updating document:", error);
    throw error;
  }
}

export async function fetchDataForUserType(objectName, userType) {
  let uniqueDocId = objectName;
  if (userType !== "all") uniqueDocId = `${objectName}_${userType}`;

  const docRef = doc(db, 'sceneObjects', uniqueDocId);
  const docSnap = await getDoc(docRef);

  return docSnap.exists() ? docSnap.data() : null;
}
