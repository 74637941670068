export class System {
    constructor(entityManager, systemManager) {
        this.entityManager = entityManager;
        this.systemManager = systemManager;
        this.requiredComponents = [];
        this.excludedComponents = []; 
        this.cachedEntities = new Set();  // Cached entities
        this.needsUpdate = true;  // Flag to indicate whether cache needs update

        // Register this system for notifications from EntityManager
        this.entityManager.systems.add(this);
    }

    // Get entities, updating the cache if needed
    getEntities() {
        if (this.needsUpdate) {
            this.cachedEntities = new Set(this.entityManager.getEntitiesWithComponents(this.requiredComponents, this.excludedComponents));
            this.needsUpdate = false;
        }
        return this.cachedEntities;
    }

    // Invalidate entity cache, to be updated upon the next getEntities call
    invalidateEntitiesCache() {
        this.needsUpdate = true;
    }


    // Default update method, to be overridden by subclasses
    update(deltaTime) {
        if (!this.isActive) return;
    }

    // Collision check between two PositionComponents
    collisionCheck(pos1, pos2, threshold = 1.0) {
        const thresholdSq = threshold * threshold;
        const dx = pos1.x - pos2.x;
        if (dx * dx > thresholdSq) return false;
        
        const dy = pos1.y - pos2.y;
        if (dy * dy > thresholdSq) return false;
      
        const dz = pos1.z - pos2.z;
        if (dz * dz > thresholdSq) return false;
      
        return dx * dx + dy * dy + dz * dz < thresholdSq;
      }
    
}
