import {
    Group,
    DoubleSide,
    Mesh,
    Vector4,
    Vector3,
    Color,
    AdditiveBlending,
    NormalBlending,
    TextureLoader,
    MeshBasicMaterial,
} from 'three';

import {
    ConstantColor,
    PointEmitter,
    IntervalValue,
    ConstantValue,
    ConeEmitter,
    SizeOverLife,
    PiecewiseBezier,
    Bezier,
    RenderMode,
    RandomColor,
    ColorOverLife,
    RandomColorBetweenGradient,
    Gradient,
    ApplyForce,
    ApplyCollision,
    ColorRange,
} from 'three.quarks';

const texture = new TextureLoader().load("/textures/settings.png"); // Replace with your actual texture path

export const muzzleFlashConfig = {
    duration: 0.1,
    looping: false,
    autoDestroy: true,
    startLife: new IntervalValue(0.1, 0.2),
    startSpeed: new IntervalValue(2, 4),
    startSize: new IntervalValue(0.05, 0.2),
    startColor: new RandomColor(new Vector4(1, 0.91, 0.51, 1), new Vector4(1, 0.44, 0.16, 1)),
    worldSpace: true,

    maxParticle: 5,
    emissionOverTime: new ConstantValue(100),
    emissionBursts: [
        /*{
            time: 0,
            count: new ConstantValue(100),
            cycle: 1,
            interval: 0.01,
            probability: 1,
        },*/
    ],

    shape: new PointEmitter(),
    material: new MeshBasicMaterial({
        map: texture,
        blending: AdditiveBlending,
        transparent: true,
        side: DoubleSide,
    }),

    behaviors: [
        new ApplyForce(new Vector3(0, 0, 2), new ConstantValue(400)),
        new ColorOverLife(new ColorRange(new Vector4(1, 0.3882312, 0.125, 1), new Vector4(1, 0.826827, 0.3014706, 1))),
        new SizeOverLife(new PiecewiseBezier([[new Bezier(1, 0.95, 0.75, 0), 0]])),
    ]
};

export const explosionConfig = {
    duration: 5,
    looping: false,
    autoDestroy: true,
    startLife: new IntervalValue(3.8, 4.4),
    startSpeed: new IntervalValue(10, 15),
    startSize: new ConstantValue(0.2),
    startColor: new ColorRange(new Vector4(1, 1, 1, 1), new Vector4(1, 1, 1, 1)),
    worldSpace: true,

    maxParticle: 10,
    emissionOverTime: new ConstantValue(0),
    emissionBursts: [
        {
            time: 0,
            count: new ConstantValue(100),
            cycle: 1,
            interval: 0.01,
            probability: 1,
        },
    ],

    shape: new ConeEmitter({radius: 0.1, angle: 1}),
    material: new MeshBasicMaterial({
        map: texture,
        blending: AdditiveBlending,
        transparent: true,
        side: DoubleSide,
    }),
    renderMode: RenderMode.Trail,
    rendererEmitterSettings: {
        startLength: new ConstantValue(20),
    },
    startTileIndex: new ConstantValue(0),
    uTileCount: 10,
    vTileCount: 10,
    renderOrder: 0,

    behaviors: [
        new SizeOverLife(new PiecewiseBezier([[new Bezier(1, 0.95, 0.75, 0), 0]])),
        //beam.addBehavior(new ColorOverLife(new ColorRange(new Vector4(1, 1, 1, 1), new Vector4(0.6, 0.6, 0.6, 1))));
        new ColorOverLife(
            new RandomColorBetweenGradient(
                new Gradient(
                    [
                        [new Vector3(1, 0, 0), 0],
                        [new Vector3(1, 0, 0), 0],
                    ],
                    [
                        [1, 0],
                        [1, 1],
                    ]
                ),
                new Gradient(
                    [
                        [new Vector3(0, 1, 0), 0],
                        [new Vector3(0, 1, 0), 1],
                    ],
                    [
                        [1, 0],
                        [1, 1],
                    ]
                )
            )
        ),
        
        new ApplyForce(new Vector3(0, -1, 0), new ConstantValue(20)),
        new ApplyCollision(
            {
                resolve(pos, normal) {
                    if (pos.y <= 0) {
                        normal.set(0, 1, 0);
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            0.6
        ),      
    ]
};

export const hitWhitePlasterConfig = {
    duration: 1.2, // Short-lived
    looping: false,
    autoDestroy: true,
    startLife: new IntervalValue(0.05, 0.3), // Quick dissipation
    startSpeed: new IntervalValue(2, 8), // Moderate speed
    startSize: new IntervalValue(0.01, 0.1), // Small particles
    startColor: new ConstantColor(new Vector4(0.7, 0.6, 0.8, 1)), // White
    worldSpace: true,

    maxParticle: 10, // Not too many particles
    emissionOverTime: new ConstantValue(0), // No constant emission
    emissionBursts: [
        {
            time: 0,
            count: new ConstantValue(10), // All at once
            cycle: 1,
            interval: 0.01,
            probability: 1,
        },
    ],

    shape: new PointEmitter(), // Emitted from a single point
    material: new MeshBasicMaterial({
        map: texture,
        blending: AdditiveBlending, // Normal blending to keep the white color
        transparent: true,
        side: DoubleSide,
    }),

    behaviors: [
        new ApplyForce(new Vector3(0, -2, 0), new ConstantValue(10)), // Some upward force
        //new SizeOverLife(new PiecewiseBezier([[new Bezier(1, 0.8, 0.6, 0), 0]])), // Shrink over time
    ]
};


