import { canPlay, displayingPopup, isMobile, setDisplayingPopup, toggleCanPlay } from "../core/sharedState";
import { hideAllUIElements, setupEventListener } from "../utils/uiUtils";

const pauseGuiTextElement = document.getElementById('pause-gui-text');
const settingsIconPath = '/textures/settings.png';
const movementIconPath = '/textures/movement.png';
const escIconPath = '/textures/esc.png';

export function showGUI(e = null) {
    if(!canPlay) return;
    if (e) e.stopPropagation();
    hideAllUIElements(true);
    setDisplayingPopup(true);

    document.getElementById('pause-gui').style.display = 'block';
    document.getElementById('lock-backdrop').style.display = 'block';


    const mobileText = `
        <h3>NAVIGATION</h3>
        <p>Double tap on ground to move.</p>
        <br></br>
        <p>Drag to look around.</p>
        <br></br>
        <p><img src="${settingsIconPath}" alt="Button Image" style="vertical-align: middle; margin-right: 10px;"><br></br> press for settings.</p> 
    `;

    const desktopText = `
        <h3>NAVIGATION</h3>
        <p>Use the mouse to look around.</p>
        <br></br>
        <p><img src="${movementIconPath}" alt="Button Image" style="vertical-align: middle; margin-right: 10px; max-width: 80px"><br></br>  "wasd" keys to move.</p>
        <br></br>
        <p><img src="${escIconPath}" alt="Button Image" style="vertical-align: middle; margin-right: 10px; max-width: 30px"><br></br> "esc" key to unlock the mouse.</p>
        <br></br>
        <p><img src="${settingsIconPath}" alt="Button Image" style="vertical-align: middle; margin-right: 10px;"><br></br> press for settings.</p>

    `;

    pauseGuiTextElement.innerHTML = isMobile ? mobileText : desktopText;
}

export function initInfoPopup() {
    setupEventListener('info-btn', (e) => showGUI(e));

    setupEventListener('pause-gui-button', (e) => {
        e.stopPropagation();
        hideAllUIElements();
    });

    setupEventListener('pause-gui', (e) => {
        e.stopPropagation();
    });
}