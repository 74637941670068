import { System } from './system';
import { cameraRig, isInVRSession, currentSceneName, camera } from '../core/sharedState';
import { isMultiplayerSession } from '../multiplayer/sessionManager';
import * as THREE from 'three';
import { sendDataToServer } from '../multiplayer/multiplayerSetup';
import { controllers } from '../XR/XRControllers';
import { inventoryManagementSystem } from '../ECS-utils/managerECS';
import { PLAYER_HEIGHT, upVector } from '../utils/constants';
import { switchControllerState } from '../XR/XRInteractions';

export class ItemPickupSystem extends System {
  constructor(entityManager, systemManager) {
    super(entityManager, systemManager);
    this.requiredComponents = ['ItemComponent', 'RenderComponent', 'StatusComponent'];
    this.tempControllerPos = new THREE.Vector3();
    this.pickupCooldown = 0;
    this.systemManager.onEvent('itemPickUpAccepted', this.handleItemPickup.bind(this));
    this.rateLimitInterval = 11000; // 1 second interval for logging
    this.lastLogTime = 0;
    this.cameraWorldPosition = new THREE.Vector3();
  }
  
  update(deltaTime) {
    this.getEntities().forEach(itemEntity => {
      if (this.pickupCooldown > 0) {
        this.pickupCooldown -= deltaTime;
        return;
      }

      const itemComponent = itemEntity.getComponent('ItemComponent');
      const itemStatus = itemEntity.getComponent('StatusComponent');
      const renderComponent = itemEntity.getComponent('RenderComponent');

      // only process pickupable that are visible and not owned
      if (!itemComponent.pickupable || !renderComponent.mesh.visible || itemStatus.owner) return;
      
      const itemPos = itemEntity.getComponent('RenderComponent').mesh.position;
      let controllerHand = "";
      let collisionDetected = false;

      if (isInVRSession && controllers) {
        // Perform collision check with each controller in VR
        controllers.forEach((controller, index) => {
          controller.getWorldPosition(this.tempControllerPos);  // Update the reusable Vector3 object

          if (this.collisionCheck(this.tempControllerPos, itemPos, 0.2)) {
            collisionDetected = true;
            controllerHand = controller.isHand;

            // Ensure the controller that picks up the item is not in interaction mode
            const indexOfOtherController = index === 0 ? 1 : 0; // this offcourse assumes 2 controllers
            switchControllerState(indexOfOtherController);
          }
        }); 
      } 

      
      else {
        
        camera.getWorldPosition(this.cameraWorldPosition);
        collisionDetected = this.collisionCheck(this.cameraWorldPosition , itemPos, 1);
      }

      if (collisionDetected) {

          //console.log('Item: ' + itemComponent.itemName + ' is within pickup range, attempting to pick up');
          //console.log('Item mesh is visible:', renderComponent.mesh.visible);
          //console.log('All relevant data:', itemComponent, itemStatus, renderComponent);
          
          if (isMultiplayerSession) sendDataToServer("itemPickupRequest", {
            itemId: itemComponent.itemName, 
            itemType: itemComponent.itemType,
            itemScene: currentSceneName,
            controllerHand: controllerHand
          });

          // Handle pickup immediately in single-player mode  
          else {
            this.handleItemPickup({itemEntity, controllerHand});
          }
          
          this.pickupCooldown = 2; 
        }
    });
  }

  //Also call this if server sends item pickup event
  handleItemPickup(pickupPackage) {

    // If its a multiplayer session, the server and syncManager will handle the item state
    if(!isMultiplayerSession) {
      const itemStatus = pickupPackage.itemEntity.getComponent('StatusComponent');
      itemStatus.setOwner('player');
    }

    //console.log('handle item pickup', pickupPackage);

    // if item is already in inventory, return
    if (inventoryManagementSystem.isItemInInventory(pickupPackage.itemEntity)){
      //console.log('Item: ' + pickupPackage.itemEntity.getComponent('ItemComponent').itemName + ' is already in inventory');
      return;
    }
    
    this.systemManager.emitEvent('itemPickedUp', pickupPackage);
    this.systemManager.emitEvent('playSound', 'itemEquip');
  }
}
