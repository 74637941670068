import { camera, cameraRig } from '../core/sharedState.js';
import { textManager } from '../ui/textManager.js';
import { ANNOTATION_DISTANCE_THRESHOLD } from '../utils/constants.js';
import { Annotation } from './annotationObject.js';
import { getObjectData } from '../interactions/interactionWithObjectManager.js';
import { objectEvents } from '../utils/objectEventTarget.js';
import * as THREE from 'three';

export class AnnotationManager {
    constructor() {
        this.floatingAnnotations = [];
        this.currentAnnotation = null;
        this.annotationPool = [];  // Pool to hold unused Annotation objects
        this.boundingBox = new THREE.Box3();
        this.center = new THREE.Vector3();
        this.annotationStates = new Map(); // Map<annotation, state>
        this.clearingAnnotations = false;
        this.hoverDebounceTimer = null;

        this.animationQueue = Promise.resolve();  // Initialize the animation queue
        this.queuedAnimationsCount = 0;  // track the number of queued animations
        this.MAX_QUEUED_ANIMATIONS = 200;  // define the maximum number of queued animations

        objectEvents.addEventListener('hoverObject', this.handleAnnotationOnHover.bind(this));
        objectEvents.addEventListener('resetObject', this.clearHoveredAnnotation.bind(this));
    }

    setAnnotationState(annotation, state) {
        this.annotationStates.set(annotation, state);
    }
    
    getAnnotationState(annotation) {
        return this.annotationStates.get(annotation);
    }

    isAnnotationInInvalidState(annotation) {
        const state = this.getAnnotationState(annotation);
        return state === 'Hiding' || state === 'Emerging';
    }

    getAnnotation(name, object, isFloatingAnnotation) {
        if(this.clearingAnnotations) {
            console.log('In the process of clearing annotations, returning null');
            return null;
        }

        let annotation;
        if (this.annotationPool.length > 0) {
            annotation = this.annotationPool.pop();
            annotation.title = name;
            annotation.object = object;
            annotation.isFloatingAnnotation = isFloatingAnnotation;
            this.boundingBox.setFromObject(object);
            this.boundingBox.getCenter(this.center);
            this.center.y = this.boundingBox.max.y;
            annotation.position = this.center;
            annotation.rotation = object.rotation;

        } else {
            annotation = new Annotation(name, object, isFloatingAnnotation);
        }
        return annotation;
    }

    releaseAnnotation(annotation) {
        this.annotationPool.push(annotation);
    }
    
    handleAnnotationOnHover(event) {
        const object = event.detail;
        const objectData = getObjectData(object);
        let annotationTitle = object.name;
        
        if(objectData){
            annotationTitle = objectData.headline;
        }
        
        else if(object && object.userData.annotationTitle){
            annotationTitle = object.userData.annotationTitle;
        }
        
        if (this.queuedAnimationsCount < this.MAX_QUEUED_ANIMATIONS) {
            this.animationQueue = this.animationQueue
            .then(() => {
                return new Promise((resolve, reject) => {
                    try {
                        this.currentAnnotation = this.getAnnotation(annotationTitle, object, false);
                        if (this.currentAnnotation) {
                            textManager.displayAnnotationText(this.currentAnnotation)
                            .then(() => {
                                this.queuedAnimationsCount--;
                                resolve();
                            })
                            .catch(error => {  // Catch errors from displayAnnotationText
                                console.error("Error in displayAnnotationText:", error);
                                reject(error);
                            });
                        } else {
                            resolve();
                        }
                    } catch (error) {
                        console.error("Error in animationQueue:", error);
                        // Ensure count does not go negative and log current count for debugging
                        this.queuedAnimationsCount = Math.max(0, this.queuedAnimationsCount - 1);  
                        console.log('Current queuedAnimationsCount:', this.queuedAnimationsCount);
                    }
                });
            })
            .catch(error => {
                console.error("Error in animationQueue:", error);
                // handle recovery from error if needed
            });
            
            this.queuedAnimationsCount++;
        } else {
            console.log('Max queued animations reached');
        }
    }
    
    clearHoveredAnnotation() {
        // Check if we can add a new animation
        if (this.queuedAnimationsCount < this.MAX_QUEUED_ANIMATIONS) {
            // Enqueue clear action
            this.animationQueue = this.animationQueue.then(() => {
                return new Promise(resolve => {
                    if (this.currentAnnotation && !this.clearingAnnotations) {
                        this.clearingAnnotations = true;
                        textManager.clearAnnotationText(this.currentAnnotation);
                        textManager.hideTextAndLine(
                            this.currentAnnotation.textMesh,
                            this.currentAnnotation.line,
                            this.currentAnnotation.endPosition,
                            this.currentAnnotation.startPosition,
                            this.currentAnnotation,
                            () => {
                                if (this.currentAnnotation) {
                                    this.currentAnnotation.clear();
                                    this.releaseAnnotation(this.currentAnnotation);
                                    this.currentAnnotation = null;
                                    this.clearingAnnotations = false;
                                    this.queuedAnimationsCount--;  // Decrement the counter when the animation completes
                                    resolve();
                                }
                            }
                        );
                    } else {
                        resolve();
                    }
                });
            });
            this.queuedAnimationsCount++;  // Increment the counter when a new animation is added
        }
    }

    checkAnnotationProximity() {
        const currentlyCloseAnnotations = this.getCurrentlyCloseFloatingAnnotations();
        this.clearNoLongerCloseFloatingAnnotations(currentlyCloseAnnotations);
    }

    parseAnnotationName(name) {
        // Verify the format starts correctly with "annotation"
        if (!name.startsWith("annotation_")) return null;
    
        // Find the index of the last underscore, which separates the value from the type
        const lastUnderscoreIndex = name.lastIndexOf('_');
        if (lastUnderscoreIndex === -1 || lastUnderscoreIndex <= 10) return null; // Ensure there's content after "annotation_"
    
        // Extract the 'type' from the end of the string
        const type = name.substring(lastUnderscoreIndex + 1);
    
        // Extract the 'value' part between the first and last underscore, and replace all underscores with spaces
        const valueWithUnderscores = name.substring(11, lastUnderscoreIndex); // Start after "annotation_" (11 characters)
        const value = valueWithUnderscores.replace(/_/g, ' '); // Replace all underscores with spaces
    
        return {
            identifier: "annotation",
            title: value, // The value part with underscores replaced by spaces
            type // The type part extracted from after the last underscore
        };
    }
     
    
    getCurrentlyCloseFloatingAnnotations() {
        
        return this.floatingAnnotations.filter(annotation => {
            const distance = cameraRig.position.distanceTo(annotation.position);
            const factor = annotation.type === 'large' ? 2 : 1;
            const isClose = distance < ANNOTATION_DISTANCE_THRESHOLD*factor;
    
            if (isClose && !this.isAnnotationDisplayed(annotation)) {
                annotation.isFloatingAnnotation = true;
                textManager.displayAnnotationText(annotation, 0.04);
            }
    
            return isClose;
        });

    }

    isAnnotationDisplayed(annotation) {
        return textManager.displayedAnnotations.some(displayed => displayed.annotation === annotation);
    }

    clearNoLongerCloseFloatingAnnotations(currentlyCloseAnnotations) {
        textManager.displayedAnnotations.forEach(displayed => {
            if (!currentlyCloseAnnotations.includes(displayed.annotation) && displayed.annotation.isFloatingAnnotation) {
                textManager.clearAnnotationText(displayed.annotation);
            }
        });
    }

    setupFloatingAnnotations(child) {
        const annotationData = this.parseAnnotationName(child.name);
        if (annotationData) {
            const annotation = {
                ...annotationData,
                position: child.position,
                rotation: child.rotation,
                object: child
            };
            this.floatingAnnotations.push(annotation);
        }
    }

    unload() {
        // Stop any ongoing animations
        this.animationQueue = Promise.resolve();
        this.queuedAnimationsCount = 0;
    
        // Clear annotations
        this.floatingAnnotations.forEach(annotation => {
            //cleanup for all annotation
            annotation = null;

        });
        this.floatingAnnotations.length = 0;
        this.currentAnnotation = null;
    
        // Reset state
        this.annotationStates.clear();
        this.clearingAnnotations = false;
    
        // Clear any text and lines from textManager
        textManager.clearAllAnnotationsText();
    
        // Reset timers if applicable
        if (this.hoverDebounceTimer) {
            clearTimeout(this.hoverDebounceTimer);
            this.hoverDebounceTimer = null;
        }
    }   
}

export const annotationManager = new AnnotationManager();
