// XR/XRVibrationFeedback.js

const DEFAULT_VIBRATION_DURATION = 50; // in milliseconds
const DEFAULT_VIBRATION_STRENGTH = 0.5; // Range: 0 to 1

/**
 * Applies vibration feedback to the XR controller.
 * @param {Object} controller - The XR controller object.
 * @param {number} [duration=DEFAULT_VIBRATION_DURATION] - Duration of the vibration in milliseconds.
 * @param {number} [strength=DEFAULT_VIBRATION_STRENGTH] - Strength of the vibration (0 to 1).
 */
function applyVibration(controller, duration = DEFAULT_VIBRATION_DURATION, strength = DEFAULT_VIBRATION_STRENGTH) {
    if (!controller || !controller.gamepad || !controller.gamepad.hapticActuators || controller.gamepad.hapticActuators.length === 0) {
        console.warn('Vibration feedback is not supported on this controller.');
        return;
    }

    // Use the first haptic actuator to apply the vibration
    const actuator = controller.gamepad.hapticActuators[0];
    if (actuator) {
        actuator.pulse(strength, duration);
    }
}

/**
 * Custom vibration patterns for different interaction types
 */

export function vibrationOnSelect(controller) {
    applyVibration(controller, 100, 0.75);
}

export function vibrationOnHover(controller) {
    applyVibration(controller, 50, 0.25);
}

export function vibrationOnTeleport(controller) {
    applyVibration(controller, 150, 1.0);
}

export function vibrationOnGrab(controller) {
    applyVibration(controller, 200, 0.5);
}

export function vibrationOnRelease(controller) {
    applyVibration(controller, 100, 0.3);
}

// Export as default if you prefer to import the whole module
export default {
    vibrationOnSelect,
    vibrationOnHover,
    vibrationOnTeleport,
    vibrationOnGrab,
    vibrationOnRelease
};
