import { isInVRSession, isMobile, renderer, scene } from '../core/sharedState';
import { getReflectionResolutionFromIndex } from '../models/modelLoaderHelper';
import { System } from './system';
import * as THREE from 'three';

export class ReflectionSystem extends System {
    constructor(entityManager, systemManager) {
        super(entityManager, systemManager);
        this.cubeRenderTargetObject = {};
        this.cubeCamera = null;
        this.objectsForReflectionBaking = {};
        this.tempVector = new THREE.Vector3();
        this.systemManager.onEvent('cleanUp', this.resetReflectionsForSceneChange.bind(this));
        this.systemManager.onEvent('sceneLoaded', this.collectObjectsForReflection.bind(this));
    }

    initializeReflectionCamera(renderPosition, resolution) {

        const renderTarget = new THREE.WebGLCubeRenderTarget(resolution, {
            generateMipmaps: true,
            minFilter: THREE.LinearMipmapLinearFilter,
        });

        if (!this.cubeCamera) {
            const cubeCamera = new THREE.CubeCamera(0.1, 10000, renderTarget);
            this.cubeCamera = cubeCamera;
            scene.add(cubeCamera);
        }
        else {
            this.cubeCamera.renderTarget = renderTarget;
        }

        this.cubeCamera.position.copy(renderPosition);
    }

    renderReflection(child, renderPosition, resolution) {
        if (!this.cubeRenderTargetObject[child.name]) {
            this.initializeReflectionCamera(renderPosition, resolution);
            child.visible = false;
            this.cubeCamera.update(renderer, scene);
            child.visible = true;
            this.cubeRenderTargetObject[child.name] = this.cubeCamera.renderTarget;
            if (child.material) {
                child.material.envMap = this.cubeCamera.renderTarget.texture;
                child.material.needsUpdate = true;
            }
        }
    }

    collectObjectsForReflection() {
        if(!scene || isMobile && !isInVRSession) return;
        scene.traverse(child => {
            // Ensure the child has userData and the specific properties you're interested in
            if (child.userData && child.userData.showroom_properties) {
                const properties = child.userData.showroom_properties;
                let resolution = getReflectionResolutionFromIndex(properties.reflectiveSurface);
                if (resolution === -1 || resolution === "none" || !resolution ) return;

                // convert resolution to a number
                if (typeof resolution === 'string') {
                    resolution = parseInt(resolution);
                }


                // Calculate the render position if required
                let renderPosition = child.position;
                child.traverse((subChild) => {
                    const childProperties = subChild.userData.showroom_properties;
                    if(childProperties && childProperties.Placement) {
                        subChild.getWorldPosition(tempVector);
                        renderPosition = tempVector();
                    }
                });
                this.objectsForReflectionBaking[child.name] = {child, renderPosition, resolution};
            }
        });
        this.batchRenderReflections();
    }

    batchRenderReflections() {
        Object.keys(this.objectsForReflectionBaking).forEach(objectName => {
            const { child, renderPosition, resolution } = this.objectsForReflectionBaking[objectName];
            this.renderReflection(child, renderPosition, resolution);
        });
    }

    resetReflectionsForSceneChange() {
        Object.keys(this.cubeRenderTargetObject).forEach(objectName => {
            let renderTarget = this.cubeRenderTargetObject[objectName];
            // Dispose of the texture associated with the render target
            if (renderTarget.texture) {
                renderTarget.texture.dispose();
            }
            renderTarget.dispose();
            renderTarget.texture = null;
            renderTarget = null;

            
        });
        if(this.cubeCamera && this.cubeCamera.renderTarget) {
            this.cubeCamera.renderTarget.dispose();
            if(this.cubeCamera.renderTarget.texture) this.cubeCamera.renderTarget.texture.dispose();
        }
        this.cubeRenderTargetObject = {};
        this.objectsForReflectionBaking = {};

        if (this.cubeCamera) {
            scene.remove(this.cubeCamera);
            this.cubeCamera = null;
        }
    }
}