import { LineManager } from './lineManager.js';
import { annotationManager } from '../annotations/annotationManager.js';
import { cameraRig, camera } from '../core/sharedState.js';
import * as THREE from 'three';
import { TextAnimationManager } from './TextAnimationManager.js';
import { LineAnimationManager } from './LineAnimationManager.js';
import { systemManager } from '../ECS-utils/managerECS.js';
import { textMeshManager } from './TextMeshManager.js';

let cameraWorldPosition = new THREE.Vector3();

class TextManager {
    constructor() {
        this.lineManager = new LineManager();
        this.textAnimationManager = new TextAnimationManager();
        this.lineAnimationManager = new LineAnimationManager();
        this.displayedAnnotations = [];
        this.startPosition = new THREE.Vector3();
        this.endPosition = new THREE.Vector3();
        this.translationOffset = new THREE.Vector3(0, 0.15, 0);
    }

    displayAnnotationText(annotation, fontSize = 0.1) {
        // Check if annotation is in a state that allows a new display action.
        if (annotationManager.isAnnotationInInvalidState(annotation)) {
            annotationManager.setAnnotationState(annotation, 'Hidden');  
            return Promise.resolve();  // Resolve promise here to prevent animation queue from getting stuck
        }
        
        systemManager.emitEvent('playSound', 'annotationEmerge');
    
        const textMesh = textMeshManager.getTextMesh();
        textMesh.text = "";
        textMesh.fontSize = fontSize;
        textMesh.anchorX = 'center';
        textMesh.visible = false;
        textMesh.rotation.copy(annotation.object.rotation);
        textMesh.sync();

        this.startPosition.copy(annotation.position);
        this.endPosition.copy(annotation.position).add(this.translationOffset);
        
        const line = this.lineManager.getLine();
        line.geometry.setFromPoints([this.startPosition, this.startPosition]);
        line.visible = true;
        
        annotationManager.setAnnotationState(annotation, 'Emerging');
        
        const annotationData = {annotation: annotation, textMesh: textMesh, line: line, startPosition: this.startPosition, endPosition: this.endPosition};
        annotation.textMesh = textMesh;
        annotation.line = line;
        annotation.endPosition = this.endPosition;
        annotation.startPosition = this.startPosition;
        
        textMesh.position.set(
            annotation.endPosition.x,
            annotation.endPosition.y + 0.1,
            annotation.endPosition.z
        );

        this.displayedAnnotations.push(annotationData);
    
        // Return a promise that resolves once the animation is complete
        return new Promise(resolve => {
            this.lineAnimationManager.animateLine(line, this.startPosition, this.endPosition, 200, () => {
                textMesh.visible = true;
    
                this.textAnimationManager.displayText(textMesh, annotation.title, 200, () => {
                    annotationManager.setAnnotationState(annotation, 'Visible');
                    resolve();  // Resolve promise here after animation completes
                });
            });
        });
    }
        
    hideTextAndLine(textMesh, line, startPosition, endPosition, annotation, onComplete = () => {}) {  
    
        // Ensure that onComplete is called after the animations
        annotationManager.setAnnotationState(annotation, 'Hiding');
        this.textAnimationManager.hideText(textMesh, 100, () => {
            this.lineAnimationManager.hideLine(line, startPosition, endPosition, 100, () => {
                annotationManager.setAnnotationState(annotation, 'Hidden');
                textMesh.clear();
                line.clear();
                onComplete();
            });
        });
    }

    clearAnnotationText(annotation) {
        const index = this.displayedAnnotations.findIndex(text => text.annotation === annotation);
        if (index !== -1) {
            textMeshManager.returnTextMeshToPool(this.displayedAnnotations[index].textMesh);
            this.lineManager.returnLineToPool(this.displayedAnnotations[index].line);
            this.displayedAnnotations.splice(index, 1);
        }
    }

    updateTextMeshRotation() {
        this.displayedAnnotations.forEach(displayed => {
            cameraWorldPosition = camera.getWorldPosition(cameraWorldPosition);
            displayed.textMesh.lookAt(cameraWorldPosition);
        });        
    }

    clearAllAnnotationsText() {
        this.displayedAnnotations.forEach(({ textMesh, line }) => {
            textMeshManager.returnTextMeshToPool(textMesh);
            this.lineManager.returnLineToPool(line);
        });
        this.displayedAnnotations.length = 0;;

        textMeshManager.unload();
        this.lineManager.unload();
    }
    
}

export const textManager = new TextManager();
