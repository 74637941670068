import { System } from './system';
import { scene, camera, currentSceneName } from '../core/sharedState';
import { isMultiplayerSession } from '../multiplayer/sessionManager';
import { sendDataToServer } from '../multiplayer/multiplayerSetup';
import { controllers } from '../XR/XRControllers';


export class InventoryManagementSystem extends System {
    constructor(entityManager, systemManager) {
        super(entityManager, systemManager);
        this.requiredComponents = ['InventoryComponent'];
        this.inventory = null;
        this.systemManager.onEvent('itemPickedUp', this.addItemToInventory.bind(this));
        this.systemManager.onEvent('itemDropped', this.dropItemFromInventory.bind(this));
        this.systemManager.onEvent('useActiveItem', this.useActiveItem.bind(this));
        this.systemManager.onEvent('resetInventory', this.resetInventory.bind(this));
    }

    addItemToInventory(equipPackage) {
        //console.log('Item picked up, adding to inventory:', equipPackage);
        const playerEntity = this.entityManager.getEntitiesWithComponent('PlayerComponent')[0];
        const inventory = playerEntity.getComponent('InventoryComponent');
        inventory.items.push(equipPackage.itemEntity);

        // Hide the item mesh
        const itemMesh = equipPackage.itemEntity.getComponent('RenderComponent').mesh;
        if(itemMesh){
            //console.log('Item has been added to inventory, hiding mesh:', itemMesh.name);
            itemMesh.visible = false;
        }

        //const itemComponent = equipPackage.itemEntity.getComponent('ItemComponent');
        //const statusComponent = equipPackage.itemEntity.getComponent('StatusComponent');
        //const renderComponent = equipPackage.itemEntity.getComponent('RenderComponent');
        //console.log('Lets check all relevant components on the new inventory item:', itemComponent, statusComponent, renderComponent);

        // Try and equip the item
        this.systemManager.emitEvent('itemEquipped', equipPackage);
    }

    dropItemFromInventory(itemEntity) {
        
        const playerEntity = this.entityManager.getEntitiesWithComponent('PlayerComponent')[0];
        const inventory = playerEntity.getComponent('InventoryComponent');
        const itemComponent = itemEntity.getComponent('ItemComponent');
        this.systemManager.emitEvent('resetItem', itemComponent.itemName);
        inventory.items = inventory.items.filter(item => item.id !== itemEntity.id);

        this.returnItemToWorld(itemEntity);

        "play sound effect for dropping item here"
        this.systemManager.emitEvent('playSound', 'itemEquip');

        if (isMultiplayerSession) {
            sendDataToServer('itemDropRequest', {
                itemId: itemComponent.itemName,
                itemType: itemComponent.itemType,
            });
        }
    }

    checkForActiveItem() {
        if(!this.inventoryComponent) {
            const playerEntity = this.entityManager.getEntitiesWithComponent('PlayerComponent');
            this.inventory = playerEntity[0].getComponent('InventoryComponent');
        }
        
        const activeItemEntityId = this.inventory.activeItem;
        return this.entityManager.getEntity(activeItemEntityId);
    }

    isItemInInventory(itemName) {
        const playerEntity = this.entityManager.getEntitiesWithComponent('PlayerComponent');
        const inventory = playerEntity[0].getComponent('InventoryComponent');
        return inventory.items.some(item => item.getComponent('ItemComponent').itemName === itemName);
    }

    useActiveItem() {
        const playerEntity = this.entityManager.getEntitiesWithComponent('PlayerComponent');
        const inventory = playerEntity[0].getComponent('InventoryComponent');
        const activeItemEntityId = inventory.activeItem;
        const activeItemEntity = this.entityManager.getEntity(activeItemEntityId);
        const itemComponent = activeItemEntity ? activeItemEntity.getComponent('ItemComponent') : null;

        if (itemComponent) {
            this.systemManager.emitEvent('fire', {
                itemId: itemComponent.itemName, 
                itemType: itemComponent.itemType}); 
                //Never ever include the playerId in the client side fireequipPackage. 
                //This will trigger an endless loop of fire requests if more than one player is in the room
        }
    }

    resetInventory(data) {
        data.inventory.items.forEach(itemEntity => {
            if (isMultiplayerSession) {
                const itemComponent = itemEntity.getComponent('ItemComponent');
                sendDataToServer('itemDropRequest', {
                    itemId: itemComponent.itemName,
                    itemType: itemComponent.itemType,
                });
            }
        });
        data.inventory.items = [];
    }

    returnItemToWorld(itemEntity) {
        const renderComponent = itemEntity.getComponent('RenderComponent');
        const itemComponent = itemEntity.getComponent('ItemComponent');
        renderComponent.mesh.visible = true;

        camera.remove(renderComponent.mesh);
        if(controllers) controllers.forEach(controller => controller.remove(renderComponent.mesh));

        renderComponent.mesh.position.set(renderComponent.initialPosition.x, renderComponent.initialPosition.y, renderComponent.initialPosition.z);
        renderComponent.mesh.rotation.set(renderComponent.initialRotation.x, renderComponent.initialRotation.y, renderComponent.initialRotation.z);
        
        //add the item to the child model of the scene matching the name of the currentSceneName variable
        scene.children.find(child => child.name === currentSceneName).add(renderComponent.mesh);

        if(!isMultiplayerSession){
            itemEntity.getComponent('StatusComponent').setOwner('');
        }       
    }
}
