import { BaseEntity } from './baseEntity.js';
import { ParticleEffectComponent } from '../ECS-components/particleEffectComponent.js';

export class ParticleEntity extends BaseEntity {
    constructor(id, options) {
        super(id);

        const particleEffectComponent = new ParticleEffectComponent(options, id);
        this.addComponent(particleEffectComponent);
    }
}

