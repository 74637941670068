import { setDisplayingPopup } from '../core/sharedState.js';
import { handleCreateUser, getUsers, updateUser, deleteUser } from '../firebase/userAuthAndManagement.js';
import { hideAllUIElements, setupEventListener } from '../utils/uiUtils.js';

let createUserPopup = document.getElementById('create-user-popup-window');
let editUserModal = document.getElementById('edit-user-modal');
let editUsernameInput = document.getElementById('edit-username');
let editUsertypeSelect = document.getElementById('edit-usertype');
let currentUserBeingEdited = null;

export function showUserManagementPopup(e = null) {
    hideAllUIElements(true);
    setDisplayingPopup(true);
    
    createUserPopup.style.display = 'block';
    document.getElementById('backdrop').style.display = 'block';

    // Fetch and display user list
    renderUserList();
}

export function initAdminTerminalPopup() {
    setupAdminTerminalEventListeners();
    setupEditUserModalEventListeners();
}

function setupAdminTerminalEventListeners() {
    setupEventListener('create-user-btn', async (e) => {
        e.stopPropagation();
        const email = document.getElementById('user-username').value;
        const password = document.getElementById('user-password').value;
        const userType = document.getElementById('user-usertype').value;

        try {
            await handleCreateUser(email, password, userType);
            await renderUserList();
        } catch (error) {
            console.error('Error in handleCreateUser:', error);
            // Handle or display the error appropriately
        }
    });
}


function setupEditUserModalEventListeners() {
    // Add event listeners for update and delete buttons
    document.getElementById('update-user-btn').addEventListener('click', async () => {
        const updatedUser = {
            id: currentUserBeingEdited.id,
            username: editUsernameInput.value,
            userType: editUsertypeSelect.value
        };

        await updateUser(updatedUser);
        await renderUserList();
        closeEditUserModal();
    });


    document.getElementById('delete-user-btn').addEventListener('click', async () => {
        await deleteUser(currentUserBeingEdited.id);
        await renderUserList();
        closeEditUserModal();
    });

    // Close modal event
    document.querySelector('.close-button').addEventListener('click', closeEditUserModal);
}

async function renderUserList() {
    let users = await getUsers(); // Assuming getUsers() is an async function fetching users from the database
    let userListElement = document.getElementById('user-list');
    userListElement.innerHTML = '';

    users.forEach(user => {
        console.log(user);
        if(user.userType === 'admin') return; // Don't show admin user in the list (can't be edited or deleted)
        let userItem = document.createElement('li');
        userItem.textContent = `${user.username} (${user.userType})`;
        userItem.addEventListener('click', () => openEditUserModal(user));
        userListElement.appendChild(userItem);
    });
}

function openEditUserModal(user) {
    currentUserBeingEdited = user;
    editUsernameInput.value = user.username;
    editUsertypeSelect.value = user.userType;

    editUserModal.style.display = 'block';
}

function closeEditUserModal() {
    editUserModal.style.display = 'none';
    currentUserBeingEdited = null;
}