import * as THREE from 'three';

export class Annotation {
    constructor(title, object, isFloatingAnnotation = true) {
        this.title = title;

        // Compute the bounding box of the object
        let boundingBox = new THREE.Box3().setFromObject(object);

        // Get the center of the bounding box
        let center = new THREE.Vector3();
        boundingBox.getCenter(center);

        // Set the x-coordinate of the position to the max x-coordinate of the bounding box
        center.y = boundingBox.max.y;

        this.position = center;
        this.rotation = object.rotation;  // Reference, not clone
        this.object = object;  // Store the whole object
        this.isFloatingAnnotation = isFloatingAnnotation;
        this.textMesh = null;
        this.line = null;
        this.endPosition = null;
        this.startPosition = null;
    }

    clear() {
        this.textMesh = null;
        this.line = null;
        this.endPosition = null;
        this.startPosition = null;
        this.position = null;
        this.rotation = null;
        this.object = null;
        this.title = null;
        this.isFloatingAnnotation = null;
    }
}
