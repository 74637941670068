import { cameraRig } from '../core/sharedState.js';

class ConditionLibrary {
  constructor() {
    this.conditions = {};
    this.initializeConditions();
  }

  initializeConditions() {
    this.addCondition('isPlayerClose', this.isPlayerClose);
    this.addCondition('isPlayerFar', this.isPlayerFar);
    this.addCondition('isPlayerInSight', this.isPlayerInSight);
    this.addCondition('isHealthLow', this.isHealthLow);
    this.addCondition('isUnderAttack', this.isUnderAttack);
    this.addCondition('clickedOn', this.clickedOn);
    this.addCondition('shotOn', this.shotOn);
    this.addCondition('isDead', this.isDead);
  }

  addCondition(name, conditionFn) {
    if (typeof conditionFn !== 'function') {
      throw new Error('Condition must be a function');
    }
    this.conditions[name] = conditionFn;
  }
  
  getCondition(name) {
    return this.conditions[name];
  }

  startMoodChange(entity) {
    const characterStateComponent = entity.getComponent('CharacterStateComponent');
    const moods = Object.values(characterStateComponent.MoodEnum);
    //random interval between 5 and 10 seconds
    let interval = Math.floor(Math.random() * (20000 - 10000 + 1) + 5000);
    setInterval(() => {
      characterStateComponent.currentMood = moods[Math.floor(Math.random() * moods.length)];
        interval = Math.floor(Math.random() * (20000 - 10000 + 1) + 5000);
    }, interval);
  }

  isPlayerClose(entity) {
    // Get the PositionComponent, which is expected to exist for all entities
    const positionComponent = entity.getComponent('PositionComponent');
    const renderComponent = entity.getComponent('RenderComponent');
    let dx;
    let dy;
    let dz;
    if (positionComponent) {
      // Calculate distance between the entity and the cameraRig
       dx = cameraRig.position.x - positionComponent.x;
       dy = cameraRig.position.y - positionComponent.y;
       dz = cameraRig.position.z - positionComponent.z;
    } else if (renderComponent) {
      // If PositionComponent is missing, use the mesh's position
      const mesh = renderComponent.mesh;
      dx = cameraRig.position.x - mesh.position.x;
      dy = cameraRig.position.y - mesh.position.y;
      dz = cameraRig.position.z - mesh.position.z;
    } else {
      // If both PositionComponent and AnimationComponent are missing, return false
      return false;
    }


    const distanceSquared = dx * dx + dy * dy + dz * dz;
    const isClose = distanceSquared < 10;

    // Check for CharacterStateComponent and AnimationComponent only if necessary
    if (isClose) {
        const characterStateComponent = entity.getComponent('CharacterStateComponent');
        const animationComponent = entity.getComponent('AnimationComponent');

        // Only proceed if both components are present
        if (characterStateComponent && animationComponent) {
            const stateMachine = animationComponent.stateMachine;
            if (!characterStateComponent.hasBeenStartled) {
                stateMachine.transitionCooldown = stateMachine.getStateByName('reacting').clip.duration * 1000;
                characterStateComponent.hasBeenStartled = true;
            }
        }
    } else {
        // Reset hasBeenStartled flag if the entity is not close, and it has a CharacterStateComponent
        const characterStateComponent = entity.getComponent('CharacterStateComponent');
        if (characterStateComponent) {
            characterStateComponent.hasBeenStartled = false;
        }
    } 
    return isClose;
  }

  clickedOn(entity) {
      const animationComponent = entity.getComponent('AnimationComponent');
      if (animationComponent && animationComponent.clickedOn){
        // Reset the clickedOn once evaluated to true
        animationComponent.clickedOn = false;
        return true;
      }
      return false;
  }

  shotOn(entity) {
    const animationComponent = entity.getComponent('AnimationComponent');
    if (animationComponent && animationComponent.shotOn){
      animationComponent.shotOn = false;
      return true;
    }
    return false;
  }

  isPlayerFar(entity) {
    const positionComponent = entity.getComponent('PositionComponent');
    const renderComponent = entity.getComponent('RenderComponent');
    let dx;
    let dy;
    let dz;
    if (positionComponent) {
      dx = cameraRig.position.x - positionComponent.x;
      dy = cameraRig.position.y - positionComponent.y;
      dz = cameraRig.position.z - positionComponent.z;
    }
    else if (renderComponent) {
      const mesh = renderComponent.mesh;
      dx = cameraRig.position.x - mesh.position.x;
      dy = cameraRig.position.y - mesh.position.y;
      dz = cameraRig.position.z - mesh.position.z;
    }
    else {
      return false;
    }

    const distanceSquared = dx * dx + dy * dy + dz * dz;
    const isFar = distanceSquared > 12;
    return isFar;
  }

  isPlayerInSight(entity) {
    const npcPosition = entity.getComponent('PositionComponent').position;
    const cameraRigPosition = cameraRig.position;
    const distanceVector = new THREE.Vector3().subVectors(cameraRigPosition, npcPosition);
    const npcForward = new THREE.Vector3(0, 0, 1);  
  
    const angle = distanceVector.angleTo(npcForward);
    return angle < (Math.PI / 4);  // 45 degrees
  }

  isHealthLow(entity) {
    const health = entity.getComponent('HealthComponent').health;  
    return health < 20;  
  }

  isDead(entity) {
    const animationComponent = entity.getComponent('AnimationComponent');
    if (animationComponent && animationComponent.isDead){
      animationComponent.isDead = false;
      return true;
    }
    return false; 
  }

  isUnderAttack(entity) {
    const isUnderAttack = entity.getComponent('StatusComponent').isUnderAttack; 
    return isUnderAttack;
  }
}

export const conditionLibrary = new ConditionLibrary();
