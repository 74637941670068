import { EntityManager } from './entityManager.js';
import { SystemManager } from './systemManager.js';
import { InteractionSystem } from '../ECS-systems/interactionSystem.js';
import { PulseSystem } from '../ECS-systems/pulseSystem.js';
import { WindSystem} from '../ECS-systems/windSystem.js';
import { WindmillSystem} from '../ECS-systems/windmillSystem.js';
import { AnimationSystem } from '../ECS-systems/animationSystem.js';
import { ItemPickupSystem } from '../ECS-systems/itemPickupSystem.js';
import { ItemEquipSystem } from '../ECS-systems/itemEquipSystem.js';
import { WeaponSystem } from '../ECS-systems/weaponSystem.js';
import { ColorBrushSystem } from '../ECS-systems/colorBrushSystem.js';
import { InventoryManagementSystem } from '../ECS-systems/inventoryManagementSystem.js';
import { ParticleEffectSystem } from '../ECS-systems/particleEffectSystem.js';
import { PlayerSystem } from '../ECS-systems/playerSystem.js';
import { SoundSystem } from '../ECS-systems/soundSystem.js';
import { TriggerSystem } from '../ECS-systems/triggerSystem.js';
import { PDFRenderingSystem } from '../ECS-systems/PDFRenderingSystem.js';
import { AnnotationSystem } from '../ECS-systems/annotationSystem.js';
import { ReflectionSystem} from '../ECS-systems/reflectionSystem.js';
import { MeasurementToolSystem } from '../ECS-systems/measurementToolSystem .js';
import { LearningSystem } from '../ECS-systems/learningSystem.js';
import { HealthSystem } from '../ECS-systems/healthSystem.js';
import { gltfLoader } from '../models/modelLoader.js';
import { scene } from '../core/sharedState.js';

export let entityManager = new EntityManager();
export let systemManager = null; 
export let ECSinitialized = false;

export let interactionSystem = null;
export let animationSystem = null;
export let itemEquipSystem = null;
export let inventoryManagementSystem = null;
export let playerSystem = null;

let pulseSystem = null;
let windSystem = null;
let windmillSystem = null;
let itemPickupSystem = null;
let weaponSystem = null;
let colorBrushSystem = null;
let particleEffectSystem = null;
let soundSystem = null;
let triggerSystem = null;
let pdfRenderingSystem = null;
let annotationSystem = null;
let reflectionSystem = null;
let measurementToolSystem = null;
let learningSystem = null;
let healthSystem = null;

export function initializeECS() {
    
    systemManager = new SystemManager();
    pulseSystem = new PulseSystem(entityManager, systemManager);
    interactionSystem = new InteractionSystem(entityManager, systemManager);
    animationSystem = new AnimationSystem(entityManager, gltfLoader, systemManager);
    windSystem = new WindSystem(entityManager, systemManager);
    windmillSystem = new WindmillSystem(entityManager, windSystem, systemManager);
    itemPickupSystem = new ItemPickupSystem(entityManager, systemManager);
    itemEquipSystem = new ItemEquipSystem(entityManager, systemManager);
    weaponSystem = new WeaponSystem(entityManager, systemManager);
    colorBrushSystem = new ColorBrushSystem(entityManager, systemManager);
    particleEffectSystem = new ParticleEffectSystem(entityManager, systemManager);
    playerSystem = new PlayerSystem(entityManager, systemManager);
    inventoryManagementSystem = new InventoryManagementSystem(entityManager, systemManager);
    soundSystem = new SoundSystem(entityManager, systemManager);
    triggerSystem = new TriggerSystem(entityManager, systemManager);
    pdfRenderingSystem = new PDFRenderingSystem(entityManager, systemManager);
    annotationSystem = new AnnotationSystem(entityManager, systemManager, scene);
    reflectionSystem = new ReflectionSystem(entityManager, systemManager);
    measurementToolSystem = new MeasurementToolSystem(entityManager, systemManager);
    learningSystem = new LearningSystem(entityManager, systemManager);
    healthSystem = new HealthSystem(entityManager, systemManager);
    
    systemManager.addSystem(pulseSystem);
    systemManager.addSystem(interactionSystem);
    systemManager.addSystem(animationSystem);
    systemManager.addSystem(windSystem);
    systemManager.addSystem(windmillSystem);
    systemManager.addSystem(weaponSystem);
    systemManager.addSystem(itemPickupSystem);
    systemManager.addSystem(itemEquipSystem);
    systemManager.addSystem(particleEffectSystem);
    systemManager.addSystem(playerSystem);
    systemManager.addSystem(inventoryManagementSystem);
    systemManager.addSystem(soundSystem);
    systemManager.addSystem(triggerSystem);
    systemManager.addSystem(pdfRenderingSystem);
    systemManager.addSystem(colorBrushSystem);
    systemManager.addSystem(annotationSystem);
    systemManager.addSystem(reflectionSystem);
    systemManager.addSystem(measurementToolSystem);
    systemManager.addSystem(learningSystem);
    systemManager.addSystem(healthSystem);

    ECSinitialized = true;

    //Notify listerners that ECS is initialized
    window.dispatchEvent(new CustomEvent('ECSinitialized'));
}

export function updateECS(deltaTime) {
    systemManager.updateSystems(deltaTime);
}



