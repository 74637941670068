
export class StatusComponent {
    constructor(status) {
        this.type = 'StatusComponent';
        this.state = status;
        this.equippedInHand = "";
        this.owner = null;
    }

    setState(newState) {
        this.state = newState;
    }

    setOwner(owner) {
        this.owner = owner;
    }

    setEquippedInHand(hand) {
        this.equippedInHand = hand;
    }
}
