import { camera, scene } from '../core/sharedState';
import { disposeObject, shootableObjects } from '../models/modelLoader';
import { System } from './system';
import * as THREE from 'three';

export class HealthSystem extends System {
    constructor(entityManager, systemManager) {
        super(entityManager, systemManager);
        this.requiredComponents = ['HealthComponent', 'RenderComponent']; 
        this.healthBars = new Map(); // Store health bars for each entity
        this.systemManager.onEvent('healthChange', this.handleHealthChange.bind(this));
        this.systemManager.onEvent('cleanUp', this.resetHealthSystem.bind(this));
        this.tempVector = new THREE.Vector3();
        this.tempVector2 = new THREE.Vector3();
        this.throttle = 1;
    }

    handleHealthChange(firePackage) {
        const { damage, mesh } = firePackage;
        const entity = this.entityManager.findEntityByMeshAndRequiredComponents(mesh, ["RenderComponent", "HealthComponent"]);
        if (!entity) return;
        
        const healthComponent = entity.getComponent('HealthComponent');
        healthComponent.takeDamage(damage);
    
        let healthBar = this.healthBars.get(entity);
        if (!healthBar) {
            healthBar = this.createHealthBar();
            this.healthBars.set(entity, healthBar);
            const renderComponent = entity.getComponent('RenderComponent');
            if (renderComponent && renderComponent.mesh) {
                //renderComponent.mesh.add(healthBar);
                scene.add(healthBar);
            }
        }
        
        // Update the health bar scale
        healthBar.scale.x = Math.max(healthComponent.currentHealth / healthComponent.maxHealth, 0);
    
        // Calculate the color based on the current health
        const healthPercentage = healthComponent.currentHealth / healthComponent.maxHealth;
        this.setHealthColor(healthBar, healthPercentage);

        // Set the entity to dead if health is zero or less
        if (healthComponent.isAlive && healthComponent.currentHealth <= 0) {
            healthComponent.isAlive = false;
            this.systemManager.emitEvent('entityDied', entity);
            if (healthComponent.removeOnDeath) {
                setTimeout(() => {
                    this.removeEntityAndObject(mesh, entity, healthBar);
                }, 10);
            }
        }
    }

    setHealthColor(healthBar, healthPercentage) {
        const color = healthBar.material.color;
        const greenToRed = 1 - healthPercentage;
        color.r = greenToRed;
        color.g = healthPercentage;
        color.b = 0; // Keep blue at 0
    }

    removeEntityAndObject(mesh, entity, healthBar) {
        // Entity and animation entity removal logic
        this.entityManager.removeEntity(entity.id);
        disposeObject(mesh); // Your existing removal logic

        // Additional: Remove the health bar
        if (healthBar && healthBar.parent) {
            healthBar.parent.remove(healthBar);
            healthBar.geometry.dispose();
            healthBar.material.dispose();
        }
        this.healthBars.delete(entity);
    }

    createHealthBar() {
        const geometry = new THREE.BoxGeometry(1, 0.1, 0.1);
        // Initially set the color to green
        const material = new THREE.MeshBasicMaterial({ color: new THREE.Color(0, 1, 0) });
        const healthBar = new THREE.Mesh(geometry, material);
        return healthBar;
    }

    update(deltaTime) {

        // Ensure health bars face the camera
        if (this.throttle > 0) {
            this.throttle -= deltaTime;
            return;
        }
        this.throttle = 0.01;

        this.healthBars.forEach((healthBar, entity) => {
            healthBar.getWorldPosition(this.tempVector);
            camera.getWorldPosition(this.tempVector2);
            this.tempVector2
            const cameraDirection = this.tempVector2.sub(this.tempVector).normalize();
            const angle = Math.atan2(cameraDirection.x, cameraDirection.z);
            healthBar.rotation.y = angle; // Rotate health bar to always face the camera
            const renderComponent = entity.getComponent('RenderComponent');
            if (renderComponent && renderComponent.mesh) {
                healthBar.position.copy(renderComponent.mesh.position);
                healthBar.position.y += renderComponent.height + 0.1; // Adjust the position relative to the entity
            }
        });
    }

    resetHealthSystem() {
        this.healthBars.forEach((healthBar, entity) => {
            if (healthBar && healthBar.parent) {
                healthBar.parent.remove(healthBar);

                healthBar.geometry.dispose();
                healthBar.material.dispose();
            }

            this.healthBars.delete(entity);
        });
        this.healthBars.clear();
    }
}
