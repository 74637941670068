import ThreeMeshUI from 'three-mesh-ui';
import * as THREE from 'three';
import { objectEvents } from '../utils/objectEventTarget.js';
import { raycaster } from '../utils/constants.js';
import { InteractableEntity } from '../ECS-entities/interactableEntity.js';
import { entityManager } from '../ECS-utils/managerECS.js';
import { handleObjectInteraction } from '../interactions/interactionWithObjectManager.js';
import { objectToBeConsideredForRaycast } from '../models/modelLoader.js';
import { getCSSVariableValue } from '../utils/domUtils.js';

const FONT_JSON = '/fonts/Roboto-msdf.json';
const FONT_TEXTURE = '/fonts/Roboto-msdf.png';

const backdropColor = new THREE.Color(getCSSVariableValue('--background-color'));
const textColor = new THREE.Color(getCSSVariableValue('--title-color'));
const backgroundAnswerColor = new THREE.Color(getCSSVariableValue('--button-hover-color'));
const backgroundQuestionsColor = new THREE.Color(getCSSVariableValue('--button-hover-color'));

let mouse = new THREE.Vector2();

class QuizUI {
    constructor(clickCallback) {
        this.clickCallback = clickCallback;
        this.uiGroup = new ThreeMeshUI.Block({
            width: 0.85,
            height: 0.85,
            contentDirection: 'column',
            justifyContent: 'center',
            borderRadius: 0.04,
            backgroundColor: backdropColor,
            backgroundOpacity: 0.1,
            fontFamily: FONT_JSON,
            fontTexture: FONT_TEXTURE,
        });
        this.uiGroup.visible = true;
        this.questionText = null;
        this.answerTexts = [];
        this.isAnswering = false;

        this.boundClick = this.click.bind(this);
        this.boundHoverOn = this.hoverOn.bind(this);
        this.boundHoverOff = this.hoverOff.bind(this);

        objectEvents.addEventListener('clickObjectQuizAnswerOption', this.boundClick);
        objectEvents.addEventListener('hoverObjectQuizAnswerOption', this.boundHoverOn);
        objectEvents.addEventListener('resetObjectQuizAnswerOption', this.boundHoverOff);
    }

    click(event) {
        this.handleClick(event.detail);
    }
        
    hoverOn(event) {    
        event.detail.parent.set({ backgroundOpacity: 0.5 });
    }

    hoverOff(event) {
        event.detail.parent.set({ backgroundOpacity: 1 });
    }
    
    handleClick(intersectedObject) {
        let objectToCheck = intersectedObject;
        let foundIndex = -1;

        // Traverse the parent hierarchy to find the index of the intersected answer object
        while (objectToCheck) {
            const index = this.answerTexts.findIndex(answer => answer.block === objectToCheck);
            if (index !== -1) {
                foundIndex = index;
                break;
            }
            objectToCheck = objectToCheck.parent;
        }

        if (foundIndex !== -1) {
            this.clickCallback(foundIndex);
        }
    }

    createUI(scene) {
        const container = this.uiGroup;
        scene.add(container);
    
        this.questionText = new ThreeMeshUI.Text({
            content: 'Question will appear here',
            fontSize: 0.07,
            fontColor: textColor,
        });
    
        const questionBlock = new ThreeMeshUI.Block({
            width: 0.8,
            offset: 0.01,
            height: 0.3,
            margin: 0.01, 
            backgroundColor: backgroundQuestionsColor,
            backgroundOpacity: 1,
            justifyContent: 'center',
            textAlign: 'center',
            bestFit: 'auto',
            padding: 0.02
        });
    
        questionBlock.add(this.questionText);
        container.add(questionBlock);
    
        for (let i = 0; i < 4; i++) {
            const answerText = new ThreeMeshUI.Text({
                content: 'Answer will appear here',
                fontSize: 0.03,
                fontColor: textColor,
            });
    
            const answerBlock = new ThreeMeshUI.Block({
                width: 0.8,
                height: 0.1,
                offset: 0.01,
                
                backgroundOpacity: 1,
                justifyContent: 'center',
                backgroundColor: backgroundAnswerColor,
                backgroundOpacity: 1,
                textAlign: 'center',
                margin: 0.01,
                padding: 0.02, // Added margin for better separation between answers
            });
            
            
            this.answerTexts.push({ text: answerText, block: answerBlock });
    
            // Adding Hover effect
            answerBlock.onBeforeRender = () => {
                if (raycaster.intersectObject(answerBlock).length > 0) {
                    answerBlock.setBackgroundOpacity(0.5); // Change opacity on hover
                } else {
                    answerBlock.setBackgroundOpacity(1);
                }
            };
    
            answerBlock.add(answerText);
            container.add(answerBlock);

            //give answerblock a userdata of quizAnswerOption and clickable
            answerBlock.userData.quizAnswerOption = true;

            // Add the answer block to objectToBeConsideredForRaycast for VR interaction
            objectToBeConsideredForRaycast.push(answerBlock);
        }

        this.answerTexts.forEach(block => { 

            const clickCallback = (entity) => {
                handleObjectInteraction(entity);
            }

            const multiplayerCallback = (entity) => {
                console.log("other player clicked on answer option");
            }

            const callbacks = {
                click: clickCallback,
                multiplayerClick: multiplayerCallback
            };

            if(entityManager){
                const options = { isQuiz: true };
                entityManager.createEntity(InteractableEntity, block.block.frame, callbacks, false, '', 'click', 'quiz', options);
                } 
            }
        );
    }  

    updateUI(questionData) {
        if (this.questionText && this.answerTexts.length === questionData.answers.length) {
            this.questionText.set({ content: questionData.question });

            for (let i = 0; i < questionData.answers.length; i++) {
                this.answerTexts[i].text.set({ content: questionData.answers[i] });
            }

            this.uiGroup.visible = true;
        }
    }

    hideUI() {
        this.uiGroup.visible = false;
    }
    
    showUI() {
        this.uiGroup.visible = true;
    }

    indicateAnswer(isCorrect, answerIndex, callback) {
        const color = isCorrect ? new THREE.Color(0x00ff00) : new THREE.Color(0xff0000); // green for correct, red for incorrect
        this.answerTexts[answerIndex].block.set({ backgroundColor: color });
        this.isAnswering = true; // Block any further interactions till callback is executed.
    
        setTimeout(() => {
            this.answerTexts[answerIndex].block.set({ backgroundColor: backgroundAnswerColor }); // Reset the color
            this.answerTexts.forEach(answer => {
                answer.block.set({ backgroundOpacity: 1 }); // Reset hover state for all answers
            });
            callback(); // Load the next question or end the quiz
            this.isAnswering = false; // Allow further interactions
            this.intersectedObject = null; // Reset intersected object
            this.pressedObject = null; // Reset pressed object
        }, 1000); // Delay for 1 second
    }

    showScore(score, total) {
        this.questionText.set({ content: `Quiz Completed!`, fontSize: 0.04, fontColor: textColor });
        
        const scoreMessage = score === total ? 'Perfect Score!' : score > total / 2 ? 'Great Job!' : 'Better Luck Next Time!';
        const scoreColor = score === total ? textColor : score > total / 2 ? textColor : textColor;
        
        if (!this.scoreBlock) {
            this.scoreBlock = new ThreeMeshUI.Block({
                width: 0.8,
                height: 0.4,
                backgroundColor: new THREE.Color(0x333333),
                backgroundOpacity: 1,
                justifyContent: 'center',
                textAlign: 'center',
            });

            this.uiGroup.add(this.scoreBlock);
        }
        
        const scoreText = new ThreeMeshUI.Text({
            content: `${scoreMessage}\nYour Score: ${score}/${total}`,
            fontSize: 0.04,
            fontColor: scoreColor,
            lineHeight: 0.1,
        });
        
        this.scoreBlock.clear();
        this.scoreBlock.add(scoreText);
        
        this.answerTexts.forEach(answer => {
            answer.block.visible = false; // Hide the block
        });
        
        this.uiGroup.visible = true;
    }
    
    resetQuiz() {
        this.uiGroup.visible = true; // Make the UI visible again
        
        if (this.scoreBlock) {
            this.uiGroup.remove(this.scoreBlock);
            this.scoreBlock = null;
        }
        
        this.answerTexts.forEach(answer => {
            answer.block.visible = true; // Make the answer block visible again
        });

        // Reset the properties of questionText
        this.questionText.set({ 
            content: 'Question will appear here', 
            fontSize: 0.07, 
            fontColor: textColor 
        });
        
        this.isAnswering = false; // Allow interactions again
    }    

    destroyUI(scene) {
        // Remove event listeners
        objectEvents.removeEventListener('hoverObjectQuizAnswerOption', this.boundHoverOn);
        objectEvents.removeEventListener('resetObjectQuizAnswerOption', this.boundHoverOff);
        objectEvents.removeEventListener('clickObjectQuizAnswerOption', this.boundClick);

        // Traverse and dispose of each element in uiGroup
        this.uiGroup.traverse((child) => {
            if (child.material) child.material.dispose();
            if (child.geometry) child.geometry.dispose();
        });
    
        // Remove uiGroup from the scene and clear it
        scene.remove(this.uiGroup);
        this.uiGroup.clear();
        
        // Reset visibility and answerTexts
        this.uiGroup.visible = false;
        this.answerTexts = [];
    }
}

export { QuizUI };
