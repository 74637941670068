import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { scene, renderer } from '../core/sharedState.js';
import { annotationManager } from '../annotations/annotationManager.js';
import { manager } from '../ui/loadingManager.js';
import { createCustomShaderMaterial, getItemNameFromItemTypeIndex, getParticleEffectNameFromIndex } from './modelLoaderHelper.js';
import { animationSystem, entityManager, systemManager } from '../ECS-utils/managerECS.js';
import { getObjectData, handleObjectInteraction } from '../interactions/interactionWithObjectManager.js';
import { instancingManager } from './instancingManager.js';
import { materialManager } from '../shaders/materialManager.js';
import { characterManager } from './characterManager.js';
import { ParticleEntity } from '../ECS-entities/particleEntity.js';
import { InteractableEntity } from '../ECS-entities/interactableEntity.js';
import { PulseEntity } from '../ECS-entities/pulseEntity.js';
import { WindmillEntity } from '../ECS-entities/windmillEntity.js';
import { SoundEntity } from '../ECS-entities/soundEntity.js';
import { AnimationEntity } from '../ECS-entities/animationEntity.js';
import { TriggerEntity } from '../ECS-entities/triggerEntity.js';
import { PDFEntity } from '../ECS-entities/pdfEntity.js';
import { activateFog, activateOceanAndSkybox, setAmbientLightIntensity } from '../core/environmentSetup.js';

export const shootableObjects = [];
export const traversableGrounds = [];
export const objectToBeConsideredForRaycast = [];
export const physicsObjectsForInitialization = [];

export let playerStart = null;

const objectsToDispose = [];

const dracoLoader = new DRACOLoader(manager);
export const gltfLoader = new GLTFLoader(manager);
dracoLoader.setDecoderPath('/draco/');
gltfLoader.setDRACOLoader(dracoLoader);


function handleObjectsWithOptions(child, identifier, properties) {

    //assemble options for the entity
    const options = {};
    for (const [key, value] of Object.entries(properties)) {
        if (key.startsWith(identifier + "-")) {
            const optionName = key.split("-")[1];
            options[optionName] = value;
        }
    }
    options.object = child;


    //Create entity based on the identifier
    if (identifier === "isAudio") {
        const soundEntity = entityManager.createEntity(SoundEntity, options);
        const soundComponent = soundEntity.getComponent('SoundComponent');
        systemManager.emitEvent('initializeSound', soundComponent)
    }

    if (identifier === "isPulsing") {
        entityManager.createEntity(PulseEntity, options);
    }

    if (identifier === "isTrigger") {
        const triggerEntity = entityManager.createEntity(TriggerEntity, options);
        const triggerComponent = triggerEntity.getComponent('TriggerComponent');

        if(triggerComponent.useObjectData) triggerComponent.objectData = getObjectData(child);
    }

    if (identifier === "isParticleEffect") {
        const particleName = getParticleEffectNameFromIndex(options.type);
        const particleEntity = entityManager.createEntity(ParticleEntity, options);
        const particleComponent = particleEntity.getComponent('ParticleEffectComponent');
        particleComponent.effectType = particleName;
    }

    if (identifier === "isItem") {
        handleInteractiveObjects(child, options.type_Str, options.pickupable, "active", "trigger", options);
    }

    if (identifier === "clickable") {
        handleInteractiveObjects(child, "clickable", false, "", "click", options);
    }

    if (identifier === "isPhysicsObject") {
        physicsObjectsForInitialization.push(options);
    }
     
}

function handleInteractiveObjects(child, itemType, pickupable, status, interactionType, options = {}) {
    child.matrixAutoUpdate = true;
    let callbacks = {};

    // If the object is a clickable item, Remember to add the callbacks for the click and multiplayerClick events
    if(itemType === "clickable"){
        const clickCallback = (entity) => {
            handleObjectInteraction(entity);
        };
    
        const multiplayerClickCallback = (entity) => {
            const object = entity.getComponent('RenderComponent').mesh;
            const objectData = getObjectData(object);

            console.log("Multiplayer click callback");
        };
    
        callbacks = {
            click: clickCallback,
            multiplayerClick: multiplayerClickCallback
        };
    }

    if (entityManager) { 
        entityManager.createEntity(InteractableEntity, child, callbacks, pickupable, status, interactionType, itemType, options);
    }

    // Add the object to the raycastable objects array - Exclude interactive objects (and their children) that are items
    if(child.userData.showroom_properties && child.userData.showroom_properties.isItem){
        return;
    }

    if(child.parent && child.parent.userData && child.parent.userData.showroom_properties && child.parent.userData.showroom_properties.isItem){
        return;
    }

    objectToBeConsideredForRaycast.push(child);
}

async function handleObjects(child, properties) {

    // Handle lights
    if (child instanceof THREE.Light) {
        child.intensity = child.intensity*0.002; // Scale down the intensity of the light to match the Blender scene
        child.castShadow = false;
    }  

    if (properties.isAudio){
        handleObjectsWithOptions(child, "isAudio", properties);
    } 

    if (properties.clickable) {
        handleObjectsWithOptions(child, "clickable", properties);
    }

    if (properties.isItem) {
        handleObjectsWithOptions(child, "isItem", properties);
    }

    if (properties.npc) {
        characterManager.characterHoldersFromBlender.push(child);
    }

    /*
    if(properties.isPDF) {
        child.matrixAutoUpdate = true;
        entityManager.createEntity(PDFEntity, child, properties.isPDF, 1);
    }
    */

    if (properties.isAnimator) {
        child.matrixAutoUpdate = true;
        const entity = entityManager.createEntity(AnimationEntity, child);
        const animationComponent = entity.getComponent('AnimationComponent');
        animationSystem.initializeStateMachine(animationComponent, properties)
        animationComponent.stateMachine.rootMesh = child;
    } 
        
    if(properties.isParticleEffect) {
        child.matrixAutoUpdate = true;
        handleObjectsWithOptions(child, "isParticleEffect", properties);
    }

    if(properties.isPulsing) {
        child.matrixAutoUpdate = true;
        handleObjectsWithOptions(child, "isPulsing", properties);
    }

    if (properties.windmill) {
        const worldPosition = child.getWorldPosition(new THREE.Vector3());
        child.matrixAutoUpdate = true;
        entityManager.createEntity(WindmillEntity, properties.windmill, worldPosition.x, worldPosition.y, worldPosition.z, child);
    }

    if (properties.traversableGround) {
        child.material.side = THREE.DoubleSide;
        if (child.geometry) child.geometry.computeBoundsTree();
        traversableGrounds.push(child);
    }

    if (properties.raycastBlocker) {
        child.material.side = THREE.DoubleSide;
        objectToBeConsideredForRaycast.push(child);
    }

    if (properties.masterInstance && properties.masterInstance.trim() !== "") {
        instancingManager.registerMasterInstance(properties.masterInstance, child, properties);
        objectsToDispose.push(child);
    }

    if (properties.isInstance && properties.isInstance.trim() !== "") {
        instancingManager.registerInstance(properties.isInstance, child.position, child.rotation, child.scale);
        objectsToDispose.push(child);
    }

    if (properties.collider) {
        child.matrixAutoUpdate = true;
        child.geometry.computeBoundsTree();

        traversableGrounds.push(child);
        child.visible = false;
    }

    if(properties.isPhysicsObject) {
        child.matrixAutoUpdate = true;
        child.geometry.computeBoundsTree();
        handleObjectsWithOptions(child, "isPhysicsObject", properties);
    }

    if(properties.isTrigger) {
        handleObjectsWithOptions(child, "isTrigger", properties);
    }

    if (properties.startPosition) {
        playerStart = child;
    }

    materialManager.setToMostPerformantShader(child, properties.materialType === 1, properties.materialType === 2);
    if (properties.isHidden) child.visible = false; 

    // Don't apply custom shader or handle annotations to objects that will be disposed (instanced meshes etc)
    if(objectsToDispose.includes(child)) return; 

    // create and apply custom shader material if the child has a shader- custom property
    createCustomShaderMaterial(child);

    // Add annotations to the octree
    annotationManager.setupFloatingAnnotations(child);

    //Fill shootable objects array
    if(child.isMesh && child.visible){
        // Don't add items (objects that can be picked up and used) to the shootable objects array (nor their children)
        if(properties && properties.isItem || child.parent && child.parent.userData.showroom_properties && child.parent.userData.showroom_properties.isItem){
            //If the have a "hasHealth" property, add them to the shootable objects array
            if(properties && properties["isItem-hasHealth"] || child.parent && child.parent.userData.showroom_properties && child.parent.userData.showroom_properties["isItem-hasHealth"]){
                shootableObjects.push(child);
            }
            return;
        } 
        else shootableObjects.push(child);
    } 
}

function handleObjectsConfiguration(model) {
    model.traverse((child) => {
        const properties = child.userData.showroom_properties;
        if(!properties) return

        // General configuration for all objects that is Mesh and has geometry
        if (child.isMesh && child.geometry){
            child.castShadow = true;
            child.receiveShadow = true;
            child.geometry.computeBoundsTree();
            child.matrixAutoUpdate = false;
            child.updateMatrix();
        }

        handleObjects(child, properties);
    });

    instancingManager.createInstancedMeshes(model);
}

function handleSceneConfiguration(model) {
    const properties = model.userData.showroom_scene_properties;

    // Set tone mapping for the specific scene
    if(properties && properties.useToneMapping > 0) {
        renderer.toneMapping = THREE.ACESFilmicToneMapping;
        renderer.toneMappingExposure = properties.useToneMapping;
    }
    else renderer.toneMapping = THREE.NoToneMapping;


    // Set ocean for the specific scene
    if(properties && properties.useOcean) activateOceanAndSkybox(properties.useOcean === 1? true : false);
    else activateOceanAndSkybox(false);

    // Set fog for the specific scene
    if(properties && properties.useFog) activateFog(properties.useFog === 1? true : false);
    else activateFog(false);

    // Set ambient light intensity for the specific scene
    if(properties && properties.ambientLightIntensity > 0) setAmbientLightIntensity(properties.ambientLightIntensity);
    else setAmbientLightIntensity(1.0);
}

function handleModelAnimations(model) {
    model.animations.forEach((clip) => {

        const splitName = clip.name.split("#");
        const animationEvent = splitName[1];
        const animationValue = splitName[2];

        clip.animationEvent = animationEvent;
        clip.animationValue = animationValue;

        animationSystem.animations[clip.name] = clip;

    });
}

export function loadModel(path, init = true) {
    return new Promise((resolve, reject) => {
        gltfLoader.load(path, (model) => {
    
            const modelScene = model.scene;

            if (init) {
                
                handleModelAnimations(model);
                handleObjectsConfiguration(modelScene);
                handleSceneConfiguration(modelScene);
                
                objectsToDispose.forEach(obj => disposeObject(obj));
                objectsToDispose.length = 0;
            }
            
            scene.add(modelScene);
            resolve(modelScene);

        }, undefined, (error) => {
            console.error('Error loading model:', error);
            reject(error);
        });
    });
}

export function disposeObject(object) {
    
    if (!object) return;

    if (shootableObjects.includes(object)) {
        shootableObjects.splice(shootableObjects.indexOf(object), 1);
    }

    if (traversableGrounds.includes(object)) {
        traversableGrounds.splice(traversableGrounds.indexOf(object), 1);
    }

    if (objectToBeConsideredForRaycast.includes(object)) {
        objectToBeConsideredForRaycast.splice(objectToBeConsideredForRaycast.indexOf(object), 1);
    }

    if (object.geometry) {
        object.geometry.dispose();
    }
    if (object.material) {
        if (Array.isArray(object.material)) {
            object.material.forEach(mat => {
                mat.dispose();
                if (mat.map) mat.map.dispose();
                if (mat.emissiveMap) mat.emissiveMap.dispose();
                if (mat.lightMap) mat.lightMap.dispose();
                if (mat.bumpMap) mat.bumpMap.dispose();
                if (mat.normalMap) mat.normalMap.dispose();
                if (mat.specularMap) mat.specularMap.dispose();
                if (mat.envMap) mat.envMap.dispose();
            });
        } else {
            object.material.dispose();
            if (object.material.map) object.material.map.dispose();
            if (object.material.lightMap) object.material.lightMap.dispose();
            if (object.material.emissiveMap) object.material.emissiveMap.dispose();
            if (object.material.bumpMap) object.material.bumpMap.dispose();
            if (object.material.normalMap) object.material.normalMap.dispose();
            if (object.material.specularMap) object.material.specularMap.dispose();
            if (object.material.envMap) object.material.envMap.dispose();
        }
    }
    if (object instanceof THREE.InstancedMesh) {
        object.geometry.dispose();
        object.material.dispose();
    }
    if (object.parent) {
        object.parent.remove(object);

    } else {
        scene.remove(object);
    }
}
