import { setDisplayingPopup } from "../core/sharedState";

class DropdownManager {
    static activeDropdown = null;

    static showDropdown(dropdownElement) { 
        if (this.activeDropdown) {
            this.hideDropdown(this.activeDropdown);
        }

        dropdownElement.style.display = 'block';
        setDisplayingPopup(true);
        document.getElementById('backdrop').style.display = 'block';
        
        setTimeout(() => {
            dropdownElement.style.opacity = '1';
            dropdownElement.style.transform = 'translateY(0)';
        }, 10);
        this.activeDropdown = dropdownElement;
    }

    static hideDropdown(dropdownElement) {  
        
        dropdownElement.style.opacity = '0';
        dropdownElement.style.transform = 'translateY(-10px)';

        setTimeout(() => {
            dropdownElement.style.display = 'none';
        }, 200);  // This delay should match the transition duration in the CSS for a smooth effect
        if (this.activeDropdown === dropdownElement) {
            this.activeDropdown = null;
        }
    }

    static isDropdownVisible(dropdownElement) {
        return dropdownElement.style.display === 'block';
    }
}

export default DropdownManager;
