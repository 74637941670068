import objectData from '../content/objectData.json';
import { getModelURL } from '../firebase/dataManager';
import { loadModel } from './modelLoader';
import { scene } from '../core/sharedState.js';
import * as THREE from 'three';

// Cache to store loaded model data
const DropzoneModelCache = {};

// Manager to track instances of models in dropzones
export const DropzoneModelManager = {};

export async function loadDropzoneModels() {
    const dropzoneNames = Object.keys(objectData)
        .filter(objectName => {
            const existsInScene = Boolean(scene.getObjectByName(objectName));
            return existsInScene && objectData[objectName].dropType === "dropzone";
        });

    for (const dropzoneName of dropzoneNames) {
        const modelURL = await getModelURL(dropzoneName);
        if (modelURL) {
            await loadModelIntoDropzone(dropzoneName, modelURL);
        } else {
            console.log(`No model URL found for ${dropzoneName}, skipping load.`);
        }
    }
}

function extractBaseModelURL(modelURL) {
    const extension = ".glb";
    const index = modelURL.indexOf(extension);

    if (index === -1) {
        console.error("The URL does not contain a .glb extension.");
        return null;
    }

    // Add the length of the extension to include it in the substring
    return modelURL.substring(0, index + extension.length);
}

export async function loadModelIntoDropzone(dropzoneName, modelURL) {
    return new Promise(async (resolve, reject) => {
        let model;
        try {
            if (DropzoneModelCache[extractBaseModelURL(modelURL)]) {
                console.log("Dropzone Model already loaded, cloning...");
                model = DropzoneModelCache[extractBaseModelURL(modelURL)].clone();
                model.userData.isAR = true;
                scene.add(model);
            } else {
                console.log("Dropzone Model not loaded, loading...");
                model = await loadModel(modelURL, false);
                DropzoneModelCache[extractBaseModelURL(modelURL)] = model;  // Store model data in cache
            }

            // Scale, position, and associate the model with a dropzone
            const dropzoneObject = scene.getObjectByName(dropzoneName);
            if (dropzoneObject) {
                
                const scaleAndPosition = computeSizeAndPositionOfDropzoneModelBasedOnDropzoneObject(dropzoneObject, model);
                model.scale.set(scaleAndPosition.scale.x, scaleAndPosition.scale.y, scaleAndPosition.scale.z);
                model.position.copy(scaleAndPosition.position);
            } else {
                console.warn(`Dropzone object ${dropzoneName} not found in the scene.`);
            }
            DropzoneModelManager[dropzoneName] = { modelURL, model };
                        
            return resolve();

        } catch (error) {
            console.error(`Error loading model into dropzone ${dropzoneName}:`, error);
            return reject();
            // Handle the error, possibly by setting a placeholder or leaving the dropzone empty
        }
    });
}

export function removeSpecificDropzoneModel(dropzoneName) {
    const entry = DropzoneModelManager[dropzoneName];
    if (entry) {
        const modelToRemove = scene.getObjectByName(entry.model.name);
        if (modelToRemove) {
            if (modelToRemove.parent) {
                modelToRemove.parent.remove(modelToRemove);
            } else {
                scene.remove(modelToRemove);
            }
            console.log(`Successfully removed model: ${entry.model.name}`);
        } else {
            console.warn(`Model ${entry.model.name} not found in the scene.`);
        }
        delete DropzoneModelManager[dropzoneName];  // Remove entry from manager
    } else {
        console.warn(`Dropzone ${dropzoneName} not found in DropzoneModelManager.`);
    }
}

function computeSizeAndPositionOfDropzoneModelBasedOnDropzoneObject(dropzoneObject, dropzoneModel) {
    // Reset dropzone model transformation to ensure correct bounding box calculation
    dropzoneModel.position.set(0, 0, 0);
    dropzoneModel.rotation.set(0, 0, 0);
    dropzoneModel.scale.set(1, 1, 1);

    const dropzoneBox = new THREE.Box3().setFromObject(dropzoneObject);
    const dropzoneSize = dropzoneBox.getSize(new THREE.Vector3());

    const modelBox = new THREE.Box3().setFromObject(dropzoneModel);
    const modelSize = modelBox.getSize(new THREE.Vector3());

    // Calculate scale factors for each axis
    const scaleX = dropzoneSize.x / modelSize.x;
    const scaleZ = dropzoneSize.z / modelSize.z;

    // Use the smaller scale factor for uniform scaling
    const uniformScaleFactor = Math.min(scaleX, scaleZ);

    // Set new scale
    const newScale = new THREE.Vector3(uniformScaleFactor, uniformScaleFactor, uniformScaleFactor);

    // Compute new position
    let newPosition = dropzoneBox.getCenter(new THREE.Vector3());
    newPosition.y = dropzoneBox.max.y + 0.01;  // Add a small offset to avoid z-fighting

    return { scale: newScale, position: newPosition };
}

export function removeAndDisposeDropzoneModels() {
    DropzoneModelCache.forEach(entry => {
        const modelToRemove = scene.getObjectByProperty('uuid', entry.uuid);
        
        if (modelToRemove) {
            modelToRemove.traverse((object) => {
                if (object.isMesh) {
                    if (object.material.map) object.material.map.dispose();
                    if (object.material.lightMap) object.material.lightMap.dispose();
                    if (object.material.aoMap) object.material.aoMap.dispose();
                    // Add other textures as needed
                    object.material.dispose(); // dispose of material
                    object.geometry.dispose(); // dispose of geometry
                }
            });
            
            if (modelToRemove.parent) {
                modelToRemove.parent.remove(modelToRemove);  // Remove from its parent to ensure no unintended removals
            } else {
                scene.remove(modelToRemove);
            }
        } else {
            console.warn(`Model with UUID ${entry.uuid} not found in the scene.`);
        }
    });

    // Clear the loadedDropzoneModels array
    DropzoneModelCache.length = 0;
}

