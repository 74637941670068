import * as THREE from 'three';

export const vegetationVertexShader = `
uniform float time;
uniform vec2 windDirection;
uniform float windStrength;

varying vec2 vUv;
varying float vDistanceFromCamera;

void main() {
    // Calculate the distance from the center of the object
    float distanceFromCenter = length(position);
    
    // Factor to make the wind effect more pronounced at the top
    float heightFactor = max(0.0, position.y*0.02); // Assuming the bottom of the object is at y = 0 in object space
    
    // Scale the wind effect based on the distance from the center and the height of the vertex
    float windEffectFactor = clamp(distanceFromCenter * heightFactor / 1.0, 0.0, 1.0); // Adjust this depending on how quickly you want the wind effect to scale with distance from center
    
    // Calculate the wind effect
    float windEffect = sin(position.y * 1.0 + time * 1.5) * windStrength * windEffectFactor;
    vec3 displacedPosition = position + vec3(windDirection, 0.0) * windEffect;
  
    // Update the model view position with the displaced position
    vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4(displacedPosition, 1.0);

    vDistanceFromCamera = length(-mvPosition.xyz);
    vUv = uv;
    gl_Position = projectionMatrix * mvPosition;
}
`;

export const vegetationFragmentShader = `
  uniform sampler2D baseTexture;
  varying vec2 vUv;
  varying float vDistanceFromCamera;

  void main() {
    vec4 texColor = texture(baseTexture, vUv);
    float maxDistance = 300.0;
    float aerialFactor = clamp(vDistanceFromCamera / maxDistance, 0.0, 1.0);
    vec3 atmosphericColor = vec3(0.5, 0.7, 1.0);
    vec3 finalColor = mix(texColor.rgb, atmosphericColor, aerialFactor * 0.6);    //(0.8 - texColor.a));
    //if (texColor.a < 0.4) discard;

    gl_FragColor = vec4(finalColor, texColor.a);
    //gl_FragColor = vec4(finalColor, 1.0);
  }
`;

export const vegetationProperties = {
  transparent: true,
  depthWrite: false,
  depthTest: true,
  blending: THREE.NormalBlending,
  side: THREE.DoubleSide,
  lights: false,
};

export const vegetationUniforms = {
  baseTexture: { value: null },
  time: { value: 0.0 },
  windDirection: { value: new THREE.Vector2(0.1, 0.0) }, // For example, for wind blowing along the positive X-axis.
  windStrength: { value: 2.75 }, 
};