import * as THREE from 'three';
import { currentSceneName } from '../core/sharedState.js';
import { materialManager, textureLoader } from '../shaders/materialManager.js';
import propertyDefinitions from '../../../Showroom_Blender_Addon/property_metadata.json';

const lightMapTextureCache = {};
let lightmapLoadPromise = null; // A Promise object to handle the texture loading
let waitingObjects = []; // Array to hold objects waiting for the texture

export function loadLightmapTextureForObject(child) {
    if (child.geometry && child.geometry.attributes.uv1) {

        // Construct the dynamic path for the lightmap
        const lightmapPath = `/textures/${currentSceneName}Lightmap.jpg`;
        // Check if the texture is already in the cache
        if (lightMapTextureCache[lightmapPath]) {
            // Use cached texture
            const cachedTexture = lightMapTextureCache[lightmapPath];
            createLightmapMaterial(cachedTexture, child, materialManager);
        } else {
            // Add the object to the waiting list
            waitingObjects.push(child);

            // If no Promise exists for texture loading, create one
            if (lightmapLoadPromise === null) {
                lightmapLoadPromise = new Promise((resolve, reject) => {
                    // Load and cache the texture
                    textureLoader.load(
                        lightmapPath,
                        (texture) => {
                            texture.flipY = false;
                            texture.minFilter = THREE.LinearFilter;

                            // Cache the loaded texture
                            lightMapTextureCache[lightmapPath] = texture;

                            // Resolve the promise with the loaded texture
                            resolve(texture);
                        },
                        undefined,
                        (error) => {
                            console.error('Error loading lightmap:', error);
                            reject(error);
                        }
                    );
                });
            }

            // Use the Promise to apply the texture to the object
            lightmapLoadPromise.then((texture) => {
                createLightmapMaterial(texture, child, materialManager);
            }).catch((error) => {
                console.error('Failed to apply lightmap:', error);
            }).finally(() => {
                // Remove the object from the waiting list
                const index = waitingObjects.indexOf(child);
                if (index > -1) {
                    waitingObjects.splice(index, 1);
                }
                // Reset the Promise if all waiting objects have been handled
                if (waitingObjects.length === 0) {
                    lightmapLoadPromise = null;
                }
            });
        }
    }
}

function createLightmapMaterial(texture, child, materialManager) {
    const lightmapMaterial = materialManager.createMaterial('Lightmap', {
        map: { value: child.material.map },
        lightmap: { value: texture },
        ambientLightIntensity: { value: 0.3 }
    });
    child.material.needsUpdate = true;
    child.material = lightmapMaterial;
}

export function createCustomShaderMaterial(child, shaderNameOverride = null) {
    let shaderName;
    if (shaderNameOverride) {
        shaderName = shaderNameOverride;
    } else {
        if(!child.userData.showroom_properties) return;
        const properties = child.userData.showroom_properties;
        const materialTypeIndex = properties.materialType;
        if (typeof materialTypeIndex === 'number') {
            shaderName = getShaderNameFromMaterialTypeIndex(materialTypeIndex);
        }
    }

    if (shaderName) {
        // Ensure that the material and map exist
        if (!child.material) {
            console.warn(`No material found for object: ${child.name}`);
            return;
        }

        const baseTexture = child.material.map ? { value: child.material.map } : undefined;

        // Pass the existence-checked texture to createMaterial
        const shaderMaterial = materialManager.createMaterial(shaderName, {
            baseTexture,
        }, {});

        if (shaderMaterial) {
            child.material = shaderMaterial;
            //child.minFilter = THREE.LinearFilter;
        }
    }
}

export function getShaderNameFromMaterialTypeIndex(index) {
    const materialTypeDefinition = propertyDefinitions.materialType;
    if (index >= 0 && index < materialTypeDefinition.options.length) {
        const option = materialTypeDefinition.options[index];        
        if (option && option[0].startsWith("shader")) return option[0];
    }
    return null; 
}

export function getItemNameFromItemTypeIndex(index){
    const itemTypeDefinition = propertyDefinitions['isItem-type'];
    if (index >= 0 && index < itemTypeDefinition.options.length) {
        const option = itemTypeDefinition.options[index];
        if (option) return option[0];
    }
    return null;
}

export function getParticleEffectNameFromIndex(index){
    const particleEffectDefinition = propertyDefinitions['isParticleEffect-type'];
    if (index >= 0 && index < particleEffectDefinition.options.length) {
        const option = particleEffectDefinition.options[index];
        if (option) return option[0];
    }
    return null;
}

export function getNpcIdFromIndex(index){
    const npcDefinition = propertyDefinitions['npc-npcId'];
    if (index >= 0 && index < npcDefinition.options.length) {
        const option = npcDefinition.options[index];
        if (option) return option[0];
    }
    return null;
}

export function getNpcAnimationFromIndex(index){
    const npcDefinition = propertyDefinitions['npc-npcAnimation'];
    if (index >= 0 && index < npcDefinition.options.length) {
        const option = npcDefinition.options[index];
        if (option) return option[0];
    }
    return null;
}

export function getNpcTypeFromIndex(index){
    const npcDefinition = propertyDefinitions['npc-npcSex'];
    if (index >= 0 && index < npcDefinition.options.length) {
        const option = npcDefinition.options[index];
        if (option) return option[0];
    }
    return null;
}

export function getReflectionResolutionFromIndex(index){
    const reflectionResolutionDefinition = propertyDefinitions.reflectiveSurface;
    if (index >= 0 && index < reflectionResolutionDefinition.options.length) {
        const option = reflectionResolutionDefinition.options[index];
        if (option) return option[0];
    }
    return null;
}

