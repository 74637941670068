
import { GRAVITY, PLAYER_HEIGHT } from "../utils/constants.js";
import { physicsObjectsForInitialization } from "../models/modelLoader.js";
import { cameraRig } from "../core/sharedState.js";
import * as THREE from 'three';

let playerRigidBody;
let playerCollider;
let world;
let RAPIER;
let physicsObjects;


export async function loadRapier() {
    RAPIER = await import('@dimforge/rapier3d');
}

export function initializePhysics() {
    if (!RAPIER) {
        console.error("RAPIER has not been loaded.");
        return;
    }

    // Create physics world
    world = new RAPIER.World(GRAVITY);
    physicsObjects = [];

    //Create player rigid body
    //createPlayerRigidBody();

    // Create colliders for all collision meshes
    physicsObjectsForInitialization.forEach(options => {
        addPhysics(options.object, options.rigidBodyType_Str, true, null, options.colliderType_Str, options.mass);
    }); 
}

export function createPlayerRigidBody() {
    const rigidBodyDesc = RAPIER.RigidBodyDesc.kinematicPositionBased()
    playerRigidBody = world.createRigidBody(rigidBodyDesc);
        
    const colliderDesc = RAPIER.ColliderDesc.capsule(PLAYER_HEIGHT, 0.25)
    playerCollider = world.createCollider(colliderDesc, playerRigidBody);
}

export function updatePhysics() {
    if(RAPIER === undefined) return;

    // Update physics
    world?.step();

    // Update physics objects
    for (let i = 0; i < physicsObjects.length; i++) {
        const po = physicsObjects[i]
        const autoAnimate = po.autoAnimate

        if (autoAnimate) {
          const mesh = po.mesh
          const collider = po.collider
          mesh.position.copy(collider.translation())
          mesh.quaternion.copy(collider.rotation())
        }

        const postPhysicsUpdateFunction = po.postPhysicsUpdateFunction
        postPhysicsUpdateFunction && postPhysicsUpdateFunction()
    }

    // Update player position
    if (playerRigidBody) {
        playerRigidBody.setNextKinematicTranslation({x: cameraRig.position.x, y: cameraRig.position.y, z: cameraRig.position.z});
    }
}    

function addPhysics(mesh, rigidBodyType = "fixed", autoAnimate = true, postPhysicsUpdateFunction = null, colliderType = "box", mass = 1) {

    //Set default values
    let colliderDesc;
    let colliderSettings;
    
    //Set rigidBodyType
    if(rigidBodyType == "dynamic") rigidBodyType = RAPIER.RigidBodyDesc.dynamic();
    else if(rigidBodyType == "kinematic") rigidBodyType = RAPIER.RigidBodyDesc.kinematicVelocityBased();
    else if(rigidBodyType == "fixed") rigidBodyType = RAPIER.RigidBodyDesc.fixed();
    else rigidBodyType = RAPIER.RigidBodyDesc.fixed();

    //Get bounding box of the mesh - Used to calculate the center of the box type mesh
    const bbox = new THREE.Box3().setFromObject(mesh);
    const center = bbox.getCenter(new THREE.Vector3());
    const size = bbox.getSize(new THREE.Vector3());
    const halfExtents = size.multiplyScalar(0.5);
    const rbRotation = mesh.getWorldQuaternion(new THREE.Quaternion());

    //Determines the position of the rigidBody and collider based on the collider type
    let rbPosition;
    if(colliderType == "box") rbPosition = center;
    else rbPosition = mesh.position;

    //Determines the collider settings based on the collider type
    if(colliderType == "box") colliderSettings = {width: halfExtents.x, height: halfExtents.y, depth: halfExtents.z};
    else if(colliderType == "ball") colliderSettings = {radius: halfExtents.x};
    else if(colliderType == "capsule") colliderSettings = {halfHeight: halfExtents.y, radius: halfExtents.x};
    else colliderSettings = null;
    
    //Create the rigidBody
    const rigidBodyDesc = rigidBodyType
        .setTranslation(rbPosition.x, rbPosition.y, rbPosition.z)
        .setRotation({ w: rbRotation.w, x: rbRotation.x, y: rbRotation.y, z: rbRotation.z })
        .setLinearDamping(0.5)
        .setAngularDamping(1.0)
        
    const rigidBody = world.createRigidBody(rigidBodyDesc)
    rigidBody.mass(mass)


    //Create the collider
    switch (colliderType) {
        case 'box':
        {
            const { width, height, depth } = colliderSettings
            colliderDesc = RAPIER.ColliderDesc.cuboid(width, height, depth)
        }
        break

        case 'ball':
        {
            const { radius } = colliderSettings
            colliderDesc = RAPIER.ColliderDesc.ball(radius)
        }
        break

        case 'capsule':
        {
            const { halfHeight, radius } = colliderSettings
            colliderDesc = RAPIER.ColliderDesc.capsule(halfHeight, radius)
        }
        break

        case 'triMesh':
        {
            colliderDesc = RAPIER.ColliderDesc.trimesh(
            mesh.geometry.attributes.position.array,
            mesh.geometry.index?.array
            )
        }
        break;
    }

    if (!colliderDesc) {
        console.error('Collider Mesh Error: convex mesh creation failed.')
    }

    const collider = world.createCollider(colliderDesc, rigidBody)
    collider.setDensity(mass)
    
    const physicsObject = { mesh, collider, rigidBody, postPhysicsUpdateFunction, autoAnimate }
    physicsObjects.push(physicsObject)

    return physicsObject
}

export function addImpulse(mesh, force, position) {
    const rigidBody = physicsObjects.find(po => po.mesh === mesh)?.rigidBody
    if (rigidBody) {
        console.log("Adding force to mesh:", mesh.name, force, position);
        rigidBody.applyImpulseAtPoint(force, position, true);
    }
}