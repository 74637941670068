// cacheManager.js
export let objectDataCache = {};

export function getFromMemoryCache(objectName, userType) {
  const cacheKey = userType ? `${objectName}_${userType}` : objectName;
  return objectDataCache[cacheKey];
}

export function updateMemoryCache(data, userType) {
  Object.keys(data).forEach(objectName => {
    const cacheKey = userType ? `${objectName}_${userType}` : objectName;
    objectDataCache[cacheKey] = data[objectName];
  });
}

export function getFromLocalStorage(key) {
  return localStorage.getItem(key);
}

export function updateLocalStorage(key, value) {
  localStorage.setItem(key, value);
}