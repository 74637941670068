export const firebaseConfig = {
  apiKey: "AIzaSyAuS-hr08uErapMnu4EDisoUYy6bmzUUrM",
  authDomain: "demovicoshowroom.firebaseapp.com",
  projectId: "demovicoshowroom",
  storageBucket: "demovicoshowroom.appspot.com",
  messagingSenderId: "968466189974",
  appId: "1:968466189974:web:183a339a5ad6b5200e7cb7"
};

export const clientConfig = {
  useAnnotation: true,
  userTypesAvailableInThisShowroom: {
    "userTypes": "whitecollar, bluecollar"
  },
  movementDataFrequency: 200,
};
