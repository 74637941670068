import { System } from './system.js';
import * as THREE from 'three';
import { shootableObjects } from '../models/modelLoader.js';
import { camera, currentSceneName, isInVRSession, scene } from '../core/sharedState.js';
import { getObjectData } from '../interactions/interactionWithObjectManager.js';
import notificationManager from '../ui/notificationManager.js';
import TWEEN from '@tweenjs/tween.js';
import { isMultiplayerSession } from '../multiplayer/sessionManager.js';
import { sendDataToServer } from '../multiplayer/multiplayerSetup.js';
import { lastTriggeredControllerIndex } from '../XR/XRInteractions.js';

export class ColorBrushSystem extends System {
  constructor(entityManager, systemManager) {
    super(entityManager, systemManager);
    this.requiredComponents = ['InventoryComponent'];
    this.systemManager.onEvent('fire', this.paintObject.bind(this));
    this.raycaster = new THREE.Raycaster();
    this.localForward = new THREE.Vector3(0, 0, -1); // Pre-allocate to reduce object creation
    this.colorIndexMap = new Map(); // Map to keep track of the current color index for each object
    // Pre-allocate temporary objects for efficiency
    this.tempVector = new THREE.Vector3();
    this.tempQuaternion = new THREE.Quaternion();
    this.cooldown = 302;
    this.systemManager.onEvent('cleanUp', this.resetColorBrushSystem.bind(this));
  }

  paintObject(paintPackage) {
    if(paintPackage.itemType !== 'colorBrush') return;

    let activeItemEntity = this.entityManager.findEntityByObjectName(paintPackage.itemId);
    let activeItemComponent = activeItemEntity.getComponent('ItemComponent');
    let activeItemStatusComponent = activeItemEntity.getComponent('StatusComponent');
    activeItemComponent.cooldown = this.cooldown;

    if (!this.canFire(activeItemComponent, activeItemStatusComponent)) return;
    
    let intersectionData;
    let intersectionPoint = null;
    let intersectedObjectId = null

    if(!isInVRSession) intersectionData = this.raycastFromCenterOfScreen();
    else intersectionData = this.raycastFromBrush(activeItemComponent);

    //First check if this has been called from another player in a multiplayer session. If so,
    // We should replace the intersectionData with the data from the paintPackage
    if(isMultiplayerSession) {
      if(paintPackage.playerId && paintPackage.otherPlayerShooting) {
        const object = scene.getObjectByName(paintPackage.intersectedObjectId);
        intersectionData = {object: object, point: paintPackage.hitPosition};
      }
    }
    
    if (intersectionData && intersectionData.object) {
      const objectData = getObjectData(intersectionData.object);
      intersectionPoint = intersectionData.point;
      intersectedObjectId = intersectionData.object.name;
      if (objectData && objectData.colorsAvailable) {
        // Use the object's name as the key
        const objectName = intersectionData.object.name;
        // Get the current color index for the object, or set it to -1 if not yet painted
        let currentColorIndex = this.colorIndexMap.get(objectName);
        if (currentColorIndex === undefined) {
            currentColorIndex = -1;
          }
          // Cycle to the next color
          currentColorIndex = (currentColorIndex + 1) % objectData.colorsAvailable.length;
          // Update the map with the new color index using the object's name
          this.colorIndexMap.set(objectName, currentColorIndex);
          notificationManager.showNotification(`${objectData.colorsAvailable[currentColorIndex].name}`, undefined, undefined, undefined, 10);
          // Apply the new color
          this.applyPaint(intersectionData.object, objectData.colorsAvailable[currentColorIndex].colorData, activeItemComponent);
          this.applyBrushFeedback(activeItemComponent);
        }
    }

    if(isMultiplayerSession && !paintPackage.otherPlayerShooting && !paintPackage.playerId) {

      // For the loving god, please dont send a fire request if this is not the player that fired the weapon. 
      // Only a firepackage received clientside (local) should send the fire request to the server. The clientside firepackage
      // will never include the playerId, which can be used as a condition to determine if the fire request should be sent to the server.
      // This is to avoid an endless loop of fire requests if more than one player is in the room
      sendDataToServer('itemFireRequest', {
        itemId: activeItemComponent.itemName,
        itemScene: currentSceneName,
        itemType: activeItemComponent.itemType,
        hitPosition: intersectionPoint,
        intersectedObjectId: intersectedObjectId,
        playerBeingHit: 0,
        hitDamage: 0
      });   
    }
  }
    
  raycastFromBrush(brushComponent) {
    if (!brushComponent.mesh) return null;
    const origin = new THREE.Vector3();
    const direction = this.calculateWorldForward(brushComponent.mesh);
    this.raycaster.camera = camera;
    brushComponent.mesh.localToWorld(origin);
    this.raycaster.set(origin, direction);
    const filteredShootableObjects = shootableObjects.filter(obj => obj && obj.isMesh);
    const intersects = this.raycaster.intersectObjects(filteredShootableObjects);
    if (intersects.length > 0) {
      return {
        point: intersects[0].point,
        normal: intersects[0].face.normal,
        object: intersects[0].object
      };
    }
    return null;
  }

  raycastFromCenterOfScreen() {
    // Calculate the center of the screen in normalized device coordinates (NDC)
    // (-1 to +1) for both components
    const ndcCenterX = 0;
    const ndcCenterY = 0;
  
    // Set the picking ray from the camera position and direction
    this.raycaster.setFromCamera({ x: ndcCenterX, y: ndcCenterY }, camera);
  
    // Filter the objects that can be interacted with using the raycaster
    const filteredShootableObjects = shootableObjects.filter(obj => obj && obj.isMesh);
  
    // Calculate intersects
    const intersects = this.raycaster.intersectObjects(filteredShootableObjects, true);
  
    // Return the first intersect object, if any
    if (intersects.length > 0) {
      return {
        point: intersects[0].point,
        normal: intersects[0].face.normal,
        object: intersects[0].object
      };
    }
    return null;
  }

  applyPaint(object, color, activeItemComponent) {
    activeItemComponent.lastFired = Date.now();
    this.systemManager.emitEvent('playSound', 'click');
    const newColor = new THREE.Color(color.r / 255, color.g / 255, color.b / 255); // Normalize RGB values to 0-1 range
    if (object.material ) {
      object.material.setValues({ color: newColor });
    }
  }

  calculateWorldForward(mesh) {
    mesh.updateMatrixWorld(true);
    return this.localForward.clone().transformDirection(mesh.matrixWorld);
  }

  canFire(activeItemComponent, activeItemStatusComponent) {
    const finishedCooldown = activeItemComponent && Date.now() - activeItemComponent.lastFired >= activeItemComponent.cooldown;
    let canfire = finishedCooldown;
    
    if(isInVRSession) {
      const convertIndexToHand = lastTriggeredControllerIndex === 0 ? 'right' : 'left';
      const itemInTheHandThatTriggersFire = convertIndexToHand === activeItemStatusComponent.equippedInHand;
      canfire = finishedCooldown && itemInTheHandThatTriggersFire;
    }

    return canfire;
  }

  applyBrushFeedback(activeItemComponent) {
    if (!activeItemComponent.mesh) return;
  
    const mesh = activeItemComponent.mesh;
    const translationMagnitude = 0.05; // For subtle forward movement
    const rotationAngle = Math.PI / 10; // Tilt angle for the brush
  
    // Original position and quaternion for reference
    const originalPosition = mesh.position.clone();
    const originalQuaternion = mesh.quaternion.clone();
  
    // Calculate feedback position and quaternions for down and up tilt
    const feedbackPosition = originalPosition.clone().add(new THREE.Vector3(0, 0, -translationMagnitude));
    const downQuaternion = new THREE.Quaternion().setFromEuler(new THREE.Euler(-rotationAngle, 0, 0)).multiply(originalQuaternion);
  
    // Start with moving the brush forward and tilting it down
    new TWEEN.Tween(mesh.position)
      .to({ x: feedbackPosition.x, y: feedbackPosition.y, z: feedbackPosition.z }, 100)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .yoyo(true)
      .repeat(1)
      .start();
  
    new TWEEN.Tween(mesh.quaternion)
      .to({ x: downQuaternion.x, y: downQuaternion.y, z: downQuaternion.z, w: downQuaternion.w }, 100)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onComplete(() => {
        // After tilting down, smoothly return to the original rotation
        new TWEEN.Tween(mesh.quaternion)
          .to({ x: originalQuaternion.x, y: originalQuaternion.y, z: originalQuaternion.z, w: originalQuaternion.w }, 100)
          .easing(TWEEN.Easing.Quadratic.InOut)
          .start();
      })
      .start();  
  }
  
  resetColorBrushSystem() {
    this.colorIndexMap.clear();
  }
}
