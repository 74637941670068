export class BaseEntity {
    #components; // Using private field for encapsulation
    #componentTypes; // Using private field to store component types as a Set

    constructor(id) {
        this.id = id;
        this.persistent = false;
        this.#components = {};
        this.#componentTypes = new Set(); // Using Set for O(1) deletions
        this.onComponentChanged = null;
    }

    addComponent(component) {
        if (!component.type) {
            throw new Error("Component must have a type property");
        }
        if (this.#components[component.type]) {
            throw new Error("Component of type " + component.type + " already exists!");
        }
        this.#components[component.type] = component;
        this.#componentTypes.add(component.type);  // Update componentTypes
        this.notifyComponentChanged('added', component.type);
    }

    removeComponent(componentType) {
        delete this.#components[componentType];
        this.#componentTypes.delete(componentType);  // Update componentTypes
        this.notifyComponentChanged('removed', componentType);
    }

    getComponent(componentType) {
        return this.#components[componentType];
    }

    get componentTypes() {
        // Providing a read-only array to external code
        return [...this.#componentTypes];
    }

    hasComponent(componentType) {
        return this.#componentTypes.has(componentType);
    }

    notifyComponentChanged(action, componentType) {
        this.onComponentChanged && this.onComponentChanged(this, action, componentType);
    }
}
