import { System } from './system.js';
import * as THREE from 'three';
import { camera, currentSceneName, isInVRSession, scene } from '../core/sharedState.js';
import { Text } from 'troika-three-text';
import { disposeObject, shootableObjects } from '../models/modelLoader.js';
import { isMultiplayerSession } from '../multiplayer/sessionManager.js';
import { sendDataToServer, socket } from '../multiplayer/multiplayerSetup.js';
import { ensureVector3 } from '../utils/helpers.js';
import { lastTriggeredControllerIndex } from '../XR/XRInteractions.js';

export class MeasurementToolSystem extends System {
  constructor(entityManager, systemManager) {
    super(entityManager, systemManager);
    this.requiredComponents = ['InventoryComponent'];
    this.startPoint = null;
    this.endPoint = null;
    this.measurementLine = null;
    this.lines = [];
    this.startPoints = [];
    this.endPoints = [];
    this.textLabels = [];
    this.cooldown = 300;
    this.systemManager.onEvent('fire', this.activateMeasurementTool.bind(this));
    this.systemManager.onEvent('cleanUp', this.cleanup.bind(this));
    this.systemManager.onEvent('resetItem', this.resetItem.bind(this));
    this.raycaster = new THREE.Raycaster();
    this.tempVector = new THREE.Vector3();
    this.localForward = new THREE.Vector3(0, 0, -1); // Pre-allocate to reduce object creation
    this.activeItemComponent = null;
    this.activeItemStatusComponent = null;
    this.maxMeasurements = 5; // Maximum number of measurements allowed
    this.measurementsCount = 0; // Current count of measurements
    this.labelMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
  }

  resetItem(itemName) {
    if (this.activeItemComponent && this.activeItemComponent.itemName === itemName) {
      this.cleanup();
    }
  }

  removeOldestMeasurement() {
    // Directly check the arrays' lengths to decide if removal is needed
    if (this.lines.length > 0) {
      console.log('Removing oldest measurement');
        disposeObject(this.lines.shift());
        disposeObject(this.startPoints.shift());
        disposeObject(this.endPoints.shift());
        disposeObject(this.textLabels.shift());
        this.measurementsCount--;
    }
}

  activateMeasurementTool(activationPackage) {
    if (activationPackage.itemType !== 'tapeMeasure') return;

    let activeItemEntity = this.entityManager.findEntityByObjectName(activationPackage.itemId);
    let activeItemComponent = activeItemEntity.getComponent('ItemComponent');
    this.activeItemStatusComponent = activeItemEntity.getComponent('StatusComponent');
    this.activeItemComponent = activeItemComponent;

    if (!this.canFire(this.activeItemComponent, this.activeItemStatusComponent)) return;

    this.systemManager.emitEvent('playSound', 'click');

    let intersectionData;
    let intersectionPoint = null;
    let intersectedObjectId = null

    if (!isInVRSession) intersectionData = this.raycastFromCenterOfScreen();
    else intersectionData = this.raycastFromTool(activeItemComponent);

    //First check if this has been called from another player in a multiplayer session. If so,
    // We should replace the intersectionData with the data from the Package
    if(isMultiplayerSession) {
      if(activationPackage.playerId && activationPackage.otherPlayerShooting) {
        const object = scene.getObjectByName(activationPackage.intersectedObjectId);
        intersectionData = {object: object, point: activationPackage.hitPosition};
      }
    }

    if (intersectionData && intersectionData.object) {
      intersectionPoint = intersectionData.point;
      intersectedObjectId = intersectionData.object.name;
      if(!intersectionPoint) return;
        if (!this.startPoint) {
            this.startPoint = ensureVector3(intersectionPoint);
            this.showStartPoint(this.startPoint);
            // Initialize temporary line and label only if they don't already exist
            if (!this.tempLine && !this.tempLabel) {
                this.tempLine = this.createLine(this.startPoint, this.startPoint); // Start a temporary line
                this.tempLabel = this.createLabel(this.startPoint, "0 meters"); // Start a temporary label
            }
        } else {
            this.endPoint = ensureVector3(intersectionPoint);
            this.showEndPoint(this.endPoint);
            this.measureDistance(true); // Finalize the measurement with the second click
        }
    }

    if(isMultiplayerSession && !activationPackage.otherPlayerShooting && !activationPackage.playerId) {

      // For the loving god, please dont send a fire request if this is not the player that fired the weapon. 
      // Only a firepackage received clientside (local) should send the fire request to the server. The clientside firepackage
      // will never include the playerId, which can be used as a condition to determine if the fire request should be sent to the server.
      // This is to avoid an endless loop of fire requests if more than one player is in the room
      sendDataToServer('itemFireRequest', {
        itemId: activeItemComponent.itemName,
        itemScene: currentSceneName,
        itemType: activeItemComponent.itemType,
        hitPosition: intersectionPoint,
        intersectedObjectId: intersectedObjectId,
        playerBeingHit: 0,
        hitDamage: 0
      });

      console.log('Sending fire request to server', intersectionPoint);
    }
  }

  update() {
    // Ensure text labels are always facing the camera
    this.textLabels.forEach((text) => {
        if (text instanceof Text) {
            text.quaternion.copy(camera.quaternion);
        }
    });

    // Only update the measurement tool if you're the owner of the active item
    if(isMultiplayerSession && this.activeItemStatusComponent && this.activeItemStatusComponent.owner !== socket.id) return;

    if(this.activeItemComponent ){
      if (this.startPoint && !this.endPoint) {
          // Dynamically update the line and label positions
          let intersectionData;
          if (!isInVRSession) intersectionData = this.raycastFromCenterOfScreen();
          else intersectionData = this.raycastFromTool(this.activeItemComponent);
  
          if (intersectionData) {
              this.tempVector = intersectionData.point;
              const distance = this.startPoint.distanceTo(this.tempVector);
              this.updateLine(this.tempLine, this.startPoint, this.tempVector);
              this.updateLabel(this.tempLabel, distance, this.tempVector, this.tempVector);
          }
      }
    }
  }

  calculateWorldForward(mesh) {
    mesh.updateMatrixWorld(true);
    return this.localForward.clone().transformDirection(mesh.matrixWorld);
  }

  raycastFromTool(toolComponent) {
    if (!toolComponent.mesh) return null;
    const origin = new THREE.Vector3();
    const direction = this.calculateWorldForward(toolComponent.mesh);
    this.raycaster.camera = camera;
    toolComponent.mesh.localToWorld(origin);
    this.raycaster.set(origin, direction);
    const filteredShootableObjects = shootableObjects.filter(obj => obj && obj.isMesh);
    const intersects = this.raycaster.intersectObjects(filteredShootableObjects);
    if (intersects.length > 0) {
      return {
        point: intersects[0].point,
        normal: intersects[0].face.normal,
        object: intersects[0].object
      };
    }
    return null;
  }

  raycastFromCenterOfScreen() {
    // Calculate the center of the screen in normalized device coordinates (NDC)
    // (-1 to +1) for both components
    const ndcCenterX = 0;
    const ndcCenterY = 0;
  
    // Set the picking ray from the camera position and direction
    this.raycaster.setFromCamera({ x: ndcCenterX, y: ndcCenterY }, camera);
  

  
    // Calculate intersects
    const intersects = this.raycaster.intersectObjects(shootableObjects, true);
  
    // Return the first intersect object, if any
    if (intersects.length > 0) {
      return {
        point: intersects[0].point,
        object: intersects[0].object
      };
    }
    return null;
  }

  canFire(activeItemComponent, activeItemStatusComponent) {
    const finishedCooldown = activeItemComponent && Date.now() - activeItemComponent.lastFired >= activeItemComponent.cooldown;
    let canfire = finishedCooldown;
    
    if(isInVRSession) {
      const convertIndexToHand = lastTriggeredControllerIndex === 0 ? 'right' : 'left';
      const itemInTheHandThatTriggersFire = convertIndexToHand === activeItemStatusComponent.equippedInHand;
      canfire = finishedCooldown && itemInTheHandThatTriggersFire;
    }

    return canfire;
  }

  measureDistance(finalize = false) {
    if (!this.startPoint || !finalize) return;

    let currentPoint = this.endPoint; // Since we're finalizing, use the endPoint
    let distance = this.startPoint.distanceTo(currentPoint);

    // Update the temporary line and label to reflect the final measurement
    this.updateLine(this.tempLine, this.startPoint, currentPoint);
    this.updateLabel(this.tempLabel, distance, this.startPoint, currentPoint);

    // counter for the number of measurements
    this.measurementsCount++;

    // Check if we're at the maximum number of measurements before adding a new one
    if (this.measurementsCount >= this.maxMeasurements) {
        this.removeOldestMeasurement();
    }

    // Now add the new measurement components
    this.lines.push(this.tempLine);


    // Reset temporary components for the next measurement
    this.tempLine = null;
    this.tempLabel = null;
    this.startPoint = null;
    this.endPoint = null;
}

  showStartPoint(point) {
    const geometry = new THREE.SphereGeometry(0.04, 16, 16); // Small sphere
    const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 }); // Green color
    const sphere = new THREE.Mesh(geometry, material);
    sphere.position.copy(point);
    this.startPoints.push(sphere); // Store the reference
    scene.add(sphere); // Add the sphere to the scene
  }

  showEndPoint(point) {
    const geometry = new THREE.SphereGeometry(0.04, 16, 16); // Small sphere
    const material = new THREE.MeshBasicMaterial({ color: 0xff0000 }); // Red color
    const sphere = new THREE.Mesh(geometry, material);
    sphere.position.copy(point);
    this.endPoints.push(sphere); // Store the reference
    scene.add(sphere); // Add the sphere to the scene
  }

  createLabel(position, textContent) {
    const text = new Text();
    text.text = textContent;
    const material = this.labelMaterial;
    text.material = material;
    text.position.copy(position);
    text.fontSize = 0.1;
    text.color = 0xffffff;
    text.anchorX = 'center';
    text.anchorY = 'middle';
    text.material.depthTest = false;
    text.material.depthWrite = false;
    text.outlineColor = 'black'; // Set the outline color
    text.outlineWidth = 0.005; 
    scene.add(text);
    this.textLabels.push(text); // Keep track of the label for later removal
    text.sync();
    return text;
  }

  updateLine(line, start, end) {
    line.geometry.setFromPoints([start, end]);
    line.geometry.verticesNeedUpdate = true;
  }

  updateLabel(label, distance, start, end) {
    const midPoint = new THREE.Vector3().addVectors(start, end).multiplyScalar(0.5);
    label.position.copy(midPoint);
    label.text = `${distance.toFixed(2)} meters`;
    label.sync(); // Important to call sync after updating the text
  }

  createLine(start, end) {
    const geometry = new THREE.BufferGeometry().setFromPoints([start, end]);

    const material = new THREE.LineBasicMaterial({ 
      color: 0xff0000,
      depthTest: false,
      depthWrite: false, 
      transparent: true,
    });
  
    const line = new THREE.Line(geometry, material);
    line.frustumCulled = false; // Ensure the line is rendered even if it's outside the camera's frustum
    line.renderOrder = 999; // Ensure this line is rendered last
    scene.add(line);
    return line;
}

  showDistanceLabel(start, end, distance) {
    const midPoint = new THREE.Vector3().addVectors(start, end).multiplyScalar(0.5);
    const text = new Text();
    text.text = `${distance.toFixed(2)} meters`;
    text.fontSize = 0.1;
    text.color = 0xffffff;
    text.position.copy(midPoint);
    text.anchorX = 'center';
    text.anchorY = 'middle';
    text.material.depthTest = false; // Disable depth test
    text.material.depthWrite = false; // Prevent the text from writing to the depth buffer
    text.outlineWidth = 0.005; // Adjust the width of the outline
    text.outlineColor = 'black';
    text.renderOrder = 999; // Render last

    // You might need to adjust the position to ensure it's visible and not clipping with other objects
    text.position.z += 0.1; // Adjust this based on your scene's specific needs
    text.position.y += 0.2; // Adjust this based on your scene's specific needs

    this.textLabels.push(text); // Store the reference
    scene.add(text);

    // Since Troika-3D-Text uses a deferred rendering technique, you need to call `sync()` after changes
    text.sync();
  }

  resetMeasurement() {
    this.startPoint = null;
    this.endPoint = null;
  }

  cleanup(){
    console.log('Cleaning up measurement tool');

    disposeObject(this.measurementLine);
    this.measurementLine = null;

    disposeObject(this.startPoint);
    this.startPoint = null;

    disposeObject(this.endPoint);
    this.endPoint = null;

    for (let line of this.lines) {
      disposeObject(line);
    }

    for (let point of this.startPoints) {
        disposeObject(point);
        }
    
    for (let point of this.endPoints) {
        disposeObject(point);
    }

    for (let text of this.textLabels) {
        disposeObject(text);
    }

    this.lines = [];
    this.startPoints = [];
    this.endPoints = [];
    this.textLabels = [];
    this.activeItemComponent = null;    

    if (this.tempLine) {
      disposeObject(this.tempLine);
      this.tempLine = null;
    }

    if (this.tempLabel) {
      disposeObject(this.tempLabel);
      this.tempLabel = null;
    }

    this.measurementsCount = 0;
  }
}
