import { isInVRSession, usePhysics , setMobile } from '../core/sharedState.js';
import { initializeFPSControls, updateFPSControls } from '../core/fpsControls.js';
import { initializeXRControllers, updateXRControls } from '../XR/XRControllers.js';
import { initMobileControls, updateMobileControls  } from '../mobile/mobileControls.js';
import { updateKeyboardButtons } from '../XR/Keyboard/XRKeyboard.js';

let desktopControlsInitialized = false;
let xrControlsInitialized = false;
let mobileControlsInitialized = false;
let advCharacterController;

export function initializeControllers() {
    // Initialize desktop controls if not already initialized
    if (!desktopControlsInitialized) {
        
        initializeFPSControls();           
        desktopControlsInitialized = true;
    }

    // Initialize Mobile controls if on a mobile device and not in a VR session
    if (!isInVRSession && isMobileDevice() && !mobileControlsInitialized) { 
        initMobileControls();
        mobileControlsInitialized = true;
        setMobile(true);
    }

    // Initialize XR controllers if not already initialized
    if (!xrControlsInitialized) {
        initializeXRControllers();
        xrControlsInitialized = true;
    }
}

export function updateControllers(timestamp, timeDiffCapped) {

    if (isInVRSession) {
        updateXRControls();
    }
    else {

        //updateKeyboardButtons();
        updateFPSControls();
        
    }

    if (isMobileDevice && mobileControlsInitialized) {
        updateMobileControls();
    } 
}

export function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}
