import * as THREE from 'three';
import ThreeMeshUI from 'three-mesh-ui';

import { camera, scene } from '../core/sharedState.js'; // Update path as necessary
import { getCSSVariableValue } from '../utils/domUtils.js';
import { formatVRText, sanitizeText } from '../XR/UI/XRTextUtility.js';

// Configuration constants
const config = {
    fontJson: '/fonts/arial-msdf.json',
    fontTexture: '/fonts/arial.png',
    colors: {
        background: new THREE.Color(getCSSVariableValue('--background-color')),
        title: new THREE.Color(getCSSVariableValue('--title-color')),
        text: new THREE.Color(getCSSVariableValue('--text-color')),
    },
    dimensions: {
        width: 1.0,
        height: 1.3,
    },
    padding: 0.05,
    borderRadius: 0.1,
};

class FloatingPopupManager {
    constructor() {
        this.activePopup = null;
        this.tempVectorPosition = new THREE.Vector3();
        this.tempVectorDirection = new THREE.Vector3();
        this.popupTimeout = null; // Keep track of the popup timeout
    }

    createPopupContent(data) {
        const container = new ThreeMeshUI.Block({
            width: config.dimensions.width,
            height: config.dimensions.height,
            padding: config.padding,
            contentDirection: 'column',
            justifyContent: 'center',
            borderRadius: config.borderRadius,
            backgroundColor: config.colors.background,
            backgroundOpacity: 1.0,
            fontFamily: config.fontJson,
            fontTexture: config.fontTexture,
        });

        if (data.headline) {
            container.add(this._createHeadlineBlock(data.headline));
        }

        if (data.text) {
            container.add(this._createTextBlock(data.text));
        }

        return container;
    }

    _createHeadlineBlock(headline) {
        const headlineBlock = new ThreeMeshUI.Block({
            width: 0.8,
            height: 0.2,
            backgroundOpacity: 0,
            justifyContent: 'center',
            textAlign: 'left',
            bestFit: 'auto',
        });
        const headlineText = new ThreeMeshUI.Text({
            content: sanitizeText(headline),
            fontSize: 0.14,
            fontColor: config.colors.title,
            bestFit: 'auto',
        });
        headlineBlock.add(headlineText);
        return headlineBlock;
    }

    _createTextBlock(text) {
        const textBlock = new ThreeMeshUI.Block({
            width: 0.8,
            height: 0.8,
            backgroundOpacity: 0,
            justifyContent: 'center',
            textAlign: 'left',
            whiteSpace: 'pre-wrap',
            bestFit: 'auto',
        });
        const textContent = new ThreeMeshUI.Text({
            content: formatVRText(sanitizeText(text)),
            fontSize: 0.06,
            fontColor: config.colors.text
        });
        textBlock.add(textContent);
        return textBlock;
    }

    _setPositionForPopup(predefinedPosition) {
        if (predefinedPosition) {
            this.activePopup.position.copy(predefinedPosition);
        } else {
            // Automatically place the popup in front of the camera if no position is predefined
            camera.getWorldPosition(this.tempVectorPosition);
            camera.getWorldDirection(this.tempVectorDirection);
            const popupPosition = this.tempVectorPosition.add(this.tempVectorDirection.multiplyScalar(1.5));
            this.activePopup.position.copy(popupPosition);
            this.activePopup.lookAt(this.tempVectorPosition);
        }
    }

    displayPopup(data, predefinedPosition, duration) {
        this.disposePopup(); // Dispose any existing popup before displaying a new one

        this.activePopup = this.createPopupContent(data);
        this._setPositionForPopup(predefinedPosition); // Assuming _setPositionForPopup is correctly implemented
        scene.add(this.activePopup);
        this.showPopup();

        if (this.popupTimeout) {
            clearTimeout(this.popupTimeout); // Clear any existing timeout to prevent multiple timeouts running
        }

        if (duration && !isNaN(duration)) {
            this.popupTimeout = setTimeout(() => {
                this.disposePopup(); // Automatically dispose the popup after the duration
            }, duration);
        }
    }

    showPopup() {
        if (this.activePopup) {
            this.activePopup.visible = true;
            this.activePopup.traverse((child) => {
                child.visible = true;
            });
        }
    }

    hidePopup() {
        if (this.activePopup) {
            this.activePopup.visible = false;
            this.activePopup.traverse((child) => {
                child.visible = false;
            });
        }
    }

    disposePopup() {
        if (!this.activePopup) return;

        if (this.popupTimeout) {
            clearTimeout(this.popupTimeout); // Clear the timeout upon disposing
            this.popupTimeout = null;
        }

        this.activePopup.traverse((child) => {
            if (child.material) child.material.dispose();
            if (child.geometry) child.geometry.dispose();
        });
        scene.remove(this.activePopup);
        this.activePopup = null;
    }

    update() {
        if (this.activePopup) {
            camera.getWorldPosition(this.tempVectorPosition);
            this.activePopup.lookAt(this.tempVectorPosition);
        }
    }
}

export default new FloatingPopupManager();
