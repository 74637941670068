// selectAvatarPopupManager.js

import { hideAllUIElements } from '../utils/uiUtils.js';
import { showSpinner, hideSpinner } from './spinner.js';

export function showAvatarSelectionPopup() {
    hideSpinner(document.body);
    hideAllUIElements(true);
    
    const avatarPopup = document.getElementById('avatar-selection-popup');
    avatarPopup.style.display = 'block';
    document.getElementById('lock-backdrop').style.display = 'block';
}

function closeAvatarSelectionPopup() {
    const avatarPopup = document.getElementById('avatar-selection-popup');
    avatarPopup.style.display = 'none';
}

export function selectAvatar() {
    return new Promise((resolve, reject) => {
        showAvatarSelectionPopup();
        
        document.querySelectorAll('.avatar-button').forEach(button => {
            button.onclick = () => {
                showSpinner(document.body);
                const selectedModelName = button.getAttribute('data-model');
                closeAvatarSelectionPopup();
                resolve(selectedModelName);
            };
        });
    });
}
