import { ARButton } from 'three/examples/jsm/webxr/ARButton.js';
import * as THREE from 'three';
import { camera, cameraRig, renderer, scene } from './sharedState';
import { checkXRSession } from './rendererSetup';
import { setupEnvironmentAndLightning } from './environmentSetup';
import { PLAYER_HEIGHT } from '../utils/constants';

// List for storing the names of all visible objects
let visibleObjects = [];
let cameraHeight = 0;

export function setupAR() {
    if ('xr' in navigator) {
        renderer.xr.enabled = true;
        renderer.xr.setReferenceSpaceType('local-floor');

        // Manual AR session initiation
        navigator.xr.isSessionSupported('immersive-ar').then((supported) => {
            if (supported) {
                console.log('AR supported on this device.');
                const arButton = document.getElementById('ar-button');

                arButton.onclick = () => {
                    navigator.xr.requestSession('immersive-ar', { requiredFeatures: ['local-floor', 'hit-test'] })
                        .then((session) => {
                            cameraHeight = cameraRig.position.y+PLAYER_HEIGHT;

                            renderer.xr.setSession(session);
                            onSessionStart();
                            //arButton.remove();
                        })
                        .catch((error) => {
                            console.error('Failed to start AR session:', error);
                        });
                };

            } else {
                console.warn('AR not available on this device.');
            }
        });

        // Event listeners for AR session start and end
        renderer.xr.addEventListener('sessionend', onSessionEnd);
    } else {
        console.warn('AR not available on this device.');
    }
}

function onSessionStart() {
    console.log('AR Session started');
    checkXRSession();

    scene.background = null;
    scene.environment = null;

    // Set the initial camera height
    camera.position.set(0, cameraHeight, 0);


    // Store the names of all visible objects
    /*
    scene.traverse((object) => {
        if (object.visible) {
            //visibleObjects.push(object.name);
        }
    });

    // Hide all objects in the visibleObjects list unless they are in the AR scene

    scene.traverse((object) => {
        if (!object.userData.isAR && visibleObjects.includes(object.name)) {
            //object.visible = false;
        }
    });
    */

}

function onSessionEnd() {
    console.log('AR Session ended');
    checkXRSession();

    // Restore visibility of all objects in visibleObjects list

    /*
    scene.traverse((object) => {
        if (visibleObjects.includes(object.name)) {
            object.visible = true;
        }
    });
    */

    setupEnvironmentAndLightning();
    camera.position.set(0, PLAYER_HEIGHT, 0)

    // Clear the visibleObjects list
    visibleObjects = [];
}


