import { isMobile } from '../core/sharedState';
import { webrtc } from '../multiplayer/multiplayerSetup';
import { createElement } from '../utils/domUtils';

class PlayerListUIManager {
    constructor() {
        this.playerNotifications = [];
    }

    createPlayerListContainer() {
        this.container = createElement('div', {
            className: 'player-notification-container',
        });
        this.container.style.height = '30px'; // Example initial height

        this.toggleButton = createElement('button', {
            className: 'toggle-button',
            innerHTML: 'players online ⬆',
        });
        this.toggleButton.onclick = () => this.togglePlayerList();

        this.container.appendChild(this.toggleButton);
        document.body.appendChild(this.container);
    }

    togglePlayerList() {
        if (this.container.classList.contains('folded')) {
            this.container.style.height = '500px'; // Expand to fit content
            this.container.classList.remove('folded');
        } else {
            this.container.style.height = `${this.container.scrollHeight}px`; // Set height before transition
            this.container.offsetHeight; // Force reflow
            this.container.style.height = '30px'; // Collapse to the height of the toggle button
            this.container.classList.add('folded');
        }
        this.toggleButton.innerHTML = this.container.classList.contains('folded') ? 'players online ⬇' : 'players online ⬆';
    }

    foldPlayerList() {
        this.container.style.height = '30px'; // Collapse to the height of the toggle button
        this.container.classList.add('folded');
        this.toggleButton.innerHTML = 'players online ⬇';
    }

    unfoldPlayerList() {
        this.container.style.height = '500px'; // Expand to fit content
        this.container.classList.remove('folded');
        this.toggleButton.innerHTML = 'players online ⬆';
    }
    
    addPlayerNotification(message, fontSize, duration, playerId) {
        if (this.container === undefined) {
            this.createPlayerListContainer();
            if(isMobile) {
                this.container.style.top = '0px';
            }
        }
        this.unfoldPlayerList();
        if (this.playerNotifications.length > 0 && !this.playerNotifications[0].isStacked) {
            this.moveToTopLeftStack(this.playerNotifications[0]);
        }

        const playerNotification = this.createNotificationElement(message, fontSize, playerId);
        console.log('Player notification created for the player with id:', playerId );
        this.container.appendChild(playerNotification);
        playerNotification.isStacked = false;
        playerNotification.playerId = playerId;
        console.log('Player notification added for the player with id:', playerNotification.playerId );

        this.slideInFromRightCenter(playerNotification);

        setTimeout(() => {
            this.moveToTopLeftStack(playerNotification);
            playerNotification.isStacked = true;
        }, duration);

        this.playerNotifications.unshift(playerNotification);
    }

    removePlayerNotification(playerId) {
        // Find the notification with the matching playerId
        const notificationIndex = this.playerNotifications.findIndex(notif => notif.playerId === playerId);
    
        if (notificationIndex !== -1) {
            const notificationToRemove = this.playerNotifications[notificationIndex];
    
            // Ensure it's a player notification
            if (notificationToRemove.classList.contains('player-notification')) {
                this.container.removeChild(notificationToRemove); // Remove from DOM
                this.playerNotifications.splice(notificationIndex, 1); // Remove from array
    
                // Reposition remaining notifications
                this.repositionNotifications();
            }
        } else {
            console.error('Player notification not found for playerId:', playerId);
        }
    }
    
    

    repositionNotifications() {
        let yOffset = 40;
        this.playerNotifications.forEach(notif => {
            if (notif.isStacked) {
                notif.style.top = `${yOffset}px`;
                yOffset += 40; // Adjust this value based on your notification height
            }
        });
    }

    createNotificationElement(message, fontSize, playerId) {
        const container = createElement('div', {
            className: 'notification-flex-container',
        });

        const textElement = createElement('span', {
            className: 'notification-text',
            innerText: message,
            style: `font-size: ${fontSize}px;`
        });
        container.appendChild(textElement);

        const button = createElement('button', {
            className: 'mute-button',
            innerHTML: '&#x1F399;', // Unicode microphone icon
            style: 'cursor: pointer;'
        });
        button.onclick = () => this.handlePlayerNotificationClick(button, playerId);
        container.appendChild(button); 

        return createElement('div', {
            className: `notification player-notification`, // Added 'player-notification' class
        }, container);
    }

    handlePlayerNotificationClick(button, playerId) {
        const isMuted = button.classList.toggle('muted');
        button.innerHTML = isMuted ? '&#x1F507;' : '&#x1F399;';
        if(webrtc) webrtc.mutePlayerAudio(playerId, isMuted);
        console.log('Player notification button clicked. Muted:', isMuted);
    }

    slideInFromRightCenter(element) {
        element.style.left = '75%';
        element.style.top = '75%';
        element.style.transform = 'scale(0)';
        setTimeout(() => {
            element.style.transition = 'left 0.5s ease, transform 0.5s ease';
            if(isMobile) element.style.left = '50%';
            else element.style.left = '45%';
            if(isMobile) element.style.transform = 'scale(1)';
            else element.style.transform = 'scale(2)';
        }, 10);
    }

    moveToTopLeftStack(element) {
        if(isMobile) element.style.transform = 'scale(1)';
        else element.style.transform = 'scale(2)';
        setTimeout(() => {
            const yOffset = this.calculateTopOffsetForStack();
            element.style.top = `${yOffset}px`;
            element.style.left = '10px';
            element.style.transition = 'top 0.5s ease, left 0.5s ease, transform 0.5s ease';
            element.style.transform = 'scale(1)';
        }, 10);
    }

    calculateTopOffsetForStack() {
        let offset = 0;
        for (const notif of this.playerNotifications) {
            if (notif.isStacked) {
                offset += 40;
            }
        }
        return offset;
    }
}

// Styles for the playerList
const styles = `
.player-notification-container {
    position: absolute;
    top: 60px;
    left: 10;
    height: 30px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.0); /* Slightly darker semi-transparent background */
    overflow: hidden;
    transition: height 0.5s ease;
    z-index: 10;
    overflow: hidden;
    pointer-events: none; /* Ignore pointer events */
}

.toggle-button {
    position: fixed;
    margin-top: 10px;
    left: 10px; 
    width: 170px;
    height: 20px; 
    z-index: 1000; 
    border: none;
    background-color: rgba(0, 0, 0, 0.0); /* Slightly darker semi-transparent background */
    color: var(--title-color);  
    transition: 0.2s;
    text-align: left; 
    cursor: pointer;
}

.notification {
    position: absolute;
    padding: 5px 10px;
    width: 150px;
}

.notification-text {
    font-family: var(--primary-font);
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.player-notification-container > * {
    pointer-events: auto; /* Enable pointer events for direct children */
}

.toggle-button, .mute-button {
    pointer-events: auto; /* Specifically enable pointer events for buttons */
}
`;

// Append styles to the head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);


export default new PlayerListUIManager();
