// XRLoadingScreenManager.js
import * as THREE from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import { camera, cameraRig, scene } from '../../core/sharedState';

let vrLoadingScreen = null;

//Define font:
const FontJSON = '/fonts/Roboto-msdf.json';
const FontImage = '/fonts/Roboto-msdf.png';

// Define colors
const backgroundColor = new THREE.Color('black'); 
const titleColor = new THREE.Color('white');

export function createVRLoadingScreen() {
    if (vrLoadingScreen) return vrLoadingScreen; // Return existing screen if already created

    // Create the loading screen elements
    const loadingScreen = new ThreeMeshUI.Block({
        width: 10,
        height: 10,
        padding: 0.1,
        backgroundOpacity: 1,
        backgroundColor: backgroundColor,
        alignContent: 'center',
        justifyContent: 'center'
    });

    /*
    const loadingText = new ThreeMeshUI.Text({
        content: 'Loading...',
        fontFamily: FontJSON,
        fontTexture: FontImage,
        fontSize: 0.1,
        opacity: 1,
        offset: 0.01,
        fontColor: titleColor
    });

    loadingScreen.add(loadingText);
    */

    vrLoadingScreen = loadingScreen; // Assign the created screen to the vrLoadingScreen variable
    vrLoadingScreen.scale.set(0.2, 0.2, 0.2);

    return vrLoadingScreen;
}

export function setVRLoadingScreen(visible) {
    if (!vrLoadingScreen) {
        vrLoadingScreen = createVRLoadingScreen(); // Ensure vrLoadingScreen is created if not already
    }

    // Toggle visibility
    vrLoadingScreen.visible = visible;

    if (visible && !vrLoadingScreen.parent) {
        camera.add(vrLoadingScreen); // Add to camera to ensure it's always in view
        vrLoadingScreen.position.set(0, 0, -0.13);
    } else if (!visible && vrLoadingScreen.parent) {
        vrLoadingScreen.parent.remove(vrLoadingScreen); // Remove from camera 
    }
}
