import * as THREE from 'three';

export const PulseTypes = {
    NORMAL: 'normal',
    SLOW: 'slow',
    PAUSE: 'pause'
};

export class PulseComponent {
    constructor(options = {})
    {
        this.type = 'PulseComponent';
        this.triggerAtHover = options.triggerAtHover || 0;
        this.normalColor = options.normalColor || new THREE.Color(1, 1, 1);
        this.pulseSpeed = options.pulseSpeed || 1.0;
        this.maxScale = options.maxScale || 1.5;
        this.normalScale = options.normalScale || 1.0;
        this.minScale = options.minScale || 0.5;
        this.maxColor = options.maxColor || new THREE.Color(4, 4, 4);
        this.minColor = options.minColor || new THREE.Color(0.5, 0.5, 0.5);
        this.maxOpacity = options.maxOpacity || 1;
        this.minOpacity = options.minOpacity || 0.5;
        this.increasing = options.increasing || true;
        this.active = options.active || true;
        this.justDeactivated = false;
        this.pulseType = options.pulseType || PulseTypes.NORMAL;
        this.pauseDuration = options.pauseDuration || 1;
        this.pauseElapsed = options.pauseElapsed || 0;
        this.isPaused = options.isPaused || false;
        this.currentTime = options.currentTime || 0;
        this.animateColor = options.animateColor || false;
        this.animateScale = options.animateScale || false;
    }
}
