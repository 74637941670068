import { systemManager } from "../ECS-utils/managerECS";
import { setupPlayerAudioAndIcon } from "./playerManagerHelper";

export class WebRTCManager {
    constructor(socket) {
        this.socket = socket;
        this.peerConnections = {};
        this.localStream = null;
        this.socket.on('webrtc-offer', (data) => this.handleOffer(data));
        this.socket.on('webrtc-answer', (data) => this.handleAnswer(data));
        this.socket.on('webrtc-ice-candidate', (data) => this.handleIceCandidate(data));
        systemManager.onEvent('toggleMicrophone', this.toggleMicrophone.bind(this));
    }

    async captureAudio() {
        try {
            if (!navigator.mediaDevices) {
                throw new Error("MediaDevices not supported");
            }
            const constraints = { 
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    autoGainControl: true
                }, 
                video: false 
            };
            this.localStream = await navigator.mediaDevices.getUserMedia(constraints);
            return this.localStream;
        } catch (error) {
            console.error('Error capturing audio:', error);
            this.audioCaptureFailed();
            throw error; // Rethrow to allow caller to handle the error as well
        }
    }

    audioCaptureFailed() {
        let micToggleButton = document.getElementById('microphone-access');
        if(micToggleButton) {
            micToggleButton.disabled = true;
            micToggleButton.title = "Microphone not available";
        }
    }

    toggleMicrophone(isMicrophoneEnabled) {
        if (this.localStream) {
            this.localStream.getAudioTracks()[0].enabled = isMicrophoneEnabled;
            alert("Microphone " + (isMicrophoneEnabled ? "enabled" : "muted"));
        }
    }

    createPeerConnection() {
        const config = {
            iceServers: [
                { urls: 'stun:stun.l.google.com:19302' } // Google's public STUN server
            ]
        };
        return new RTCPeerConnection(config);
    }

    createOffer(targetId) {
        const peerConnection = this.createPeerConnection();
        this.peerConnections[targetId] = peerConnection;

         // Add the local stream to the connection
         if (this.localStream) {
            console.log("Adding local stream to connection");
            this.localStream.getTracks().forEach(track => {
                peerConnection.addTrack(track, this.localStream);
            });
        }

        peerConnection.onconnectionstatechange = () => {
            if (peerConnection.connectionState === 'connected') {
                console.log('WebRTC Connection established with:', targetId);
            }
        };

        peerConnection.onicecandidate = (event) => {
            if (event.candidate) {
                this.socket.emit('webrtc-ice-candidate', {
                    target: targetId,
                    candidate: event.candidate
                });
            }
        };

        peerConnection.ontrack = (event) => {
            setupPlayerAudioAndIcon(targetId, event.streams[0]);
        };
        

        peerConnection.createOffer()
            .then(offer => peerConnection.setLocalDescription(offer))
            .then(() => {
                this.socket.emit('webrtc-offer', {
                    target: targetId,
                    offer: peerConnection.localDescription
                });
            });
    }

    handleOffer(data) {
        const peerConnection = this.createPeerConnection();
        this.peerConnections[data.sender] = peerConnection;
    
        // Check if local stream is available
        if (this.localStream) {
            // Add local stream tracks to the peer connection
            this.localStream.getTracks().forEach(track => {
                peerConnection.addTrack(track, this.localStream);
            });
        } else {
            console.log("No local stream available (mic might be declined or not present).");
            // Optionally, inform the remote peer that this client has no microphone access
            this.socket.emit('webrtc-no-mic', {
                target: data.sender
            });
        }
    
        peerConnection.onicecandidate = (event) => {
            if (event.candidate) {
                this.socket.emit('webrtc-ice-candidate', {
                    target: data.sender,
                    candidate: event.candidate
                });
            }
        };
    
        peerConnection.ontrack = (event) => {
            console.log("Received remote stream from:", data.sender);
            setupPlayerAudioAndIcon(data.sender, event.streams[0]);
        };
    
        peerConnection.setRemoteDescription(new RTCSessionDescription(data.offer))
            .then(() => peerConnection.createAnswer())
            .then(answer => peerConnection.setLocalDescription(answer))
            .then(() => {
                this.socket.emit('webrtc-answer', {
                    target: data.sender,
                    answer: peerConnection.localDescription
                });
            });
    }
    
    handleAnswer(data) {
        const peerConnection = this.peerConnections[data.sender];
        if (peerConnection) {
            peerConnection.setRemoteDescription(new RTCSessionDescription(data.answer));

            peerConnection.onconnectionstatechange = () => {
                if (peerConnection.connectionState === 'connected') {
                    console.log('WebRTC Connection established with:', data.sender);
                }
            };
        }

    }

    handleIceCandidate(data) {
        const peerConnection = this.peerConnections[data.sender];
        if (peerConnection) {
            peerConnection.addIceCandidate(new RTCIceCandidate(data.candidate));
            
        }
    }

    mutePlayerAudio(playerId, mute) {

        const peerConnection = this.peerConnections[playerId];
        if (peerConnection) {
            console.log("Muting audio for:", playerId);
            peerConnection.getReceivers().forEach(receiver => {
                if (receiver.track.kind === 'audio') {
                    receiver.track.enabled = !mute;
                }
            });
        }
    }
    

    closeConnection(targetId) {
        console.log("Closing connection with:", targetId);
        const peerConnection = this.peerConnections[targetId];
        if (peerConnection) {
            peerConnection.close();
            delete this.peerConnections[targetId];
        }
    }

    closeAllConnections() {
        console.log("Closing all connections...");
        Object.values(this.peerConnections).forEach(pc => {
            if (pc) {
                pc.close();
            }
        });
        this.peerConnections = {};
    
        if (this.localStream) {
            this.localStream.getTracks().forEach(track => track.stop());
            this.localStream = null;
        }
    }
    
}
