import { entityManager, systemManager } from '../ECS-utils/managerECS.js';
import { sendDataToServer, socket } from './multiplayerSetup.js';
import { removeItemFromPlayerModel, syncPlayersStoredInThisModuleWithEnvironmentState, updatePlayerModelWithItem, updatePlayersVisibility } from './playerManager.js';
import { camera, cameraRig, currentSceneName } from '../core/sharedState.js';
import { getCurrentRoom } from './roomManager.js';
import { filterDataByAllowedKeys } from '../utils/helpers.js';
import { sendPlayerMovementData } from './playerMovementDataSender.js';

const allowedPlayerKeys = ['playerId', 'playerAvatar', 'playerType', 'sceneName', 'playerState', 'playerName', 'position', 'equippedItem'];
const allowedItemKeys = ['itemId', 'itemType', 'itemState', 'itemAnimationState', 'itemInteractionType', 'itemEquip', 'pickedUpBy', 'controllerHand', 'itemScene'];

let environmentState = { players: {}, items: {}};

export function getEnvironmentState() {
    return environmentState;
}

// Handle player scene change
export function handlePlayerChangedScene(data) {
    updateEnvironmentState(data);
    sendPlayerMovementData(cameraRig, camera, true); // Send movement data to update other players
}

export function syncEnvironmentState(receivedState) {

    // Convert players array to an object with playerId as keys (server sends players as an array, but we need an object for easier access)
    const playersObject = {};
    receivedState.players.forEach(player => {
        playersObject[player.playerId] = player;
    });

    // Update environmentState with the converted players object
    environmentState = { 
        players: playersObject,
        items: receivedState.items
    };

    for (const itemId in environmentState.items) {
        const item = environmentState.items[itemId];
        if (item.itemScene === currentSceneName) {
            applyStateChange(item, 'ItemComponent');
        }
    }

    for (const playerId in environmentState.players) {
        const player = environmentState.players[playerId];
        if (player.sceneName === currentSceneName) {
            console.log('Syncing player:', player);
            applyStateChange(player, 'PlayerComponent');
        }
    }
}

export function updateEnvironmentState(data) {

    console.log('Updating environment state:', data);
    // Handle Item Updates
    if (data.itemId !== undefined && data.itemId !== null) {
        updateItemState(data);
    }

    // Handle Player Updates
    if (data.playerId !== undefined && data.playerId !== null) {
        updatePlayerState(data);
    }
}

function updateItemState(data) {
    const filteredItemData = filterDataByAllowedKeys(data, allowedItemKeys);
    environmentState.items[data.itemId] = { ...environmentState.items[data.itemId], ...filteredItemData };
    applyStateChange(data, 'ItemComponent');
}

function updatePlayerState(data) {
    if (data.playerLeft) {
        if (environmentState.players[data.playerId]) {
            delete environmentState.players[data.playerId];
        }
    } else {
        const filteredPlayerData = filterDataByAllowedKeys(data, allowedPlayerKeys);
        // Update or add the player state
        environmentState.players[data.playerId] = { ...environmentState.players[data.playerId], ...filteredPlayerData };
        applyStateChange(data, 'PlayerComponent');
    }
}

// Handle other player interaction with an item
export function handleOtherPlayerInteractedWithItem(data) {
    updateEnvironmentState(data);
    //systemManager.emitEvent('otherPlayerInteracted', data);
}

// Apply changes to entity based on component type
function applyStateChange(data, componentType) {
    const itemEntity = entityManager.findEntityByObjectName(data.itemId);

    switch (componentType) {
        case 'ItemComponent':
            if (itemEntity) handleItemComponent(itemEntity, data);
            break;
        case 'PlayerComponent':
            handlePlayerComponent(data);
            break;
    }
}

// Handle item component state
function handleItemComponent(itemEntity, data) {
    const itemComponent = itemEntity.getComponent('ItemComponent');
    const statusComponent = itemEntity.getComponent('StatusComponent');
    const renderComponent = itemEntity.getComponent('RenderComponent');
    const interactionComponent = itemEntity.getComponent('InteractionComponent');
    let animationComponent = null

    const animationEntity = entityManager.findEntityByMeshAndRequiredComponents(renderComponent.mesh, ['AnimationComponent', 'RenderComponent']);
    if (animationEntity) animationComponent = animationEntity.getComponent('AnimationComponent');
    
    if (itemComponent){
        itemComponent.itemType = environmentState.items[data.itemId].itemType;
    }

    if(statusComponent){
        if(data.pickedUpBy !== undefined) {
            const status = data.pickedUpBy === null ? "available" : "picked_up";
            const owner = data.pickedUpBy === null ? "" : data.pickedUpBy;
            statusComponent.setState(status);
            statusComponent.setOwner(owner);
        }
    }

    if (renderComponent && environmentState.items[data.itemId].itemScene === currentSceneName) {
        if(data.pickedUpBy !== undefined)
            if(data.pickedUpBy === null){
                console.log('Item dropped by player:', data);
                renderComponent.mesh.visible = true;
                systemManager.emitEvent('resetItem', itemComponent.itemName);
            }
            else renderComponent.mesh.visible = false;

            if(data.pickedUpBy === socket.id) renderComponent.mesh.visible = true;     
    }

    if (interactionComponent) {
        interactionComponent.interactionType = environmentState.items[data.itemId].itemInteractionType;
    }

    if (animationComponent && data.playerId !== socket.id) {
        if(animationComponent.stateMachine.currentState !== environmentState.items[data.itemId].itemAnimationState){
            if (environmentState.items[data.itemId].itemAnimationState){
                animationComponent.stateMachine.setState(environmentState.items[data.itemId].itemAnimationState, true);
                console.log('Setting animation state:', environmentState.items[data.itemId].itemAnimationState);
            }
        }
    }

    if (data.pickedUpBy === socket.id) {
        console.log('Item picked up by you:', data);
        systemManager.emitEvent('itemPickUpAccepted', {itemEntity, controllerHand: data.controllerHand});
    }
}

// Handle player component state
function handlePlayerComponent(data) {
    if (data.playerId !== socket.id){
        console.log('Player component state change:', data);
        const equippedItemEntity = entityManager.findEntityByObjectName(data.equippedItem);
        
        if("equippedItem" in data){
            if (equippedItemEntity && data.equippedItem !== null) {
                updatePlayerModelWithItem(equippedItemEntity, data); 
            }
            else if(data.equippedItem === null || data.equippedItem === undefined || data.equippedItem === "") {
                removeItemFromPlayerModel(data);
            }
        }
    }

    const syncData = environmentState.players[data.playerId];
    syncPlayersStoredInThisModuleWithEnvironmentState(syncData);
    updatePlayersVisibility();
}


export function sendItemFireRequest(itemType, itemId, itemScene, hitPosition, playerBeingHit, hitDamage) {

    const fireRequestData = {
        roomId: getCurrentRoom(),
        itemId: itemId,
        itemType: itemType,
        itemScene: itemScene,
        hitPosition: hitPosition,
        playerBeingHit: playerBeingHit,
        hitDamage: hitDamage
    };
    sendDataToServer('itemFireRequest', fireRequestData);
}


