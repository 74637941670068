
export class InteractionComponent {
    constructor(callbacks, interactionType, options) {
        this.type = 'InteractionComponent';
        this.callbacks = callbacks;
        this.interactionType = interactionType;
        this.isQuiz = options.isQuiz || false;
        this.isAdminTerminal = options.isAdminTerminal || false;
        this.isLocked = options.isLocked || false;
        this.keyType = options.keyType || null;
        this.isPDF = options.isPDF || ""
        this.isNewRoom = options.isNewRoom || ""
        this.showAnnotation = options.showAnnotation || false;
        this.annotationText = options.annotationText || "";
        this.annotationScale = options.annotationScale || 1;
        this.annotationScaleToDistance = options.annotationScaleToDistance || false;
        this.annotationFaceCamera = options.annotationFaceCamera || false;
        this.annotationOffset = options.annotationOffset || 0.0;
        this.playAnimation = options.playAnimation_Str || "";
        this.positionHelperObject = options.positionHelperObject || "";
        this.stepAction = { actionId: null, objectName: null };
    }

    setStepAction(actionId, objectName) {
        this.stepAction.actionId = actionId;
        this.stepAction.objectName = objectName;
    }

}