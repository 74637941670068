export class CharacterStateComponent {
    constructor() {
        this.type = 'CharacterStateComponent';

        this.sex = 'female';
        
        this.hasBeenStartled = false;

        this.isUnderAttack = false;

        this.isHealthLow = false;

        this.MoodEnum = Object.freeze({
            IDLE1: 'idle1',
            IDLE2: 'idle2',
            IDLE3: 'idle3'
        });

        this.currentMood = this.MoodEnum.IDLE1;

        this.lastReactionTime = 0;

        this.isPlayerInSight = false;

        this.isCurious = false;

        this.isAngry = false;
    }

    setSex(sex) {
        this.sex = sex;
    }

    // Method to reset the startled flag
    resetStartled() {
        this.hasBeenStartled = false;
    }

    // Method to set the entity as being under attack
    setUnderAttack(status) {
        this.isUnderAttack = status;
    }

    // Method to set the health status
    setHealthStatus(status) {
        this.isHealthLow = status;
    }

    // Method to set the current mood
    setCurrentMood(mood) {
        this.currentMood = mood;
    }

    isMood(mood) {
        return this.currentMood === mood;
    }
}
