export class ItemComponent {
  constructor(itemType, itemMesh, damage = 0, range = 0, cooldown = 200, pickupable = false) {
      this.type = 'ItemComponent';
      this.itemName = itemMesh.name;
      this.mesh = itemMesh;
      this.itemType = itemType;
      this.pickupable = pickupable;
      this.damage = damage;
      this.range = range;
      this.cooldown = cooldown;
      this.lastFired = 0;  // Timestamp of the last time this weapon was fired
  }
}