import { characterSheet } from "../../utils/constants";

// XRTextUtility.js

export function sanitizeText(text) {
    // Split the text into an array of characters
    const sanitizedText = text.split('').filter(char => 
        characterSheet.includes(char) || /\s/.test(char) // Check if the character is in the characterSheet or is a whitespace character
    ).join('');
    return sanitizedText;
}


export function formatVRText(text, characterSheet) {
    let formattedText = text.replace(/\[\s*p\s*\]/g, (match) => characterSheet.includes(match) ? match : "\n")
                            .replace(/\[\s*\/\s*p\s*\]/g, (match) => characterSheet.includes(match) ? match : "\n\n");

    formattedText = formattedText.replace(/\[\s*ul\s*\]/g, (match) => characterSheet.includes(match) ? match : "")
                                 .replace(/\[\s*\/\s*ul\s*\]/g, (match) => characterSheet.includes(match) ? match : "")
                                 .replace(/\[\s*li\s*\]/g, (match) => characterSheet.includes(match) ? match : "- ")
                                 .replace(/\[\s*\/\s*li\s*\]/g, (match) => characterSheet.includes(match) ? match : "\n");

    return formattedText;
}

