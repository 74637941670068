// Import required modules and configuration
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "@firebase/storage";
import { FIREBASE_CONFIG } from '../utils/constants.js';
import { handleLogin, handleLogout } from './userAuthAndManagement.js';

// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);

// Initialize Firebase services
export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);

export function initializeAuthListeners() {
    // Attach event listener for login
    const loginAdminBtn = document.getElementById('login-admin-btn');
    const logoutAdminBtn = document.getElementById('logout-admin-btn');
    const loginNormalBtn = document.getElementById('login-normal-btn');
    loginAdminBtn.addEventListener('click', handleLogin);
    logoutAdminBtn.addEventListener('click', handleLogout);
    loginNormalBtn.addEventListener('click', handleLogin);
}
