// multiplayerPopupManager.js

import popupManager from "./popupManager.js";
import { isMultiplayerSession } from "../multiplayer/sessionManager.js";
import { generateInvitationLink, getCurrentRoom, joinRoom } from "../multiplayer/roomManager.js";

import { setupEventListener, hideAllUIElements } from '../utils/uiUtils.js';
import { showSpinner, hideSpinner } from './spinner.js';
import { copyToClipboard } from "../utils/helpers.js";
import { setDisplayingPopup } from "../core/sharedState.js";
import { welcomeMessage } from "../core/begin.js";

let multiplayerPopup = document.getElementById('multiplayer-popup-window');
let multiplayerInitPopup = document.getElementById('multiplayer-init-container');
let multiplayerCopyLinkPopup = document.getElementById('multiplayer-session-container');

export function showMultiplayerPopup() {
    hideAllUIElements(true);
    setDisplayingPopup(true, true);

    multiplayerPopup.style.display = 'block';
    if (isMultiplayerSession) {
        multiplayerCopyLinkPopup.style.display = 'block'; 
        multiplayerInitPopup.style.display = 'none';
    } else {
        multiplayerCopyLinkPopup.style.display = 'none'; 
        multiplayerInitPopup.style.display = 'block';
    }
        
    document.getElementById('backdrop').style.display = 'block';
}

export function initMultiplayerPopup() {
    setupEventListener('multiplayer-btn', (e) => {
        e.stopPropagation();
        showMultiplayerPopup();
    });

    setupMultiplayerEventListeners();
}

function setupMultiplayerEventListeners() {
    // Event listener for creating a new room
    setupEventListener('create-room-btn', async (e) => {
        e.stopPropagation();
        const playerName = document.getElementById('player-name-input').value;
        if (playerName) {
            showSpinner(document.body);
            try {
                const roomId = await joinRoom(null, playerName); // Null roomId will generate a new one
                document.getElementById('room-id-display').textContent = `Room ID: ${roomId}`;
                multiplayerInitPopup.style.display = 'none';
                multiplayerPopup.style.display = 'block';
                hideSpinner(document.body);
                multiplayerCopyLinkPopup.style.display = 'block';
            } catch (error) {
                hideSpinner(document.body);

                if (error.message.includes("Failed to fetch")) {
                    alert("The server is currently down. Please try again later.");
                } else {
                    alert("An unexpected error occurred. Please try again.");
                }
                hideAllUIElements(true);
            }
        } else {
            alert('Please enter your name before creating a room.');
        }
    });

    // Event listener for copying the invitation link
    setupEventListener('copy-invite-link-btn', (e) => {
        e.stopPropagation();
        const invitationLink = generateInvitationLink(getCurrentRoom());
        copyToClipboard(invitationLink);

        hideAllUIElements();
        setDisplayingPopup(false);

        
        //alert('Invitation link copied to clipboard. Share it with your friends to invite them to this room!');
    });

    // Event listener for joining a room
    setupEventListener('invitation-join-btn', async (e) => {
        e.stopPropagation();
        const playerName = document.getElementById('invitation-player-name').value;
        const roomId = document.getElementById('invitation-room-id').textContent.split(": ")[1];
        if (playerName && roomId) {
            showSpinner(document.body);
            try {
                await joinRoom(roomId, playerName);
            }
            catch (error) {
                if (error.message.includes("Failed to fetch")) {
                    alert("The server is currently down. Please try again later.");
                } else {
                    alert("An unexpected error occurred. Please try again.");
                }
            }
            finally {
                document.getElementById('invitation-popup-window').style.display = 'none';
                hideSpinner(document.body);
                welcomeMessage();
            }

        } else {
            alert('Please enter your name before joining.');
        }
    });
}
