import { camera } from '../core/sharedState.js';
import { raycaster, mouse } from '../utils/constants.js';
import { resetHoveredObject, setNewHoveredObject } from './hoverManager.js';
import { objectToBeConsideredForRaycast } from '../models/modelLoader.js';
import { getObjectUserData } from '../utils/objectDataHelper.js';

let hoverStates = {
    controller1: null,
    controller2: null,
    mouse: null
};

let lastTime = 0;

export function handleHoveredObject(intersects, source) {
    const currentHoveredObject = hoverStates[source];

    // If the object is still being intersected, do nothing
    if (currentHoveredObject && intersects[0] && currentHoveredObject === intersects[0].object) {
        return;
    }

    // Reset the previously hovered object if any
    if (currentHoveredObject) {
        resetHoveredObjectForSource(currentHoveredObject, source);
    }

    // If there's a new intersection, hover the new object
    if (intersects.length > 0) {
        const object = intersects[0].object;

        // If the object is a raycast blocker, do nothing
        if (getObjectUserData(object, 'raycastBlocker')){
            return;
        }

        setNewHoveredObjectForSource(object, source);
    }
}

function resetHoveredObjectForSource(object, source) {
    const otherSourcesHovering = Object.keys(hoverStates).filter(s => s !== source && hoverStates[s] === object);
    if (otherSourcesHovering.length === 0) {
        resetHoveredObject(object);
    }
    hoverStates[source] = null;
}

function setNewHoveredObjectForSource(object, source) {
    setNewHoveredObject(object);
    hoverStates[source] = object;
}

export function updateHover() {
    const currentTime = Date.now();
    const THROTTLE_MS = 20;

    if (currentTime - lastTime > THROTTLE_MS) {
        raycaster.setFromCamera(mouse, camera);

        let intersects = raycaster.intersectObjects(objectToBeConsideredForRaycast);

        handleHoveredObject(intersects, 'mouse');
        lastTime = currentTime;
    }
}
