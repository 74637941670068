import * as THREE from 'three';
import { scene, setScene, setCameraAndEnvironmentInitialized, cameraRig, camera, setSceneName, currentSceneName } from './sharedState.js';
import { isMultiplayerSession } from '../multiplayer/sessionManager.js';
import { entityManager, animationSystem, systemManager } from '../ECS-utils/managerECS.js';
import { setupCamera } from './cameraSetup.js';
import { setupEnvironmentAndLightning } from './environmentSetup.js';
import { loadDropzoneModels } from '../models/dropzoneManager.js';
import { loadModel, disposeObject, traversableGrounds, playerStart, objectToBeConsideredForRaycast, shootableObjects } from '../models/modelLoader.js';
import { instancingManager } from '../models/instancingManager.js';
import { annotationManager } from '../annotations/annotationManager.js';
import { materialManager } from '../shaders/materialManager.js';
import { characterManager } from '../models/characterManager.js';
import { setLoadingFinished, setLoadingStarted } from '../ui/loadingManager.js';
import { quizManagersMap } from '../interactions/interactionWithObjectManager.js';
import { initializeLastValidPosition, setPlayerPosition } from './playerMovement.js';
import { hideAllUIElements } from '../utils/uiUtils.js';
import { addFootprintsToScene, hideFootprints } from '../interactions/uxHelpers.js';
import TextureDropManager from '../models/textureDropManager.js';
import { sendDataToServer } from '../multiplayer/multiplayerSetup.js';
import { sendPlayerMovementData } from '../multiplayer/playerMovementDataSender.js';
import { initializePhysics } from '../physics/physicsManager.js';

export class SceneManager {
    constructor() {
        this.loadedScenes = {};
        this.activeSceneModelGroupName = null;
    }

    async loadScene(sceneName, modelPath) {
        this.dispatchSceneAboutToLoadEvent(sceneName);
        setLoadingStarted();
        await this.unloadCurrentScene();
        await this.setOrInitializeScene(sceneName);
        await this.setupCameraAndEnvironment();
        await this.loadAnimationsAndModels(sceneName, modelPath);
        initializePhysics();

        setLoadingFinished();
        hideAllUIElements();
        hideFootprints();
        
        // Reset player position
        camera.rotation.copy(playerStart.rotation);
        setPlayerPosition(playerStart.position.x, playerStart.position.y, playerStart.position.z);  
        
        // If in multiplayer session, send the scene change to the server and update the environment according to the latest environment state
        if (isMultiplayerSession) {
            sendPlayerMovementData(cameraRig, camera); // Send initial position
            sendDataToServer('playerChangedScene');
        }
        
        // Emit event to notify systems that the scene has changed
        systemManager.emitEvent('sceneLoaded', sceneName);
        
        // Bring uxHelperObjects to new scene
        addFootprintsToScene(scene);
    }

    async unloadCurrentScene() {
        try {
            await this.unloadScene(scene, this.activeSceneModelGroupName);
        } catch (error) {
            console.warn("Scene can't be unloaded. Disregard if it's the first load:", error);
        }
    }

    async setOrInitializeScene(sceneName) {
        if (this.loadedScenes[sceneName]) {
            setScene(this.loadedScenes[sceneName]);
        } 
        else {
            const newScene = new THREE.Scene();
            this.loadedScenes[sceneName] = newScene;
            setScene(newScene);
        }
        scene.name = sceneName;
    }

    async setupCameraAndEnvironment() {
        setupEnvironmentAndLightning();
        setupCamera();

        // initalize components that depend on the camera
        initializeLastValidPosition();
        setCameraAndEnvironmentInitialized(true);
    }

    async loadAnimationsAndModels(sceneName, modelPath) {
        try {
            let sceneData = await this.loadSceneModel(modelPath, sceneName);
            this.activeSceneModelGroupName = sceneName;

            const scene_properties = sceneData.showroom_scene_properties;

            if(scene_properties && scene_properties.hasCharacters){
                await animationSystem.loadGlobalCharacterAnimations();
                await characterManager.setupCharacters(entityManager);
            }

            sceneData = null;

        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    async unloadScene(scene, sceneToUnloadName) {
        return new Promise((resolve, reject) => {
            if (!scene) {
                console.warn("No scene to unload");
                return reject();
            }
            systemManager.emitEvent('cleanUp');
            const objectsToDispose = this.collectObjectsForDisposal(scene, sceneToUnloadName);
            this.disposeObjectsAndClearScene(objectsToDispose);
            resolve();
        });
    }

    collectObjectsForDisposal(scene, sceneToUnloadName) {
        const objectsToDispose = [];
        let targetGroup = scene.children.find(object => object.name === sceneToUnloadName);
        if (targetGroup) {
            targetGroup.traverse(object => {
                if(isMultiplayerSession && object.userData.isPlayerModel) return;
                objectsToDispose.push(object)
            });
        }
        [traversableGrounds, objectToBeConsideredForRaycast, shootableObjects, characterManager.characterHoldersFromBlender].forEach(array => {
            objectsToDispose.push(...array);
        });
        return objectsToDispose;
    }

    disposeObjectsAndClearScene(objectsToDispose) {
        shootableObjects.length = 0;
        traversableGrounds.length = 0;
        objectToBeConsideredForRaycast.length = 0;
        characterManager.characterHoldersFromBlender.length = 0;
        objectsToDispose.forEach(object => disposeObject(object));
        entityManager.clear();
        instancingManager.unload();
        annotationManager.unload();
        materialManager.clearCache();
        this.disposeQuizManagers();
    }

    disposeQuizManagers() {
        quizManagersMap.forEach((quizManager) => {
            quizManager.dispose();
        });
        quizManagersMap.clear();
    }

    async loadSceneModel(path, sceneName) {
        try {
            setSceneName(sceneName);
            const model = await loadModel(path);
            model.name = sceneName;
            animationSystem.initMixerForAnimationSystem(model);      

            try {
                await loadDropzoneModels();
            } catch (error) {
                console.error("Error loading dropzone models:", error);
            }

            try {
                await TextureDropManager.registerTextureDropsFromData();
            }
            catch (error) {
                console.error("Error loading texture drops:", error);
            }
            
            this.dispatchSceneLoadedEvent(sceneName);

            return model.userData;
        } catch (error) {
            console.error("Error loading scene model:", error);
        }
    }

    dispatchSceneAboutToLoadEvent(sceneName) {
        const sceneAboutToLoadEvent = new CustomEvent('sceneAboutToLoad', {
            detail: { sceneName: sceneName }
        });
        window.dispatchEvent(sceneAboutToLoadEvent);
    }

    dispatchSceneLoadedEvent(sceneName) {
        const sceneLoadedEvent = new CustomEvent('sceneLoaded', {
            detail: { sceneName: sceneName }
        });
        window.dispatchEvent(sceneLoadedEvent);
    }
}

export const sceneManager = new SceneManager();
