// XRPopupManager.js
import * as THREE from 'three';
import ThreeMeshUI from 'three-mesh-ui';

import { sanitizeText, formatVRText } from './XRTextUtility.js';
import { controllers } from '../XRControllers.js';
import { activeControllerIndex } from '../XRInteractions.js';
import { camera, cameraRig, isInVRSession, scene } from '../../core/sharedState.js';
import { getCSSVariableValue } from '../../utils/domUtils.js';
import { raycaster } from '../../utils/constants.js';
import { RAY_COLORS, updateCursorPosition, updateRayColor, updateRayLength } from '../XRRayAndCursor.js';
import { getControllerData } from '../XRUtilities.js';
import { systemManager } from '../../ECS-utils/managerECS.js';

// Configuration constants
const config = {
    fontJson: '/fonts/arial-msdf.json',
    fontTexture: '/fonts/arial.png',
    colors: {
        background: new THREE.Color(getCSSVariableValue('--background-color')),
        title: new THREE.Color(getCSSVariableValue('--title-color')),
        text: new THREE.Color(getCSSVariableValue('--text-color')),
    },
    dimensions: {
        width: 1.0,
        height: 1.3,
    },
    padding: 0.05,
    borderRadius: 0.1,
};

class XRPopupManager {
    constructor() {
        this.activeVrPopup = null;
        this.textContent = null;
        this.headlineText = null;
        this.container = null;
        this.tempVectorPosition = new THREE.Vector3();
        this.tempVectorDirection = new THREE.Vector3();
        this.tempVector = new THREE.Vector3();
        this.showingButtons = false;
        this.xrButtonObjectsForRaycasting = [];
        this.activeButtons = [];
        this.colors = {
            keyboardBack: new THREE.Color(getCSSVariableValue('--background-color')),
            panelBack: new THREE.Color(getCSSVariableValue('--background-color')),
            button: new THREE.Color(getCSSVariableValue('--background-color')),
            hovered: new THREE.Color(getCSSVariableValue('--XRButton-hover-color')),
            selected: new THREE.Color(getCSSVariableValue('--title-color')),
            errorText: new THREE.Color(getCSSVariableValue('--button-remove-color')),
          };
    }

    createButton(data) {
        const button = new ThreeMeshUI.Block({
            width: 0.7,
            height: 0.15,
            justifyContent: 'center',
            alignContent: 'center',
            borderRadius: 0.05,
            backgroundColor: new THREE.Color(this.colors.button),
            backgroundOpacity: 1.0,
            offset: 0.02,
            margin: 0.02,
            bestFit: 'auto',
        });

        const buttonText = new ThreeMeshUI.Text({
            content: data.buttonName,
            fontSize: 0.06,
            fontColor: new THREE.Color(this.colors.text),
        });

        button.add(buttonText);

        // Setup interaction states (idle, hovered, selected)
        button.setupState({
            state: 'hovered',
            attributes: {
                backgroundColor: new THREE.Color(this.colors.hovered),
                backgroundOpacity: 1,
                fontColor: new THREE.Color(this.colors.selected),
            },
        });

        button.setupState({
            state: 'idle',
            attributes: {
                backgroundColor: new THREE.Color(this.colors.button),
                backgroundOpacity: 1,
                fontColor: new THREE.Color(this.colors.text),
            },
        });

        button.setupState({
            state: 'selected',
            attributes: {
                backgroundColor: new THREE.Color(this.colors.selected),
                fontColor: new THREE.Color(this.colors.text),
            },
            onSet: () => {
                this.executeButtonFunction(data.buttonType, data.buttonFunction, data.buttonFunctionArgs);
            },
        });

        this.xrButtonObjectsForRaycasting.push(button);
        return button;
    }

    executeButtonFunction(buttonType, buttonFunction, buttonFunctionArgs) {
        // Teleport button function
        if (buttonType === 'goto' && buttonFunction === 'teleport') {
            const args = parseTeleportFunctionArgs(buttonFunctionArgs);

            if (args) {
                
                const [positionArgs, rotationArgs] = args;
                const targetPosition = new THREE.Vector3(...positionArgs);

                const targetRotation = new THREE.Euler(...rotationArgs.map(r => THREE.MathUtils.degToRad(r)));
                setPlayerPosition(targetPosition.x, targetPosition.y, targetPosition.z);
                cameraRig.rotation.copy(targetRotation);
                
            }
        }

        // Start learning module button function
        if (buttonType === 'goto' && buttonFunction === 'startLearningModule') {
            const args = buttonFunctionArgs.split(',');
            const learningModuleId = args[0].trim();
            const modeId = args[1].trim();
            const objectName = args[2].trim();

            const learningPackage = {moduleId: learningModuleId, modeId: modeId, objectName: objectName};
            systemManager.emitEvent('startLearningModule', learningPackage);
        }

        // Always remove and dispose buttons after a button is clicked
        this.removeAndDisposeButtons();
    }

    removeAndDisposeButtons() {
        this.activeButtons.forEach(button => {
            this.container.remove(button);
            button.children[0].clear();
            button.clear();
        });

        //Clear arrays
        this.activeButtons = [];
        this.xrButtonObjectsForRaycasting = [];
    }


    createPopupContent(data) {
        if (!data) return null;

        this.container = new ThreeMeshUI.Block({
            width: config.dimensions.width,
            height: config.dimensions.height,
            padding: config.padding,
            contentDirection: 'column',
            justifyContent: 'center',
            bestFit: 'auto',
            borderRadius: config.borderRadius,
            backgroundColor: config.colors.background,
            backgroundOpacity: 0.8,
            fontFamily: config.fontJson,
            fontTexture: config.fontTexture,
        });

        if (data.headline) {
            this.container.add(this._createHeadlineBlock(data.headline));
        }

        if (data.text) {
            this.container.add(this._createTextBlock(data.text));
        }

        return this.container;
    }

    _createHeadlineBlock(headline) {
        const headlineBlock = new ThreeMeshUI.Block({
            width: 0.8,
            height: 0.2,
            backgroundOpacity: 0,
            justifyContent: 'center',
            textAlign: 'left',
            bestFit: 'auto',
        });
       this.headlineText = new ThreeMeshUI.Text({
            content: sanitizeText(headline),
            fontSize: 0.14,
            fontColor: config.colors.title,
            bestFit: 'auto',
        });
        headlineBlock.add(this.headlineText);
        return headlineBlock;
    }

    _createTextBlock(text) {
        const textBlock = new ThreeMeshUI.Block({
            width: 0.8,
            height: 0.8,
            backgroundOpacity: 0,
            justifyContent: 'center',
            textAlign: 'left',
            whiteSpace: 'pre-wrap',
            bestFit: 'auto',
        });
        this.textContent = new ThreeMeshUI.Text({
            content: formatVRText(sanitizeText(text)),
            fontSize: 0.06,
            fontColor: config.colors.text
        });
        textBlock.add(this.textContent);
        return textBlock;
    }

    displayPopup(data, usePopupControllerAttach = true) {
        if (!this.activeVrPopup) {
            console.log('Creating new VR popup');
            console.log(data);
            this.activeVrPopup = this.createPopupContent(data);
            this.updatePopupContent(this.activeVrPopup, data);
            this._attachPopupToScene(this.activeVrPopup, usePopupControllerAttach);
        } else {
            if(this.activeButtons.length > 0) this.removeAndDisposeButtons();
            this.updatePopupContent(this.activeVrPopup, data);
            this._attachPopupToScene(this.activeVrPopup, usePopupControllerAttach);
        }
        this.showPopup(); // Ensure the popup is visible
    }

    showPopup() {
        if (this.activeVrPopup) {
            this.activeVrPopup.visible = true; // Make the popup visible
            this.activeVrPopup.traverse((child) => {
                child.visible = true; // Ensure all children are also visible
            });
        }
    }

    hidePopup() {
        if (this.activeVrPopup) {
            this.activeVrPopup.visible = false; // Hide the popup
            this.activeVrPopup.traverse((child) => {
                child.visible = false; // Also hide all children
            });
        }
    }
    
    updatePopupContent(popup, data) {
        if(!popup) return;

        // Update the components using the set method, which internally schedules
        // the necessary updates based on the type of attributes being set.
        if(data.headline){
            const sanitizedHeadline = sanitizeText(data.headline);
            this.headlineText.set({
                content: sanitizedHeadline
            });
        }
        
        if(data.text){
            const formattedText = formatVRText(sanitizeText(data.text));
            this.textContent.set({
                content: formattedText
            });
        }

        if (data.buttons && Array.isArray(data.buttons)) {
            data.buttons.forEach(button => {
                const btn = this.createButton(button);
                this.container.add(btn);
                this.showingButtons = true;
                this.activeButtons.push(btn);
            });
        }
        else this.showingButtons = false;

    }
    
    _attachPopupToScene(popup, usePopupControllerAttach) {
        if (usePopupControllerAttach && typeof activeControllerIndex !== 'undefined') {
            
            controllers[activeControllerIndex].add(popup);
            this._setPositionForControllerAttachedPopup(popup);
        } else {
            this._setPositionForStandalonePopup(popup);
            scene.add(popup);
        }
    }

    _setPositionForControllerAttachedPopup(popup) {
        popup.position.set(0, 0.25, 0);
        popup.scale.set(0.3, 0.3, 0.3);
    }

    _setPositionForStandalonePopup(popup) {
        camera.getWorldPosition(this.tempVectorPosition);
        camera.getWorldDirection(this.tempVectorDirection);
        const popupPosition = this.tempVectorPosition.add(this.tempVectorDirection.multiplyScalar(1.5));
        popup.position.copy(popupPosition);
        popup.lookAt(this.tempVectorPosition);
    }

    disposePopup() {
        console.log('Disposing active VR popup');
        if (!this.activeVrPopup) return;

        this.activeVrPopup.traverse((child) => {
            if (child.material) child.material.dispose();
            if (child.geometry) child.geometry.dispose();
        });
        scene.remove(this.activeVrPopup);
        this.activeVrPopup = null;
    }



    // Copied from the XRKeyboard class
    updateElementState(element, state) {
        if (element && element.isUI) {
          if ( element.states[ state ] ) element.setState(state);
        }
    }
      
    raycast() {
        return this.xrButtonObjectsForRaycasting.reduce( ( closestIntersection, obj ) => {
    
            const intersection = raycaster.intersectObject( obj, true );
            if ( !intersection[ 0 ] ) return closestIntersection;
    
            if ( !closestIntersection || intersection[ 0 ].distance < closestIntersection.distance ) {
                intersection[ 0 ].object = obj;
                return intersection[ 0 ];
            }
    
            return closestIntersection;
        }, null );
    
    }
      
    updateXRButtons(controller = null) {
        if (!this.showingButtons && !this.container?.visible) return;
    
        let intersect;
        if (isInVRSession && controller) {
          const [position, , direction] = getControllerData(controller);
          raycaster.set(position, direction);
        } else {
          raycaster.setFromCamera(mouse, camera);
        }
        intersect = this.raycast();
      
        if (intersect && intersect.object.isUI) {
          if (isInVRSession) {
            this.updateCursorPositionAndRay(controller, intersect);
            updateRayLength(controller, intersect.point);
          }
          this.updateElementState(intersect.object, 'hovered');
        }
          this.xrButtonObjectsForRaycasting.forEach(obj => {
            if (obj !== intersect?.object) this.updateElementState(obj, 'idle');
          });   
    }
      
    handleXRButtonInteractions(controller) {
        if (!this.showingButtons && !this.container?.visible) return true;
      
        let intersect;
      
        if (isInVRSession && controller) {
          const [position, , direction] = getControllerData(controller);
          raycaster.set(position, direction);
        } else {
          raycaster.setFromCamera(mouse, camera);
        }
      
        intersect = this.raycast();
        
      
        if (intersect && intersect.object.isUI) {
          this.updateElementState(intersect.object, 'selected');  
          return false;  
        }
      
        return true
    }
      
    updateCursorPositionAndRay(controller, intersect) {
        // Use the existing tempVector to copy the point coordinates without cloning
        if(intersect && intersect.point){
          this.tempVector.copy(intersect.point);
          const localPoint = controller.worldToLocal(this.tempVector);
          updateCursorPosition(controller, localPoint);
          updateRayColor(controller, RAY_COLORS.KEYBOARD);
        }
    }
}

export default new XRPopupManager();
