import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import ThreeMeshUI from 'three-mesh-ui'
import { initializeLoadingManager } from '../ui/loadingManager.js';
import { checkXRSession, setupRenderer } from './rendererSetup.js';
import { updateCameraRotation } from './cameraSetup.js';
import { materialManager } from '../shaders/materialManager.js';
import { updateControllers } from '../interactions/controllerManager.js';
import { scene, camera, renderer, lastTimestamp, setLastTimestamp, isInVRSession, canPlay, isMobile, isLoading} from './sharedState.js';
import { initializeAuthListeners } from '../firebase/firebaseSetup.js';
import { initializeObjectData } from '../firebase/dataManager.js';
import { annotationManager } from '../annotations/annotationManager.js';
import { textManager } from '../ui/textManager.js';
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';
import { ECSinitialized, entityManager, initializeECS, systemManager, updateECS } from '../ECS-utils/managerECS.js';
import { sceneManager } from './sceneManager.js';
import { characterManager } from '../models/characterManager.js';
import { updatePlayerNameTextRotation } from '../multiplayer/playerManagerHelper.js';
import { PlayerEntity } from '../ECS-entities/playerEntity.js';
import { initLoginPopup } from '../ui/loginPopupManager.js';
import { initMultiplayerPopup } from '../ui/multiplayerPopupManager.js';
import { initSettingsPopup } from '../ui/settingsPopupManager.js';
import { initGlobalClickListener } from '../utils/uiUtils.js';
import { timeUpdate, timeStart } from '../utils/time.js';
import { unloadEnvironmentAndLighting, updateSkyBoxAndOcean } from './environmentSetup.js';
import { initInfoPopup } from '../ui/pauseGui.js';
import { webrtc } from '../multiplayer/multiplayerSetup.js';
import { initAdminTerminalPopup } from '../ui/userManagementPopupManager.js';
import { updateHover } from '../interactions/hover.js';
import { initializeDebugMenu } from '../utils/debugMenu.js';
import FloatingPopupManager from '../ui/floatingPopupManager.js';
import { loadRapier, updatePhysics } from '../physics/physicsManager.js';
//import Stats from 'three/addons/libs/stats.module.js';

// Extend Three.js prototypes with three-mesh-bvh methods
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

const clock = new THREE.Clock();
//const stats = new Stats();
//document.body.appendChild(stats.dom);

// Time variables
let checkIntervalInSeconds = 0.05;
let elapsedTimeSinceLastCheck = 0;
let deltaTime, timestamp, timeDiff, timeDiffCapped;

function updateTimeVariables() {
    deltaTime = clock.getDelta();
    timestamp = clock.getElapsedTime() * 1000;
    timeDiff = timestamp - lastTimestamp;
    setLastTimestamp(timestamp);
    timeDiffCapped = Math.min(Math.max(timeDiff, 0), 100);
    elapsedTimeSinceLastCheck += deltaTime;
    timeUpdate();
}

function updateSceneState() {
    if (canPlay && !isLoading) performPlayableUpdates();
    handleTimedChecks();
    checkXRSession();
}

function performPlayableUpdates() {
    updateControllers(timestamp, timeDiffCapped);
    if (!isInVRSession && !isMobile) updateHover();
    textManager.updateTextMeshRotation(camera.position);
    updatePlayerNameTextRotation(camera);
}

function handleTimedChecks() {
    if (elapsedTimeSinceLastCheck >= checkIntervalInSeconds) {
        annotationManager.checkAnnotationProximity();
        elapsedTimeSinceLastCheck = 0;
    }
}

async function initializeEssentials() {

    handleGlobalEventListeners();
    initializeLoadingManager();
    setupRenderer();
    initializeAuthListeners();
    initializeObjectData();
    initInfoPopup();
    initLoginPopup();
    initMultiplayerPopup();
    initSettingsPopup();
    initAdminTerminalPopup();
    initGlobalClickListener();
    initializeECS();
    initializeDebugMenu();

    const playerEntity = entityManager.createEntity(PlayerEntity, 'John Doe', 200); // Create player
    playerEntity.persistent = true;
}

async function initializeSceneAndPhysics() {
    timeStart();
    await loadRapier();
    await sceneManager.loadScene('roomStart', '/models/roomStart.glb');
} 

export async function initializeThreeSetup() {
    await initializeEssentials();
    await initializeSceneAndPhysics();
    renderer.setAnimationLoop(animate);
}

function animate() {
    if (!camera || !scene) return;
    //postProcessing.composer.render();
    renderer.render(scene, camera);
    ThreeMeshUI.update();
    TWEEN.update();
    //stats.update();

    if(!isLoading) {
        updateTimeVariables();
        updateSceneState();
        updateCameraRotation();

        // Other updates
        updateMaterialsWithTime();
        if(FloatingPopupManager) FloatingPopupManager.update();
        
        // ECS updates
        if (ECSinitialized) updateECS(deltaTime);

        // Physics updates
        updatePhysics();
    }

}

function updateMaterialsWithTime() {
    materialManager.materialsWithTimeUniform.forEach(material => {
        material.uniforms.time.value = clock.getElapsedTime();
    });
    updateSkyBoxAndOcean();
}

function handleGlobalEventListeners() {
    // Debounce resize handler
    let resizeTimer;
    const debouncedResizeHandler = () => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
            handleWindowResize();
        }, 100);
    };

    window.addEventListener('resize', debouncedResizeHandler);
    if (window.visualViewport) {
        window.visualViewport.addEventListener('resize', debouncedResizeHandler);
    }
    window.addEventListener("orientationchange", () => {
        setTimeout(handleWindowResize, 300);
    });

    window.addEventListener('beforeunload', handleCleanup);
}

function handleWindowResize() {
    // Use visualViewport dimensions if available
    const newWidth = window.visualViewport?.width || window.innerWidth;
    const newHeight = window.visualViewport?.height || window.innerHeight;

    renderer.setSize(newWidth, newHeight);
    camera.aspect = newWidth / newHeight;
    camera.updateProjectionMatrix();
}

function handleCleanup() {
    // Clean up if the user leaves the page
    if (webrtc) webrtc.closeAllConnections();
    if (sceneManager) {
        const objectsToDispose = sceneManager.collectObjectsForDisposal(scene, sceneManager.activeSceneModelGroupName);
        sceneManager.disposeObjectsAndClearScene(objectsToDispose);
        characterManager.cleanup();
        systemManager.emitEvent('cleanUp');
        unloadEnvironmentAndLighting();
    }
}
