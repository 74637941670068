import * as THREE from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { cameraAndEnvrionmentHasBeenInitialized, scene } from './sharedState.js';
import { manager } from '../ui/loadingManager.js';
import { getCSSVariableValue } from '../utils/domUtils.js';
import { ShaderChunkStart } from '../shaders/settings.js';
import * as Skybox from "../scene/skybox.js";
import * as Ocean from "../scene/ocean.js";

let backgroundTexture = null;
let lightGroup = null;
let fog = null;
let useOceanAndSkybox = false;
let ambientLight = null;
export let environmentTexture = null;
const loader = new THREE.CubeTextureLoader(manager);

// HDRI
function setupHDRI() {
    new RGBELoader(manager)
        .setPath('/environments/' )
        .load('kloofendal_43d_clear_puresky_2k.hdr', function (texture) {

            texture.mapping = THREE.EquirectangularReflectionMapping;
            
            // Ensure no automatic mipmapping
            texture.minFilter = THREE.LinearFilter;
            texture.magFilter = THREE.LinearFilter;

            scene.background = texture;
            scene.environment = texture;
            backgroundTexture = texture;
            environmentTexture = texture;

            // Create skybox and ocean
            initalizeSkyBoxAndOcean();
        }
    );
}

function loadCubemap() {
    // Define the path to the cubemap folder. It should use the current scene to identify the correct folder witing the environments folder
    const path = '/environments/' + scene.name + '/cubemap/';
    
    // Define the URLs of the cubemap images
    const urls = [
        path + 'px.png', path + 'nx.png',
        path + 'pz.png', path + 'nz.png',
        path + 'py.png', path + 'ny.png',
    ];
    
    // Load the cubemap texture
    environmentTexture = loader.load(urls, function(texture) {
        environmentTexture = texture;
        scene.environment = texture;
    }, undefined, function(error) {
        console.error('An error occurred while loading the cubemap texture:', error);
    });
}

// Lighting
export function setupEnvironmentAndLightning() {

    if(!cameraAndEnvrionmentHasBeenInitialized){
        
        ambientLight = new THREE.AmbientLight(0xffffff, 1.6);  // 0.5 is the intensity
        ambientLight.i
        ambientLight.castShadow = false;
        
        lightGroup = new THREE.Group();
        lightGroup.name = "lightGroup";
        lightGroup.add(ambientLight);

        const fogColor = new THREE.Color(getCSSVariableValue('--fog-color'));

        // Create linear fog
        const near = 5;
        const far = 1000;
        fog = new THREE.Fog(fogColor, near, far);
        
        //setupHDRI();
        //loadCubemap();
    }
    
    else {
        scene.background = backgroundTexture;
        scene.environment = environmentTexture;
    }
    
    scene.add(lightGroup);
}

export function setAmbientLightIntensity(intensity){
    ambientLight.intensity = intensity;
}

export function activateFog(state){
    if(state === true){
        scene.fog = fog
    }
    else{
        scene.fog = null;
    }
}

export function activateOceanAndSkybox(state){
    if(state === true){
        useOceanAndSkybox = true;
        //scene.add(Skybox.skybox);
        scene.add(Ocean.surface);
    }
    else{
        useOceanAndSkybox = false;
        //scene.remove(Skybox.skybox);
        scene.remove(Ocean.surface);
    }
}

export function initalizeSkyBoxAndOcean() {
    ShaderChunkStart();
    Skybox.Start();
    Ocean.Start();
}

export function updateSkyBoxAndOcean() {
    if(useOceanAndSkybox){
        //Skybox.Update();
        Ocean.Update();
    }
}

export function getEnvironmentTexture() {
    return environmentTexture;
}

export function unloadEnvironmentAndLighting() {
    // Find and remove lightGroup from the scene
    const lightGroup = scene.getObjectByName("lightGroup");
    if (lightGroup) {
        lightGroup.children.forEach(light => {
            if (light.isLight) {
                light.dispose();  // Disposing of light resources (if any)
            }
        });
        scene.remove(lightGroup);
    }

    // Reset scene background and environment to null
    scene.background = null;
    scene.environment = null;
}
