export class HealthComponent {
    constructor(options) {
      this.type = 'HealthComponent';
      this.maxHealth = options.health; // Maximum health of the object
      this.currentHealth = options.health; // Current health of the object
      this.isAlive = true; // Flag to check if the object is alive
      this.removeOnDeath = options.removeOnDeath; // Flag to check if the object should be removed from the scene on death
    }
  
    takeDamage(amount) {
      this.currentHealth -= amount;
    }
  
    heal(amount) {
      this.currentHealth += amount;
      if (this.currentHealth > this.maxHealth) {
        this.currentHealth = this.maxHealth;
      }

    }
  }
  