import { canPlay, isInVRSession, renderer, scene, setVRMode } from './sharedState.js';
import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';
import * as THREE from 'three';
import { SRGBColorSpace } from 'three';
import { hideFootprints } from '../interactions/uxHelpers.js';
import { hideAllUIElements } from '../utils/uiUtils.js';
import { configureDesktopAndMobileCamera } from './cameraSetup.js';
import { hideXRKeyboardUI } from '../XR/Keyboard/XRKeyboard.js';
import { systemManager } from '../ECS-utils/managerECS.js';

export function setupRenderer() {
    const container = document.querySelector('#scene-container');
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0xffffff);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.colorSpace = SRGBColorSpace;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    
    container.appendChild(renderer.domElement);
}

export function useRenderSize() {
    return {
        width: window.innerWidth,
        height: window.innerHeight
    };
}

export function enableXR() {
    if ('XRSystem' in window) {
        renderer.xr.enabled = true;
        const vrBtn = VRButton.createButton(renderer);
        vrBtn.style.zIndex = "2";
        document.body.appendChild(vrBtn);
    } else {
        console.warn('WebXR not available on this device.');
    }
}

export function checkXRSession() {
    if(canPlay && renderer && renderer.xr.enabled === true){
        // Check if the VR session has just started
        if (renderer.xr.isPresenting && !isInVRSession) {
            setVRMode(true);
            hideAllUIElements();
            console.log('Entered VR Session');
            if(systemManager) systemManager.emitEvent('enterXRSession');

        }
        // Check if the VR session has just ended
        else if (!renderer.xr.isPresenting && isInVRSession) {
            setVRMode(false);
            console.log('Exited VR Session');
            hideFootprints();
            hideXRKeyboardUI();
            configureDesktopAndMobileCamera();
            if(systemManager) systemManager.emitEvent('exitXRSession');
        }
    }
}


