// notificationManager.js
import { showNotification as showDesktopNotification } from './notifications.js'; // Importing unified notification function
import { isInVRSession } from '../core/sharedState.js';
import playerListUIManager from './playerListUIManager.js';
import XRPopupManager from '../XR/UI/XRPopupManager.js';

class NotificationManager {
    constructor() {
    }

    // Unified method to show notifications
    showNotification(message, type = 'info', duration = 3000, position = 'top', fontSize = 25, playerId = null) {
        if (isInVRSession) {
            this.showVRNotification(message, type, duration);
        }
        
        this.showDesktopMobileNotification(message, type, duration, position, fontSize, playerId);
        
    }

    showDesktopMobileNotification(message, type, duration, position, fontSize, playerId) {
        // Use the unified notification function for desktop/mobile
        if (type === 'player') {
            // Delegate player notification handling to PlayerListUIManager
            playerListUIManager.addPlayerNotification(message, fontSize, duration, playerId);
        } else if (type === 'playerRemove') {
            playerListUIManager.removePlayerNotification(playerId);
        } 
        
        else {
            // Handle general notifications
            showDesktopNotification(document.body, { message, type, duration, position, fontSize});
        }
    }

    showVRNotification(message, type, duration) {

        let headlineContent = "";

        if(type === 'playerRemove') {
            headlineContent = "Player left";
        }

        else if(type === 'player') {
            headlineContent = "Player joined";
        }

        const data = { headline: headlineContent, text: message };
        XRPopupManager.displayPopup(data, true);
    }
}

export default new NotificationManager();
