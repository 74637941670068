import { camera, currentSceneName, scene } from "../core/sharedState";
import { avatarModelCopies, players } from "./playerManager";
import { gltfLoader } from '../models/modelLoader.js';
import { TextMesh } from 'troika-3d-text';
import { listener } from '../core/cameraSetup.js';
import { socket } from './multiplayerSetup.js';
import { SPEAKING_THRESHOLD } from '../utils/constants.js';
import { materialManager } from '../shaders/materialManager.js';
import * as THREE from 'three';
import { entityManager } from "../ECS-utils/managerECS.js";
import { HealthComponent } from "../ECS-components/healthComponent.js";
import { RenderComponent } from "../ECS-components/renderComponent.js";

let avatarModels = {};
let cameraWorldPosition = new THREE.Vector3();
let dataArray = null;
let speakerIconUpdateInterval;

//Player Mesh and Avatar Management
export async function createPlayerMesh(position, playerAvatar, playerId) {

    // Load the player model
    const avatarModel = await loadAvatar(playerAvatar, playerId);

    avatarModel.position.set(position.x, position.y, position.z);
    avatarModel.userData.isPlayerModel = true;

    // Create a new entity for the player
    /*
    const playerEntity = entityManager.createEntity();
    const healthComponent = new HealthComponent(300);
    playerEntity.addComponent(healthComponent);
    const renderComponent = new RenderComponent(avatarModel.children[0]);
    playerEntity.addComponent(renderComponent);
    */

    scene.add(avatarModel);
    return avatarModel;
}

export async function loadAvatar(avatarModelIndex, playerId) {
    const url = '/models/avatarModels.glb';
    console.log('Loading avatar model:', url);

    // Check if the avatar model is already loaded
    if (avatarModels[avatarModelIndex]) {
        //we should return a clone of the model, not the model itself
        const model = avatarModels[avatarModelIndex].clone();
        model.visible = true;
        avatarModelCopies[playerId] = model;

        return model;
    }

    // Else, load the avatar model
    try {
        const gltf = await gltfLoader.loadAsync(url);
        avatarModels[avatarModelIndex] = gltf.scene.children[avatarModelIndex]; // Store the avatar model in the cache
        avatarModels[avatarModelIndex].visible = false; // Hide the avatar model by default

        const model = avatarModels[avatarModelIndex].clone();
        model.visible = true;

        // Set each mesh to use a Phong material
        model.traverse((child) => {
            if (child.isMesh) {
                materialManager.setToMostPerformantShader(child, false, false);
            }
        });

        avatarModelCopies[playerId] = model;

        return model;
    }
    catch (error) {
        console.log('An error occurred while loading the avatar, defaulting to the default avatar:', error);
        const mesh = new THREE.Mesh(new THREE.BoxGeometry(0.5, 2.0, 0.5), new THREE.MeshBasicMaterial({ color: 0x00ff00 }));
        return mesh;
    }

}

//Audio Management
export function setupPlayerAudioAndIcon(playerId, audioStream) {
    console.log('Setting up audio for player:', playerId);
    if (!players[playerId]) {
        console.error('Player not found for playerId:', playerId);
        return;
    }

    // Create a PositionalAudio object (instead of THREE.Audio)
    const audio = new THREE.PositionalAudio(listener);

    // Set up positional audio properties
    audio.setDistanceModel('exponential');
    audio.setRefDistance(3); // 3 meters
    audio.setRolloffFactor(1.5);
    audio.setMaxDistance(50); // Can hear up to 50 meters away
    audio.setVolume(1); // Default volume, can be adjusted by the user
    
    const audioEl = new Audio();
    audioEl.srcObject = audioStream;
    audioEl.play();

    // Set the media element source for the PositionalAudio object
    audio.setMediaElementSource(audioEl);

    // Attach the audio to the specific part of the player's mesh
    //players[playerId].mesh.add(audio);
    createAudioAnalyserForPlayer(playerId, audioEl.srcObject);

    // Note, Three.Audio here is currently not audible (working on it. Until then, dont mute the audioEL)
}

function createAudioAnalyserForPlayer(playerId, audioStream) {
    const analyser = createAudioAnalyser(audioStream);
    players[playerId].analyser = analyser;
}

function createAudioAnalyser(audioStream) {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const source = audioContext.createMediaStreamSource(audioStream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 512; // A higher fftSize means more detail but more processing
    source.connect(analyser);
    return analyser;
}

function analyzeSpeaking(analyser) {
    if(dataArray === null) {
        console.log("dataArray is null");
        dataArray = new Uint8Array(analyser.frequencyBinCount);
    }


    const EARLY_EXIT_COUNT = 5; // Number of bins to exceed threshold for early exit

    analyser.getByteFrequencyData(dataArray);

    let sum = 0;
    let earlyExitCounter = 0;
    for (let i = 0; i < dataArray.length; i++) {
        sum += dataArray[i];
        if (dataArray[i] > SPEAKING_THRESHOLD) {
            earlyExitCounter++;
            if (earlyExitCounter >= EARLY_EXIT_COUNT) {
                return true;
            }
        }
    }

    let average = sum / dataArray.length;
    return average > SPEAKING_THRESHOLD;
}

function updateSpeakerIcons() {
    Object.values(players).forEach(player => {
        if (player.analyser) {
            const isSpeaking = analyzeSpeaking(player.analyser); // As previously defined
            player.speakerIcon.visible = isSpeaking;
            camera.getWorldPosition(cameraWorldPosition);
            player.speakerIcon.lookAt(cameraWorldPosition);
        }
    });
}

export function createSpeakerIcon() {
    // Load the texture for the speaker icon
    const loader = new THREE.TextureLoader();
    const texture = loader.load('/textures/speaker.png'); 

    // Create a sprite material with the texture
    const material = new THREE.SpriteMaterial({ map: texture, transparent: true, depthTest: false, depthWrite: false });

    // Create the sprite
    const sprite = new THREE.Sprite(material);
    sprite.scale.set(0.5, 0.5, 1);  // Set the size of the sprite (adjust as needed)
    sprite.position.set(0, 2.9, 0); // Set the position of the sprite (adjust as needed)

    return sprite;
}

//UI Components for players
export function createPlayerNameTextMesh(playerName, playerMesh) {
    const textMesh = new TextMesh();
    textMesh.text = playerName;
    textMesh.fontSize = 0.2;
    textMesh.color = 0xffffff;
    textMesh.material = new THREE.MeshBasicMaterial({ color: 0xffffff, transparent: true, depthTest: false, depthWrite: false });
    textMesh.outlineWidth = 0.006;
    textMesh.outlineColor = '#000000';
    textMesh.outlineOpacity = 0.5;
    const yOffset = 0.5;
    textMesh.position.y = playerMesh.geometry.boundingBox ? playerMesh.geometry.boundingBox.max.y + yOffset : playerMesh.position.y + yOffset;
    textMesh.position.x = playerMesh.geometry.boundingBox ? playerMesh.geometry.boundingBox.max.x / 2 : playerMesh.position.x;
    textMesh.anchorX = 'center';
    return textMesh;
}

export function updatePlayerNameTextRotation(camera) {
    Object.values(players).forEach(player => {
        if (player.textMesh) {
            cameraWorldPosition = camera.getWorldPosition(cameraWorldPosition);
            player.textMesh.lookAt(cameraWorldPosition);
        }
    });
}

//Miscellaneous Utilities
export function startUpdatingSpeakerIcons() {
    const UPDATE_INTERVAL = 100;
    speakerIconUpdateInterval = setInterval(updateSpeakerIcons, UPDATE_INTERVAL);
}

export function stopUpdatingSpeakerIcons() {
    if (speakerIconUpdateInterval) {
        clearInterval(speakerIconUpdateInterval);
        speakerIconUpdateInterval = null;
    }
}

export function returnPlayerAttachedItemToWorld(itemEntity, itemScene) {
    const renderComponent = itemEntity.getComponent('RenderComponent');
    renderComponent.mesh.visible = true;
    renderComponent.mesh.position.set(renderComponent.initialPosition.x, renderComponent.initialPosition.y, renderComponent.initialPosition.z);
    renderComponent.mesh.rotation.set(renderComponent.initialRotation.x, renderComponent.initialRotation.y, renderComponent.initialRotation.z);

    const sceneToAddTo = itemScene ? itemScene : currentSceneName;
    scene.children.find(child => child.name === sceneToAddTo).add(renderComponent.mesh);
}

export function playerHitEffect(playerBeingHitId, hitPosition) {
    if(playerBeingHitId === socket.id) return; // Do not apply hit effect to local player

    const player = players[playerBeingHitId];
    if (!player || !player.mesh) {
        console.warn(`Player ${playerBeingHitId} does not exist or has no mesh.`);
        return;
    }

    console.log('Player hit effect:', playerBeingHitId, hitPosition);
}