export function getMediaTypeFromURL(url) {
    if (!url) return null;

    if (url.includes('.jpg') || url.includes('.jpeg') || url.includes('.png') || url.includes('.gif')) {
        return 'image';
    }
    if (url.includes('.mp4') || url.includes('.webm')) {
        return 'video';
    }
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
        return 'youtube';
    }
    return null;
}