import * as THREE from 'three';
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls.js';
import { mouse } from '../utils/constants.js';
import { handleMovement } from './playerMovement.js';
import { camera, canPlay, isInVRSession, isMobile, renderer, setPointerLock, cameraRig, displayingPopup } from './sharedState.js';
import { setEscIconVisibility } from '../utils/uiUtils.js';
import { doc } from 'firebase/firestore';
import { handleDoubleClickOrTap } from '../mobile/mobileControls.js';
import { inventoryManagementSystem } from '../ECS-utils/managerECS.js';

export let controls;
export let moveForward = false;
export let moveBackward = false;
export let moveLeft = false;
export let moveRight = false;
export let isSprinting = false;
export let isJumping = false;
export function stopJump() {isJumping = false;}

export function initializeFPSControls() {
    if (renderer && renderer.domElement) {

        controls = new PointerLockControls(camera, renderer.domElement);

        // Add click event listener to activate or deactivate pointer lock
        renderer.domElement.addEventListener('click', function() {
            if(!isInVRSession && canPlay && controls.isLocked === false && !isMobile){
                controls.lock();
            }
        });

        // Add double click event listener for using mobile locomotion
        renderer.domElement.addEventListener('mousedown', () => {
            if(inventoryManagementSystem && !inventoryManagementSystem.checkForActiveItem()) handleDoubleClickOrTap();
        });
        cameraRig.add(controls.getObject());

    } else {
        console.error("Renderer is not initialized yet.");
    }
}

document.addEventListener('pointerlockchange', function () {
    if (document.pointerLockElement === renderer.domElement) {
        if(isMobile) return;
        mouse.x = 0;
        mouse.y = 0;
        setEscIconVisibility(true);
        setPointerLock(true);
    } else {
        setEscIconVisibility(false);
        setPointerLock(false);
        stopMovement();
    }
}, false);

document.addEventListener('keydown', (event) => {
    // Dont apply movement if controls are unlocked or if canPlay is false
    if(!canPlay) return;
    if(controls.isLocked === false) return;
    
    switch (event.code) {
        case 'KeyW': moveForward = true; break;
        case 'KeyS': moveBackward = true; break;
        case 'KeyA': moveLeft = true; break;
        case 'KeyD': moveRight = true; break;
        case 'Space': if (!isJumping) isJumping = true; break;
        case 'ShiftLeft': isSprinting = true; break;
    }
}, false);

document.addEventListener('keyup', (event) => {
    // Dont apply movement if controls are unlocked or if canPlay is false
    if(!canPlay) return;
    if(controls.isLocked === false) return;

    switch (event.code) {
        case 'KeyW': moveForward = false; break;
        case 'KeyS': moveBackward = false; break;
        case 'KeyA': moveLeft = false; break;
        case 'KeyD': moveRight = false; break;
        case 'ShiftLeft': isSprinting = false; break;
    }
}, false);

const clock = new THREE.Clock();

export function updateFPSControls() {
    if (!controls) {
        console.warn('FPS controls have not been initialized.');
        return;
    }

    handleMovement();  // Delegate movement logic to playerMovement.js
}

export function stopMovement() {
    moveForward = false;
    moveBackward = false;
    moveLeft = false;
    moveRight = false;
}

export function unlockPointer() {
    setPointerLock(false);
    if(controls){
            if(!isMobile && !isInVRSession) {
                controls.unlock();
            }
        }
    
}

export function lockPointer() {
    setPointerLock(true);
    if(controls){
            if(!isMobile && !isInVRSession) {
                controls.lock();
            }
        
    }
}
