import * as THREE from 'three';
import { Vector3 } from 'three';
import { camera, scene, cameraRig, setCamera, setCameraRig, cameraAndEnvrionmentHasBeenInitialized } from './sharedState.js';
import { PLAYER_HEIGHT } from '../utils/constants.js';
import PostProcessingSetup from './postProcessingSetup.js';

export let postProcessing = null;
export let inventoryEntity = null;
export const listener = new THREE.AudioListener();

export const cameraRight = new Vector3();
export const cameraUp = new Vector3();
export const cameraForward = new Vector3();

const rightVector = new Vector3(1, 0, 0);
const upVector = new Vector3(0, 1, 0);
const forwardVector = new Vector3(0, 0, -1);

const tempVector1 = new Vector3();
const tempVector2 = new Vector3();
const tempVector3 = new Vector3();

export function setupCamera() {

    if(!cameraAndEnvrionmentHasBeenInitialized){
        initialSetup();
    }

    scene.add(cameraRig); // Add the rig to the scene   
}

function initialSetup() {
    let cameraRigGroup = new THREE.Group();
    cameraRigGroup.name = 'cameraRig';
    
    setCamera(new THREE.PerspectiveCamera());
    setCameraRig(cameraRigGroup);

    configureDesktopAndMobileCamera();
    camera.position.y = PLAYER_HEIGHT;
    
    cameraRig.add(camera);
    camera.add(listener);
    //postProcessing  = new PostProcessingSetup(renderer, scene, camera);
}

export function configureDesktopAndMobileCamera() {
    if (window.innerWidth > window.innerHeight) {
        camera.fov = 70;
    } else {
        camera.fov = 90;
    }
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.near = 0.1;
    camera.far = 4000;
    camera.updateProjectionMatrix();
}

export function unloadCamera() {

    // Remove the listener from the camera
    if (listener && camera) {
        camera.remove(listener);
    }

    // Remove the camera from the rig
    if (cameraRig && camera) {
        cameraRig.remove(camera);
    }

    // Remove the rig from the scene
    if (scene && cameraRig) {
        scene.remove(cameraRig);
    }

    // Dispose of any resources used by the camera
    // In this case, the camera itself doesn't need disposing, 

    // Reset or nullify the shared state
    setCamera(null);
    setCameraRig(null);
}

export function updateCameraRotation()
{
    tempVector1.copy(rightVector);;
    tempVector2.copy(upVector);
    tempVector3.copy(forwardVector);

    cameraRight.copy(tempVector1.applyQuaternion(camera.quaternion));
    cameraUp.copy(tempVector2.applyQuaternion(camera.quaternion));
    cameraForward.copy(tempVector3.applyQuaternion(camera.quaternion));
}

