import { TextMesh } from 'troika-3d-text';
import * as THREE from 'three';
import { scene } from '../core/sharedState.js';

class TextMeshManager {
    constructor() {
        this.textMeshPool = [];
        this.displayedTextMeshes = [];
    }

    getTextMesh() {
        let textMesh;
        
        if (this.textMeshPool.length > 0) {
            console.log('Reusing text mesh');
            textMesh = this.textMeshPool.pop();
        } else {
            console.log('Creating new text mesh');
            textMesh = new TextMesh();
            
            // Material setup
            textMesh.material = new THREE.MeshBasicMaterial();
            textMesh.material.transparent = true;
            textMesh.material.depthTest = false;
            textMesh.material.depthWrite = false;
            textMesh.material.color = new THREE.Color('#000000');

            // Text setup
            textMesh.fillOpacity = 1;

            // Anchor
            textMesh.anchorX = 'center';

            // Outline
            textMesh.outlineWidth = 0.004;
            textMesh.outlineColor = '#000000';  // Black outline
            textMesh.outlineOpacity = 0.5;
            textMesh.renderOrder = 99999;
            
            // Ensure to sync after making changes
            textMesh.sync();
            scene.add(textMesh);
        }
        textMesh.visible = true; 
        return textMesh;
    }

    returnTextMeshToPool(textMesh) {
        textMesh.visible = false;
        this.textMeshPool.push(textMesh);
    }

    unload() {
        // Iterate over each textMesh in the pool
        for (let textMesh of this.textMeshPool) {

            // Remove the mesh from the scene and memory
            scene.remove(textMesh);
            textMesh.dispose();
    
            // Finally, nullify the object to help with garbage collection
            textMesh = null;
        }
    
        // Clear the pool array
        this.textMeshPool.length = 0;
    }
    
}

export const textMeshManager = new TextMeshManager();
