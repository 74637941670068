import objectData from '../content/objectData.json';
import { scene } from '../core/sharedState';
import * as THREE from 'three';
import { getTextureURL } from '../firebase/dataManager';
import { textureLoader } from '../shaders/materialManager';
import { objectDataCache } from '../utils/cacheManager';
import { playerSystem } from '../ECS-utils/managerECS';



class TextureDropManager {
    static textureDrops = {};

    static registerTextureDrop(dropzoneName, object) {
        this.textureDrops[dropzoneName] = object;
    }

    static async registerTextureDropsFromData() {
        const textureDropNames = Object.keys(objectData)
            .filter(objectName => {
                const existsInScene = Boolean(scene.getObjectByName(objectName));
                return existsInScene && objectData[objectName].dropType === "textureDrop";
            });

        for (const objectName of textureDropNames) {
            const textureDropObject = scene.getObjectByName(objectName);
            if (textureDropObject) {
                //wait 3 seconds before loading texture

                this.registerTextureDrop(objectName, textureDropObject);

                let textureURL = null

                //append userType to objectName
                const userType = playerSystem.getPlayerProperty('userType');
                const objectNameWithUserType = `${objectName}_${userType}`;

                //check if objectDataCache has objectNameWithUserType
                const objectDataFromCache = objectDataCache[objectNameWithUserType];
                if (objectDataFromCache) {
                    console.log("objectDataFromCache", objectDataFromCache);
                    textureURL = objectDataFromCache.textureURL;
                    console.log("trying to set textureURL with userType from cache", textureURL);
                }
      
                if(!textureURL){

                    textureURL = objectDataCache[objectName].textureURL;
                    console.log("trying to set textureURL without userType from cache", textureURL);
                }
                
                // Fetch texture URL from Firestore if not available in local JSON
                if (!textureURL) {
                    textureURL = await getTextureURL(objectNameWithUserType);
                    console.log("trying to set textureURL with userType from firestore", textureURL);
                }
                
                try {
                    if (textureURL) {
                        await this.loadAndApplyTexture(textureDropObject, textureURL);
                    }
                } catch (error) {
                    console.error("Error loading texture:", error);
                }
            }
        }
    }

    static async loadAndApplyTexture(plane, textureURL) {
        return new Promise((resolve, reject) => {
            textureLoader.load(
                textureURL,
                texture => {

                    // Remove old material
                    if (plane.material.map) {
                        plane.material.map.dispose(); // Dispose of the old texture to free up memory
                    }

                    //flip uv's vertically
                    texture.flipY = false;

                    // Create a new MeshBasicMaterial
                    const newMaterial = new THREE.MeshBasicMaterial({ map: texture });
                    //newMaterial.envMap = scene.background;
                    //newMaterial.reflectivity = 0.6;

                    // Apply the new material to the plane
                    plane.material = newMaterial;
                    plane.material.needsUpdate = true;
                    resolve();
                },
                undefined,
                error => {
                    console.error('Error loading texture:', error);
                    reject(error);
                }
            );
        });
    }   

    static removeTextureFromDropzone(dropzoneName) {
        const object = this.textureDrops[dropzoneName];
        if (object && object.material.map) {
            object.material.map = null;
            object.material.needsUpdate = true;
        }
    }
}

export default TextureDropManager;
