import { System } from './system.js';
import { Tween, Easing } from '@tweenjs/tween.js';
import * as THREE from 'three';
import { displayingPopup, scene } from '../core/sharedState.js';
import { getObjectData } from '../interactions/interactionWithObjectManager.js';
import { camera } from '../core/sharedState.js';
import { getCSSVariableValue } from '../utils/domUtils.js';
import { clientConfig } from '../config/clientConfig.js';
import { inventoryManagementSystem } from '../ECS-utils/managerECS.js';
import { textMeshManager } from '../ui/TextMeshManager.js';

class HitEffect {
    constructor(text) {
        this.text = text;
        this.position = new THREE.Vector3();
        this.positionTween = new Tween(this.position);
    }

    playEffect(position, text) {
        this.text.text = text.toString();
        this.text.visible = true;
        this.position.copy(position);

        const endPosition = position.clone().add(new THREE.Vector3(0, 0.5, 0));
        this.positionTween.to({ x: endPosition.x, y: endPosition.y, z: endPosition.z }, 1500).easing(Easing.Quadratic.Out)
            .onComplete(() => {
                this.text.visible = false;
                this.position.set(this.position.x, this.position.y, this.position.z);
            }).onUpdate(() => {
                this.text.position.copy(this.position);
            }).start(undefined, true);
    }
}

export class AnnotationSystem extends System {
    constructor(entityManager, systemManager) {
        super(entityManager, systemManager);
        this.requiredComponents = ['ItemComponent', 'RenderComponent', 'InteractionComponent'];
        this.annotation = null;
        this.systemManager.onEvent('hoverObject', this.handleHover.bind(this));
        this.systemManager.onEvent('resetObject', this.resetHover.bind(this));
        this.systemManager.onEvent('sceneLoaded', this.changeScene.bind(this));
        this.systemManager.onEvent('healthChange', this.playHitEffect.bind(this));
        this.currentlyHoveredEntity = null;
        this.entityMeshMap = new Map(); // Map to hold entity-mesh relations
        this.textOffsetFromGraphics = new THREE.Vector3(0.0, 0.2, 0);
        this.translateOffset = clientConfig.useAnnotation ? new THREE.Vector3(0, 0.1, 0) : new THREE.Vector3(0, 0.3, 0);
        this.targetPosition = new THREE.Vector3();
        this.initialScale = new THREE.Vector3(0, 0, 0);
        this.tweenTime = 250;
        this.cameraWorldPosition = new THREE.Vector3();
        this.centerPositionOfBoundingBox = new THREE.Vector3();
        this.boundingBox = new THREE.Box3();
        this.hitEffectsPool = [];
        this.poolInitialized = false;

        // Fetch the color values from the CSS
        this.backgroundColor = new THREE.Color(getCSSVariableValue('--background-color'));
        this.titleColor = new THREE.Color(getCSSVariableValue('--title-color'));
        this.textColor = new THREE.Color(getCSSVariableValue('--text-color'));
        this.submitAreaColor = new THREE.Color(getCSSVariableValue('--submit-area-color'));
        this.buttonColor = new THREE.Color(getCSSVariableValue('--button-color'));
        this.buttonColorHover = new THREE.Color(getCSSVariableValue('--button-hover-color'));

        //Hide current annotation when showing popup or pause menu
        this.systemManager.onEvent('displayingPopup', this.showOrHideAnnotation.bind(this));
        this.systemManager.onEvent('pointerLockChange', this.checkIfPauseMenuIsOpenAndSetVisibilityAccordingly.bind(this));
    }

    initializeHitEffectsPool() {
        for (let i = 0; i < 10; i++) {
            const text = textMeshManager.getTextMesh();
            const hitEffect = new HitEffect(text);
            this.hitEffectsPool.push(hitEffect);
        }
    }

    playHitEffect(firepackage) {

        if (!this.poolInitialized) {
            this.initializeHitEffectsPool();
            this.poolInitialized = true;
        }
        
        const targetObjectMesh = firepackage.mesh;
        const hitDamage = firepackage.damage;

        // Find an available hit effect from the pool
        const availableHitEffect = this.hitEffectsPool.find(hitEffect => !hitEffect.positionTween.isPlaying());

        if (availableHitEffect) {
            const position = new THREE.Vector3();
            targetObjectMesh.getWorldPosition(position);
            const smallRandomX = (Math.random() - 0.5) * 0.5;
            const smallRandomZ = (Math.random() - 0.5) * 0.5;
            position.add(new THREE.Vector3(smallRandomX, 0, smallRandomZ));
            position.y += 0.5;
            availableHitEffect.playEffect(position, hitDamage);
        }
    }

    populateEntityMeshMap() {
        this.getEntities().forEach(entity => {
            const renderComponent = entity.getComponent('RenderComponent');
            const itemComponent = entity.getComponent('ItemComponent');
            if (renderComponent && renderComponent.mesh && !itemComponent.pickupable) {
                this.entityMeshMap.set(renderComponent.mesh, entity);
            }
        });
    }

    createAnnotation() {
        const graphic = new THREE.Mesh();

        const text = textMeshManager.getTextMesh();
        text.position.copy(this.textOffsetFromGraphics);
        graphic.add(text);

        const annotationCircle = new THREE.Mesh(
            new THREE.CircleGeometry(0.04, 32),
            new THREE.MeshBasicMaterial({ color: clientConfig.useAnnotation ? this.titleColor : this.titleColor})
        );
        
        annotationCircle.material.transparent = true;
        annotationCircle.material.depthTest = false;
        annotationCircle.material.depthWrite = false;
        annotationCircle.renderOrder = 9999;

        graphic.add(annotationCircle);
        graphic.name = 'Annotation';

        scene.add(graphic);

        this.populateEntityMeshMap();

        return {
            graphic,
            scaleTween: new Tween(graphic.scale),
            positionTween: new Tween(graphic.position)
        };
    }

    handleHover(object) {

        if (!this.annotation) this.annotation = this.createAnnotation();
        
        const hoveredEntity = this.entityMeshMap.get(object);
        
        if (this.currentlyHoveredEntity === hoveredEntity) return;
        
        this.currentlyHoveredEntity = hoveredEntity;
        
        if (hoveredEntity) {
            this.updateAnnotation(hoveredEntity, object);
        }
    }
    
    resetHover(object, resetCurrentlyHoveredEntity = true) {
        // Reset currently hovered entity
        if(resetCurrentlyHoveredEntity) this.currentlyHoveredEntity = null;
        
        // Reset annotation scale
        this.annotation.graphic.scale.set(0, 0, 0);
        this.annotation.graphic.children[0].scale.set(0, 0, 0);
        this.annotation.graphic.children[1].scale.set(0, 0, 0);
        
        // Stop any currently playing tweens
        if (this.annotation.scaleTween.isPlaying()) {
            this.annotation.scaleTween.stop();
        }
        
        if (this.annotation.positionTween.isPlaying()) {
            this.annotation.positionTween.stop();
        }
    }
    
    updateAnnotation(entity, object) {
        const interactionComponent = entity.getComponent('InteractionComponent');
        if(!interactionComponent.showAnnotation) return;

        if(displayingPopup) return;
        if(inventoryManagementSystem && inventoryManagementSystem.checkForActiveItem()) this.showOrHideAnnotation(true);
        else this.showOrHideAnnotation(false);
        
        // Retrieve annotation data from json file
        let annotationText;
        const objectData = getObjectData(object);

        // If annotation text is set in the interaction component, use that. Else, use the headline from the object data
        if(interactionComponent.annotationText) annotationText = interactionComponent.annotationText;
        else annotationText = objectData ? objectData.headline : object.name;
        
        if(!clientConfig.useAnnotation) annotationText ="";
        this.annotation.graphic.children[0].text = annotationText;
        
        // Reset annotation position, scale, and rotation to match new object
        this.resetHover(object, false);
        
        // Get annotation position
        const annotationPosition = this.calculatePositionForAnnotation(object);
        
        // Set annotation position
        this.annotation.graphic.position.copy(annotationPosition);

        let translateOffset = this.translateOffset;

        if(interactionComponent.annotationOffset) translateOffset = new THREE.Vector3(0, interactionComponent.annotationOffset, 0);
        
        // Set target position and start tweens
        this.targetPosition.copy(annotationPosition).add(translateOffset);
        
        this.annotation.scaleTween
            .to({ x: 1, y: 1, z: 1 }, this.tweenTime)
            .easing(Easing.Quadratic.Out)
            .onUpdate(() => {
                this.annotation.graphic.children[0].scale.copy(this.annotation.graphic.scale);
                this.annotation.graphic.children[1].scale.copy(this.annotation.graphic.scale);                
            })
            .start(undefined, true);

        this.annotation.positionTween 
            .to({ x: this.targetPosition.x, y: this.targetPosition.y, z: this.targetPosition.z }, this.tweenTime)
            .easing(Easing.Quadratic.Out)
            .start(undefined, true);
    }

    changeScene() {
        // Clear entity-mesh map and repopulate
        this.entityMeshMap.clear();
        this.populateEntityMeshMap();

        // Reset annotation and add to new scene
        if (this.annotation) {
            scene.add(this.annotation.graphic);
            this.annotation.graphic.scale.copy(this.initialScale);
        }
    }

    update(deltaTime){

        super.update(deltaTime);
        
        if (this.annotation && this.currentlyHoveredEntity) {
            const interactionComponent = this.currentlyHoveredEntity.getComponent('InteractionComponent');
            const renderComponent = this.currentlyHoveredEntity.getComponent('RenderComponent');
            const object = renderComponent.mesh;
            const rotation = object.rotation;
            camera.getWorldPosition(this.cameraWorldPosition);

            // If the annotation is not set to be shown, return
            if(interactionComponent && !interactionComponent.showAnnotation) return;
            
            // If set, scale annotation based on distance
            if(interactionComponent && interactionComponent.annotationScaleToDistance) {
                const distance = this.annotation.graphic.position.distanceTo(this.cameraWorldPosition);
                let scale = (this.calculateScaleForAnnotation(distance, camera)*interactionComponent.annotationScale);
                this.annotation.graphic.scale.set(scale, scale, scale);
            }
            else this.annotation.graphic.scale.set(interactionComponent.annotationScale, interactionComponent.annotationScale, interactionComponent.annotationScale);

            // If enabled, rotate annotation to face camera
            if(interactionComponent && interactionComponent.annotationFaceCamera) {
                this.annotation.graphic.lookAt(this.cameraWorldPosition);
            }
            else if(rotation) {
                this.annotation.graphic.rotation.copy(rotation);
            }
        }

        // Rotate hit effects to face the camera
        this.hitEffectsPool.forEach(hitEffect => {
            if (hitEffect.text.visible) {
                camera.getWorldPosition(this.cameraWorldPosition);
                hitEffect.text.lookAt(this.cameraWorldPosition);
            }
        });
    }
    
    calculateScaleForAnnotation(distance, camera) {
        // Constants for scale calculation
        const fov = camera.fov * (Math.PI / 180); // Convert to radians
        const scaleAdjustmentFactor = 0.5; 
    
        // Calculate scale based on distance and field of view
        const scale = Math.tan(fov / 2) * distance * scaleAdjustmentFactor;
    
        return scale;
    }

    calculatePositionForAnnotation(object) {
        // Compute the bounding box of the object
        this.boundingBox.setFromObject(object);

        // Get the left of the bounding box
        this.boundingBox.getCenter(this.centerPositionOfBoundingBox);

        // Set the x-coordinate of the position to the max x-coordinate of the bounding box
        this.centerPositionOfBoundingBox.y = this.boundingBox.max.y;

        return this.centerPositionOfBoundingBox;
    }

    checkIfPauseMenuIsOpenAndSetVisibilityAccordingly(value) {
        if(value) this.showOrHideAnnotation(false);
        else this.showOrHideAnnotation(true);   
    }

    showOrHideAnnotation(value) {
        if (this.annotation && value === true) {
            this.annotation.graphic.visible = false;
        }

        else if (this.annotation && value === false) {
            this.annotation.graphic.visible = true;
        }
    }
}
