import { BaseEntity } from "../ECS-entities/baseEntity.js";
import { scene } from "../core/sharedState.js";

export class EntityManager {
    constructor() {
        this.entities = {};
        this.componentEntityMap = {};
        this.systems = new Set();  // To keep track of systems for notification
        this.nextId = 0;
    }

    createEntity(EntityClass = BaseEntity, ...args) {
        const id = this.nextId++; 
        const entity = new EntityClass(id, ...args);
        this.entities[id] = entity;
    
        entity.onComponentChanged = (entity, action, componentType) => {
            if (action === 'added') {
                if (!this.componentEntityMap[componentType]) {
                    this.componentEntityMap[componentType] = new Set();
                }
                this.componentEntityMap[componentType].add(entity);
            } else if (action === 'removed') {
                if (this.componentEntityMap[componentType]) {
                    this.componentEntityMap[componentType].delete(entity);
                }
            }
        };
        this.notifySystems();  // Notify systems upon entity creation
        this.addEntity(entity);  // Add the entity using existing logic
        
        return entity;
    }
    clear() {
        // Clear non-persistent entities
        Object.keys(this.entities).forEach(id => {
            if (!this.entities[id].persistent) {
                this.removeEntity(id);
            }
        });
    
        // Clear component-entity map for non-persistent entities
        Object.keys(this.componentEntityMap).forEach(type => {
            this.componentEntityMap[type].forEach(entity => {
                if (!entity.persistent) {
                    this.componentEntityMap[type].delete(entity);
                }
            });
        });

        // Reset nextId based on the highest ID among persistent entities
        this.nextId = Math.max(0, ...Object.values(this.entities).map(entity => entity.id)) + 1;
    }
    
    removeEntity(id) {
        const entity = this.entities[id];
        if (entity) {
            entity.componentTypes.forEach(componentType => {  // using public getter
                if (this.componentEntityMap[componentType]) {
                    this.componentEntityMap[componentType].delete(entity);
                }
            });
            delete this.entities[id];
            this.notifySystems();  // Notify systems upon entity removal
        }
    }
    
    addEntity(entity) {
        this.entities[entity.id] = entity;
        entity.componentTypes.forEach(type => {
            if (!this.componentEntityMap[type]) {
                this.componentEntityMap[type] = new Set();
            }
            this.componentEntityMap[type].add(entity);
        });
        this.notifySystems();  // Notify systems upon entity addition
    }

    getEntity(id) {
        return this.entities[id];
    }

    getEntitiesWithComponent(componentType) {
        return [...(this.componentEntityMap[componentType] || [])];
    }

    getEntitiesWithComponents(requiredComponents, excludedComponents = []) {
        if (!requiredComponents.length) return Object.values(this.entities);

        let entities = new Set(this.componentEntityMap[requiredComponents[0]] || []);
        
        for (const componentType of requiredComponents.slice(1)) {
            const componentEntities = new Set(this.componentEntityMap[componentType] || []);
            entities = new Set([...entities].filter(e => componentEntities.has(e)));
        }
        
        for (const componentType of excludedComponents) {
            const componentEntities = new Set(this.componentEntityMap[componentType] || []);
            entities = new Set([...entities].filter(e => !componentEntities.has(e)));
        }
    
        return [...entities];
    }

    findEntityByMesh(mesh) {
        const entitiesWithRenderComponent = this.getEntitiesWithComponent('RenderComponent');
        try {
            return entitiesWithRenderComponent.find(entity => entity.getComponent('RenderComponent').mesh === mesh);
        } catch (error) {
            console.error('Error finding entity by mesh:', error);
            return null;
        }
    }

    findEntityByMeshAndRequiredComponents(mesh, requiredComponents) {
        const entities = this.getEntitiesWithComponents(requiredComponents);

        //if the entity has a render component, check if its mesh matches the given mesh
        if (requiredComponents.includes('RenderComponent')) {
            try {
                return entities.find(entity => entity.getComponent('RenderComponent').mesh === mesh);
            }
            catch (error) {
                console.error('Error finding entity by mesh and required components:', error);
                return null;
            }
        }
        else {
            console.warn('No RenderComponent found in required components when finding entity by mesh and required components');
            return null;
        }
    }


    findEntityByObjectName(objectName) {
        const obj = scene.getObjectByName(objectName);
        const entity = this.findEntityByMesh(obj);
        return entity;
    }

    // Notify all registered systems to invalidate their entity cache
    notifySystems() {
        this.systems.forEach(system => system.invalidateEntitiesCache());
    }
}
