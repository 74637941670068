import { System } from './system';
import { cameraRig, currentSceneName } from '../core/sharedState';
import { itemEquipSystem } from '../ECS-utils/managerECS';

export class PlayerSystem extends System {
  constructor(entityManager, systemManager) {
    super(entityManager, systemManager);
    this.requiredComponents = ['PlayerComponent', 'HealthComponent', 'InventoryComponent', 'PositionComponent'];
    this.systemManager.onEvent('entityDied', this.killPlayer.bind(this));
    window.addEventListener('cleanUp', this.resetPlayer.bind(this));
  }

  // Called at scene change
  resetPlayer() {
    itemEquipSystem.unequipItem(true);

    const playerEntity = this.entityManager.getEntitiesWithComponent('PlayerComponent')[0];
    const inventory = playerEntity.getComponent('InventoryComponent');

    const emitPackage = {
      inventory,
      itemScene: currentSceneName
    };

    this.systemManager.emitEvent('resetInventory', emitPackage);
  }

  //Kill player will reload the player
  killPlayer(entity) {
    if (!entity.hasComponent('PlayerComponent')) {
      return;
    }

    const healthComponent = playerEntity.getComponent('HealthComponent');
    this.systemManager.emitEvent('playerDied');
    healthComponent.currentHealth = healthComponent.maxHealth;
    healthComponent.isAlive = true;
    console.log('Player died and health is reset to', healthComponent.maxHealth);
  }

  updatePlayerProperty(property, value) {
    const playerEntity = this.entityManager.getEntitiesWithComponent('PlayerComponent')[0];
    const playerComponent = playerEntity.getComponent('PlayerComponent');
    playerComponent[property] = value;
  }

  getPlayerProperty(property) {
    const playerEntity = this.entityManager.getEntitiesWithComponent('PlayerComponent')[0];
    const playerComponent = playerEntity.getComponent('PlayerComponent');
    return playerComponent[property];
  }

  update(deltaTime) {
    super.update(deltaTime);

    this.getEntities().forEach(entity => {
      const positionComponent = entity.getComponent('PositionComponent');
      positionComponent.x = cameraRig.position.x;
      positionComponent.y = cameraRig.position.y;
      positionComponent.z = cameraRig.position.z;
    });
  }
}
