import { BaseEntity } from './baseEntity.js';
import { RenderComponent } from '../ECS-components/renderComponent.js';
import { InteractionComponent } from '../ECS-components/interactionComponent.js';
import { ItemComponent } from '../ECS-components/itemComponent.js';
import { StatusComponent } from '../ECS-components/statusComponent.js';
import { HealthComponent } from '../ECS-components/healthComponent.js';

export class InteractableEntity extends BaseEntity {
    constructor(id, mesh, callbacks , pickupable, status, interactionType, itemType, options) {
        super(id);

        const renderComponent = new RenderComponent(mesh);
        this.addComponent(renderComponent);

        const itemComponent = new ItemComponent(itemType, mesh, 0, 0, 100, pickupable);
        this.addComponent(itemComponent);

        const interactionComponent = new InteractionComponent(callbacks, interactionType, options);
        this.addComponent(interactionComponent);

        const statusComponent = new StatusComponent(status);
        this.addComponent(statusComponent);

        if(options.hasHealth){
            const healthComponent = new HealthComponent(options);
            this.addComponent(healthComponent);
        }
    }
}

