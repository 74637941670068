import { storage } from '../firebase/firebaseSetup.js';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "@firebase/storage";
import { loadModelIntoDropzone, DropzoneModelManager } from '../models/dropzoneManager';
import { scene } from '../core/sharedState.js';

let selectedGLBFile = null;
let selectedImageFile = null;
export let MAX_FILE_SIZE_MB;

function updateFileSizeLimit(file) {
    if (isValidGLB(file)) {
        MAX_FILE_SIZE_MB = 0.5; 
    } else if (isValidImage(file)) {
        MAX_FILE_SIZE_MB = 5;
    }
    return MAX_FILE_SIZE_MB * 1024 * 1024; 
}

function isValidGLB(file) {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    return file.type === 'model/gltf-binary' || file.type === 'model/gltf+json' || fileExtension === 'glb' || fileExtension === 'gltf';
}

function isValidImage(file) {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension);
}

function isFileSizeValid(file) {
    const MAX_FILE_SIZE_BYTES = updateFileSizeLimit(file); 
    return file.size <= MAX_FILE_SIZE_BYTES;
}

function sanitizeFileName(filename) {
    return filename.replace(/[^a-zA-Z0-9\.\-]/g, '_');
}

async function uploadFile(file, filePath, statusMessage) {
    let fileToUpload = file; // Start with the original file
    // Attempt conversion only for valid image files that are not GLB
    if (isValidImage(file) && !isValidGLB(file)) {
        try {
            const convertedFile = await convertImageToWebP(file);
            if (convertedFile) {
                console.log('Image converted to WebP');
                fileToUpload = convertedFile; // Update file to the converted one
            } else {
                console.log('Using original image file due to conversion failure or lack of browser support.');
            }
        } catch (error) {
            console.error('Error converting image to WebP, using original image:', error);
            statusMessage.innerText = 'Using original image due to conversion error.';
            // Continue with the original file if conversion fails
        }
    }
    
    // Determine the sanitized file name and full file path after deciding on fileToUpload
    // This will correctly handle both converted WebP files and unaltered GLB (or other) files
    const sanitizedFileName = sanitizeFileName(fileToUpload.name);
    const fullFilePath = `${filePath}/${sanitizedFileName}`;
    
    if (!fileToUpload) {
        console.error("No file to upload.");
        statusMessage.innerText = "No file to upload.";
        return null;
    }

    // Continue with upload if the file does not exist
    statusMessage.innerText = "Uploading...";
    const storageRef = ref(storage, fullFilePath);
    const uploadTask = uploadBytesResumable(storageRef, fileToUpload); // Now correctly using fileToUpload
    

    return new Promise((resolve, reject) => {
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                statusMessage.innerText = `Uploading: ${progress.toFixed(2)}% done.`;
            },
            (error) => {
                console.error("Error uploading file:", error);
                statusMessage.innerText = "Error uploading file.";
                reject(error);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                console.log('File available at', downloadURL);
                statusMessage.innerText = `File uploaded successfully: ${file.name}`;
                resolve(downloadURL);
            }
        );
    });
}

async function convertImageToWebP(imageFile) {
    console.log("Starting conversion of image to WebP format.");

    if (!document.createElement('canvas').toDataURL('image/webp').startsWith('data:image/webp')) {
        console.log("WebP is not supported in this browser. Conversion will be skipped.");
        return null;
    } else {
        console.log("Browser supports WebP. Proceeding with conversion.");
    }

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function(event) {
            console.log("Image file read successfully. Starting to load image for conversion.");

            const img = new Image();
            img.onload = function() {
                console.log("Image loaded successfully. Creating canvas for conversion.");

                let width = img.width;
                let height = img.height;
                const maxSide = 1024;

                // Resize if either side is greater than 1024
                if (width > maxSide || height > maxSide) {
                    const aspectRatio = width / height;
                    if (width > height) {
                        width = maxSide;
                        height = Math.round(width / aspectRatio);
                    } else {
                        height = maxSide;
                        width = Math.round(height * aspectRatio);
                    }
                    console.log(`Resizing image to maintain aspect ratio: ${width}x${height}`);
                }

                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height); // Draw the image scaled

                try {
                    console.log("Converting canvas to WebP format.");
                    const webpDataUrl = canvas.toDataURL('image/webp', 0.8);
                    console.log("Conversion to WebP data URL complete.");

                    fetch(webpDataUrl)
                        .then(res => res.blob())
                        .then(blob => {
                            console.log("Creating File object for WebP image.");
                            const webpFile = new File([blob], imageFile.name.replace(/\.\w+$/, ".webp"), { type: 'image/webp' });
                            console.log("WebP File object created successfully.");
                            resolve(webpFile);
                        }).catch(error => {
                            console.error("Error during Blob conversion:", error);
                            reject(error);
                        });
                } catch (error) {
                    console.error("Error during WebP conversion process:", error);
                    reject(error);
                }
            };
            img.onerror = () => {
                console.error("Error loading image for conversion.");
                reject("Error loading image.");
            };
            img.src = event.target.result;
        };
        reader.onerror = () => {
            console.error("Error reading image file.");
            reject("Error reading image file.");
        };
        console.log("Reading image file for conversion.");
        reader.readAsDataURL(imageFile);
    });
}

async function handleGLBFileUpload(dropzoneName, statusMessage, oldModelURL) {
    const downloadURL = await uploadFile(selectedGLBFile, 'models', statusMessage);
    if (!downloadURL) return null; // Exit if upload failed

    await deleteFileFromStorage(oldModelURL);  // Delete the old model from storage

    // Remove the old model from the scene and load the new one
    const associatedModel = DropzoneModelManager[dropzoneName];
    if (associatedModel) {
        const modelToRemove = scene.getObjectByName(associatedModel.model.name);
        if (modelToRemove) {
            scene.remove(modelToRemove);
            delete DropzoneModelManager[dropzoneName];  // Remove the entry from the DropzoneModelManager
        }
    }

    // Load the new model into the scene
    try {
        await loadModelIntoDropzone(dropzoneName, downloadURL);
        return downloadURL;
    } catch (error) {
        console.error("Error loading model into dropzone:", error);
        return null;
    }
}

async function handleImageUpload(textureHolderName, statusMessage, oldTextureURL) {
    console.log("Uploading texture...");
    const imageURL = await uploadFile(selectedImageFile, 'textures', statusMessage);
    await deleteFileFromStorage(oldTextureURL);  // Delete the old texture from storage
    return imageURL;
}

async function fileExistsInStorage(filePath) {
    const fileRef = ref(storage, filePath);
    try {
        // Try to get the download URL of the file
        const downloadURL = await getDownloadURL(fileRef);
        return { exists: true, url: downloadURL };
    } catch (error) {
        // File does not exist
        return { exists: false };
    }
}

function setSelectedGLBFile(file) {
    selectedGLBFile = file;
}

 function setSelectedImageFile(file) {
    selectedImageFile = file;
}

async function deleteFileFromStorage(filePath) {
    return null;  // TODO: Not quite working yet

    const fileRef = ref(storage, filePath);
    try {
        await deleteObject(fileRef);
        console.log('File successfully deleted from storage:', filePath);
    } catch (error) {
        console.error('Error deleting file from storage:', error);
    }
}

export { selectedGLBFile, selectedImageFile, setSelectedGLBFile, isValidGLB, handleGLBFileUpload, isFileSizeValid, setSelectedImageFile, isValidImage, handleImageUpload, deleteFileFromStorage };
