import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';

// Function to create a footprint mesh
function createFootprint(texturePath) {
    const texture = new THREE.TextureLoader().load(texturePath);
    const material = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        alphaTest: 0.8,
        blending: THREE.AdditiveBlending,
    });
    material.map.minFilter = THREE.LinearFilter;
    const geometry = new THREE.PlaneGeometry(0.2, 0.4);
    const mesh = new THREE.Mesh(geometry, material);
    mesh.rotation.x = -Math.PI / 2;
    return mesh;
}

// Create left and right footprints
const leftFootprint = createFootprint('/textures/footprintLeft.png');
const rightFootprint = createFootprint('/textures/footprintRight.png');

// Parent object for footprints
const footprintsGroup = new THREE.Group();
footprintsGroup.scale.set(0.6, 0.6, 0.6);
footprintsGroup.add(leftFootprint);
footprintsGroup.add(rightFootprint);

// Positioning footprints relative to the group
leftFootprint.position.set(-0.16, 0.002, -0.2);
rightFootprint.position.set(0.16, 0.002, 0.0);

// Initial Tweens (not started yet)
const stepSize = 0.2;
const animationTime = 500; // milliseconds

const leftFootTween = new TWEEN.Tween(leftFootprint.position)
    .to({ z: `+${stepSize}` }, animationTime)
    .easing(TWEEN.Easing.Quadratic.InOut)
    .repeat(Infinity)
    .yoyo(true);

const rightFootTween = new TWEEN.Tween(rightFootprint.position)
    .to({ z: `-${stepSize}` }, animationTime)
    .easing(TWEEN.Easing.Quadratic.InOut)
    .repeat(Infinity)
    .yoyo(true);

// Start Tweens
leftFootTween.start();
rightFootTween.start();

// Function to hide footprints
function hideFootprints() {
    if(!footprintsGroup) return;
    footprintsGroup.visible = false;
}

// Function to animate footprints
function animateFootprints(targetPosition, controllerDirection) {
    // Set initial position and rotation of the group
    footprintsGroup.position.copy(targetPosition);
    const directionAngle = Math.atan2(-controllerDirection.x, -controllerDirection.z);
    footprintsGroup.rotation.y = directionAngle + Math.PI; // Adjusting rotation to correctly align with controller direction

    // Show footprints
    footprintsGroup.visible = true;
}

// Function to add footprints to the scene
function addFootprintsToScene(scene) {
    scene.add(footprintsGroup);
}

export { animateFootprints, hideFootprints, addFootprintsToScene };
