import { System } from './system';
import { camera, isInVRSession } from '../core/sharedState';
import { isMultiplayerSession } from '../multiplayer/sessionManager';
import { sendDataToServer } from '../multiplayer/multiplayerSetup';
import { getControllerAtHand } from '../XR/XRControllers';

export class ItemEquipSystem extends System {
    constructor(entityManager, systemManager) {
        super(entityManager, systemManager);
        this.requiredComponents = ['InventoryComponent'];
        this.systemManager.onEvent('itemEquipped', this.equipItem.bind(this));
        this.systemManager.onEvent('itemUnequipped', this.unequipItem.bind(this));
        this.systemManager.onEvent('enterXRSession', this.unequipCurrentItemIfTransitingToOrFromXR.bind(this));
        this.systemManager.onEvent('exitXRSession', this.unequipCurrentItemIfTransitingToOrFromXR.bind(this));
    }

    equipItem(equipPackage) {
        //console.log('Item equipped:', equipPackage);
        const playerEntity = this.entityManager.getEntitiesWithComponent('PlayerComponent')[0];
        const inventory = playerEntity.getComponent('InventoryComponent');
        this.unequipCurrentItem(true);
        this.equipNewItem(inventory, equipPackage);
    }

    unequipItem(drop = false) {
        this.unequipCurrentItem(drop);
    }

    unequipCurrentItemIfTransitingToOrFromXR() {
        this.unequipCurrentItem(true);
    }

    unequipCurrentItem(drop = false) {
        const playerEntity = this.entityManager.getEntitiesWithComponent('PlayerComponent')[0];
        const inventory = playerEntity.getComponent('InventoryComponent');
        if (inventory.activeItem) {
            const activeItemEntity = this.entityManager.getEntity(inventory.activeItem);
            const itemComponent = activeItemEntity.getComponent('ItemComponent');

            // Set the active invetory item to null
            inventory.activeItem = null;

            if(isMultiplayerSession){
                sendDataToServer('itemEquipOrUnequip', {
                    itemType: itemComponent.itemType,
                    itemId: itemComponent.itemName,
                    itemEquip: inventory.activeItem ? true : false,
                });
            }

            if (drop) {
              this.systemManager.emitEvent('itemDropped', activeItemEntity);
            }
        }
    }

    equipNewItem(inventory, equipPackage) {
        inventory.activeItem = equipPackage.itemEntity.id;
        const itemComponent = equipPackage.itemEntity.getComponent('ItemComponent');
        const itemMeshToEquip = equipPackage.itemEntity.getComponent('RenderComponent').mesh;
        const statusComponent = equipPackage.itemEntity.getComponent('StatusComponent');
        //console.log('Item to equip:', itemComponent, statusComponent);
        statusComponent.setEquippedInHand(equipPackage.controllerHand);
        //console.log('Item equipped in hand:', statusComponent.equippedInHand);
        this.makeVisibleAndAttach(itemMeshToEquip, equipPackage.controllerHand);

        if(isMultiplayerSession){
            sendDataToServer('itemEquipOrUnequip', {
                itemType: itemComponent.itemType,
                itemId: itemComponent.itemName,
                itemEquip: inventory.activeItem ? true : false,
            });
        }
    } 

    makeVisibleAndAttach(itemMeshToEquip, controllerHand) {
        //console.log('Making item that is equipped visible and attaching to player');
        itemMeshToEquip.visible = true;
        if(controllerHand === "" || controllerHand === undefined) controllerHand = "right";
        const attachTo = isInVRSession ? getControllerAtHand(controllerHand) : camera;
        attachTo.add(itemMeshToEquip);
        this.setPositionAndRotation(itemMeshToEquip);
    }

    setPositionAndRotation(mesh) {
        const position = isInVRSession ? { x: 0, y: 0, z: 0 } : { x: 0.2, y: -0.3, z: -0.3 };
        mesh.position.set(position.x, position.y, position.z);
        mesh.rotation.set(0, 0, 0);
    }
}
