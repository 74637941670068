import * as THREE from 'three';

const vec3_4 = new THREE.Vector3()

// Calculate the size of an object
export function calculateObjectSize(object){
  const bbox = new THREE.Box3()
  bbox.expandByObject(object)
  const size = bbox.getSize(vec3_4)
  return size
}

// Verify and return userdata for showroom_properties
export function getObjectUserData(object, key) {
  let keyData;

  if (object.userData && object.userData.showroom_properties) {
    switch (key) {
      case 'raycastBlocker': {
        keyData = object.userData.showroom_properties.raycastBlocker;
        break;
      }
      case 'collider': {
        keyData = object.userData.showroom_properties.collider;
        break;
      }
      case 'traversableGround': {
        keyData = object.userData.showroom_properties.traversableGround;
        break;
      }
      default: {
        keyData = null;
        break; 
      }
    }

    if (keyData !== null) { 
      return keyData;
    }
  }

  return null;
}


