import * as THREE from 'three';
import { cameraRig } from '../core/sharedState';

// Pre-allocate reusable objects outside of the function to avoid creating new ones every frame.
let _position = new THREE.Vector3();
let _quaternion = new THREE.Quaternion();
let _direction = new THREE.Vector3();
let tempVector = new THREE.Vector3();
let tempForward = new THREE.Vector3();
let tempTargetPosition = new THREE.Vector3();

export function getControllerData(controller) {
    // Reset the objects to ensure they're in a clean state
    _position.set(0, 0, 0);
    _quaternion.set(0, 0, 0, 1); // Default quaternion is the identity quaternion
    _direction.set(0, 0, -1); // This will be reset to the initial direction and then reoriented

    // Update position and quaternion with the current values from the controller
    controller.getWorldPosition(_position);
    controller.getWorldQuaternion(_quaternion);

    // Apply the quaternion to get the current direction
    _direction.applyQuaternion(_quaternion);

    // Return the references to the reused objects
    return [_position, _quaternion, _direction];
}

export function orientTowardsCameraY(object, camera) {
    object.getWorldPosition(tempVector);
    let objectY = tempVector.y; // Store the Y position of the object

    camera.getWorldPosition(tempTargetPosition);
    tempTargetPosition.y = objectY; // Set the target Y to be the same as the object

    object.lookAt(tempTargetPosition);
}

export function positionInFrontOfCamera(object, camera, distance, yOffset = 0, xRotation = 0) {
    // Get the world position of the camera
    camera.getWorldPosition(tempVector);

    // Get the forward direction of the camera
    camera.getWorldDirection(tempForward);
    tempForward.multiplyScalar(distance); // Adjust distance in front of the camera

    // Position the object in front of the camera based on the calculated forward direction
    object.position.set(tempVector.x + tempForward.x, cameraRig.position.y + yOffset, tempVector.z + tempForward.z);

    object.rotation.set(xRotation, camera.rotation.y, camera.rotation.z)

    
    // Orient the object to face towards the camera, rotating only around the Y axis
    orientTowardsCameraY(object, camera);
    
    //set the local x rotation for a better view
    object.rotateX(xRotation);
}
