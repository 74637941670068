import * as THREE from 'three';
import { System } from './system';
import { handleLoadScene } from '../interactions/interactionWithObjectManager';
import notificationManager from '../ui/notificationManager';

export class TriggerSystem extends System {
    constructor(entityManager, systemManager) {
        super(entityManager, systemManager);
        this.requiredComponents = ['TriggerComponent', 'StatusComponent'];
        this.playerComponentType = 'PlayerComponent';
        this.playerEntitiesInTriggers = new Map();
        this.tempPlayerBox = new THREE.Box3(); // Temporary box for reuse
        this.playerSize = new THREE.Vector3(1, 1, 1); // Initialize once and reuse
    }

    update(deltaTime) {
        super.update(deltaTime);
        
        const triggers = this.getEntities();
        const players = this.entityManager.getEntitiesWithComponents([this.playerComponentType, 'PositionComponent']);
        
        triggers.forEach(triggerEntity => {
            const trigger = triggerEntity.getComponent('TriggerComponent');
            const triggerBox = trigger.getBoundingBox();
            
            players.forEach(playerEntity => {
                const playerPos = playerEntity.getComponent('PositionComponent');
                this.tempPlayerBox.setFromCenterAndSize(playerPos, this.playerSize);

                if (triggerBox.intersectsBox(this.tempPlayerBox)) {
                    if (!this.playerEntitiesInTriggers.has(playerEntity.id)) {
                        this.playerEntitiesInTriggers.set(playerEntity.id, triggerEntity.id);
                        this.onPlayerEnterTrigger(trigger, triggerEntity);
                    }
                } else {
                    if (this.playerEntitiesInTriggers.get(playerEntity.id) === triggerEntity.id) {
                        this.playerEntitiesInTriggers.delete(playerEntity.id);
                        this.onPlayerExitTrigger(trigger, triggerEntity);
                    }
                }
            });
        });
    }

    onPlayerEnterTrigger(trigger, triggerEntity) {
        if(trigger.enteredTrigger) return;

        // If the trigger has object data, handle the event
        if(trigger.objectData){
            triggerData = trigger.objectData;
   
            // If the trigger has animations defined in its object data, play the first animation
            if(triggerData.animations && triggerData.animations.length > 0){

                // Play the sound if it is defined in the object data
                if(triggerData.soundName) this.systemManager.emitEvent('playSound', triggerData.soundName);

                const animationData = 
                {
                    animation: triggerData.animations[0],
                    objectID: trigger.object.name,
                    entity: triggerEntity
                }
                this.systemManager.emitEvent('playAnimation', animationData);
            }

            // If the trigger is a drop trigger, unequip the active item
            if(triggerData.action && triggerData.action === 'dropActiveItem') {
                this.systemManager.emitEvent('itemUnequipped', true);
            }
        }

        // If the trigger han a show message event, show the message
        if(trigger.eventShowMessage && trigger.eventShowMessage !== ""){
            const duration = trigger.eventShowMessageDuration || 1;
            const message = trigger.eventShowMessage;
            const fontSize = trigger.eventShowMessageFontSize || 10;
            notificationManager.showNotification(message, undefined, duration*1000, undefined, fontSize);
        }

        // If the trigger has a play animation event, play the animation
        if(trigger.eventPlayAnimation && trigger.eventPlayAnimation !== "") {
            const animation = { 
                animationName: trigger.eventPlayAnimation,
                animationSpeed: 1.0,
                loop: false,
                loopPingPong: false,
                loopPingPongCount: 1,
                reversePlay: false,
                reversePlayDelay: 1.0,
                stopAtEnd: false
            }

            const animationData = 
            {
                animation: animation,
                objectID: trigger.object.name,
                entity: triggerEntity
            }

            this.systemManager.emitEvent('playAnimation', animationData);
        }

        // If the trigger has a play audio event, play the audio
        if(trigger.eventPlayAudio && trigger.eventPlayAudio !== "") {
            this.systemManager.emitEvent('playSound', trigger.eventPlayAudio);
        }

        if(trigger.eventDropActiveItem) {
            this.systemManager.emitEvent('itemUnequipped', true);
        }

        // If the trigger has a load room event, load the room
        if(trigger.eventLoadRoom && trigger.eventLoadRoom !== ""){
            handleLoadScene(trigger.eventLoadRoom);
        }

        trigger.enteredTrigger = true;   
    }

    onPlayerExitTrigger(trigger, triggerEntity) {
        if(!trigger.enteredTrigger) return;
        
        if(trigger.objectData){
            triggerData = trigger.objectData;
        }

        trigger.enteredTrigger = false;
    }
}
