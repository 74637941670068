import * as THREE from 'three';

export class RenderComponent {
    constructor(mesh, meshType = 'mesh') {
        this.type = 'RenderComponent';
        this.mesh = mesh;
        this.needsAddToScene = true;
        this.initialPosition = mesh.getWorldPosition(new THREE.Vector3());
        this.initialRotation = mesh.rotation.clone();
        this.bounds = new THREE.Box3().setFromObject(mesh);
        this.height = this.bounds.max.y - this.bounds.min.y;
        this.isHovered = false;
    }
}
