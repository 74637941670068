import { movementDataFrequency } from '../utils/constants.js';
import { sendDataToServer } from './multiplayerSetup.js';
import { getCurrentRoom } from './roomManager.js';
import { isMultiplayerSession } from './sessionManager.js';  
import * as THREE from 'three';

let movementDataBuffer = null;
let movementTimeout = null;
const MOVEMENT_THROTTLE_TIME = movementDataFrequency; // 200ms works well with interpolation configuration

let controllerDataBuffer = null;
let controllerTimeout = null;
const CONTROLLER_THROTTLE_TIME = 200;

// Pre-allocated objects for reuse
const worldPosition = new THREE.Vector3();
const worldQuaternion = new THREE.Quaternion();
const lastSentMovementData = { position: new THREE.Vector3(), rotation: new THREE.Quaternion() };
const lastSentControllerData = new Map();

function hasMovementDataChanged(cameraRig, camera) {
    return !cameraRig.position.equals(lastSentMovementData.position) ||
           !camera.quaternion.equals(lastSentMovementData.rotation);
}

function hasControllerDataChanged(controllers) {
    return controllers.some(controller => {
        const lastData = lastSentControllerData.get(controller.id);
        if (!lastData) return true;

        controller.getWorldPosition(worldPosition);
        controller.getWorldQuaternion(worldQuaternion);

        return !worldPosition.equals(lastData.position) ||
               !worldQuaternion.equals(lastData.rotation);
    });
}

export function sendPlayerMovementData(cameraRig, camera, forceValid = false) {
    if (!isMultiplayerSession || !hasMovementDataChanged(cameraRig, camera) && !forceValid) return;

    // Update last sent data
    lastSentMovementData.position.copy(cameraRig.position);
    lastSentMovementData.rotation.copy(camera.quaternion);

    if (!movementTimeout) {
        movementDataBuffer = {
            roomId: getCurrentRoom(), // from roomManager.js
            newPosition: cameraRig.position,
            newRotation: camera.quaternion
        };
        movementTimeout = setTimeout(() => {
            sendDataToServer('playerMovement', movementDataBuffer);
            movementTimeout = null;
            movementDataBuffer = null;
        }, MOVEMENT_THROTTLE_TIME);
    }
}

export function sendControllerData(controllers) {
    if (!isMultiplayerSession || !hasControllerDataChanged(controllers)) return;

    controllerDataBuffer = controllers.map(controller => {
        controller.getWorldPosition(worldPosition);
        controller.getWorldQuaternion(worldQuaternion);

        // Update last sent controller data
        let lastData = lastSentControllerData.get(controller.id);
        if (!lastData) {
            // Create new objects for the first time
            lastData = {
                position: worldPosition.clone(),
                rotation: worldQuaternion.clone()
            };
        } else {
            // Copy values into the existing objects
            lastData.position.copy(worldPosition);
            lastData.rotation.copy(worldQuaternion);
        }
        lastSentControllerData.set(controller.id, lastData);

        return {
            roomId: getCurrentRoom(), // from roomManager.js
            position: worldPosition.clone(), // Clone here to capture the current state independently
            rotation: worldQuaternion.clone(), // Clone here to capture the current state independently
            isHand: controller.isHand
        };
    });

    if (!controllerTimeout) {
        controllerTimeout = setTimeout(() => {
            sendDataToServer('controllerVRData', controllerDataBuffer, true);
            controllerTimeout = null;
            controllerDataBuffer = null;
        }, CONTROLLER_THROTTLE_TIME);
    }
}

