import { System } from './system';
import { ParticleSystem, BatchedParticleRenderer, PointEmitter, ApplyForce } from 'three.quarks';
import { scene } from '../core/sharedState';
import { muzzleFlashConfig, explosionConfig, hitWhitePlasterConfig } from '../content/particleConfigs';
import * as THREE from 'three';

export class ParticleEffectSystem extends System {
  constructor(entityManager, systemManager) {
    super(entityManager, systemManager);
    this.requiredComponents = ['ParticleEffectComponent'];
    this.batchSystem = new BatchedParticleRenderer();
    this.effectConfigs = { 'muzzleFlash': muzzleFlashConfig, 'explosion': explosionConfig, 'hitWhitePlaster': hitWhitePlasterConfig };
    this.systemManager.onEvent('spawnEffect', this.spawnEffect.bind(this));
    this.systemManager.onEvent('spawnEffectOnPosition', this.spawnEffectOnPosition.bind(this));
    this.systemManager.onEvent('sceneLoaded', this.addBatchSystem.bind(this));
  }

  createParticleSystem(effectType) {
    const config = this.effectConfigs[effectType];
    if (!config) return null;
    
    const particleSystem = new ParticleSystem(config);
    particleSystem.emitterShape = new PointEmitter();
    particleSystem.emitter.name = 'point';
    this.batchSystem.addSystem(particleSystem);
    scene.add(particleSystem.emitter);
    
    return particleSystem;
  }

  addBatchSystem() {
    scene.add(this.batchSystem);
    this.randomCounter += 1;
  }
  
  spawnEffectOnPosition({effectType, worldPosition, direction}) {
    const particleSystem = this.createParticleSystem(effectType);
    if (!particleSystem) return;

    // Update position and direction
    particleSystem.emitter.position.set(worldPosition.x, worldPosition.y, worldPosition.z);
    if (direction) this.updateParticleDirection(particleSystem, direction);
  }
  
  spawnEffect({ entityId, effectType, direction }) {
    const entity = this.entityManager.getEntity(entityId);
    if (entity && entity.hasComponent('ParticleEffectComponent')) {
      const particleEffectComponent = entity.getComponent('ParticleEffectComponent');
      const particleSystem = this.createParticleSystem(effectType);
  
      if (particleSystem) {
        particleEffectComponent.particleSystem = particleSystem;
        this.updateParticleSystemPosition(particleEffectComponent, particleSystem);
        this.updateParticleDirection(particleSystem, direction);
      }
    }
  }
  

  updateParticleSystemPosition(particleEffectComponent, particleSystem) {
    const worldPosition = new THREE.Vector3();
    particleEffectComponent.mesh.localToWorld(worldPosition);
    particleSystem.emitter.position.set(worldPosition.x, worldPosition.y, worldPosition.z);
  }

  updateParticleDirection(particleSystem, direction) {
    if (!direction) return;

    const applyForceBehavior = particleSystem.behaviors.find(behavior => behavior instanceof ApplyForce);
    if (applyForceBehavior) {
      applyForceBehavior.direction.copy(direction);
    }
  }

  update(deltaTime) {
    this.batchSystem.update(deltaTime);
  }
}
