export class SystemManager {
    constructor() {
        this.systems = [];
        this.events = {};
    }

    emitEvent(eventName, payload) {
        if (this.events[eventName]) {
          this.events[eventName].forEach(callback => callback(payload));
        }
      }
      
    onEvent(eventName, callback) {
        if (!this.events[eventName]) {
          this.events[eventName] = [];
        }
        this.events[eventName].push(callback);
    }

    addSystem(system, executeFirst = false) {
        if (executeFirst) {
            this.systems.unshift(system);
        } else {
            this.systems.push(system);
        }
    }


    removeSystem(system) {
        const index = this.systems.indexOf(system);
        if (index > -1) {
            this.systems.splice(index, 1);
        }
    }

    updateSystems(deltaTime) {
        for (const system of this.systems) {
            system.update(deltaTime);
        }
    }
}
