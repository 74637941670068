import { BaseEntity } from './baseEntity.js';
import { SoundComponent } from '../ECS-components/soundComponent.js';

export class SoundEntity extends BaseEntity {
    constructor(id, options) {
        super(id);

        const soundComponent = new SoundComponent(options);
        this.addComponent(soundComponent);
    }
}

