//loginPopupManager.js

import { createXRKeyboardUI } from "../XR/Keyboard/XRKeyboard.js";
import { isAdminMode, isInVRSession, setDisplayingPopup } from "../core/sharedState.js";
import { setupEventListener, hideAllUIElements } from '../utils/uiUtils.js';
import { showUserManagementPopup } from "./userManagementPopupManager.js";

let loginPopup = document.getElementById('login-popup-window');

export function showLoginPopup(e = null, object = null) {
    if (e) e.stopPropagation();

    if(!isInVRSession) {
        hideAllUIElements(true);
        setDisplayingPopup(true);
        loginPopup.style.display = 'block';
    }
    
    // Handling login based on the context (normal user or admin)
    if (object && object.userData && object.userData.isLocked) {
        if(isInVRSession){
            createXRKeyboardUI();
        }
        else displayLoginSection('normal');
    } else {
        displayLoginSection('admin');
    }

    if(!isInVRSession) document.getElementById('backdrop').style.display = 'block';
}

export function initLoginPopup() {
    setupEventListener('login-btn', (e) => showLoginPopup(e, null));
    setupEventListener('user-manager-btn', (e) => showUserManagementPopup(e));
}

// Helper function to manage display of different login sections
function displayLoginSection(type) {
    const adminLogin = document.getElementById('login-admin-container');
    const normalLogin = document.getElementById('login-normal-container');
    const logoutAdmin = document.getElementById('logout-admin-container');

    adminLogin.style.display = !isAdminMode && type === 'admin' ? 'block' : 'none';
    normalLogin.style.display = type === 'normal' ? 'block' : 'none';
    logoutAdmin.style.display = isAdminMode && type === 'admin' ? 'block' : 'none';
}
