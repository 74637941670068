import * as THREE from 'three';
import { System } from './system.js';  
import { AnimationState } from '../animations/animationState.js';  
import { scene } from '../core/sharedState.js';
import { conditionLibrary } from '../animations/conditionLibrary.js';

export class AnimationSystem extends System {
  constructor(entityManager, gltfLoader, systemManager) {
    super(entityManager, systemManager);
    this.requiredComponents = ['AnimationComponent'];
    this.gltfLoader = gltfLoader;
    this.animations = {};  
    this.systemManager.onEvent('playAnimation', this.playAnimation.bind(this));
    this.systemManager.onEvent('itemMeshInteracted', this.itemMeshInteracted.bind(this));
    this.systemManager.onEvent('entityDied', this.itemMeshDied.bind(this));
    this.systemManager.onEvent('setAllAnimationsToInitialState', this.setAllAnimationsToInitialState.bind(this));
    this.systemManager.onEvent('cleanUp', this.cleanup.bind(this));
    this.mixer = null;
    this.currentTween = null;  
    this.leftShoulderOffset = new THREE.Vector3(0.05, 0, 0);
    this.rightShoulderOffset = new THREE.Vector3(-0.05, 0, 0);
    this.animationStates = {};
  }

  initMixerForAnimationSystem(model) {
    this.mixer = new THREE.AnimationMixer(model);
  }

  async loadGlobalCharacterAnimations(url = '/models/characterAnimations.glb') {
    try {
      const gltf = await this.gltfLoader.loadAsync(url);
      gltf.animations.forEach(animation => {
        animation.tracks.forEach(track => {
          // Replace mixamorig and numerical suffixes
          track.name = track.name
            .replace(/mixamorig[:_]?/, '')
            .replace(/_\d(\.|$)/, '$1');
  
          // Replace "Armature<anything>.(position|quaternion|scale)" with "Armature.(position|quaternion|scale)"
          if (track.name.startsWith("Armature")) {
            track.name = track.name.replace(/(Armature).*\.(position|quaternion|scale)/, 'Armature.$2');
          }
        });
  
        this.animations[animation.name] = animation;
      });
      console.log("loaded character animations ", this.animations);
    } catch (error) {
      console.error('An error occurred while loading the animations:', error);
    }
  }

  initializeStateMachineForCharacter(animationComponent) {
      Object.entries(this.animations).forEach(([name, animation]) => {
      const animationState = new AnimationState(name, animation);
      animationComponent.stateMachine.addState(name, animationState);
      });

      const model = animationComponent.stateMachine.mixer.getRoot();

      // Traverse and store original bone positions
      model.traverse((object) => {
        if (object.isBone && (object.name === 'LeftEye' || object.name === 'LeftShoulder' || object.name === 'RightEye' || object.name === 'RightShoulder')) {
          animationComponent.originalBonePositions.set(object.name, object.position.clone());
        }
      });
  }

  initializeStateMachine(animationComponent, properties) {
    const registeredAnimationsMap = new Map();

    const processTransitions = (animBase, animNum) => {
        const anim = properties[`${animBase}-anim${animNum}_Str`];
        // Initialize the animation entry if it doesn't exist
        if (!registeredAnimationsMap.has(anim)) {
            registeredAnimationsMap.set(anim, { transitions: [] });
        }

        for (let i = 1; i <= 4; i++) {
            const transitionTo = properties[`${animBase}-anim${animNum}TransitionToAnim${String.fromCharCode(64 + i)}_Str`];
            const transitionCondition = properties[`${animBase}-anim${animNum}TransitionCondition${String.fromCharCode(64 + i)}_Str`];
            
            if (transitionTo && transitionTo?.toLowerCase() !== "none") {
                registeredAnimationsMap.get(anim).transitions.push({ to: transitionTo, condition: transitionCondition });
            }
        }
    };

    // Process transitions for each animation base
    for (let i = 1; i <= 4; i++) {
        processTransitions('isAnimator', i);
    }

    // Initialize animations and add transitions, filtering out 'none' and ensuring no duplicates
    const initializedStates = new Set(); // Track initialized animation states
    registeredAnimationsMap.forEach((details, animationName) => {
        if (animationName && animationName.toLowerCase() !== "none" && !initializedStates.has(animationName)) {
            console.log('Initializing animation state:', animationName);
            this.initializeAnimationState(animationName, details, animationComponent);
            initializedStates.add(animationName); // Mark as initialized
        }
    });


    // Special handling for transitions from 'none'
    if (registeredAnimationsMap.has('none')) {
      const noneTransitions = registeredAnimationsMap.get('none').transitions;
      noneTransitions.forEach(({ to: transitionTo, condition }) => {
          if (transitionTo && this.animations[transitionTo] && !initializedStates.has(transitionTo)) {
              // Ensure target animation is initialized
              const specialAnimationState = this.initializeAnimationState(transitionTo, { transitions: [] }, animationComponent, condition);
              specialAnimationState.playOnceMode = true; // Mark as play once
              initializedStates.add(transitionTo); // Mark as initialized
          }
      });
  }

    // Set the initial state if possible
    const firstAnimationStateName = Array.from(registeredAnimationsMap.keys()).find(name => name?.toLowerCase() !== "none");
    if (firstAnimationStateName) {
        animationComponent.stateMachine.setState(firstAnimationStateName);
        animationComponent.stateMachine.initialStateName = firstAnimationStateName;
    }
}

  initializeAnimationState(animationName, details, animationComponent, condition = null) {
    if (animationName && !animationComponent.animations[animationName]) {
        const animationState = new AnimationState(animationName, this.animations[animationName]);
        animationComponent.animations[animationName] = animationState;

        if (condition) {
            const conditionFunction = conditionLibrary.getCondition(condition) || (() => false);
            animationState.addTransition(animationName, conditionFunction);
        } else {
            details.transitions.forEach(({ to, condition }) => {
                if (to && this.animations[to]) { // Ensure transition target exists
                    const conditionFunction = conditionLibrary.getCondition(condition) || (() => false);
                    animationState.addTransition(to, conditionFunction, [animationName]);
                }
            });
        }

        animationComponent.stateMachine.addState(animationName, animationState);
        return animationState;
    }
  }

  getEntitiesAsArray() {
    if (!this.cachedEntitiesArray) {
      this.cachedEntitiesArray = [...this.getEntities()];
    }
    return this.cachedEntitiesArray;
  }

  invalidateEntitiesCache() {
    super.invalidateEntitiesCache();
    this.cachedEntitiesArray = null;
  }

  itemMeshInteracted(interactionPackage) {
    const { mesh, interactionId } = interactionPackage;
    const animationEntity = this.entityManager.findEntityByMeshAndRequiredComponents(mesh, ['AnimationComponent', 'RenderComponent']);
    if (animationEntity){
      const animationComponent = animationEntity.getComponent('AnimationComponent');
      
      switch (interactionId) {
        case 'shotOn': 
          animationComponent.shotOn = true;
          break;
        case 'clickedOn': 
          animationComponent.clickedOn = true;
          break;
      }
    }
  }

  itemMeshDied(entity) {
    const renderComponent = entity.getComponent('RenderComponent');
    console.log('Item is dead:', renderComponent.mesh.name);

    const animationEntity = this.entityManager.findEntityByMeshAndRequiredComponents(renderComponent.mesh, ['AnimationComponent', 'RenderComponent']);
    if (animationEntity){
      const animationComponent = animationEntity.getComponent('AnimationComponent');
      if(animationComponent){
        animationComponent.isDead = true;
      }
    }
  }

  adjustBones(entity) {
    //A hackish way to get the animations to work properly for the Male characters from Ready Player Me
    const characterStateComponent = entity.getComponent('CharacterStateComponent');
    if (!characterStateComponent || characterStateComponent.sex !== 'male') {
      return;
    }
  
    const animComponent = entity.getComponent('AnimationComponent');
    const model = animComponent.stateMachine.mixer.getRoot();
  
    model.traverse((object) => {
      if (object.isBone) {
        // Retrieve original position from the AnimationComponent of the current entity
        const originalPosition = animComponent.originalBonePositions.get(object.name);
        if (originalPosition) {
          // Reset to the original position
          object.position.copy(originalPosition);
        }
      }
      // Apply the offsets
      if (object.isBone && object.name === 'LeftShoulder') {
        object.position.add(this.leftShoulderOffset);
      }
      if (object.isBone && object.name === 'RightShoulder') {
        object.position.add(this.rightShoulderOffset);
      }
    });
  }

  playAnimation(animationData) {

     // Set default values and override with objectData properties if they exist
     let {
      animationName = '',
      stopAtEnd = false,
      reversePlay = false,
      loop = false,
      animationSpeed = 1,
    } = animationData.animation;

    const object = scene.getObjectByName(animationData.objectID);
    let entity = this.entityManager.findEntityByMesh(object);
    if(!entity){
      if(animationData.entity) entity = animationData.entity;
      else return;
    }

    const animationClip = this.animations[animationName];
    if (!animationClip) {
        console.warn(`Animation "${animationName}" not found.`);
        return;
    }

    if (this.mixer) {
        let currentAction = this.mixer.clipAction(animationClip);

        currentAction.clampWhenFinished = stopAtEnd;  
        currentAction.stop();
        currentAction.timeScale = animationSpeed;
        currentAction.setLoop(loop ? THREE.LoopRepeat : THREE.LoopOnce);
        currentAction.play(); 
      }   
  }

  getCurrentAction(animationClip) {
    return this.mixer.clipAction(animationClip);
  }

  update(delta) { 
    this.getEntitiesAsArray().forEach(entity => {
      const animComponent = entity.getComponent('AnimationComponent');
      if (animComponent.stateMachine.mixer) {
        animComponent.stateMachine.evaluateTransitions(entity);
        animComponent.stateMachine.mixer.update(delta);
        this.adjustBones(entity);
      }
    });

    this.mixer.update(delta);
  }

  setAllAnimationsToInitialState() {
    this.getEntitiesAsArray().forEach(entity => {
      const animComponent = entity.getComponent('AnimationComponent');
      if (animComponent.stateMachine.mixer) {
        animComponent.stateMachine.mixer.stopAllAction();
        animComponent.stateMachine.setState(animComponent.stateMachine.initialStateName);
      }
    });
  }

  cleanup() {
    console.log('Cleaning up AnimationSystem');
    this.animationStates = {};
    this.animations = {};
    this.getEntitiesAsArray().forEach(entity => {
      const animComponent = entity.getComponent('AnimationComponent');
      if (animComponent.stateMachine.mixer) {
        animComponent.stateMachine.mixer.stopAllAction();
        animComponent.stateMachine.mixer.uncacheAction(animComponent.stateMachine.mixer.getRoot());
        animComponent.stateMachine.mixer.uncacheClip(animComponent.stateMachine.mixer.getRoot());
        animComponent.stateMachine.mixer.uncacheRoot(animComponent.stateMachine.mixer.getRoot());
      }
    });
  }
}
