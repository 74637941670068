import * as THREE from 'three';
import { systemManager } from '../ECS-utils/managerECS';

export class AnimationState {
  constructor(name, clip) {
    this.name = name;
    this.clip = clip;
    this.action = null;
    this.animationEvent = clip.animationEvent? clip.animationEvent : null;
    this.animationValue = clip.animationValue? clip.animationValue : null;
    this.transitions = new Map(); // StateName -> Condition Function
    this.playOnceMode = false;
    this.idCounterForTheToStateName = 0;
    console.log(`Created state ${this.name}`);
  }

  addTransition(toStateName, conditionFn, allowedOriginStates = []) {
    if (!toStateName || !conditionFn) {
      console.warn(`Invalid parameters when adding transition from ${this.name}`);
      return;
    }
    //append a unique id to the toStateName to avoid overwriting transitions with the same toStateName
    toStateName = toStateName + "_" + this.idCounterForTheToStateName;
    this.idCounterForTheToStateName++;

    this.transitions.set(toStateName, { conditionFn, allowedOriginStates });
  }

  enter(mixer) {
    if (this.validateEnter(mixer)) {
      this.createOrReuseAction(mixer);
      this.playAction();
    }
  }

  validateEnter(mixer) {
    if (!mixer) {
      console.error('Mixer is undefined for state:', this.name);
      return false;
    }
    if (!this.clip) {
      console.error('Clip is undefined for state:', this.name);
      return false;
    }
    return true;
  }

  createOrReuseAction(mixer) {
    if (!mixer) {
      console.error('Mixer is undefined, cannot create or reuse action.');
      return;
    }
    if (!this.action) {
      this.action = mixer.clipAction(this.clip);
      this.action.setLoop(THREE.LoopOnce, 1);
      this.action.clampWhenFinished = true;
    }
  }

  playAction() {
    if (this.action) {
      if (!this.action.isRunning()) {
        console.log(`Playing action for state ${this.name}`);
        systemManager.emitEvent(this.animationEvent, this.animationValue);
        this.action.play();  // Remove reset() to avoid disrupting cross-fade
      }
    } else {
      console.warn(`Action is null for state ${this.name}`);
    }
  }

  playActionOnce() {
    if (this.action) {
        systemManager.emitEvent(this.animationEvent, this.animationValue);
        this.action.reset();
        this.action.play();  
    } else {
      console.warn(`Action is null for state ${this.name}`);
    }
  }

  exit(nextAction) {
    if (this.action) {
      if (nextAction && nextAction !== this.action) {
        const crossFadeDuration = 0.35; // 1 second
        this.action.enabled = true;
        this.action.crossFadeTo(nextAction, crossFadeDuration, false);
        
        setTimeout(() => {
          this.action.stop();  // Stop the previous action
        }, crossFadeDuration * 1000);
      } else {
        this.action.play();
      }
    }
  }
  
}
