import { scene } from '../core/sharedState.js';
import * as THREE from 'three';
import { disposeObject } from '../models/modelLoader.js';

export class LineManager {
    constructor() {
        this.linePool = [];
    }

    getLine() {
        let line;
        if (this.linePool.length > 0) {
            line = this.linePool.pop();
        } else {
            line = this.createNewLine();
        }
        line.visible = true; 
        return line;
    }

    createNewLine() {
        const lineMaterial = new THREE.LineBasicMaterial({ 
            color: 0xffffff,
            transparent: false,
            depthWrite: false,
            depthTest: false
        });

        const lineGeometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(), new THREE.Vector3()]);
        const line = new THREE.Line(lineGeometry, this.lineMaterial);
        scene.add(line);
        return line;
    }

    returnLineToPool(line) {
        line.visible = false;
        line.geometry.setFromPoints([new THREE.Vector3(), new THREE.Vector3()]); 
        this.linePool.push(line);
    }

    unload() {
        // Iterate over each line in the pool
        for (let line of this.linePool) {
            // Remove the line from the scene
            scene.remove(line);
            
            // Dispose of the line's geometry and material
            disposeObject(line);
    
            // Nullify the object to help with garbage collection
            line = null;
        }
    
        // Clear the pool array
        this.linePool.length = 0;
    }
    
}
