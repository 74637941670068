import * as THREE from 'three';
import { manager } from '../ui/loadingManager.js';
import { ShaderLibrary } from '../shaders/shaderLibrary.js';
import { camera, renderer, scene } from '../core/sharedState.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { cubeRenderTarget } from '../ECS-systems/reflectionSystem.js';

class MaterialManager{
    constructor() {
        this.materialCache = new Map();
        this.materialsWithTimeUniform = [];
        this.materialsWithEnvironmentMap = [];
    }

    createCacheKey(shaderKey, uniforms, additionalConfig) {
        const essentialUniforms = ['map', 'lightmap', 'baseTexture']; // or whichever uniforms are essential
        let uniformKey = essentialUniforms.map(k => {
            return uniforms[k] ? `${k}:${uniforms[k].value.uuid}` : `${k}:undefined`;
        }).join(',');
    
        let configKey = Object.keys(additionalConfig).map(k => `${k}:${additionalConfig[k]}`).join(',');
    
        return `${shaderKey}-${uniformKey}-${configKey}`;
    }

    createMaterial(shaderKey, uniforms = {}, additionalConfig = {}) {
        // Construct a unique key for the cache based on shaderKey and stringified uniforms & config
        const cacheKey = this.createCacheKey(shaderKey, uniforms, additionalConfig);

        // Check if the material already exists in the cache
        if (this.materialCache.has(cacheKey)) {
            return this.materialCache.get(cacheKey);
        }

        // Get shader from ShaderLibrary
        const shaderEntry = ShaderLibrary[shaderKey];
        if (!shaderEntry) {
            console.warn(`Shader named "${shaderKey}" not found in ShaderLibrary.`);
            return null;
        }

        // Create new material
        const materialConfig = {
            ...shaderEntry.shaders,
            ...shaderEntry.properties,
            ...additionalConfig,
            uniforms: {
                ...shaderEntry.uniforms,
                ...uniforms
            }
        };

        const material = new THREE.ShaderMaterial(materialConfig);

        // Check if the material has a time uniform and store it if it does
        if (material.uniforms && material.uniforms.time) {
            this.materialsWithTimeUniform.push(material);
        }

        // Check if the material has an environment map and set it if it does
        if (material.uniforms && material.uniforms.envMap) {
            this.materialsWithEnvironmentMap.push(material);
        }
        
        // Add the new material to the cache and return it
        this.materialCache.set(cacheKey, material);
        return material;
    }

    setToMostPerformantShader(child, useBasicShader = false, useStandardShader = false) {   
        let usePhongShader = false;
        if (child.isMesh && child.material) {

            const oldMaterial = child.material; // Keep a reference to the old material
            const cacheKey = `PerformantShader-${useBasicShader}-${child.material.uuid}`;
            
            // Check if the material already exists in the cache
            if (this.materialCache.has(cacheKey)) {
                child.material = this.materialCache.get(cacheKey);
                // Optionally, dispose of the old material if it's not in the cache (meaning not reused)
                return;
            }
    
            // Common properties for both Basic and Standard materials
            const commonProps = {
                color: child.material.color,
                map: child.material.map,
                opacity: child.material.opacity,
                transparent: child.material.transparent,
                alphaTest: child.material.alphaTest,
                side: child.material.side,
                depthTest: child.material.depthTest,
                depthWrite: child.material.depthWrite,
                blending: child.material.blending,
                blendSrc: child.material.blendSrc,
                blendDst: child.material.blendDst,
                blendEquation: child.material.blendEquation,
                blendSrcAlpha: child.material.blendSrcAlpha,
                blendDstAlpha: child.material.blendDstAlpha,
                blendEquationAlpha: child.material.blendEquationAlpha,
                vertexColors: child.material.vertexColors,
            };
    
            let MaterialType, materialConfig;
    
            if (useBasicShader) {
                MaterialType = THREE.MeshBasicMaterial;
                materialConfig = {
                    ...commonProps,
                    wireframe: child.material.wireframe,
                    wireframeLinewidth: child.material.wireframeLinewidth,
                    lightMap: child.material.emissiveMap,
                    lightMapIntensity: child.material.emissiveIntensity*5,   
                    envMap: scene.environment,
                    reflectivity: 1-child.material.roughness, 
                };

                // If the material has a lightmap we wont use mipmaps
                if (child.material.emissiveMap) {
                    // Disable mipmaps for the emissiveMap texture
                    child.material.emissiveMap.minFilter = THREE.LinearMipmapLinearFilter;
                    child.material.emissiveMap.anisotropy = renderer.capabilities.getMaxAnisotropy();
                    child.material.emissiveMap.wrapS = THREE.ClampToEdgeWrapping
                    child.material.emissiveMap.wrapT = THREE.ClampToEdgeWrapping
                }

                if (child.material.map) {
                    // Disable mipmaps for the emissiveMap texture
                    //child.material.map.minFilter = THREE.LinearFilter;
                }


            }

            else if (useStandardShader) {
                //console.log("Using standard shader");
                MaterialType = THREE.MeshStandardMaterial;
                materialConfig = {
                    ...commonProps,
                    metalness: child.material.metalness,
                    roughness: child.material.roughness,
                    emissive: child.material.emissive,
                    emissiveIntensity: child.material.emissiveIntensity,
                    emissiveMap: child.material.emissiveMap,
                    envMap: scene.environment,
                };
            } 

            else {
                usePhongShader = true;

                MaterialType = THREE.MeshPhongMaterial;
                materialConfig = {
                    ...commonProps,
                    shininess: child.material.metalness,
                    emissive: child.material.emissive,
                    emissiveIntensity: child.material.emissiveIntensity,
                    emissiveMap: child.material.emissiveMap,  
                    envMap: scene.environment,
                    bumpMap: child.material.normalMap,
                    bumpScale: child.material.normalScale.x,
                    reflectivity: 1-child.material.roughness,
                };
            }
    
            const newMaterial = new MaterialType(materialConfig);
            if(child.material.map)newMaterial.map.anisotropy = renderer.capabilities.getMaxAnisotropy();         

            // Cache the new material
            this.materialCache.set(cacheKey, newMaterial);
            child.material = newMaterial;

            // Dispose of the old material
            this.disposeMaterial(oldMaterial);
        }
    }

    disposeMaterial(material) {
        material.dispose();
    }
    
    clearCache() {
        // Dispose of all materials in the cache and clear it
        for (const material of this.materialCache.values()) {
            material.dispose();
        }
        this.materialCache.clear();
    }

    setupAddtionalMaterialSettingWhenDependenciesAreLoaded() {
        // Load the HDR environment map
        const rgbeLoader = new RGBELoader();
        rgbeLoader.load('/environments/kloofendal_43d_clear_puresky_2k.hdr', (texture) => {
            texture.mapping = THREE.EquirectangularReflectionMapping;

            // Set up additional material settings when the dependencies are loaded
            for (const material of this.materialsWithEnvironmentMap) {
                console.log("Setting up additional material settings when the dependencies are loaded");
                material.uniforms.envMap.value = texture;
            }
        });
    }
}

export const textureLoader = new THREE.TextureLoader(manager);
export const materialManager = new MaterialManager();

//list for window custom event "sceneLoaded"
window.addEventListener("sceneLoaded", function(event) {
    materialManager.setupAddtionalMaterialSettingWhenDependenciesAreLoaded();
}, false);
