import { System } from './system.js';
import * as THREE from 'three';
import { ImprovedNoise } from 'three/examples/jsm/math/ImprovedNoise.js';

export class WindSystem extends System {
    constructor(entityManager, systemManager) {
        super(entityManager, systemManager);
        this.windGrid = [];
        this.gridSize = 10;
        this.time = 0;
        this.timeFactor = 0.01;
        this.noise = new ImprovedNoise();
        this.localNoiseOffset = 1;  // Local offset for noise
        this.elapsedTime = 0;
        this.initWindGrid();
        this.requiredComponents = ['WindmillComponent', 'PositionComponent'];

    }

    initWindGrid() {
        this.windGrid = new Array(this.gridSize).fill(null).map(() => 
            new Array(this.gridSize).fill(null).map(() => 
                new THREE.Vector3(0, 0, 0)
            )
        );
    }

    getWindAt(x, y) {
        // Scale the coordinates to fit a 200x200 m2 area into the 10x10 grid
        const scaledX = (x + 100) / 20;  // Assuming x, y are in [-100, 100]
        const scaledY = (y + 100) / 20;
    
        const i = Math.floor(scaledX);
        const j = Math.floor(scaledY);
        
        const clampedI = Math.max(0, Math.min(this.gridSize - 1, i));
        const clampedJ = Math.max(0, Math.min(this.gridSize - 1, j));
    
        return this.windGrid[clampedI][clampedJ];  // Return reference assuming it's not modified elsewhere
    }
    

    update(deltaTime) {
        const entities = this.getEntities();

        if (entities.size === 0) return; // No entities that require wind
        this.time += deltaTime;

        this.elapsedTime += deltaTime;

        if (this.elapsedTime >= 1.0) { 
            this.elapsedTime = 0; 
            this.time += deltaTime;

        
            for (let i = 0; i < this.gridSize; i++) {
                for (let j = 0; j < this.gridSize; j++) {
                    const noiseValue = this.noise.noise(i + this.localNoiseOffset, j + this.localNoiseOffset, this.time * this.timeFactor);
                    const angle = noiseValue * Math.PI * 2;
                    const magnitude = Math.sin(this.time * this.timeFactor + noiseValue) + 1;
                    this.windGrid[i][j].set(
                        Math.cos(angle) * magnitude,
                        Math.sin(angle) * magnitude,
                        0
                    );
                }
            }
        }
    }

}
