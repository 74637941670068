import { glassVertexShader, glassFragmentShader, glassProperties } from './glassShader.js';
import { grassVertexShader, grassFragmentShader, grassProperties, grassUniforms } from './grassShader.js';
import { grassAndCliffVertexShader, grassAndCliffFragmentShader, grassAndCliffProperties, grassAndCliffUniforms } from './grassAndCliffShader.js';
import { vegetationVertexShader, vegetationFragmentShader, vegetationProperties, vegetationUniforms } from './vegetationShader.js';
import { aerialPerspectiveVertexShader, aerialPerspectiveFragmentShader, aerialPerspectiveProperties, aerialPerspectiveUniforms } from './aerialPerspectiveShader.js';
import { aerialPerspectiveInstanceVertexShader, aerialPerspectiveInstanceFragmentShader, aerialPerspectiveInstanceProperties, aerialPerspectiveInstanceUniforms } from './aerialPerspectiveInstanceShader.js';
import { lightmapVertexShader, lightmapFragmentShader, lightmapProperties, lightmapUniforms } from './lightmapShader.js';
import { asphaltVertexShader, asphaltFragmentShader, asphaltProperties, asphaltUniforms } from './asphaltShader.js';

export const ShaderLibrary = {
    shader_glass: { 
        shaders: {
            vertexShader: glassVertexShader, 
            fragmentShader: glassFragmentShader
        },
        properties: glassProperties,
    },
    shader_grass: { 
        shaders: {
            vertexShader: grassVertexShader, 
            fragmentShader: grassFragmentShader
        },
        properties: grassProperties,
        uniforms: grassUniforms
    },
    shader_grass_and_cliff: { 
        shaders: {
            vertexShader: grassAndCliffVertexShader, 
            fragmentShader: grassAndCliffFragmentShader
        },
        properties: grassAndCliffProperties,
        uniforms: grassAndCliffUniforms
    },
    shader_vegetation: { 
        shaders: {
            vertexShader: vegetationVertexShader, 
            fragmentShader: vegetationFragmentShader
        },
        properties: vegetationProperties,
        uniforms: vegetationUniforms
    },
    shader_aerial_perspective_instance: { 
        shaders: {
            vertexShader: aerialPerspectiveInstanceVertexShader, 
            fragmentShader: aerialPerspectiveInstanceFragmentShader
        },
        properties: aerialPerspectiveInstanceProperties,
        uniforms: aerialPerspectiveInstanceUniforms
    },
    shader_aerial_perspective: { 
        shaders: {
            vertexShader: aerialPerspectiveVertexShader, 
            fragmentShader: aerialPerspectiveFragmentShader
        },
        properties: aerialPerspectiveProperties,
        uniforms: aerialPerspectiveUniforms
    },
    shader_asphalt: { 
        shaders: {
            vertexShader: asphaltVertexShader, 
            fragmentShader: asphaltFragmentShader
        },
        properties: asphaltProperties,
        uniforms: asphaltUniforms
    },
    Lightmap: { 
        shaders: {
            vertexShader: lightmapVertexShader, 
            fragmentShader: lightmapFragmentShader
        },
        properties: lightmapProperties,
        uniforms: lightmapUniforms
    }
};
