import { createPopup } from '../ui/contentPopupCreator.js';
import objectInfo from '../content/objectData.json';
import { getFromMemoryCache } from '../utils/cacheManager.js';
import { isAdminMode, isInVRSession, scene, camera, renderer, isLoading } from '../core/sharedState.js';
import { QuizManager } from '../quiz/quizManager.js';
import { objectEvents } from '../utils/objectEventTarget.js';
import { sceneManager } from '../core/sceneManager.js';
import { entityManager, playerSystem, systemManager } from '../ECS-utils/managerECS.js';
import { showUserManagementPopup } from '../ui/userManagementPopupManager.js';
import { showLoginPopup } from '../ui/loginPopupManager.js';
import { setNextSuccesfulLoginUnlocksThisObject } from '../firebase/userAuthAndManagement.js';
import XRPopupManager from '../XR/UI/XRPopupManager.js';

export let quizManagersMap = new Map();

export async function handleObjectInteraction(entity) {

    const renderComponent = entity.getComponent('RenderComponent');
    const object = renderComponent.mesh;
    //Data from the interactionComponent
    const objectData = getObjectData(object);
    //Data from the JSON file
    const interactionComponent = entity.getComponent('InteractionComponent');

    // Handle the object based on the interactionComponent and objectData
    const proceed = handleObjectsWithComponentData(object, interactionComponent, entity, );
    if (proceed) handleObjectsWithJSONData(object, objectData, interactionComponent);
}

function handleObjectsWithComponentData(object, interactionComponent, entity) {
    if(interactionComponent.isLocked && !isLoading){

        //Keytype 0: Accessible to all
        if(interactionComponent.keyType === 0 || interactionComponent.keyType === null){
            console.log("Unlocked for all");
        }

        //Keytype 1: Email and password login required
        else if(interactionComponent.keyType === 1){ 
            console.log("Locked with email and password");
            setNextSuccesfulLoginUnlocksThisObject(object);
            showLoginPopup(null, object);
            return false;
        }

        //Keytype 2: Does player have a matching key in their inventory?
        else if(interactionComponent.keyType === 2){
            const playerEntity = entityManager.getEntitiesWithComponent('PlayerComponent')[0];
            const inventoryComponent = playerEntity.getComponent('InventoryComponent');
            if(inventoryComponent && inventoryComponent.hasKey) console.log("Unlocked with key");
            else {
                console.log("Locked with key");
                systemManager.emitEvent('playSound', 'click');        
                return false;
            }    
        }

    }

    if(interactionComponent.isNewRoom !== "" && !isLoading){
        handleLoadScene(interactionComponent.isNewRoom);
        return false;
    }

    else if(interactionComponent.isAdminTerminal){
        handleAdminTerminal()
        return false;
    }

    else if(interactionComponent.stepAction.actionId){
        systemManager.emitEvent('actionCompleted', interactionComponent.stepAction);
        return true;
    }

    if(interactionComponent.playAnimation !== "" && interactionComponent.playAnimation !== null 
        && interactionComponent.playAnimation !== undefined && interactionComponent.playAnimation !== "none"){
        const animation = { 
            animationName: interactionComponent.playAnimation,
            animationSpeed: 1.0,
            loop: false,
            loopPingPong: false,
            loopPingPongCount: 1,
            reversePlay: false,
            reversePlayDelay: 1.0,
            stopAtEnd: false
        }

        const animationData = 
        {
            animation: animation,
            objectID: object.name,
            entity: entity
        }

        systemManager.emitEvent('playAnimation', animationData);
        return true;
    }

    else return true;
}

function handleObjectsWithJSONData(object, objectData, interactionComponent) {
    if(interactionComponent.isQuiz) handleQuiz(object, objectData);
    if (objectData) {
        handleUrlRedirect(objectData);
        handlePopups(objectData, object.name);
        handlePDF(objectData);
        handleAnimations(objectData, object.name);
        handleSounds(objectData);
    }
}

export function handleLoadScene(newRoom) {
    const pathToModel = '/models/' + newRoom + '.glb';
    setTimeout(() => {
        sceneManager.loadScene(newRoom, pathToModel);
    }, 100);
}

function handleQuiz(object, objectData) {

    if(object && object.parent && object.parent.userData && object.parent.userData.quizAnswerOption){
        objectEvents.clickObjectQuizAnswerOption(object); 
        return;
    }   
    
    let quizManager = getOrCreateQuizManager(object, objectData);
    quizManager.initializeQuiz(object, 800);
}

function getOrCreateQuizManager(object, objectData) {

    let quizManager = quizManagersMap.get(object);
  
    if (!quizManager) {
      quizManager = new QuizManager(scene, camera, renderer, objectData.quizData);
      quizManagersMap.set(object, quizManager);
      quizManager.addEventListener('quizEnded', () => {
        handleQuizEndEvent(objectData, object.name);
      });
    }
    return quizManager;
}

function handleUrlRedirect(objectData) {
    if (objectData.urlRedirect) {
        window.open(objectData.urlRedirect, '_blank');
    }
}

function handlePopups(objectData, objectName) {
    if(isObjectDataValidForDisplayingPopup(objectData)){
        if (isInVRSession) {
            XRPopupManager.displayPopup(objectData);
        } else {
            createPopup(objectData, objectName);
        }
    }
}

function handlePDF(objectData) {
    if (objectData.pdf) {
        systemManager.emitEvent('showPDF', objectData.pdf);
    }
}

function handleAdminTerminal() {
    if (isAdminMode) showUserManagementPopup(null);
    else if (!isAdminMode) showLoginPopup();
}

function handleQuizEndEvent(objectData, objectName) {
    if (objectData && objectData.animations) {

        const object = scene.getObjectByName(objectName);
        let entity = entityManager.findEntityByMesh(object);
        
        const statusComponent = entity.getComponent('StatusComponent');
        if(statusComponent){
            if(statusComponent.state === 'playAnimationForwards') statusComponent.setState('playAnimationBackwards');
            else if(statusComponent.state === 'playAnimationBackwards') statusComponent.setState('playAnimationForwards');
        }

        const animationData = 
        {
            animation: objectData.animations[0],
            objectID: objectName,
        }
        
        systemManager.emitEvent('playAnimation', animationData);
    }
}

function handleAnimations(objectData, objectName) {
    if (objectData && objectData.animations) {

        const animationData = 
        {
            animation: objectData.animations[0],
            objectID: objectName,
        }
            
        systemManager.emitEvent('playAnimation', animationData);
    }
    else if (objectData && objectData.mediaType === 'dropzone') {
        systemManager.emitEvent('playDefaultTurnAnimationForDropzoneModel', objectName);
    }
}

function handleSounds(objectData) {
    if (objectData.soundName) {
        systemManager.emitEvent('playSound', objectData.soundName);
    }
    else{
        //default interaction sound if no soundName is specified
        systemManager.emitEvent('playSound', 'click');
    }
}

export function getObjectData(object, useObjectName = true) {

    let objectName = object;

    if(useObjectName){
        objectName = object.name;
    }

    let playerType = playerSystem.getPlayerProperty('userType');

    if(playerType !== "all" && playerType !== "admin" && playerType){     
        if(getFromMemoryCache(objectName, playerType)) return getFromMemoryCache(objectName, playerType);      
    }

    return objectName in objectInfo ? getFromMemoryCache(objectName) || objectInfo[objectName] : null;
}

function isObjectDataValidForDisplayingPopup(objectData) {
    if(isAdminMode) return true;
    else return objectData.text || (objectData.mediaType && objectData.mediaURL);
}


