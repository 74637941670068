import { StateMachine } from '../animations/animStateMachine.js';

export class AnimationComponent {
    constructor(mixer) {
      this.type = 'AnimationComponent';
      this.stateMachine = new StateMachine(mixer);
      this.animations = {}; 
      this.originalBonePositions = new Map();
      this.clickedOn = false;
      this.shotOn = false;
      this.isDead = false;
    }
  }
  
  