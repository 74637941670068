import { BaseEntity } from './baseEntity.js';
import { AnimationComponent } from '../ECS-components/animationComponent.js';
import * as THREE from 'three';
import { RenderComponent } from '../ECS-components/renderComponent.js';

export class AnimationEntity extends BaseEntity {
    constructor(id, mesh) {
        super(id);

        const mixer = new THREE.AnimationMixer(mesh);

        const animationComponent = new AnimationComponent(mixer);
        this.addComponent(animationComponent);

        const renderComponent = new RenderComponent(mesh);
        this.addComponent(renderComponent);
    }
}

