import { Tween } from '@tweenjs/tween.js';

export class TextAnimationManager {
    constructor() {
        this.activeTextTween = null;
    }

    displayText(textMesh, text, duration = 2000, onDisplayComplete = null) {
        if (this.activeTextTween) {
            this.activeTextTween.stop();
        }

        this.activeTextTween = new Tween({ t: 0 })
            .to({ t: text.length }, duration)
            .onUpdate(function (object) {
                textMesh.text = text.substr(0, Math.floor(object.t));
                textMesh.sync();
            })
            .onComplete(() => {
                if(onDisplayComplete) onDisplayComplete();
            })
            .start();
    }

    stopCurrentAnimation() {
        if (this.activeTextTween) {
            this.activeTextTween.stop();
        }
    }

    hideText(textMesh, duration = 2000, onHideComplete = null) {
        if (!textMesh || textMesh.text === undefined) {
            console.error('Invalid textMesh object passed to hideText method:', textMesh);
            return;
        }
        if (textMesh && textMesh.text !== undefined) {
            if (this.activeTextTween) {
                this.activeTextTween.stop();
            }
        }
        const initialText = textMesh.text;
    
        this.activeTextTween = new Tween({ t: initialText.length })
            .to({ t: 0 }, duration)
            .onUpdate(function (object) {
                textMesh.text = initialText.substr(0, Math.floor(object.t));
                textMesh.sync();
            })
            .onComplete(function() {
                textMesh.visible = false;
                if(onHideComplete) onHideComplete();  // Execute the callback
            })
            .start();
    }
}
