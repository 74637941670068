import { systemManager } from "../ECS-utils/managerECS";

class ObjectEventTarget extends EventTarget {
    hoverObject(object) {
        //this.dispatchEvent(new CustomEvent('hoverObject', { detail: object }));
        systemManager.emitEvent('hoverObject', object);
    }

    resetObject(object) {
        //this.dispatchEvent(new CustomEvent('resetObject', { detail: object }));
        systemManager.emitEvent('resetObject', object);
    }

    hoverObjectQuizAnswerOption(object) {
        this.dispatchEvent(new CustomEvent('hoverObjectQuizAnswerOption', { detail: object }));
    }

    resetObjectQuizAnswerOption(object) {
        this.dispatchEvent(new CustomEvent('resetObjectQuizAnswerOption', { detail: object }));
    }

    clickObjectQuizAnswerOption(object) {
        this.dispatchEvent(new CustomEvent('clickObjectQuizAnswerOption', { detail: object }));
    }
}

export const objectEvents = new ObjectEventTarget();