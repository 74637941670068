import { setSelectedGLBFile, setSelectedImageFile, selectedGLBFile, selectedImageFile, isValidGLB, isValidImage, isFileSizeValid, MAX_FILE_SIZE_MB  } from './fileHandlers.js';

function createElement(tag, attributes = {}, ...children) {
    const element = document.createElement(tag);
    Object.entries(attributes).forEach(([key, value]) => {
        element[key] = value;
    });

    // Flatten children and filter out null/undefined values
    [].concat(...children).filter(child => child != null).forEach(child => {
        if (typeof child === 'string') {
            child = document.createTextNode(child);
        }
        if (child instanceof Node) {
            element.appendChild(child);
        } else {
            console.error('Attempted to append a non-Node element:', child);
        }
    });
    return element;
}

function createTooltip(text) {
    const tooltipIcon = createElement('span', { className: 'tooltip-icon', innerText: 'ℹ️' });
    const tooltipText = createElement('span', { className: 'tooltip-text', innerText: text });
    tooltipIcon.appendChild(tooltipText);
    return tooltipIcon;
}

function createField(labelText, inputType, value, name, tooltipText) {
    let inputElement;

    if (inputType === 'textarea') {
        let displayValue = '';
        if (value !== undefined && value !== null) {
            // Convert <br> to line breaks for displaying in textarea
            displayValue = value.replace(/<br\s*\/?>/g, '\n');
        };
        inputElement = createElement('textarea', { name, value: displayValue });

    } else {
        inputElement = createElement('input', { type: inputType, name, value });
    }

    const label = createElement('label', { htmlFor: name }, labelText);

    if (tooltipText) {
        const tooltip = createTooltip(tooltipText);
        label.appendChild(tooltip);
    }

    return createElement(
        'div', 
        { className: 'field-container' },
        label,
        inputElement
    );
}

function createDropzone() {
    // Title for the dropzone
    const dropzoneTitle = createElement('label', { className: 'dropzone-title', innerText: 'Upload Model'});
    const dropzoneTooltip = createTooltip("Drag and drop a .glb or .gltf file here, or click to browse your files.");
    dropzoneTitle.appendChild(dropzoneTooltip);

    const dropzone = createElement('div', { className: 'dropzone', innerText: 'Drag & Drop .glb file here or click to upload.' });
    const fileInput = createElement('input', { type: 'file', style: 'display: none;' });
    const statusMessage = createElement('div', { className: 'status-message' });

    dropzone.addEventListener('dragover', (e) => {
        e.preventDefault();
        dropzone.classList.add('hover');
    });
    
    dropzone.addEventListener('dragleave', () => dropzone.classList.remove('hover'));
    dropzone.addEventListener('dragend', () => dropzone.classList.remove('hover'));

    dropzone.addEventListener('click', () => fileInput.click());
    fileInput.addEventListener('change', (e) => {
        const file = e.target.files[0];
        setSelectedGLBFile(file);
        if (!isValidGLB(file)) {
            statusMessage.innerText = "Invalid file type. Please provide a .glb or .gltf file.";
        } else if (!isFileSizeValid(file)) {
            statusMessage.innerText = `File is too large. Maximum allowed size is ${MAX_FILE_SIZE_MB}MB.`; // You'll need to import MAX_FILE_SIZE_MB too.
        } else {
            statusMessage.innerText = `File selected: ${selectedGLBFile.name}. Ready to be uploaded.`;
        }
    });

    dropzone.addEventListener('drop', (e) => {
        e.preventDefault();
        dropzone.classList.remove('hover');
    
        const file = e.dataTransfer.files[0];
        setSelectedGLBFile(file);
        if (!isValidGLB(file)) {
            statusMessage.innerText = "Invalid file type. Please provide a .glb or .gltf file.";
        } else if (!isFileSizeValid(file)) {
            statusMessage.innerText = `File is too large. Maximum allowed size is ${MAX_FILE_SIZE_MB}MB.`;
        } else {
            statusMessage.innerText = `File selected: ${selectedGLBFile.name}. Ready to be uploaded.`;
        }
    });

    const clearButton = createElement('button', { type: 'button', innerText: 'Clear Model' });
    clearButton.addEventListener('click', () => {
        setSelectedGLBFile(null);
    });
    return createElement('div', {}, dropzoneTitle, dropzone, fileInput, clearButton, statusMessage);
}

function createImageUploadField() {
    // Title for the image upload field
    const imageUploadTitle = createElement('label', { className: 'image-upload-title', innerText: 'Upload Image' });
    const imageUploadTooltip = createTooltip("Drag and drop an image file here, or click to browse your files.");
    imageUploadTitle.appendChild(imageUploadTooltip);

    const dropzone = createElement('div', { className: 'dropzone', innerText: 'Drag & Drop image file here or click to upload.' });
    const fileInput = createElement('input', { type: 'file', style: 'display: none;' });
    const statusMessage = createElement('div', { className: 'status-message' });

    dropzone.addEventListener('dragover', (e) => {
        e.preventDefault();
        dropzone.classList.add('hover');
    });

    dropzone.addEventListener('dragleave', () => dropzone.classList.remove('hover'));
    dropzone.addEventListener('dragend', () => dropzone.classList.remove('hover'));

    dropzone.addEventListener('click', () => fileInput.click());
    fileInput.addEventListener('change', (e) => {
        const file = e.target.files[0];
        setSelectedImageFile(file);
        if (!isValidImage(file)) {
            statusMessage.innerText = "Invalid file type. Please provide an image file.";
        } else if (!isFileSizeValid(file)) {
            statusMessage.innerText = `File is too large. Maximum allowed size is ${MAX_FILE_SIZE_MB}MB.`;
        } else {
            statusMessage.innerText = `File selected: ${selectedImageFile.name}. Ready to be uploaded.`;
        }
    });

    dropzone.addEventListener('drop', (e) => {
        e.preventDefault();
        dropzone.classList.remove('hover');

        const file = e.dataTransfer.files[0];
        setSelectedImageFile(file);
        if (!isValidImage(file)) {
            statusMessage.innerText = "Invalid file type. Please provide an image file.";
        } else if (!isFileSizeValid(file)) {
            statusMessage.innerText = `File is too large. Maximum allowed size is ${MAX_FILE_SIZE_MB}MB.`;
        } else {
            statusMessage.innerText = `File selected: ${selectedImageFile.name}. Ready to be uploaded.`;
        }
    });

    const clearButton = createElement('button', { type: 'button', innerText: 'Clear Image' });
    clearButton.addEventListener('click', () => {
        setSelectedImageFile(null);
    });

    return createElement('div', {}, imageUploadTitle, dropzone, fileInput, clearButton, statusMessage);
}

function getCSSVariableValue(variableName) {
    return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
}

export { createElement, createField, createTooltip, createDropzone, getCSSVariableValue, createImageUploadField };
