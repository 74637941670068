import { db } from './firebaseSetup';
import { collection, query, getDocs,doc, getDoc } from 'firebase/firestore';
import objectInfo from '../content/objectData.json';
import { updateMemoryCache, getFromLocalStorage, updateLocalStorage } from '../utils/cacheManager';

// Utility: Fetch data from Firestore
async function fetchDataFromFirestore() {
  const sceneObjectsCollection = collection(db, 'sceneObjects');
  const q = query(sceneObjectsCollection);
  const snapshot = await getDocs(q);
  const newData = {};
  snapshot.forEach(doc => newData[doc.id] = doc.data());
  return newData;
}

// Utility: Merge data from local JSON and Firestore
function mergeData(localData, firestoreData) {
  return deepMerge(localData, firestoreData);
}

// Utility: Deep merge two objects
function deepMerge(obj1, obj2) {
  const result = { ...obj1 };
  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (typeof obj2[key] === 'object' && obj2[key] !== null && obj1.hasOwnProperty(key)) {
        result[key] = deepMerge(obj1[key], obj2[key]);
      } else {
        result[key] = obj2[key];
      }
    }
  }
  return result;
}

// Utility: Update cache with merged data
function updateCacheWithData(data) {
  const now = Date.now().toString();
  updateMemoryCache(data);
  updateLocalStorage('objectData', JSON.stringify(data));
  updateLocalStorage('lastFetched', now);
}

// Utility: Fetch Last Updated Timestamp from Firestore
async function fetchLastUpdatedTimestamp() {
  const metadataRef = doc(db, 'config', 'metadata');
  const docSnap = await getDoc(metadataRef);
  return docSnap.exists() ? docSnap.data().lastUpdated.toMillis() : 0;
}

// Core function: Initialize object data
export async function initializeObjectData() {
  try {
    const cachedData = getFromLocalStorage('objectData');
    const lastFetched = parseInt(getFromLocalStorage('lastFetched'), 10);
    const lastUpdatedTimestamp = await fetchLastUpdatedTimestamp();

    // Check if the cached data is outdated compared to Firestore data
    if (!cachedData || !lastFetched || lastUpdatedTimestamp > lastFetched) {
      // Fetch fresh data if the local cache is outdated or doesn't exist
      console.log("Fetching objectData from Firestore...");
      const firestoreData = await fetchDataFromFirestore();
      const mergedData = mergeData(objectInfo, firestoreData);
      updateCacheWithData(mergedData);
    } else {
      // Use cached data if it's up-to-date
      console.log("Loaded object data from local storage.");
      updateMemoryCache(JSON.parse(cachedData));
    }
  } catch (error) {
    console.error("Error in initializeObjectData:", error);
  }
}

export async function getModelURL(objectName) {
  const objectRef = doc(collection(db, 'sceneObjects'), objectName);
  return getDoc(objectRef)
  .then(doc => {
    if (!doc.exists()) {
      console.log("Document does not exist:", objectName);
      return null;
    }
    const data = doc.data();
    if (data.modelURL) {
      return data.modelURL;
    } else {
      console.log("Model URL not specified for object:", objectName);
      return null;
    }
  })
  .catch(error => {
    console.log("Error fetching model URL from Firestore:", error);
    return null;
  });
}

export async function getTextureURL(objectName) {
  const objectRef = doc(collection(db, 'sceneObjects'), objectName);
  return getDoc(objectRef)
  .then(doc => {
    if (!doc.exists()) {
      console.log("Document does not exist:", objectName);
      return null;
    }
    const data = doc.data();
    if (data.textureURL) {
      return data.textureURL;
    } else {
      console.log("Texture URL not specified for object:", objectName);
      return null;
    }
  })
  .catch(error => {
    console.log("Error fetching texture URL from Firestore:", error);
    return null;
  });
}




