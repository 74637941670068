import * as THREE from 'three';
import { systemManager } from '../ECS-utils/managerECS';
import { lockPointer, unlockPointer } from './fpsControls';
import { micToggleButton } from '../ui/settingsPopupManager';
export const renderer = new THREE.WebGLRenderer({ antialias: true, autoUpdate: false});

export let scene = null;
export let camera = null;
export let cameraRig = new THREE.Group();

export let isInVRSession = false;
export let isMobile = false;
export let isAdminMode = false;
export let cameraAndEnvrionmentHasBeenInitialized = false;
export let lastTimestamp = 0;
export let canPlay = false;
export let pointerLocked = false;
export let isLoading = false;
export let currentSceneName = null;
export let displayingPopup = false;
export let selectState = false;
export let micAccess = false;

export function setSelectState(state) {
    selectState = state;
}

export function setDisplayingPopup(state, dontAdjustPointer = false) {
    displayingPopup = state;
    if(dontAdjustPointer) return;
    if(!state && !isMobile || !state && !isInVRSession) {
        //console.trace();
        lockPointer();
    }
    else {
        unlockPointer();
    }
        
    if(systemManager) systemManager.emitEvent('displayingPopup', state);
}

export function setLastTimestamp(timestamp) {
    lastTimestamp = timestamp;
}

export function setMobile(state) {
    isMobile = state;
}

export function setVRMode(state) {
    isInVRSession = state;
}

export function setAdminMode(state) {
    isAdminMode = state;
}

export function setPointerLock(state) {
    pointerLocked = state;
    if(systemManager) systemManager.emitEvent('pointerLocked', state);
}

export function toggleCanPlay() {
    canPlay = !canPlay;
}

export function setScene(newScene) {
    scene = newScene;
}

export function setCamera(newCamera) {
    camera = newCamera;
}

export function setCameraRig(newCameraRig) {
    cameraRig = newCameraRig;
}

export function setLoading(state) {
    isLoading = state;
}

export function setCameraAndEnvironmentInitialized(state) {
    cameraAndEnvrionmentHasBeenInitialized = state;
}

export function setSceneName(name) {
    currentSceneName = name;
}

export function setMicAccess(state) {
    micToggleButton.checked = state;
    micAccess = state;
}