import { io } from 'socket.io-client';
import { spawnPlayer, updatePlayerPosition, removePlayer, updateControllerPosition, players } from './playerManager.js';
import { playerHitEffect, startUpdatingSpeakerIcons, stopUpdatingSpeakerIcons } from './playerManagerHelper.js';
import { setMultiplayerSession } from './sessionManager.js';
import { playerSystem, systemManager } from '../ECS-utils/managerECS.js';
import * as THREE from 'three';
import { currentSceneName, micAccess } from '../core/sharedState.js';
import { syncEnvironmentState, handlePlayerChangedScene, handleOtherPlayerInteractedWithItem, updateEnvironmentState, getEnvironmentState } from './syncManager.js';
import { getCurrentRoom } from './roomManager.js';
import { WebRTCManager } from './webRTCManager.js';
import notificationManager from '../ui/notificationManager.js';

export let socket;
export let webrtc;

// Pre-allocated objects to avoid repeated creation
const receivedPosition = new THREE.Vector3();
const targetQuaternion = new THREE.Quaternion();

export function initMultiplayer() {
    return new Promise((resolve, reject) => {
        if (socket) {
            console.log("Already connected to the server.");
            resolve();
            return;
        }

        console.log('Pinging server to ensure it is awake...');

        // Ping the server to wake it up
        fetch('https://threejsshowroomproject.fly.dev/ping')
            .then(response => {
                if (response.ok) {
                    console.log('Server is awake. Initializing multiplayer environment...');

                    // reset local player before initializing socket
                    playerSystem.resetPlayer();

                    return initializeSocket();
                } else {
                    console.error('Failed to ping the server.');
                    reject(new Error('Failed to ping the server.'));
                }
            })
            .then(() => {
                resolve();
            })
            .catch(error => {
                console.error('Error pinging the server:', error);
                reject(error);
            });
    });
}

export function initializeSocket() {
    return new Promise((resolve, reject) => {
        socket = io('https://threejsshowroomproject.fly.dev/', {
            transports: ['websocket'], 
            reconnection: true,             
            reconnectionAttempts: 2,       
            reconnectionDelay: 1000,       // start with a delay of 1 second
            reconnectionDelayMax: 5000     // max delay between attempts is 5 seconds
        });
    
        socket.on('playerJoined', handlePlayerJoined);
        socket.on('playerMoved', handlePlayerMoved);
        socket.on('playerChangedScene', handlePlayerChangedScene);
        socket.on('playerLeft', handlePlayerLeft);
        socket.on('playerHit', handlePlayerHit);
        socket.on('playerFiredItem', handlePlayerFiredItem);
        socket.on('playerEquippedOrUnequippedItem', updateEnvironmentState);
        socket.on('playerInteractedWithItem', handleOtherPlayerInteractedWithItem);
        socket.on('playerReadyToEstablishWebRTCConnection', handlePlayerReadyToEstablishWebRTCConnection);
        socket.on('itemOwnershipChange', updateEnvironmentState);
        socket.on('itemReleased', updateEnvironmentState);
        socket.on('itemPickupFailed', (data) => { console.log('Item with id:', data.itemId, 'pickup failed due to: ', data.reason); });
        socket.on('itemDropFailed', (data) => { console.log('Item drop failed due to: ', data.reason); });
        socket.on('syncEnvironmentState', syncEnvironmentState);
        socket.on('youHaveBeenHit', handleYouHaveBeenHit);
        socket.on('vrControllerMoved', handleVRControllerMoved);
        socket.on('existingPlayers', handleExistingPlayers);
        socket.on('roomFullError', handleRoomFull);
        
        socket.on('connect_error', (error) => {
            console.error("Connection Error:", error);
            alert("Failed to connect to the server.");
        });

        socket.on('reconnect', () => {
            const roomId = getCurrentRoom(); // from roomManager.js
            sendDataToServer('joinRoom', { roomId: roomId });
        });
        
        socket.on('disconnect', (reason) => {
            console.log('Disconnected from server:', reason);
            stopUpdatingSpeakerIcons();
            setMultiplayerSession(false);
            if(webrtc) webrtc.closeAllConnections();
            socket = null; // Reset the socket to allow reconnection
        });

        socket.on('connect', () => {
            console.log('Connected to multiplayer server');
            setMultiplayerSession(true);
            startUpdatingSpeakerIcons();
            
            if(micAccess){
                webrtc = new WebRTCManager(socket);

                // Attempt to capture the audio stream
                webrtc.captureAudio().then(stream => {
                    let micToggleButton = document.getElementById('microphone-access');
                    if(micToggleButton) micToggleButton.checked = true;
            
                    // Audio stream captured successfully
                }).catch(error => {
                    console.error('Error capturing audio:', error);
                    alert("Unable to capture audio. Please ensure your microphone is working and permissions are granted. Audio communication will not be available.");
                }).finally(() => {
                    resolve();
                });
            }
            else resolve();
        });

        socket.on('connect_error', (error) => {
            console.error("Connection Error:", error);
            reject(new Error('Failed to establish socket connection.'));
        });

    });
}

function handleRoomFull(data) {
    alert(data.message);
}

async function spawnIfNotSelf(playerId, position, playerName, sceneName, playerAvatar) {
    if (playerId !== socket.id) { 
        await spawnPlayer(playerId, position, playerName, sceneName, playerAvatar);
    }
}

async function handleExistingPlayers(data) {

    console.log('Handle Existing Players:', data.players);

    const playersFromEnvironmentState = getEnvironmentState().players;
    const itemsFromEnvironmentState = getEnvironmentState().items;
    
    for (const player of data.players) {
        await spawnIfNotSelf(player.playerId, player.position, player.playerName, player.sceneName, player.playerAvatar);       
    }

    for (const itemId in itemsFromEnvironmentState) {
        const item = itemsFromEnvironmentState[itemId];
        if (item.itemScene === currentSceneName) {
            updateEnvironmentState(item);
        }
    }

    for (const playerId in playersFromEnvironmentState) {
        const player = playersFromEnvironmentState[playerId];
        if (player.sceneName === currentSceneName) {
            updateEnvironmentState(player);
        }
    }

 
    // When all the other player models have been spawned, send a message to the server to let it know we are ready to establish WebRTC connections
    // This will tell the other players in the room to create an offer to us
    sendDataToServer('playerReadyToEstablishWebRTCConnection');

    data.players.forEach((player, index) => {
        if (player.playerId !== socket.id) {
            setTimeout(() => {
                notificationManager.showNotification(`${player.playerName}`, 'player', 1000, 'top', 10, player.playerId);
            }, 500 * index);
        }
    })
}

async function handlePlayerJoined(data) {
    console.log('Handle Player joined:', data);
    await spawnIfNotSelf(data.playerId, data.position, data.playerName, data.sceneName, data.playerAvatar);
    updateEnvironmentState(data);

    if(data.playerId !== socket.id)notificationManager.showNotification(`${data.playerName}`, 'player', 1000, 'top', 10, data.playerId);
}

function handlePlayerReadyToEstablishWebRTCConnection(data) {
    // If the new player is not you, create an offer to the new player
    console.log('Player ready to establish WebRTC connection:', data);
    if (data.playerId !== socket.id && webrtc) {
        webrtc.createOffer(data.playerId);
        console.log('Creating voice connection offer to the new player:', data.playerId);
    }
}

function handlePlayerLeft(data) {
    // Notify other players and update UI
    notificationManager.showNotification('', 'playerRemove', 1000, 'top', 20, data.playerId);
    const playerName = players[data.playerId]?.playerName || 'A player';
    setTimeout(() => {
        notificationManager.showNotification(`${playerName} left the room.`, 'info', 1000, 'center', 10);
    }, 500);

    // Remove player from environment state
    updateEnvironmentState(data);

    // Close the WebRTC connection with the player who left
    if(webrtc) webrtc.closeConnection(data.playerId);
    removePlayer(data.playerId); // ECS and visual cleanup
}

function handlePlayerMoved(data) {
    // Update pre-allocated Vector3 and Quaternion objects
    receivedPosition.set(data.newPosition.x, data.newPosition.y, data.newPosition.z);
    targetQuaternion.set(data.newRotation[0], data.newRotation[1], data.newRotation[2], data.newRotation[3]);

    // Use the updated objects to update the player's position and rotation
    updatePlayerPosition(data.playerId, receivedPosition, targetQuaternion);
}

function handlePlayerHit(data) {
    playerHitEffect(data.hitPlayerId, data.hitPosition);
}

function handleYouHaveBeenHit(data) {
    systemManager.emitEvent('healthChanged', data.hitDamage);
}

function handlePlayerFiredItem(data) {
    if(socket.id !== data.playerId && currentSceneName === data.itemScene){
        const firePackage = {
            otherPlayerShooting: true,
            itemId: data.itemId,
            playerId: data.playerId,
            hitPosition: data.hitPosition,
            itemType: data.itemType,
            intersectedObjectId: data.intersectedObjectId,
        };
        console.log('Player fired item:', data);
        systemManager.emitEvent('fire', firePackage);
    }
}

function handleVRControllerMoved(controllerData) {
    const playersFromEnvironmentState = getEnvironmentState().players;
    const player = playersFromEnvironmentState[controllerData.playerId];
    if (player && player.sceneName === currentSceneName) updateControllerPosition(controllerData.playerId, [controllerData]);
}

export function sendDataToServer(event, data = {}, sendWithoutProcessing = false) {
    if (socket) {
        const packageToSend = { ...data, roomId: getCurrentRoom(), sceneName: currentSceneName };
        if(sendWithoutProcessing) socket.emit(event, data);
        else socket.emit(event, packageToSend);
    }
}
