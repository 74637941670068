import * as THREE from 'three';
import { createCustomShaderMaterial, getShaderNameFromMaterialTypeIndex } from './modelLoaderHelper.js';


export class InstancingManager {
    constructor() {
        this.masterInstances = new Map();
        this.instancePositions = new Map();
        this.shaderData = new Map(); // Store shader data
    }

    registerMasterInstance(type, mesh, properties) {
        this.masterInstances.set(type, mesh);

        // Also store shader data
        const shaderKey = getShaderNameFromMaterialTypeIndex(properties.materialType);

        if (shaderKey) {
            this.shaderData.set(type, shaderKey);
            console.log("Stored shader data for", type, ":", shaderKey);
        }
        mesh.visible = false; // Hide the master instance
    }

    registerInstance(type, position, rotation, scale) {
        if(!this.instancePositions.has(type)) this.instancePositions.set(type, []);
        // Store more data about each instance
        this.instancePositions.get(type).push({ position, rotation, scale, shaderName: this.shaderData.get(type) });
    }
    

    createInstancedMeshes(model) {
        this.masterInstances.forEach((masterInstanceMesh, type) => {
            //console.log(masterInstanceMesh.material.map);
    
            if (this.instancePositions.has(type)) {
                // Gather all unique shader names for the given type
                const uniqueShaders = [...new Set(this.instancePositions.get(type).map(data => data.shaderName))];
    
                uniqueShaders.forEach(shaderName => {
                    // Filter instance positions by the current shaderName
                    const filteredPositions = this.instancePositions.get(type).filter(data => data.shaderName === shaderName);
    
                    const instanceCount = filteredPositions.length;
    
                    const instancedMesh = new THREE.InstancedMesh(masterInstanceMesh.geometry, masterInstanceMesh.material, instanceCount);
                    
                    // Apply customShader to instancedMesh using shader data from InstancingManager
                    if (shaderName) {
                        console.log("Creating custom shader material for", shaderName);
                        if(shaderName === 'shader_aerial_perspective') shaderName = 'shader_aerial_perspective_instance'; // Special case for AerialPerspective shader

                        createCustomShaderMaterial(instancedMesh, shaderName);
                    }
            
                    filteredPositions.forEach((data, index) => {
                        const matrix = new THREE.Matrix4()
                                    .compose(data.position, 
                                             new THREE.Quaternion().setFromEuler(data.rotation), 
                                             data.scale);
                        instancedMesh.setMatrixAt(index, matrix);
                    });

                    instancedMesh.instanceMatrix.needsUpdate = true;
            
                    model.add(instancedMesh);
                });
            }
        });
    }

    unload() {
        // Remove and Dispose of InstancedMeshes
        this.masterInstances.forEach((masterInstanceMesh, type) => {
            if (masterInstanceMesh.parent) {
                masterInstanceMesh.parent.remove(masterInstanceMesh);
            }
            if (masterInstanceMesh.geometry) {
                masterInstanceMesh.geometry.dispose();
            }
            if (masterInstanceMesh.material) {
                masterInstanceMesh.material.dispose();
            }
        });
    
        // Reset Internal State
        this.masterInstances.clear();
        this.instancePositions.clear();
        this.shaderData.clear();
    }   
}

export const instancingManager = new InstancingManager();
