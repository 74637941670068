import { fetchDataForUserType, updateObjectInFirestore } from '../firebase/firestoreObjectManager';
import { updateMemoryCache } from '../utils/cacheManager';
import {
    selectedGLBFile,
    handleGLBFileUpload,
    isFileSizeValid,
    isValidGLB,
    MAX_FILE_SIZE_MB,
    setSelectedGLBFile,
    handleImageUpload,
    selectedImageFile,
    setSelectedImageFile,
    isValidImage,
    deleteFileFromStorage
} from '../utils/fileHandlers'; // Consolidated file handlers for better organization
import { createElement, createField, createDropzone, createTooltip, createImageUploadField } from '../utils/domUtils';
import popupManager from './popupManager';
import { showSpinner, hideSpinner } from './spinner';
import { removeSpecificDropzoneModel } from '../models/dropzoneManager';
import TextureDropManager from '../models/textureDropManager';
import { scene } from '../core/sharedState';
import { clientConfig } from '../config/clientConfig';
import notificationManager from './notificationManager';

let inProcessOfSaving = false;

function deriveMediaType(data) {

    if (data.mediaURL && data.mediaURL.includes('youtube')) data.mediaType = 'youtube';
    else data.mediaType = 'image';
    
    return data.mediaType;
}

function createFieldsForPopup(data) {

    let urlRedirect, mediaURL;

    let userTypes = [];
    if ('userTypesAvailableInThisShowroom' in clientConfig) {
        userTypes = clientConfig.userTypesAvailableInThisShowroom.userTypes.split(",").map(s => s.trim());
        userTypes.unshift("all"); // The 'all' user option is always available
    }

    if(data.urlRedirect) urlRedirect = data.urlRedirect === 'undefined' ? '' : data.urlRedirect;
    else urlRedirect = '';
    
    if(data.mediaURL) mediaURL = data.mediaURL === 'undefined' ? '' : data.mediaURL;
    else mediaURL = '';
    
    const fields = [
        createUserTypeDropdown(userTypes, data.userType),
        createField('Headline', 'text', data.headline, 'headline', 'Enter a catchy headline for your content. Keep it short and sweet.'),
        createField('Text', 'textarea', data.text, 'text', 'Write your main content here. Feel free to include details!'),
        createField('URL Redirect', 'url', urlRedirect, 'urlRedirect', 'Have a specific webpage you want to redirect users to? Paste the URL here.'),
        createField('Media URL', 'url', mediaURL, 'mediaURL', 'Add a link to an image or a YouTube video that complements your content.')
    ];

    if(data.dropType){
        if (data.dropType === 'dropzone') {
            fields.push(createDropzone()); // Add dropzone field
        } else if (data.dropType === 'textureDrop') {
            fields.push(createImageUploadField()); // Add image upload field
        }
    }

    return fields;
}

function createUserTypeDropdown(userTypes, selectedType) {
    const tooltipText = "Select the type of user this content is available to.";
    const label = createElement('label', { className: 'field-label', innerText: 'Available for' });
    
    const tooltip = createTooltip(tooltipText);
    label.appendChild(tooltip);

    const selectElement = createElement('select', { name: 'userType' });

    // Populate the dropdown with options
    userTypes.forEach(type => {
        const option = createElement('option', { value: type }, type);
        if (type === selectedType) {
            option.selected = true;
        }
        selectElement.appendChild(option);
    });

    const userTypeField = createElement('div', { className: 'field-container' }, label, selectElement);

    return userTypeField;
}

function setupDropzoneForPopup(data, contentContainer, objectName) {
    let statusMessage;

    // Setup for dropzone or textureDrop
    if (['dropzone', 'textureDrop'].includes(data.dropType)) {
        const isDropzone = data.dropType === 'dropzone';
        setSelectedGLBFile(null);
        setSelectedImageFile(null); // Reset selected image file

        const dropzone = contentContainer.querySelector('.dropzone');
        const fileInput = contentContainer.querySelector('input[type="file"]');
        statusMessage = contentContainer.querySelector('.status-message');

        setDropzoneCurrentValues(contentContainer, data);
        
        // Clear button functionality
        const clearButton = contentContainer.querySelector('button[type="button"]');
        if (clearButton) {
            clearButton.addEventListener('click', () => {
                if (inProcessOfSaving) return;

                console.log("Clearing model or texture...");
                const proceed = isDropzone ? handleModelRemoval(objectName, data) : handleTextureRemoval(objectName, data);
                if (!proceed) {
                    console.log("User cancelled model or texture removal.");
                    return;
                }

                dropzone.innerText = isDropzone ? 'Drag & Drop .glb file here or click to upload.' : 'Drag & Drop image here or click to upload.';
                fileInput.value = "";  // Reset the file input
            });
        }
    }
    return statusMessage;
}

async function handleFormSubmission(e, data, statusMessage, objectName) {
    e.preventDefault();

    if (inProcessOfSaving) return;

    // File validation for GLB and Image
    if(data.dropType){
        console.log("Data.dropType is: ", data.dropType);
        if ((data.dropType === 'dropzone' && selectedGLBFile) || (data.dropType === 'textureDrop' && selectedImageFile)) {
            const file = data.dropType === 'dropzone' ? selectedGLBFile : selectedImageFile;
            const isValidFile = data.dropType === 'dropzone' ? isValidGLB(file) : isValidImage(file);
    
            if (!isValidFile) {
                const fileType = data.dropType === 'dropzone' ? '.glb or .gltf' : 'an image';
                statusMessage.innerText = `Invalid file type. Please provide ${fileType}.`;
                alert(`Invalid file type. Please provide ${fileType}.`);
                return;
            } else if (!isFileSizeValid(file)) {
                statusMessage.innerText = `File is too large. Maximum allowed size is ${MAX_FILE_SIZE_MB}MB.`;
                alert(`File is too large. Maximum allowed size is ${MAX_FILE_SIZE_MB}MB.`);
                return;
            }
        }
    }

    popupManager.closePopup(popupManager.activePopup);
    showSpinner(document.body);

    const formData = new FormData(e.target);
    let updatedData = Object.fromEntries(formData);

    updatedData.userType = formData.get('userType'); 

    if (updatedData.text) {
        updatedData.text = updatedData.text.replace(/\n/g, '<br>');
    }

    // Handle file upload for GLB or Image
    if (data.dropType === 'dropzone') {
        updatedData.dropType = 'dropzone';
        if(selectedGLBFile) updatedData = await handleFileUpload(updatedData, objectName, statusMessage, true, data.modelURL);
        if(data.modelURL) data.modelURL = updatedData.modelURL;
    } 
    else if (data.dropType === 'textureDrop') {
        updatedData.dropType = 'textureDrop';
        if (selectedImageFile) {
            updatedData = await handleFileUpload(updatedData, objectName, statusMessage, false, data.textureURL);
            console.log("TextureDrop updatedData is: ", updatedData);
            if (updatedData.textureURL) {
                // Apply texture to dropzone
                const textureDropObject = scene.getObjectByName(objectName);
                console.log("textureDropObject is: ", textureDropObject);
                if (textureDropObject) {
                    await TextureDropManager.loadAndApplyTexture(textureDropObject, updatedData.textureURL);
                }
            }
        }
    }

    if (!updatedData) return; // Exit if file upload failed

    try {
        await updateObjectInFirestore(objectName, updatedData);
        console.log("Data saved successfully!");
        updateMemoryCache(objectName, updatedData);
        hideSpinner(document.body);
        notificationManager.showNotification("Saved successfully!", 'info', 3000, 'center', 10);
    } catch (error) {
        console.error("Error updating Firestore:", error);
        hideSpinner(document.body);
    }
}

async function handleFileUpload(data, objectName, statusMessage, isGLB, oldFileURL) {
    try {
        const fileURL = isGLB ? await handleGLBFileUpload(objectName, statusMessage, oldFileURL) : await handleImageUpload(objectName, statusMessage, oldFileURL);
        return isGLB ? { ...data, modelURL: fileURL } : { ...data, textureURL: fileURL };
    } catch (error) {
        console.error("Error uploading file:", error);
        hideSpinner(document.body);
        return null;
    }
}

async function handleModelRemoval(dropzoneName, data) {
    // Removal functionality for model
    return handleFileRemoval(dropzoneName, data, true);
}

async function handleTextureRemoval(dropzoneName, data) {
    // Removal functionality for texture
    console.log("Removing texture...");
    TextureDropManager.removeTextureFromDropzone(dropzoneName);
    return handleFileRemoval(dropzoneName, data, false);
}

async function handleFileRemoval(dropzoneName, data, isModel) {
    const fileType = isModel ? 'model' : 'texture';
    const confirmation = confirm(`Are you sure you want to remove the ${fileType}?`);
    if (!confirmation) return false;

    try {
        inProcessOfSaving = true;
        if (isModel) {
            await deleteFileFromStorage(data.modelURL);
            removeSpecificDropzoneModel(dropzoneName);
        } else {
            await deleteFileFromStorage(data.textureURL);
            // Add logic for removing texture
        }

        const updatedData = { ...data, [isModel ? 'modelURL' : 'textureURL']: null };

        await updateObjectInFirestore(dropzoneName, updatedData);
        updateMemoryCache(dropzoneName, updatedData);
        alert(`${fileType.charAt(0).toUpperCase() + fileType.slice(1)} removed successfully.`);
        inProcessOfSaving = false;
        return true;
    } catch (error) {
        inProcessOfSaving = false;
        console.error(`Error removing ${fileType}:`, error);
        alert(`Failed to remove ${fileType}.`);
    }
}

function setDropzoneCurrentValues(container, data) {
    const dropzone = container.querySelector('.dropzone');

    if (dropzone) {
        if (data.modelURL) {
            const cleanedModelURL = getFileNameFromURL(data.modelURL);
            dropzone.innerText = `Current model: ${cleanedModelURL}\nDrag & Drop to replace, or click to upload a new .glb file.`;
        } else if (data.textureURL) {
            const cleanedTextureURL = getFileNameFromURL(data.textureURL);
            dropzone.innerText = `Current texture: ${cleanedTextureURL}\nDrag & Drop to replace, or click to upload a new image.`;
        } else {
            dropzone.innerText = data.dropType === 'dropzone' ? 'Drag & Drop .glb file here or click to upload.' : 'Drag & Drop image here or click to upload.';
        }
    }
}

function updatePopupFields(container, data) {
    if (!data) return;

    const headlineField = container.querySelector('input[name="headline"]');
    const textField = container.querySelector('textarea[name="text"]');
    const urlRedirectField = container.querySelector('input[name="urlRedirect"]');
    const mediaURLField = container.querySelector('input[name="mediaURL"]');
    setDropzoneCurrentValues(container, data);

    headlineField.value = data.headline || '';
    textField.value = data.text ? data.text.replace(/<br\s*\/?>/g, '\n') : '';
    urlRedirectField.value = data.urlRedirect || '';
    mediaURLField.value = data.mediaURL || '';
}

function getFileNameFromURL(url) {
    try {
        const urlObj = new URL(url);
        const path = urlObj.pathname;

        // Decode URI components and split by '/'
        const segments = decodeURIComponent(path).split('/');

        // Get the last segment (filename)
        let filename = segments.pop();

        // Remove 'textures/' and 'models/' segments if present
        filename = filename.replace('textures/', '').replace('models/', '');

        return filename;
    } catch (error) {
        console.error('Error parsing URL:', error);
        return '';
    }
}

function onUserTypeChange(e, contentContainer, objectName) {
    const selectedUserType = e.target.value;
    fetchDataForUserType(objectName, selectedUserType).then(updatedData => {
        updatePopupFields(contentContainer, updatedData);
    });
}

function onFormSubmit(e, data, statusMessage, objectName) {
    handleFormSubmission(e, data, statusMessage, objectName);
}

function onPopupClose(contentContainer) {
    // Cleanup function to remove event listeners
    const userTypeDropdown = contentContainer.querySelector('select[name="userType"]');
    
    userTypeDropdown.removeEventListener('change', onUserTypeChange);
    contentContainer.removeEventListener('submit', onFormSubmit);

    console.log("edit Popup closed and successfully removed event listeners.");
}

export function createEditPopup(data, objectName) {
    console.log("Creating edit popup with data: ", data);
    deriveMediaType(data);
    const fields = createFieldsForPopup(data);
    const contentContainer = createElement('form', { className: 'editPopup-content-container' }, ...fields);

    const userTypeDropdown = contentContainer.querySelector('select[name="userType"]');
    userTypeDropdown.addEventListener('change', (e) => onUserTypeChange(e, contentContainer, objectName));

    const saveButton = createElement('button', { innerText: 'Save', type: 'submit' });
    contentContainer.appendChild(saveButton);

    const popup = popupManager.createPopup(contentContainer, ['editPopup']);
    const statusMessage = setupDropzoneForPopup(data, contentContainer, objectName);

    contentContainer.addEventListener('submit', (e) => onFormSubmit(e, data, statusMessage, objectName));
    contentContainer.addEventListener('click', (e) => e.stopPropagation());

    popupManager.showPopup(popup, () => onPopupClose(contentContainer));

    return popup;
}
