import { System } from './system';
import * as THREE from 'three';
import { manager } from '../ui/loadingManager.js';
import { listener } from '../core/cameraSetup.js';
import { canPlay, renderer } from '../core/sharedState.js';

export class SoundSystem extends System {
    constructor(entityManager, systemManager) {
        super(entityManager, systemManager);
        this.requiredComponents = ['SoundComponent'];
        this.audioLoader = new THREE.AudioLoader();
        this.soundFolder = '/sounds/';
        this.extensions = ['mp3', 'wav', 'ogg', 'aac', 'm4a'];
        this.sounds = {};
        this.soundsThatWillPlayAtStart = [];
        this.systemManager.onEvent('playSound', this.playSound.bind(this));
        this.systemManager.onEvent('stopSound', this.stopSound.bind(this));
        this.systemManager.onEvent('adjustVolume', this.adjustVolume.bind(this));
        this.systemManager.onEvent('disposeSound', this.disposeSound.bind(this));
        this.systemManager.onEvent('cleanUp', this.resetSoundsForSceneChange.bind(this));
        this.systemManager.onEvent('initializeSound', this.initializeSound.bind(this));
    }

    update(deltaTime) {
        
        this.getEntities().forEach(entity => {
            const soundComponent = entity.getComponent('SoundComponent');
                        
            if (soundComponent.playAtStart && this.sounds[soundComponent.name] && canPlay) {
                this.playSound(soundComponent.name);
                soundComponent.playAtStart = false;
            }
        });
    }

    initializeSound(soundComponent) {
        
        const { name, playAtStart, positional, volume, loop, attenuation, object } = soundComponent;
        const soundOptions = { volume, loop, positional, attenuation, object };

        const tryLoadSound = (index) => {
            if (index >= this.extensions.length) {
                console.error(`Error initializing sound: ${name}, no valid file found`);
                return;
            }

            const path = `${this.soundFolder}${name}.${this.extensions[index]}`;
            this.loadSound(name, path, soundOptions)
                .then(() => playAtStart && this.soundsThatWillPlayAtStart.push(name))
                .catch(() => tryLoadSound(index + 1));
        };

        tryLoadSound(0);    
    }

    async loadSound(name, path, soundOptions) {
        if (this.sounds[name]){
            console.warn(`Sound "${name}" already exists.`);
            return;
        }

        manager.itemStart(path);
        await new Promise((resolve, reject) => {
            this.audioLoader.load(path, buffer => {
                resolve(this.createSound(name, buffer, soundOptions));
            }, undefined, error => {
                console.error(`Error loading sound: ${name}`, error);
                manager.itemEnd(name);
                reject(error);
            });
        });
    }

    createSound(name, buffer, soundOptions) {
        const sound = soundOptions.positional ? this.createPositionalAudio(soundOptions.attenuation, soundOptions.object) : new THREE.Audio(listener);
        sound.setBuffer(buffer);
        sound.setLoop(soundOptions.loop);
        sound.setVolume(soundOptions.volume);

        console.log(`Sound ${name} loaded and created`);

        if (!this.sounds[name]) {
            this.sounds[name] = [sound];
        } else {
            this.sounds[name].push(sound);
        }

        manager.itemEnd(name);
    }

    createPositionalAudio(attenuation, object) {
        const sound = new THREE.PositionalAudio(listener);
        sound.setRolloffFactor(3); 
        sound.setDistanceModel('inverse'); // 'linear', 'inverse', 'exponential
        sound.setMaxDistance(100);
        sound.setRefDistance(attenuation);
        object.add(sound);

        sound.sourceObject = object; // Store a reference to the object the sound is attached to

        return sound;
    }

    playSound(name) {
        if (!this.sounds[name] || this.sounds[name].length === 0) {
            console.warn(`Sound "${name}" not found.`);
            return;
        }

        let availableSound = this.sounds[name].find(sound => !sound.isPlaying);
        if (!availableSound && this.sounds[name].length < 10) {
            console.log(`Creating new sound for ${name}`);
            const originalSound = this.sounds[name][0];
            availableSound = this.copySound(originalSound);
            this.sounds[name].push(availableSound);
        }

        if (availableSound) {
            if (availableSound.isPlaying) {
                availableSound.stop();
            }
            availableSound.set
            availableSound.play();
        }
    }

    copySound(originalSound) {
        const newSound = originalSound instanceof THREE.PositionalAudio ? new THREE.PositionalAudio(listener) : new THREE.Audio(listener);
        newSound.setBuffer(originalSound.buffer);
        newSound.setLoop(originalSound.getLoop());
        newSound.setVolume(originalSound.getVolume());
        if (originalSound instanceof THREE.PositionalAudio) {
            newSound.setDistanceModel(originalSound.getDistanceModel());
            newSound.setMaxDistance(originalSound.getMaxDistance());
            
            // If the original sound was attached to an object, attach the new sound to the same object
            if (originalSound.sourceObject) {
                originalSound.sourceObject.add(newSound);
                newSound.sourceObject = originalSound.sourceObject; // Carry over the reference to the new sound
            }
        }
        return newSound;
    }

    stopSound(name) {
        if (!this.sounds[name]) return;
        this.sounds[name].forEach(sound => sound.stop());
    }

    adjustVolume(volume, name = null) {
        if (name && this.sounds[name]) {
            this.sounds[name].forEach(sound => sound.setVolume(volume));
        } else {
            for (let key in this.sounds) {
                this.sounds[key].forEach(sound => sound.setVolume(volume));
            }
        }
    }

    resetSoundsForSceneChange() {
        console.log(renderer.info);
        for (let name in this.sounds) {
            if (this.sounds[name]){
                this.sounds[name].forEach(sound => {
                    if (sound.isPlaying) sound.stop();
                });
            }
        }
        this.soundsThatWillPlayAtStart.length = 0;
    }

    disposeSound(name) {
        if (!this.sounds[name]) return;
        this.sounds[name].forEach(sound => {
            if (sound.isPlaying) sound.stop();
            if(sound instanceof THREE.PositionalAudio) {
                sound.parent.remove(sound);
            }
            if (sound instanceof THREE.Audio) {
                sound.parent.remove(sound);
            }
            sound.buffer.dispose();

        });
        delete this.sounds[name];
    }
}