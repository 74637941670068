import * as THREE from 'three';
import { scene } from '../core/sharedState.js';
import { AnimationComponent } from '../ECS-components/animationComponent.js';
import { animationSystem } from '../ECS-utils/managerECS.js';
import { gltfLoader } from './modelLoader.js';
import { conditionLibrary } from '../animations/conditionLibrary.js';
import { CharacterStateComponent } from '../ECS-components/characterStateComponent.js';
import { PositionComponent } from '../ECS-components/positionComponent.js';
import { materialManager } from '../shaders/materialManager.js';
import { getNpcAnimationFromIndex, getNpcIdFromIndex, getNpcTypeFromIndex } from './modelLoaderHelper.js';

class CharacterManager {
  constructor() {
    this.characterCache = {};  // Cache to store loaded character GLTF data
    this.characters = {};  // Object to map character IDs to character instances
    this.characterEntities = [];  // Array to store character entities
    this.characterHoldersFromBlender = [];
    this.lastReactionTime = 0;  // Stores the last time the NPC reacted to the player
  }

  async setupCharacters(entityManager) {

    const loadPromises = [];
    this.characterHoldersFromBlender.forEach(child => {
        loadPromises.push(this.loadCharacterAsync(child, entityManager));
    });

    // Wait for all characters to load
    await Promise.all(loadPromises);
    console.log("All characters loaded");

    // When all characters are loaded, run the initializeTestAnimations function
    this.initializeTestAnimations();
}

  loadCharacterAsync(child, entityManager) {
    return new Promise((resolve) => {
        console.log("Loading character from Blender");
        const properties = child.userData.showroom_properties;
        const npcId = getNpcIdFromIndex(properties['npc-npcId']);
        const npcSex = getNpcTypeFromIndex(properties['npc-npcSex']);
        const npcAnimation = getNpcAnimationFromIndex(properties['npc-npcAnimation']);
        const npcLOD = properties['npc-npcLOD'];

        this.loadCharacter(properties.npcTitle, npcId, child.position, child.rotation, child.scale, npcLOD, (character) => {
            const mixer = new THREE.AnimationMixer(character);

            console.log("Character loaded from Blender");

            // Create an entity for this character
            const entity = entityManager.createEntity();  
            const animationComponent = new AnimationComponent(mixer);
            const positionComponent = new PositionComponent(child.position.x, child.position.y, child.position.z);
            const characterStateComponent = new CharacterStateComponent();

            if(npcSex && npcSex === 'male') {characterStateComponent.setSex(npcSex);}

            entity.addComponent(animationComponent);
            entity.addComponent(positionComponent);
            entity.addComponent(characterStateComponent);

            // Initialize the state machine for this character
            animationSystem.initializeStateMachineForCharacter(animationComponent);
            
            //Set the initial state for this character based on the userData from Blender
            if (animationComponent.stateMachine.states && npcAnimation) {
                animationComponent.stateMachine.setState(npcAnimation, animationComponent.mixer);
            }

            // Add this character entity to the array of character entities
            this.characterEntities.push(entity);

            resolve();  // Resolve the promise when this character is loaded
        });
    });
  }

  // Function to load, position, and rotate a character with a specified level of detail
  loadCharacter(id, urlID, position, rotation, scale, lod, onSuccess, onError) {
    // Construct the URL using the uniqueId
    const url = `https://api.readyplayer.me/v1/avatars/${urlID}.glb?meshLod=${lod}`;

    // Check if the character ID already exists
    if (this.characters[id]) {
        console.log("Character already exists");
        const character = this.characters[id];

        // Update position and rotation
        character.position.set(position.x, position.y, position.z);
        character.rotation.set(rotation.x, rotation.y, rotation.z);
        character.scale.set(scale.x, scale.y, scale.z);

        // Ensure character is added to the scene
        if (!scene.children.includes(character)) {
            scene.add(character);
        }

        // Call the onSuccess callback with the existing character
        if (onSuccess) {
            onSuccess(character);
        }
    } else {
        // Character ID does not exist, check if the character GLTF data is in the cache
        if (this.characterCache[url]) {
            console.log("Character already in cache");
            const gltf = this.characterCache[url];
            const character = gltf.scene.clone();  // Clone the character from the cached GLTF data

            // Set the position and rotation
            character.position.set(position.x, position.y, position.z);
            character.rotation.set(rotation.x, rotation.y, rotation.z);
            character.scale.set(scale.x, scale.y, scale.z);

            // Add character to the scene
            scene.add(character);

            // Store character instance by ID
            this.characters[id] = character;

            // Call the onSuccess callback with the cloned character
            if (onSuccess) {
                onSuccess(character);
            }
        } else {
            console.log("Character not in cache");
            // Character GLTF data not in cache, load it
            try {
            gltfLoader.load(
                url,
                (gltf) => {
                    const character = gltf.scene;

                    // Set its material to phong
                    character.traverse((child) => {
                        if (child.isMesh) {
                            materialManager.setToMostPerformantShader(child, false, false);
                        }
                    });

                    // Set the position and rotation
                    character.position.set(position.x, position.y, position.z);
                    character.rotation.set(rotation.x, rotation.y, rotation.z);
                    character.scale.set(scale.x, scale.y, scale.z);

                    // Add character to the scene
                    scene.add(character);

                    // Store character GLTF data in cache
                    this.characterCache[url] = gltf;

                    // Store character instance by ID
                    this.characters[id] = character;

                    // Call the onSuccess callback with the loaded character
                    if (onSuccess) {
                        onSuccess(character);
                    }
                },
                undefined,
                (error) => {
                    console.error('An error occurred while loading the character:', error);

                    // Call the onError callback with the error
                    if (onError) {
                        onError(error);
                    }
                    

                }
            );

            } catch (error) {
                console.error('An error occurred while loading the character:', error);

                // Call the onError callback with the error
                if (onError) {
                    onError(error);
                }
            }
        }
    }
  }

  initializeTestAnimations() {

    this.characterEntities.forEach(entity => {
        
        const animationComponent = entity.getComponent('AnimationComponent');
        const characterStateComponent = entity.getComponent('CharacterStateComponent');
        
        const idle1State = animationComponent.stateMachine.getStateByName("idle1");
        const idle2State = animationComponent.stateMachine.getStateByName("idle2");
        const idle3State = animationComponent.stateMachine.getStateByName("idle3");
        const reactionState = animationComponent.stateMachine.getStateByName("reacting");

        idle1State.addTransition(idle2State.name, () => characterStateComponent.isMood(characterStateComponent.MoodEnum.IDLE2));
        idle1State.addTransition(idle3State.name, () => characterStateComponent.isMood(characterStateComponent.MoodEnum.IDLE3));
        idle1State.addTransition(reactionState.name, conditionLibrary.getCondition('isPlayerClose'));
        
        idle2State.addTransition(idle1State.name, () => characterStateComponent.isMood(characterStateComponent.MoodEnum.IDLE1));
        idle2State.addTransition(idle3State.name, () => characterStateComponent.isMood(characterStateComponent.MoodEnum.IDLE3));
        idle2State.addTransition(reactionState.name, conditionLibrary.getCondition('isPlayerClose'));
        
        idle3State.addTransition(idle1State.name, () => characterStateComponent.isMood(characterStateComponent.MoodEnum.IDLE1));
        idle3State.addTransition(idle2State.name, () => characterStateComponent.isMood(characterStateComponent.MoodEnum.IDLE2));
        idle3State.addTransition(reactionState.name, conditionLibrary.getCondition('isPlayerClose'));
        
        reactionState.addTransition(idle1State.name, () => characterStateComponent.isMood(characterStateComponent.MoodEnum.IDLE1));
        reactionState.addTransition(idle2State.name, () => characterStateComponent.isMood(characterStateComponent.MoodEnum.IDLE2));
        reactionState.addTransition(idle3State.name, () => characterStateComponent.isMood(characterStateComponent.MoodEnum.IDLE3));
        
        // Start the mood change simulation for this character
        conditionLibrary.startMoodChange(entity, animationComponent);
    });
  }

  cleanup() {
    // Iterate over each character and perform cleanup
    for (const id in this.characters) {
        const character = this.characters[id];
        
        // Remove the character from the scene
        if (scene.children.includes(character)) {
            scene.remove(character);
        }

        // Dispose of character materials and geometries
        character.traverse((node) => {
            if (node.isMesh) {
                if (node.geometry) {
                    node.geometry.dispose();
                }
                if (node.material) {
                    if (Array.isArray(node.material)) {
                        node.material.forEach(material => material.dispose());
                    } else {
                        node.material.dispose();
                    }
                }
            }
        });
    }

    // Clear character caches and arrays
    this.characterCache = {};
    this.characters = {};
    this.characterEntities = [];
    this.characterHoldersFromBlender = [];
}
}

export const characterManager = new CharacterManager();
