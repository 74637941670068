// notifications.js
import * as THREE from 'three';
import { createElement, getCSSVariableValue } from '../utils/domUtils';

let activeNotification = null;

export function showNotification(targetElement, { message, type = 'info', duration = 3000, position = 'top', fontSize = 25 }) {

    // Immediately remove any existing notification
    if (activeNotification) {
        slideOut(activeNotification);
    }

    // Create a new notification element with common styles
    const notification = createNotificationElement(message, type, fontSize);
    positionNotification(notification, position);
    targetElement.appendChild(notification);
    activeNotification = notification;

    // Slide in from the left
    slideIn(notification, 'left');

    // Set timeout for slide out
    setTimeout(() => {
        if (notification === activeNotification) {
            slideOut(notification);
            activeNotification = null;
        }
    }, duration);
}

function createNotificationElement(message, type, fontSize) {
    // Create container with flex layout
    const container = createElement('div', {
        className: 'notification-flex-container',
    });

    // Create and append text element
    const textElement = createElement('span', {
        className: 'notification-text',
        innerText: message,
        style: `font-size: ${fontSize}px;`
    });
    container.appendChild(textElement);

    // Create and append button for 'player' notifications (Please help with handle the visual part here. I imagine a mic icon or something similar, that can be clicked on off (white = on, grey = off))
    if (type === 'player') {
        const button = createElement('button', {
            className: 'mute-button',
            innerHTML: '&#x1F399;', // Unicode microphone icon
        });
        button.onclick = () => handlePlayerNotificationClick(button);
        container.appendChild(button);
    }

    return createElement('div', {
        className: `notification ${type}`,
    }, container);
}

function positionNotification(notification, position) {
    notification.style.top = position === 'top' ? '10%' : position === 'bottom' ? '90%' : '50%';
    notification.style.transform = 'translate(-50%, -50%) scale(0)';
}

function slideIn(element, direction) {
    element.style[direction] = '0%';
    element.style.opacity = '0';
    setTimeout(() => {
        element.style.transition = `${direction} 0.5s ease, opacity 0.5s ease, transform 0.5s ease`;
        element.style[direction] = '50%';
        element.style.opacity = '1';
        element.style.transform = 'translate(-50%, -50%) scale(2)';
    }, 10);
}

function slideOut(element) {
    element.style.left = '100%';
    element.style.opacity = '0';
    element.style.transform = 'translate(-50%, -50%) scale(0)';
    element.addEventListener('transitionend', () => element.remove());
}

