// Purpose: Specialized for creating popups with specific content (images, videos, text).
// Responsibility: Include functions to generate different media elements and assemble them into a popup.
// Interaction with popupManager.js: Utilize popupManager to handle the display and lifecycle of these popups.

import DOMPurify from 'dompurify';
import { cameraRig, isAdminMode } from '../core/sharedState';
import { createEditPopup } from './editPopup';
import popupManager from './popupManager'; 
import { getMediaTypeFromURL } from '../utils/mediaTypeDetector';
import { createElement } from '../utils/domUtils';
import * as THREE from 'three';
import { setPlayerPosition } from '../core/playerMovement';
import { systemManager } from '../ECS-utils/managerECS';

let activeData = null;
let activeObjectName = null;

function createMediaElement(mediaType, mediaURL) {
    switch (mediaType) {
        case 'image':
            return createElement('img', { src: mediaURL, alt: 'media' });
        case 'video':
            return createElement('video', { src: mediaURL, controls: true });
        case 'youtube':
            const videoID = new URL(mediaURL).searchParams.get('v');
            return createElement('iframe', {
                width: "560",
                height: "315",
                src: `https://www.youtube.com/embed/${videoID}?autoplay=1`,
                frameBorder: "0",
                allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                allowFullscreen: true
            });
    }
}

function createPopupContent(data) {
    const contentElements = [];

    if (data.headline) {
        contentElements.push(createElement('h2', { innerHTML: DOMPurify.sanitize(transformedText(data.headline)) }));
    }

    if (data.text) {
        contentElements.push(createElement('div', { innerHTML: DOMPurify.sanitize(transformedText(data.text)) }));
    }

    const mediaType = getMediaTypeFromURL(data.mediaURL);
    if (mediaType && data.mediaURL) {
        contentElements.push(createMediaElement(mediaType, data.mediaURL));
    }

    if (data.buttons && Array.isArray(data.buttons)) {
        data.buttons.forEach(button => {
            const btn = createElement('button', { innerText: button.buttonName, className: 'edit-button' });
            btn.addEventListener('click', () => {
                executeButtonFunction(button.buttonType, button.buttonFunction, button.buttonFunctionArgs);
            });
            contentElements.push(btn);
        });
    }

    return createElement('div', { className: 'showroomPopup-content-container' }, contentElements);
}

export function createPopup(data, objectName, callbackAtClose = null, dontAdjustPointerLock = false) {

    activeData = data;
    activeObjectName = objectName;
    
    if(popupManager.activePopup) {
        popupManager.closePopup(popupManager.activePopup, dontAdjustPointerLock);
        return;
    }

    const content = createPopupContent(data);
    const popup = popupManager.createPopup(content, ['showroomPopup']);

    // Add edit button if in admin mode
    if (isAdminMode) {
        const editButton = createElement('button', { innerText: 'EDIT', className: 'edit-button' }, []);
        editButton.addEventListener('click', editButtonHandler);
        popup.appendChild(editButton);
    }

    popupManager.showPopup(popup, callbackAtClose, dontAdjustPointerLock);

    return popup;
}

function editButtonHandler(e) {
    e.stopPropagation();
    popupManager.closePopup(popupManager.activePopup);
    createEditPopup(activeData, activeObjectName);

    // remove event listener
    e.target.removeEventListener('click', editButtonHandler);
}

function transformedText(text) {
    return text.replace(/\[p\]/g, "<p>").replace(/\[\/p\]/g, "</p>")
    .replace(/\[ul\]/g, "<ul>").replace(/\[\/ul\]/g, "</ul>")
    .replace(/\[li\]/g, "<li>").replace(/\[\/li\]/g, "</li>")
    .replace(/\n/g, "<br>"); // This line replaces newline characters with <br> tags
}

function executeButtonFunction(buttonType, buttonFunction, buttonFunctionArgs) {

    // Teleport button function
    if (buttonType === 'goto' && buttonFunction === 'teleport') {
        const args = parseTeleportFunctionArgs(buttonFunctionArgs);

        if (args) {
            
            const [positionArgs, rotationArgs] = args;
            const targetPosition = new THREE.Vector3(...positionArgs);


            const targetRotation = new THREE.Euler(...rotationArgs.map(r => THREE.MathUtils.degToRad(r)));

            setPlayerPosition(targetPosition.x, targetPosition.y, targetPosition.z);
            cameraRig.rotation.copy(targetRotation);

        }
    }

    // Start learning module button function
    if (buttonType === 'goto' && buttonFunction === 'startLearningModule') {
        const args = buttonFunctionArgs.split(',');
        const learningModuleId = args[0].trim();
        const modeId = args[1].trim();
        const objectName = args[2].trim();

        const learningPackage = {moduleId: learningModuleId, modeId: modeId, objectName: objectName};
        systemManager.emitEvent('startLearningModule', learningPackage);
    }
}

function parseTeleportFunctionArgs(argsString) {
    const regex = /\(([^)]+)\)/g;
    let match;
    let parsedArgs = [];

    while (match = regex.exec(argsString)) {
        parsedArgs.push(match[1].split(',').map(n => parseFloat(n.trim())));
    }

    return parsedArgs.length === 2 ? parsedArgs : null;
}
