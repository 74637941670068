import * as THREE from 'three';
import { Tween } from '@tweenjs/tween.js';
import { systemManager } from '../ECS-utils/managerECS.js';
import { QuizUI } from './quizUI';


class QuizManager extends EventTarget {
  constructor(scene, camera, renderer, quizData) {
    super();
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    this.ui = null;
    this.currentQuestionIndex = 0;
    this.score = 0;
    this.startScale = 0.001;
    this.endScale = 1.5;
    this.quizRunning = false;
    this.isAnimating = false;
    this.quizObject = null;
    this.positionSet = false;
    this.quizData = quizData;
  }
  
  initializeQuiz(object, duration) {

    // Clear any existing timers
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }

    if (!this.ui) {
        this.quizObject = object;
        this.ui = new QuizUI(this.handleAnswerClick.bind(this));
        this.ui.createUI(this.scene, this.camera);
        this.ui.uiGroup.scale.set(this.startScale, this.startScale, this.startScale);     
    } 
    else {
        if(!this.quizRunning && !this.isAnimating) this.ui.resetQuiz();
    }

      
    if(!this.quizRunning && !this.isAnimating) {
      this.currentQuestionIndex = 0; // Reset current question index
      this.score = 0; // Reset score
      this.loadQuestion(this.currentQuestionIndex); // Load the first question
      if(!this.positionSet) this.setPositionAndRotation(object);
      this.quizRunning = true;
    }

    this.timerId = setTimeout(() => {
        this.scaleQuizUI(this.startScale, this.endScale, 300);
    }, duration);
}


  setPositionAndRotation(object) {
    console.log("setting position as it has not been set yet");
    let targetObject = object;
    object.traverse(child => {
        const properties = child.userData.showroom_properties;
        if(properties && properties.Placement) {
            targetObject = child;
        }
    });

    // Use getWorldPosition and getWorldQuaternion to get the world position and rotation
    this.ui.uiGroup.position.copy(targetObject.getWorldPosition(new THREE.Vector3()));
    this.ui.uiGroup.quaternion.copy(targetObject.getWorldQuaternion(new THREE.Quaternion()));
    this.positionSet = true;
  }

  scaleQuizUI(startScaleValue, endScaleValue, duration) {
    const startScale = { x: startScaleValue, y: startScaleValue, z: startScaleValue };
    const endScale = { x: endScaleValue, y: endScaleValue, z: endScaleValue };
    new Tween(startScale)
        .to(endScale, duration) // The duration of the animation, in milliseconds
        .onStart(() => {
            // Set isAnimating to true when the animation starts
            this.isAnimating = true;
        })
        .onUpdate(() => {
            this.ui.uiGroup.scale.set(startScale.x, startScale.y, startScale.z);
        })
        .onComplete(() => {
            // Set isAnimating to false when the animation completes
            this.isAnimating = false;
        })
        .start();
  }
  

  loadQuestion(index) {
    const questionData = this.quizData[index];
    this.ui.updateUI(questionData);
  }

  handleAnswerClick(answerIndex) {
    // Return early if the quiz has ended or an answer is being processed
    if(this.ui.isAnswering || this.currentQuestionIndex >= this.quizData.length) return;
    this.ui.isAnswering = true;
    systemManager.emitEvent('playSound', 'itemPickup');

    const questionData = this.quizData[this.currentQuestionIndex];
    const isCorrect = questionData.correctAnswerIndex === answerIndex;

    if (isCorrect) {
        this.score++; 
    } else {
    }
  
    this.ui.indicateAnswer(isCorrect, answerIndex, () => {
        this.currentQuestionIndex++;
        if (this.currentQuestionIndex < this.quizData.length) {
            this.loadQuestion(this.currentQuestionIndex);
        } else {
            this.endQuiz();
        }
    });
}

  endQuiz() {
    this.ui.showScore(this.score, this.quizData.length); 
    //add timer so the user can see the score for a short time, then call scaleQuizUI to scale the UI back down
    setTimeout(() => {
        this.scaleQuizUI(this.endScale, this.startScale, 300);
        this.quizRunning = false;
        // Dispatch a custom event to inform that the quiz has ended.
        if(this.quizObject) {
          this.dispatchEvent(new Event('quizEnded'));
      }
      
    }, 4000);
  }

  dispose() {
    // Remove any event listeners
    // You can remove custom event listeners like quizEnded here if any

    // Dispose internal resources
    if (this.ui) {
      console.log("disposing quiz");
        this.ui.destroyUI(this.scene);
    }
    
    // Clear timers
    if (this.timerId) {
        clearTimeout(this.timerId);
    }
    
    // Nullify objects to free up memory
    this.scene = null;
    this.camera = null;
    this.renderer = null;
    this.ui = null;
}
}

export { QuizManager };
