import { System } from './system.js';
import * as THREE from 'three';
import { PulseTypes } from '../ECS-components/pulseComponent.js';

export class PulseSystem extends System {
    constructor(entityManager, systemManager) {
        super(entityManager, systemManager);
        this.requiredComponents = ['RenderComponent', 'PulseComponent'];
        this.tempColor = new THREE.Color();
    }

    update(deltaTime) {
        const entities = this.getEntities();


        entities.forEach(entity => {
            const renderComponent = entity.getComponent('RenderComponent');
            const pulseComponent = entity.getComponent('PulseComponent');

            if (!pulseComponent || !renderComponent || (pulseComponent.justDeactivated && !pulseComponent.active)){
                if(renderComponent.mesh.scale.x !== pulseComponent.normalScale || 
                    renderComponent.mesh.scale.y !== pulseComponent.normalScale || 
                    renderComponent.mesh.scale.z !== pulseComponent.normalScale ||
                    renderComponent.mesh.material.color.r !== pulseComponent.normalColor.r ||
                    renderComponent.mesh.material.color.g !== pulseComponent.normalColor.g ||
                    renderComponent.mesh.material.color.b !== pulseComponent.normalColor.b ||
                    renderComponent.mesh.material.opacity !== 1){

                        renderComponent.mesh.scale.set(pulseComponent.normalScale, pulseComponent.normalScale, pulseComponent.normalScale);
                        renderComponent.mesh.material.color.copy(pulseComponent.normalColor);
                        renderComponent.mesh.material.opacity = 1;
                }
                return;
            }

            if (!pulseComponent.active) {
                pulseComponent.justDeactivated = true; // Mark for transition to standard state
                return;
            } else {
                pulseComponent.justDeactivated = false; // Reset if active
                this.updatePulse(pulseComponent, deltaTime);
                if (pulseComponent.triggerAtHover && !renderComponent.isHovered) return;
            }
            
            this.updatePulse(pulseComponent, deltaTime);
            this.updateScaleAndColor(renderComponent, pulseComponent, deltaTime);
        });
    }

    updatePulse(pulseComponent, deltaTime) {
        if (pulseComponent.pulseType === PulseTypes.PAUSE && pulseComponent.isPaused) {
            pulseComponent.pauseElapsed += deltaTime;
            if (pulseComponent.pauseElapsed >= pulseComponent.pauseDuration) {
                pulseComponent.isPaused = false;
                pulseComponent.pauseElapsed = 0;
                pulseComponent.currentTime = 0; // Reset currentTime at the end of pause
            }
            return; // Skip updating during pause
        }

        const prevTime = pulseComponent.currentTime;
        pulseComponent.currentTime += deltaTime * pulseComponent.pulseSpeed;

        if (pulseComponent.currentTime >= 1) {
            pulseComponent.currentTime = 0;
            if (pulseComponent.pulseType === PulseTypes.PAUSE && prevTime < 1) {
                pulseComponent.isPaused = true;
            }
        }
    }


    updateScaleAndColor(renderComponent, pulseComponent) {
        const totalCycle = 1; // Full cycle duration
        const thirdCycle = totalCycle / 3;
        let cyclePoint = pulseComponent.currentTime * totalCycle; // 0 to 1 full cycle
    
        let scale;
        if (cyclePoint < thirdCycle) {
            // First third: normal to min
            scale = this.interpolate(pulseComponent.normalScale, pulseComponent.minScale, cyclePoint / thirdCycle);
            this.tempColor.lerpColors(pulseComponent.normalColor, pulseComponent.minColor, cyclePoint / thirdCycle);
        } else if (cyclePoint < 2 * thirdCycle) {
            // Second third: min to max
            cyclePoint -= thirdCycle;
            scale = this.interpolate(pulseComponent.minScale, pulseComponent.maxScale, cyclePoint / thirdCycle);
            this.tempColor.lerpColors(pulseComponent.minColor, pulseComponent.maxColor, cyclePoint / thirdCycle);
        } else {
            // Final third: max back down to normal
            cyclePoint -= 2 * thirdCycle;
            scale = this.interpolate(pulseComponent.maxScale, pulseComponent.normalScale, cyclePoint / thirdCycle);
            this.tempColor.lerpColors(pulseComponent.maxColor, pulseComponent.normalColor, cyclePoint / thirdCycle);
        }
    
        // Apply scale
        if(pulseComponent.animateScale){
            renderComponent.mesh.scale.set(scale, scale, scale);
        }

        // Apply color
        if(pulseComponent.animateColor){
            renderComponent.mesh.material.color.copy(this.tempColor);
            renderComponent.mesh.material.opacity = THREE.MathUtils.lerp(pulseComponent.minOpacity, pulseComponent.maxOpacity, cyclePoint / thirdCycle);
        }
    }
    

    interpolate(start, end, factor) {
        return start + (end - start) * factor;
    }
}
