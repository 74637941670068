import * as TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { movementDataFrequency } from '../utils/constants';

const DEFAULT_EASING = TWEEN.Easing.Linear.None;
const MAX_DURATION = 1 * movementDataFrequency; // in milliseconds

// Pre-allocated objects to avoid repeated creation
const yAxis = new THREE.Vector3(0, 1, 0);
const alignedTargetQuaternion = new THREE.Quaternion();

// Initialize tween pools for position and rotation
const positionTweens = [];
const rotationTweens = [];

for (let i = 0; i < 20; i++) {
    const tempVector = new THREE.Vector3();
    const tempQuaternion = new THREE.Quaternion();
    const positionTween = new TWEEN.Tween(tempVector);
    const rotationTween = new TWEEN.Tween(tempQuaternion);

    positionTweens.push({tween: positionTween, position: tempVector, owner: null});
    rotationTweens.push({tween: rotationTween, quaternion: tempQuaternion, owner: null});
}

export function releaseTween(playerId) {
    for (let i = 0; i < positionTweens.length; i++) {
        if (positionTweens[i].owner === playerId) {
            positionTweens[i].owner = null;
        }

        // Check for unique id's for VR controllers. They start with the playerId then a "___" separator and then the controller id
        if (positionTweens[i].owner && positionTweens[i].owner.startsWith(playerId + "___")) {
            positionTweens[i].owner = null;
        }
    }
    for (let i = 0; i < rotationTweens.length; i++) {
        if (rotationTweens[i].owner === playerId) {
            rotationTweens[i].owner = null;
        }

        // Check for unique id's for VR controllers. They start with the playerId then a "___" separator and then the controller id
        if (rotationTweens[i].owner && rotationTweens[i].owner.startsWith(playerId + "___")) {
            rotationTweens[i].owner = null;
        }
    }
}

function getAvailableTween(tweensPool, playerId) {
    let availableTween = null;
    for (let i = 0; i < tweensPool.length; i++) {
        if (tweensPool[i].owner) {
            if (tweensPool[i].owner === playerId) {
                availableTween = tweensPool[i];
                break;
            }
        }
    }

    if (!availableTween) {
        for (let i = 0; i < tweensPool.length; i++) {
            if (!tweensPool[i].owner) {
                availableTween = tweensPool[i];
                availableTween.owner = playerId;
                break;
            }
        }
    }

    if (!availableTween) {
        availableTween = tweensPool[0];
    }

    return availableTween;

}

export function interpolatePosition(object, targetPosition, playerId, maxDuration = MAX_DURATION) {
    if (!object || !object.visible) {
        return;
    }
    const distance = object.position.distanceTo(targetPosition);
    const duration = Math.min(Math.sqrt(distance) * movementDataFrequency, maxDuration); // Nonlinear relationship

    const tweenObject = getAvailableTween(positionTweens, playerId);
    const tween = tweenObject.tween;

    if (tween) {
        if(tween.isPlaying()) tween.stop();
            tween.to(targetPosition, duration)
                .easing(DEFAULT_EASING)
                .onUpdate(() => {
                    object.position.copy(tweenObject.position);
                })
                .start(undefined, true);
        
    }
}

export function interpolateRotation(object, targetQuaternion, playerId, duration = movementDataFrequency, rotateYOnly = false) {

    // Check if the mesh exists and is visible
    if (!object || !object.visible) {
        return; // Exit the function if the mesh is not valid or visible
    }

    targetQuaternion.normalize();  // Normalize the target quaternion

    if (rotateYOnly) {
        // Extract the angle of rotation around the Y axis
        const angle = 2 * Math.acos(targetQuaternion.w);
        const sinHalfAngle = Math.sqrt(1 - targetQuaternion.w * targetQuaternion.w);
        const yComponent = sinHalfAngle === 0 ? 0 : targetQuaternion.y / sinHalfAngle;

        // Construct a new quaternion that represents rotation around the Y axis
        alignedTargetQuaternion.setFromAxisAngle(yAxis, angle * yComponent);
    } else {
        alignedTargetQuaternion.copy(targetQuaternion);
    }

    const dot = object.quaternion.dot(alignedTargetQuaternion);
    if (dot < 0) {
        alignedTargetQuaternion.set(-alignedTargetQuaternion.x, -alignedTargetQuaternion.y, -alignedTargetQuaternion.z, -alignedTargetQuaternion.w);
    }

    const tweenObject = getAvailableTween(rotationTweens, playerId);
    const tween = tweenObject.tween;

    if(tween) {
        if(tween.isPlaying()) tween.stop();
        if (!object.quaternion.equals(alignedTargetQuaternion)) {
            tween
                .to({ 
                    x: alignedTargetQuaternion.x, 
                    y: alignedTargetQuaternion.y, 
                    z: alignedTargetQuaternion.z, 
                    w: alignedTargetQuaternion.w 
                }, duration)
                .interpolation(TWEEN.Interpolation.Slerp)
                .easing(DEFAULT_EASING)
                .onUpdate(() => {
                    object.quaternion.set(tweenObject.quaternion.x, tweenObject.quaternion.y, tweenObject.quaternion.z, tweenObject.quaternion.w);
                })
                .start(undefined, true);
        }
    }
}