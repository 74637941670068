import { BaseEntity } from './baseEntity.js';
import { WindmillComponent } from '../ECS-components/windmillComponent.js';
import { RenderComponent } from '../ECS-components/renderComponent.js';
import { PositionComponent } from '../ECS-components/positionComponent.js';

export class WindmillEntity extends BaseEntity {
    constructor(id, speed, worldPositionX, worldPositionY, worldPositionZ, mesh) {
        super(id);

        const windmillComponent = new WindmillComponent(speed);
        this.addComponent(windmillComponent);

        const renderComponent = new RenderComponent(mesh);
        this.addComponent(renderComponent);

        const positionComponent = new PositionComponent(worldPositionX, worldPositionY, worldPositionZ);
        this.addComponent(positionComponent);

    }
}

