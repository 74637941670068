import { Vector3 } from 'three';
import { HIGHLIGHT_COLOR } from '../utils/constants.js';
import { objectEvents } from '../utils/objectEventTarget.js';
import { isAdminMode } from '../core/sharedState.js';
import { entityManager } from '../ECS-utils/managerECS.js';

export let focusPoint = new Vector3(0, 0, 0);
let originalMaterial = null;
let highlightMaterial = null;

export function resetHoveredObject(object) {

    //quiz answer option reset
    if(object && object.parent && object.parent.userData && object.parent.userData.quizAnswerOption){
        objectEvents.resetObjectQuizAnswerOption(object);   
    }

    //normal object reset
    if (object && object.material && object.material.color) {

        setRenderComponentHoverState(object, false);

        objectEvents.resetObject(object);    
        if(isAdminMode){
            return;
        }

        if(object.userData && object.userData.highlightMaterial){
            object.material = originalMaterial
            highlightMaterial.dispose();
        }
    }
}

export function setNewHoveredObject(object) {

    //get focus point which is worldposition of the object
    focusPoint = object.getWorldPosition(new Vector3());

    //quiz answer option hower
    if(object && object.parent && object.parent.userData && object.parent.userData.quizAnswerOption ||
        object && object.parent && object.parent.parent && object.parent.parent.use){
        objectEvents.hoverObjectQuizAnswerOption(object);   
    }
    

    //normal object hower
    if (object && object.material && object.material.color) {     
        objectEvents.hoverObject(object);

        setRenderComponentHoverState(object, true);

        if(isAdminMode){
            return;
        }

        if(object.userData && object.userData.highlightMaterial){
            highlightMaterial = object.material.clone();
            highlightMaterial.color.set(HIGHLIGHT_COLOR);
            originalMaterial = object.material;
            object.material = highlightMaterial;
        }
    }
}

function setRenderComponentHoverState(object, hoverState) {
    
    const entity = entityManager.findEntityByMeshAndRequiredComponents(object, ['RenderComponent', 'PulseComponent']);
    if (!entity) return;
    const renderComponent = entity.getComponent('RenderComponent');
    if (!renderComponent) return;
    renderComponent.isHovered = hoverState;
}
