import { Tween } from '@tweenjs/tween.js';

export class LineAnimationManager {
    constructor() {
        this.activeLineTween = null;
    }

    animateLine(line, startPosition, endPosition, duration, onComplete = () => {}) {
        if (this.activeLineTween) {
            this.activeLineTween.stop();
        }

        this.activeLineTween = new Tween({ t: 0 })
            .to({ t: 1 }, duration)
            .onUpdate(function (object) {
                const currentPosition = startPosition.clone().lerp(endPosition, object.t);
                line.geometry.setFromPoints([startPosition, currentPosition]);
                line.geometry.computeBoundingSphere();
            })
            .onComplete(onComplete)
            .start();
    }

    stopCurrentAnimation() {
        if (this.activeLineTween) {
            this.activeLineTween.stop();
        }
    }

    hideLine(line, startPosition, endPosition, duration, onHideComplete = null) {
        if (this.activeLineTween) {
            this.activeLineTween.stop();
        }
    
        this.activeLineTween = new Tween({ t: 0 })
            .to({ t: 1 }, duration)
            .onUpdate(function (object) {
                const currentPosition = startPosition.clone().lerp(endPosition, object.t);
                line.geometry.setFromPoints([endPosition, currentPosition]);
                line.geometry.computeBoundingSphere();
            })
            .onComplete(() => {
                line.visible = false;
                if(onHideComplete) onHideComplete();
            })
            .start();
    }
}
