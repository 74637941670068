export class SoundComponent {
    constructor(options) {
        this.type = 'SoundComponent';
        this.name = options.audioName || 'default';
        this.volume = options.volume || 1.0;
        this.loop = options.loop || false;
        this.playAtStart = options.playAtStart || false;
        this.positional = options.positional || false;
        this.attenuation = options.attenuation || 1.0;
        this.object = options.object || null; // Reference to the THREE object that the sound is associated with
        this.sound = null; // This will be set when the sound is loaded
        this.initialized = false;
    }
}
