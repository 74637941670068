import { auth, db } from './firebaseSetup.js';
import { setAdminMode } from '../core/sharedState.js';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut
} from "firebase/auth";
import { entityManager, playerSystem, systemManager } from '../ECS-utils/managerECS.js';
import { doc, setDoc, getDoc, getDocs, collection, updateDoc, deleteDoc } from "firebase/firestore";
import notificationManager from '../ui/notificationManager.js';
import { hideSpinner, showSpinner } from '../ui/spinner.js';

let nextSuccesfulLoginUnlocksThisObject = null;

export function setNextSuccesfulLoginUnlocksThisObject(object) {
    nextSuccesfulLoginUnlocksThisObject = object;
}

// Function to create a user (admin or normal)
export async function createUser(email, password, type) {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const uid = userCredential.user.uid;

        const userRef = doc(db, 'users', uid);
        await setDoc(userRef, {
            uid: uid,
            username: email,
            userType: type
        });

        console.log(`User created with UID: ${uid}`);
    } catch (error) {
        console.error(`Error creating user: ${error.message}`);
        throw error;
    }
}

export function logInWithEmail(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
}

export function logOut() {
    return signOut(auth);
}

export function getCurrentUser() {
    return auth.currentUser;
}

// Function to get all users from Firestore
export async function getUsers() {
    try {
        const usersCol = collection(db, 'users');
        const userSnapshot = await getDocs(usersCol);
        const userList = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        return userList;
    } catch (error) {
        console.error(`Error fetching users: ${error.message}`);
        throw error;
    }
}

// Function to delete a user from Firestore
export async function deleteUser(userId) {
    try {
        const userRef = doc(db, 'users', userId);
        await deleteDoc(userRef);
        notificationManager.showNotification("User deleted successfully!", 'info', 3000, 'center', 8);
        console.log(`User with ID: ${userId} deleted successfully`);
    } catch (error) {
        console.error(`Error deleting user: ${error.message}`);
        throw error;
    }
}

// Function to update a user in Firestore
export async function updateUser(user) {
    try {
        const userRef = doc(db, 'users', user.id);
        await updateDoc(userRef, {
            username: user.username,
            userType: user.userType
            // Add other user fields here as necessary
        });
        notificationManager.showNotification("User updated successfully!", 'info', 3000, 'center', 8);
        console.log(`User with ID: ${user.id} updated successfully`);
    } catch (error) {
        console.error(`Error updating user: ${error.message}`);
        throw error;
    }
}

export function handleLogin(emailProvided = null, passwordProvided = null, callback = null) {
    let email, password;

    if (nextSuccesfulLoginUnlocksThisObject) {

        if(!emailProvided || !passwordProvided){
            email = document.getElementById('normal-username').value;
            password = document.getElementById('normal-password').value;
        } else {
            email = emailProvided;
            password = passwordProvided;
        }

    } else {
        email = document.getElementById('admin-username').value;
        password = document.getElementById('admin-password').value;
    }

    logInWithEmail(email, password)
        .then(async (userCredential) => {
            const user = userCredential.user;
            console.log(`Logged in as: ${user.email}`);
            
            // Fetch user type from Firestore
            const userRef = doc(db, 'users', user.uid);
            const userDoc = await getDoc(userRef);

            if (userDoc.exists()) {
                const userType = userDoc.data().userType;

                // Store the user type in the player component
                playerSystem.updatePlayerProperty('userType', userType);

                // Check if the user is an admin
                if (userType === 'admin' || nextSuccesfulLoginUnlocksThisObject) {
                    if (nextSuccesfulLoginUnlocksThisObject) {
                        const gatedEntity = entityManager.findEntityByMeshAndRequiredComponents(nextSuccesfulLoginUnlocksThisObject, ['InteractionComponent', 'RenderComponent']);

                        if(gatedEntity) {
                            const interactionComponent = gatedEntity.getComponent('InteractionComponent');

                            if(interactionComponent) {
                                // Unlock the object and reset the nextSuccesfulLoginUnlocksThisObject to null
                                nextSuccesfulLoginUnlocksThisObject = null;
                                systemManager.emitEvent('objectUnlocked', gatedEntity);
                            }
                        }

                    } else {
                        document.getElementById('login-popup-window').style.display = 'block';
                        document.getElementById('login-admin-container').style.display = 'none';
                        document.getElementById('logout-admin-container').style.display = 'block';
                        systemManager.emitEvent('initializeAdminMode');
                        setAdminMode(true);
                    }
                } else {
                    throw new Error('Only admin users can access this area');
                }
            } else {
                throw new Error('User type not found');
            }
        })
        .catch((error) => {
            console.error(`Error: ${error.message}`);
            alert('Error logging in: ' + error.message);
            if (callback) {
                callback(error.message);
            }
        });
}

export function handleLogout() {
    logOut()
        .then(() => {
            document.getElementById('logout-admin-container').style.display = 'none';
            document.getElementById('login-admin-container').style.display = 'block';
            setAdminMode(false);
        })
        .catch((error) => {
            console.error(`Error logging out: ${error.message}`);
        });
}

export async function handleCreateUser(email, password, userType) {
    return new Promise(async (resolve, reject) => {
        // Show a spinner while the user is being created
        showSpinner(document.body);
        document.getElementById('lock-backdrop').style.display = 'block';

        try {
            await createUser(email, password, userType);
            // Hide spinner and show success notification
            hideSpinner(document.body);
            notificationManager.showNotification("User created successfully!", 'info', 3000, 'center', 8);
            resolve(); // Resolve the promise
        } catch (error) {
            console.error('Error creating user:', error);
            hideSpinner(document.body);
            document.getElementById('lock-backdrop').style.display = 'none';
            alert('Error creating user: ' + error.message);
            reject(error); // Reject the promise
        }
    });
}
