import { BaseEntity } from './baseEntity.js';
import { RenderComponent } from '../ECS-components/renderComponent.js';
import { PulseComponent } from '../ECS-components/pulseComponent.js';

export class PulseEntity extends BaseEntity {
    constructor(id, options) {
        super(id);

        const renderComponent = new RenderComponent(options.object);
        this.addComponent(renderComponent);

        const pulseComponent = new PulseComponent(options);
        this.addComponent(pulseComponent);
    }
}


