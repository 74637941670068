
import { System } from './system.js';
import * as THREE from 'three';

export class WindmillSystem extends System {
    constructor(entityManager, windSystem, systemManager) {
        super(entityManager, systemManager);
        this.requiredComponents = ['WindmillComponent', 'PositionComponent'];
        this.windSystem = windSystem;
        this.rotationAxis = new THREE.Vector3(0, 0, 1);
    }

    update(deltaTime) {
        const entities = this.getEntities();

        entities.forEach(entity => {
            const windmillComponent = entity.getComponent('WindmillComponent');

            // Initialize lastUpdated and lastWind if they don't exist
            if (windmillComponent.lastUpdated === undefined) {
                windmillComponent.lastUpdated = Math.random();
                windmillComponent.lastWind = new THREE.Vector3(0, 0, 0);
            }

            windmillComponent.lastUpdated += deltaTime;

            if (windmillComponent.lastUpdated >= 1.0) {
                windmillComponent.lastUpdated -= 1.0;  // Cycle the lastUpdated time

                const positionComponent = entity.getComponent('PositionComponent');
                const wind = this.windSystem.getWindAt(positionComponent.x, positionComponent.y);
                
                if (wind) {
                    windmillComponent.lastWind.copy(wind);
                }
            }

            const renderComponent = entity.getComponent('RenderComponent');
            const rotationAmount = windmillComponent.speed * windmillComponent.lastWind.x * deltaTime;
            renderComponent.mesh.rotateOnAxis(this.rotationAxis, rotationAmount);
        });
    }
    
}
