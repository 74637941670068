import { entityManager } from "../ECS-utils/managerECS";
import { sendDataToServer } from "../multiplayer/multiplayerSetup";
import { isMultiplayerSession } from "../multiplayer/sessionManager";

export class StateMachine {
  constructor(mixer) {
    this.mixer = mixer;
    this.states = {};
    this.currentState = null;
    this.intialStateName = null;
    this.lastTransitionTime = 0;
    this.transitionCooldown = 2000; // 1 second cooldown
    this.rootMesh = null;
  }

  addState(name, state) {
    this.mixer ? (this.states[name] = state, state.createOrReuseAction(this.mixer)) : console.error('Mixer not initialized.');
  }

  getStateByName(name) {
    return this.states[name];
  }

  evaluateTransitions(entity) {
    const currentTime = Date.now();
    if (!this.currentState || currentTime - this.lastTransitionTime < this.transitionCooldown) {
      return;
    }
    for (const [toStateName, transitionObj] of this.currentState.transitions) {
      const { conditionFn, allowedOriginStates } = transitionObj;
      // Check if the transition's condition is met and, if specified, the current state is in the list of allowed origin states
      if (conditionFn(entity) && (allowedOriginStates.length === 0 || allowedOriginStates.includes(this.currentState.name))) {

        //remove the unique id from the toStateName
        const cleanToStateName = toStateName.split("_")[0];
        this.setState(cleanToStateName);
        this.lastTransitionTime = currentTime;
        break;
      }
    }
  }

  setState(name, calledFromServer = false) {
    const nextState = this.getStateByName(name);

    if (!nextState) {
      console.error(`Next state is undefined: ${name}`);
      if (this.currentState) {
        console.warn(`Falling back to current state: ${this.currentState.name}`);
        this.currentState.enter(this.mixer);
      }
      return;
    }

    // If the next state is in playOnceMode, play it once and dont break the current state
    if(nextState.playOnceMode) {
      this.transitionCooldown = 10; // 10ms cooldown as this state is often for rapid and repeated animations (like a hit animation)
      nextState.playActionOnce();
      return;
    } 
    
    const nextAction = nextState.action || this.mixer.clipAction(nextState.clip);
  
    if (this.currentState) {
      this.previousAction = this.currentState.action; // Store the current action as previousAction
      this.currentState.exit(nextAction);
      this.transitionCooldown = 1000; // 1000ms cooldown as this is the default for most animations
    }
    nextState.enter(this.mixer);
    this.currentState = nextState;

    if(isMultiplayerSession && !calledFromServer){
      const interactableEntity = entityManager.findEntityByMeshAndRequiredComponents(this.rootMesh, ['ItemComponent', 'RenderComponent', 'StatusComponent', 'InteractionComponent']);
      if(interactableEntity) {

        const itemComponent = interactableEntity.getComponent('ItemComponent');
        const statusComponent = interactableEntity.getComponent('StatusComponent');
        const interactionComponent = interactableEntity.getComponent('InteractionComponent');
  
        if(!itemComponent || !statusComponent || !interactionComponent) return;
  
        sendDataToServer('itemInteractionRequest', {
            itemId: itemComponent.mesh.name,
            itemInteractionType: interactionComponent.interactionType,
            itemType: itemComponent.itemType,
            itemAnimationState: name,
            itemState: statusComponent.state,
        });
        console.log('sending animation state:', this.currentState.name);
      }
    }
  }
  
  
}
